var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{directives:[{name:"ripple",rawName:"v-ripple",value:(20),expression:"20"}],staticClass:"button-action",class:{
    'icon-only': _vm.iconOnly,
    'no-icon': !_vm.icon,
    primary: _vm.primary,
    loading: _vm.loading,
    success: _vm.success,
    narrow: _vm.narrow,
    small: _vm.small,
    'extra-small': _vm.extraSmall,
    'warning-hover': _vm.warningHover,
    'warning-hover-light': _vm.warningHoverLight,
    warning: _vm.warning,
    disabled: _vm.disabled,
    hovered: _vm.hovered,
    enabled: _vm.enabled,
    'no-background': _vm.noBackground,
    'save-cancel': _vm.saveCancel,
    submenu: _vm.submenu
  },on:{"click":_vm.handleClick}},[_c('transition',{attrs:{"name":"button-action-loading","mode":"out-in"}},[(!_vm.internalLoading && !_vm.success)?_c('div',{key:"content",staticClass:"content"},[(_vm.icon)?_c('transition',{attrs:{"name":"button-action-loading","mode":"out-in"}},[_c('div',{key:_vm.icon,staticClass:"icon"},[_c('i',{class:`${_vm.iconPack} ${_vm.iconPack}-${_vm.icon}`}),_vm._v(" "),(_vm.submenu)?_c('i',{staticClass:"submenu las la-angle-down"}):_vm._e()])]):_vm._e(),_vm._v(" "),(!_vm.iconOnly)?_c('div',{staticClass:"text"},[_vm._t("default")],2):_vm._e()],1):(_vm.success)?_c('div',{key:"success",staticClass:"text-loader-wrapper"},[_c('i',{staticClass:"mi-check"})]):(!_vm.iconOnly)?_c('div',{key:"loading",staticClass:"text-loader-wrapper"},[_c('loading-text-dots')],1):_c('div',{key:"loading-small",staticClass:"text-loader-wrapper"},[_c('circular-loader',{attrs:{"small":""}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }