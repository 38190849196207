<template>
  <div class="input-base-wrapper">
    <div
      class="input-base"
      :class="{
        highlighted,
        disabled,
        dark,
        small,
        error,
        success,
        prepend: hasPrependSlot,
        append: hasAppendSlot,
        both: hasPrependSlot && hasAppendSlot,
        focused: isFocused
      }"
    >
      <transition name="value" mode="out-in">
        <div v-if="hasPrependSlot" class="icon-left">
          <transition name="value" mode="out-in">
            <slot name="prepend"></slot>
          </transition>
        </div>
      </transition>

      <div class="content" :class="{ up: isLabelUp, focused: isFocused }">
        <div class="label" :class="{ count: characterLimits }">
          <div class="label-text">
            {{ label }}
          </div>
          <character-count-text
            v-if="characterLimits"
            class="label-count"
            :limit="characterLimits"
            :length="inputTextLength"
          />
        </div>
        <div class="value">
          <transition name="value" mode="out-in">
            <slot></slot>
          </transition>
        </div>
      </div>

      <transition name="value" mode="out-in">
        <div v-if="hasAppendSlot" class="icon-right">
          <transition name="value" mode="out-in">
            <slot name="append"></slot>
          </transition>
        </div>
      </transition>
    </div>
    <error-text :error="error" />
  </div>
</template>

<script>
import ErrorText from "./ErrorText.vue";
import characterCountText from "./CharacterCountText.vue";

export default {
  components: {
    "error-text": ErrorText,
    "character-count-text": characterCountText
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    hasValue: {
      type: Boolean,
      default: false
    },
    isFocused: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    highlighted: {
      type: Boolean,
      default: false
    },
    error: {
      type: [String, Boolean],
      default: ""
    },
    success: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    characterLimits: {
      type: Number,
      default: null
    },
    inputTextLength: {
      type: Number,
      default: 0
    }
  },

  computed: {
    isLabelUp() {
      return this.hasValue || this.isFocused;
    },

    hasAppendSlot() {
      return !!this.$slots.append;
    },

    hasPrependSlot() {
      return !!this.$slots.prepend;
    }
  }
};
</script>

<style lang="sass">
.input-base
  width: 100%
  height: var(--input-height)
  padding: 0 7px
  border-radius: 4px
  box-shadow: none
  outline: none
  background-color: #fff
  color: rgba(0,0,0,1)
  transition: all 0.2s ease-in-out
  font-size: var(--font-size-small)
  border: 1px solid rgb(var(--color-white))
  font-weight: 400
  display: grid
  grid-template-columns: 1fr
  grid-gap: 7px
  align-items: center
  &.focused, &:hover
    border: 1px solid rgb(0, 0, 0, 0.5)
  &.append
    grid-template-columns: 1fr auto
  &.prepend
    grid-template-columns: auto 1fr
  &.both
    grid-template-columns: auto 1fr auto
  > *
    min-width: 0

  .content
    width: 100%
    transition: all 0.2s ease-in-out
    height: 100%
    flex: 1
    line-height: 1
    position: relative
    i
      color: rgba(0,0,0,0.5)
      transition: all 0.2s ease-in-out
      font-size: var(--font-size-large)
    .value
      margin-top: 5px
      max-width: calc(100%)
      height: 18px
      line-clamp: 1
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      transition: all 0.2s ease-in-out
    .label
      position: absolute
      top: 11px
      left: 0
      z-index: 1
      color: rgba(0,0,0,0.5)
      pointer-events: none
      font-size: var(--font-size-small)
      font-weight: 400
      width: 100%
      &.count
        display: flex
        justify-content: space-between
        width: 100%
        .label-text
          transform-origin: left top
          transition: all 0.2s ease-in-out
        .label-count
          transform-origin: right top
          transition: all 0.2s ease-in-out
          line-height: 1.25
          white-space: nowrap
          span
            padding: 0
      .label-text
          transform-origin: left top
          transition: all 0.2s ease-in-out
          width: 100%
          line-height: 1.25
          text-overflow: ellipsis
          overflow: hidden
          white-space: nowrap
    &.up
      .value
        padding-top: 12px
        height: 30px
      .label-text
        width: 120%
        transform: scale(0.8) translateY(-10px)
        color: rgba(0,0,0, .5)
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
      .label-count
        transform: scale(0.8) translateY(-10px)
        color: rgba(0,0,0, .5)
        white-space: nowrap
      &.count
        .label-count
          display: block

  // Options
  &.disabled
    pointer-events: none
    background-color: rgba(var(--color-blue-light), .05) !important
    color: rgba(0,0,0, .5) !important
    border: 1px solid rgba(0,0,0, 0) !important
    .label
      color: rgba(0,0,0, .3) !important

  &.dark
    background-color: rgba(255,255,255,0.1)
    border: 1px solid rgba(255,255,255,0.05) !important
    i
      color: rgba(255,255,255,0.8) !important
    .content
      .label-text
        color: rgba(255,255,255,0.8) !important
      .value
        color: rgba(255,255,255,1) !important
    &.error
      background-color: rgba(var(--color-warning), .05)
      border: 1px solid rgba(var(--color-warning), .3)
    &.disabled
      background-color: rgba(255,255,255,0.05) !important
      border: 1px solid rgba(255,255,255,0.05) !important
    &.success
      background-color: rgba(var(--color-success), .05)
      border: 1px solid rgba(var(--color-success), .3)

  &.small
    height: 32px
    .label
      top: 8px !important
      font-size: var(--font-size-regular) !important
    .content.up
      .label-text
        transform: scale(0.75) translateY(-8px) !important
      .value
        padding-top: 9px !important
        height: 25px !important

  &.error
    background-color: rgba(var(--color-warning), .05)
    border: 1px solid rgba(var(--color-warning), .3) !important
    animation: shake 0.52s cubic-bezier(.36,.07,.19,.97) both .32s
    .content
      i
        color: rgba(var(--color-warning), 1) !important
      .label
        color: rgba(var(--color-warning), 1) !important
        opacity: 1 !important

  &.success
    background-color: rgba(var(--color-success), .05)
    border: 1px solid rgba(var(--color-success), .3)
    .content
      i
        color: rgba(var(--color-success), 1) !important
      .label
        color: rgba(var(--color-success), 1) !important
        opacity: 1 !important

  &.highlighted
    background-color: rgba(var(--color-blue-light), .05)
    border: 1px solid rgba(var(--color-blue), .36) !important
    .content
      i
        color: rgba(var(--color-blue), 1) !important

.value-enter-active, .value-leave-active
  transition: all .2s ease-in-out

.value-enter
  transform: translateY(5px)
  opacity: 0
.value-leave-to
  transform: translateY(5px)
  opacity: 0
</style>
