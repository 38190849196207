import Vue from "vue";
import qs from "qs";
export const state = () => ({
  PAGE_WITH_ALL_SECURITIES: Object.freeze([
    "change",
    "tablet-change",
    "entity",
    "tablet-entity"
  ]),

  VALID_FILE_TYPES: {
    image: ["bmp", "png", "gif", "jpe", "jpeg", "jpg"],
    video: [
      "mp4",
      "mov",
      "m4v",
      "webm"
      // "wmv", //wmv not support
      // "avi" //avi not support
    ],
    audio: [
      "mp3",
      "wav",
      "m4a"
      // "aiff" //aiff not support
    ],
    other: [
      "doc",
      "docx",
      "dot",
      "dotx",
      "pdf",
      "rtf",
      "html",
      "htm",
      "txt",
      "xml",
      "csv",
      "xls",
      "xlsm",
      "xlsx",
      "ppt",
      "pptx",
      "pptm"
      //"xla",
    ]
  },
  securityList: [], //This one is for change page
  sectorList: [],
  exchangeList: [],
  subSectorList: [],
  descriptorList: [],
  priceSources: [
    {
      id: "0",
      name: "Manual",
      internal_name: "manual"
    },
    {
      id: "1",
      name: "API",
      internal_name: "api"
    }
    // {
    //     id: "2",
    //     name: "XML",
    //     internal_name: "xml",
    // },
  ],
  decimalOptions: [
    {
      id: "0",
      name: "2 decimals",
      internal_name: 2
    },
    {
      id: "1",
      name: "3 decimals",
      internal_name: 3
    }
  ],
  priceList: [],
  currentPrice: {},
  priceMetrics: [
    {
      key: "security_price_created_at",
      label: "Date (UTC)",
      sortable: false
    },
    {
      key: "security_price",
      label: "Price",
      sortable: false
    },
    {
      key: "security_targetprice",
      label: "Target price",
      sortable: false
    },
    {
      key: "recommendation_value",
      label: "Investment rating",
      sortable: false
    }
  ],
  currentSecurityDetail: {},
  selectedSecurityMetrics: [],
  selectedSecurityList: [], // might need to remove
  loadedSecurityList: [],
  referenceList: [],
  referenceCount: 0,
  overviewList: [],
  securityStats: {},
  noteList: [],
  totalNoteCount: 0,
  filteredSecurities: [],
  securityLoading: false,
  securitySorting: false,
  publishedReports: [],
  publishedReportCount: 0,
  ratingList: [],
  recommendationList: [],
  esgRatingList: [],
  securitySortOptions: {
    sort_by: "security_name",
    sort_type: "asc"
  },
  securitySelectionOptions: {
    type: "all",
    exclude: []
  },
  // totalSecuritiesList: [],
  securityStatusList: [
    {
      id: "1",
      name: "Active",
      internal_name: "active"
    },
    {
      id: "2",
      name: "Inactive",
      internal_name: "inactive"
    },
    {
      id: "3",
      name: "Restricted",
      internal_name: "restricted"
    }
  ],
  securityLayoutList: [
    {
      id: "1",
      name: "Regular",
      internal_name: "regular"
    },
    {
      id: "2",
      name: "Condensed",
      internal_name: "condensed"
    }
  ],
  allSecurityStatusList: [
    {
      id: "0",
      name: "All"
    },
    {
      id: "1",
      name: "Active",
      internal_name: "active"
    },
    {
      id: "2",
      name: "Inactive",
      internal_name: "inactive"
    },
    {
      id: "3",
      name: "Restricted",
      internal_name: "restricted"
    }
  ],
  PRICE_JOB_ID: "price-job-id"
});
export const getters = {
  getMetricNames: (state) =>
    state.selectedSecurityMetrics.map(
      (tmpSecurityMetric) => tmpSecurityMetric.internal_name
    ),
  getDefaultSecurityMetricSelectionList: (state, getters, rootState) => {
    let selectedMetrics = new Array(state.selectedSecurityMetrics.length);
    const unselectedMetric = [];
    for (const tmpMetric of rootState.filter.securityMetrics) {
      const newBoostrapMultiSelectObj = {
        value: tmpMetric,
        text: `${tmpMetric.name}`
      };
      const selectedIndex = state.selectedSecurityMetrics.findIndex(
        (defaultSelectedMetric) => defaultSelectedMetric === tmpMetric.id
      );
      if (
        selectedIndex >= 0 &&
        unselectedMetric.findIndex(
          (checkMetric) =>
            checkMetric.value.internal_name === tmpMetric.internal_name
        ) < 0
      ) {
        // if its in linked list
        if (newBoostrapMultiSelectObj) {
          selectedMetrics.splice(selectedIndex, 1, newBoostrapMultiSelectObj);
        }
      } else {
        if (newBoostrapMultiSelectObj) {
          unselectedMetric.push(newBoostrapMultiSelectObj);
        }
      }
    }
    selectedMetrics = selectedMetrics.filter((it) => {
      return it !== undefined;
    });
    return {
      selectedMetrics,
      unselectedMetric
    };
  },
  getBootstrapSelectOptions: () => {
    return (tmpArray) =>
      tmpArray.map((tmpSecurity) => ({
        value: {
          id: tmpSecurity.security_id,
          name: tmpSecurity.security_name,
          code: tmpSecurity.security_code,
          category: tmpSecurity.sector_name || "",
          permission:
            Object.hasOwn(tmpSecurity, "security_permission") &&
            tmpSecurity.security_permission === false
              ? false
              : true,
          active:
            Object.hasOwn(tmpSecurity, "security_status") &&
            tmpSecurity.security_status === "active"
              ? true
              : false,
          status: tmpSecurity.security_status
        },
        text: `${tmpSecurity.security_code || "-"} | ${
          tmpSecurity.security_name || "-"
        }${
          (tmpSecurity.security_status === "inactive" ||
            tmpSecurity.security_status === "restricted") &&
          (!tmpSecurity.security_name ||
            tmpSecurity.security_name[tmpSecurity.security_name.length - 1] !==
              "*")
            ? "*"
            : ""
        }`
      }));
  },
  getDefaultSecuritySelectionList: (state) => {
    const selectedSecurity = [];
    const unselectedSecurity = [];
    switch (state.securitySelectionOptions.type) {
      case "none":
        // for (const tmpSecurity of state.filterSecurities) {
        for (const tmpSecurity of state.securityList) {
          const selectedIndex =
            state.securitySelectionOptions.exclude.findIndex(
              (checkSecurity) => checkSecurity === tmpSecurity.value.id
            );
          if (selectedIndex >= 0) {
            selectedSecurity.push(tmpSecurity);
          } else {
            unselectedSecurity.push(tmpSecurity);
          }
        }
        break;
      default:
        // for (const tmpSecurity of state.filterSecurities) {
        for (const tmpSecurity of state.securityList) {
          const selectedIndex =
            state.securitySelectionOptions.exclude.findIndex(
              (checkSecurity) => checkSecurity === tmpSecurity.value.id
            );
          if (selectedIndex >= 0) {
            unselectedSecurity.push(tmpSecurity);
          } else {
            selectedSecurity.push(tmpSecurity);
          }
        }
        break;
    }
    return {
      selectedSecurity,
      unselectedSecurity
    };
  },
  pageRequireAllSecurities(state) {
    return (routeToCheck) =>
      state.PAGE_WITH_ALL_SECURITIES.includes(routeToCheck);
  }
};
export const mutations = {
  updateSecurityList(state, arrayList) {
    Vue.set(state, "securityList", arrayList);
  },
  updateSecurityFilteredList(state, arrayList) {
    Vue.set(state, "filteredSecurities", arrayList);
  },
  /* cant see being used
  updateTotalSecuritiesList(state, arrayList) {
    Vue.set(state, "totalSecuritiesList", arrayList);
  },
  */
  //Update Exchange
  updateExchangeList(state, arrayList) {
    if (!arrayList.find((exObj) => exObj.exchange_name === "All")) {
      arrayList = [
        {
          exchange_id: 0,
          exchange_name: "All",
          exchange_order: 0
        },
        ...arrayList
      ];
    }
    Vue.set(state, "exchangeList", arrayList);
  },
  updateDescriptorList(state, arrayList) {
    let newArrayList = [];
    arrayList.map((descriptor) => {
      newArrayList.push({
        descriptor_id: descriptor.descriptor_id,
        descriptor_value: descriptor.descriptor_value,
        descriptor_order: descriptor.descriptor_order
      });
    });
    if (!newArrayList.find((obj) => obj.descriptor_value === "All")) {
      newArrayList = [
        {
          descriptor_id: 0,
          descriptor_value: "All",
          descriptor_order: 0
        },
        ...newArrayList
      ];
    }

    Vue.set(state, "descriptorList", newArrayList);
  },
  updateSectorList(state, arrayList) {
    if (!arrayList.find((sectorObj) => sectorObj.sector_name === "All")) {
      arrayList = [
        {
          sector_id: 0,
          sector_name: "All",
          sector_order: "0",
          name: "All"
        },
        ...arrayList
      ];
    }
    Vue.set(state, "sectorList", arrayList);
  },
  updateSubsectorList(state, arrayList) {
    Vue.set(state, "subSectorList", arrayList);
  },
  updatePriceList(state, arrayList) {
    Vue.set(state, "priceList", arrayList);
  },
  updateCurrentPrice(state, value) {
    Vue.set(state, "currentPrice", value);
  },
  updateSecurityStats(state, arrayList) {
    Vue.set(state, "securityStats", arrayList);
  },
  addCurrentSecurityDetail(state, oneSecurity) {
    Vue.set(state, "currentSecurityDetail", oneSecurity);
  },
  updateCurrentSecurityDetail(state, newDetails) {
    for (const key in newDetails) {
      Vue.set(state.currentSecurityDetail, key, newDetails[key]);
    }
  },
  updateSelectedSecurityMetrics(state, newList) {
    Vue.set(state, "selectedSecurityMetrics", newList);
  },
  updateSelectedSecurityList(state, newList) {
    Vue.set(state, "selectedSecurityList", newList);
  },
  updateLoadedSecurityList(state, newList) {
    Vue.set(state, "loadedSecurityList", newList);
  },
  updateReferenceListOrder(state, arrayList) {
    Vue.set(state, "referenceList", arrayList);
  },
  updateReferenceList(state, arrayList) {
    Vue.set(state, "referenceList", [
      ...state.referenceList,
      ...arrayList.map((object) => ({
        referenceId: object.security_reference_id,
        referenceTitle: object.security_reference_title,
        createDate: object.security_reference_created_at,
        referenceImg: object.security_reference_thumbnail_storage,
        referenceOrder: object.security_reference_order
      }))
    ]);
  },
  updateReferences(state, arrayList) {
    Vue.set(state, "referenceList", [
      ...arrayList.map((object) => {
        const referenceFiletype =
          object.reference_original_file_name.match(/\.([^/.]+)$/)[1];
        return {
          referenceId: object.reference_id,
          referenceTitle: object.reference_original_file_name,
          referenceImg:
            referenceFiletype === "png"
              ? object.reference_file_cdn_link
              : object.thumbnail_cdn_link,
          referenceFiletype: referenceFiletype,
          referenceLink: object.reference_file_cdn_link,
          referenceOrder: object.reference_order,
          referenceSelected: object.reference_group_selected,
          createDate: object.reference_created_at
        };
      })
    ]);
  },
  updateReferenceCount(state, count) {
    Vue.set(state, "referenceCount", count);
  },
  updateReferenceFlag(state, referenceId) {
    for (const key in state.referenceList) {
      if (state.referenceList[key].referenceId === referenceId)
        Vue.set(state.referenceList[key], "referenceSelected", true);
      else if (state.referenceList[key].referenceSelected)
        Vue.set(state.referenceList[key], "referenceSelected", false);
    }
  },
  resetReferenceList(state) {
    Vue.set(state, "referenceList", []);
  },
  updateOverview(state, arrayList) {
    Vue.set(state, "overviewList", arrayList);
  },

  updateNote(state, arrayList) {
    Vue.set(state, "noteList", arrayList);
  },
  updateTotalNoteCount(state, number) {
    Vue.set(state, "totalNoteCount", number);
  },
  removeNoteById(state, note_id) {
    var array = state.noteList.filter((it) => {
      return it.note.note_id !== note_id;
    });
    Vue.set(state, "noteList", array);
  },
  toggleSecurityLoading(state, trueOrFalse) {
    state.securityLoading = trueOrFalse;
  },
  toggleSecuritySorting(state, trueOrFalse) {
    state.securitySorting = trueOrFalse;
  },
  // updatePublishedReportsOrder(state, arrayList) {
  //   Vue.set(state, "publishedReports", arrayList);
  // },
  updatePublishedReports(state, arrayList) {
    Vue.set(state, "publishedReports", arrayList);
  },

  updatePublishedReportCount(state, count) {
    Vue.set(state, "publishedReportCount", count);
  },
  resetPublishedReports(state) {
    Vue.set(state, "publishedReports", []);
  },
  updateSavedSorted(state, { sort_by, sort_type }) {
    if (sort_by) Vue.set(state.securitySortOptions, "sort_by", sort_by);
    if (sort_type) Vue.set(state.securitySortOptions, "sort_type", sort_type);
  },
  updateSavedChanges(state, { type, exclude }) {
    Vue.set(state, "securitySelectionOptions", {
      type,
      exclude
    });
  },
  updateRatingList(state, ratingList) {
    Vue.set(state, "ratingList", ratingList);
  },
  updateRecommendationList(state, ratingList) {
    Vue.set(state, "recommendationList", ratingList);
  },
  updateEsgRatingList(state, ratingList) {
    Vue.set(state, "esgRatingList", ratingList);
  }
};
export const actions = {
  filterSecurities(
    { commit, state, rootState, getters, rootGetters },
    filterOption
  ) {
    if (state.securityLoading) return Promise.reject("list loading");
    commit("toggleSecurityLoading", true);
    filterOption.security_sort_type = "asc";
    filterOption.security_sort_by = "security_name";
    filterOption.per_page = 0;
    // filterOption.page_offset = 0;
    filterOption.limited_fields = true;
    filterOption.member_flag = true;
    return this.$axios
      .$get(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/filter`,
        {
          params: filterOption
        }
      )
      .then((r) => {
        commit(
          "updateSecurityFilteredList",
          getters.getBootstrapSelectOptions(r.data)
        );
        // }

        commit("toggleSecurityLoading", false);
        return {
          action: "accept",
          message: r.data
        };
      })
      .catch((e) => {
        switch (e.response.status) {
          case 417:
            return {
              action: "refresh token",
              message: "need to refresh token"
            };
          default:
            return {
              action: "error",
              message: e.response.data
            };
        }
      });
  },
  getSecuritiesSelectionList(
    { commit, rootState, getters },
    member_flag = false
  ) {
    return this.$axios
      .$get(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/filter`,
        {
          params: {
            per_page: 0,
            limited_fields: true,
            member_flag: member_flag
          }
        }
      )
      .then((r) => {
        const newList = getters.getBootstrapSelectOptions(r.data);
        //NOTE: use the same variable as the security page
        if (member_flag) commit("updateSecurityList", newList);
        else commit("updateSelectedSecurityList", newList);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  // todo: get
  getTotalSecuritiesList({ commit, getters, rootState }) {
    // NOTE this is different to getSecuritiesSelectionList with member_flag, because it does not get sector and is cached on the browser for 2 min
    // this is new and different to the comment out one below
    return this.$axios
      .$get(`/v2/fetch/${rootState.user.selectedShownEntity}/securities`)
      .then((r) => {
        const newList = getters.getBootstrapSelectOptions(r);
        commit("updateSecurityList", newList);
      })
      .catch((e) => {
        console.error(e);
      });
  },
  /* cant see being used
  remoteUpdateFilteredSecurities({ commit, state }) {
    commit("updateSecurityFilteredList", state.securityList);
  },
  */
  /* Cant see this being used
  getTotalSecuritiesList(
    { commit, rootState, getters, rootGetters },
    limited_fields = true
  ) {
    return this.$axios
      .$get(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/filter`,
        {
          params: {
            per_page: 0,
            limited_fields: limited_fields,
            member_flag: true,
          },
        }
      )
      .then((r) => {
        let newList;
        // if (rootGetters["user/isViewer"]) {
        //   newList = getters.getBootstrapSelectOptions(
        //     r.data.filter((it) => it.security_status.toLowerCase() === "active")
        //   );
        // } else {
        newList = getters.getBootstrapSelectOptions(r.data);
        // }
        commit("updateTotalSecuritiesList", newList);
        commit("updateSecurityFilteredList", newList);

        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  */
  remoteLoadSecurityListDetails({ commit, state, rootGetters, rootState }) {
    const perPage = 30;
    if (state.selectedSecurityList.length === 0) return false;
    const params = {
      per_page: perPage,
      limited_fields: false,
      member_flag: false,
      page_offset: state.loadedSecurityList.length
    };
    return this.$axios
      .$get(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/filter`,
        {
          params,
          paramsSerializer: (params) => qs.stringify(params)
        }
      )
      .then((r) => {
        if (!state.securitySorting || !state.securityLoading) {
          r.data.map((obj) => {
            if (obj.price_decimal_places && obj.price_decimal_places == 2) {
              if (
                obj.current_security_price &&
                obj.current_security_price !== "-" &&
                obj.current_security_price !== "None"
              ) {
                obj.current_security_price = parseFloat(
                  obj.current_security_price.replaceAll(",", "")
                )
                  .toFixed(2)
                  .toString();
              }
              if (
                obj.current_security_targetprice &&
                obj.current_security_targetprice !== "-" &&
                obj.current_security_targetprice !== "None"
              ) {
                obj.current_security_targetprice = parseFloat(
                  obj.current_security_targetprice.replaceAll(",", "")
                )
                  .toFixed(2)
                  .toString();
              }
            }
          });
          commit("updateLoadedSecurityList", [
            ...state.loadedSecurityList,
            ...r.data
          ]);
        }
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  getSecurityDescriptors({ commit, rootState }) {
    return this.$axios
      .$get(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/descriptor`
      )
      .then((r) => {
        commit("updateDescriptorList", r);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  //Create Exchange
  createSecurityDescriptor({ rootState }, descriptor) {
    return this.$axios
      .$put(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/descriptor`,
        descriptor
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        // return null;
        throw e;
      });
  },
  updateSecurityDescriptor({ rootState }, updateDetails) {
    return this.$axios
      .$patch(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/descriptor`,
        updateDetails
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        // return null;
        throw e;
      });
  },
  deleteSecurityDescriptor({ rootState }, descriptor_id) {
    return this.$axios
      .$delete(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/descriptor/${descriptor_id}`
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        throw e;
      });
  },
  getSecurityExchanges({ commit, rootState }) {
    return this.$axios
      .$get(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/exchanges`
      )
      .then((r) => {
        commit("updateExchangeList", r);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  //Create Exchange
  postSecurityExchange({ rootState }, exchange) {
    return this.$axios
      .$post(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/exchange`,
        exchange
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        // return null;
        throw e;
      });
  },
  //Update Exchange
  updateSecurityExchange({ rootState }, { exchange_id, ...updateDetails }) {
    return this.$axios
      .$put(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/exchange/${exchange_id}`,
        updateDetails
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        // return null;
        throw e;
      });
  },

  //Update a list of Exchange
  updateSecurityExchanges({ rootState }, payload) {
    return this.$axios
      .$put(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/exchanges`,
        {
          exchanges: payload
        }
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        // return null;
        throw e;
      });
  },

  //Delete Exchange
  deleteSecurityExchange({ rootState }, exchange_id) {
    return this.$axios
      .$delete(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/exchange/${exchange_id}`
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        throw e;
      });
  },
  //Get Security sectors
  getSecuritySectors({ commit, rootState }) {
    return this.$axios
      .$get(`/v1/security/entity/${rootState.user.selectedShownEntity}/sectors`)
      .then((r) => {
        commit("updateSectorList", r);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  //Create Sector
  postSecuritySector({ commit, rootState }, sector) {
    return this.$axios
      .$post(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/sector`,
        sector
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        throw e;
      });
  },
  //Update sector
  updateSecuritySector({ commit, rootState }, { sector_id, ...updateDetails }) {
    return this.$axios
      .$put(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/sector/${sector_id}`,
        updateDetails
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        throw e;
      });
  },
  //Delete sector
  deleteSecuritySector({ rootState }, sector_id) {
    return this.$axios
      .$delete(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/sector/${sector_id}`
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        throw e;
      });
  },

  //Get Security subsectors
  getSecuritySubsectors({ commit, rootState }) {
    return this.$axios
      .$get(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/subsectors`
      )
      .then((r) => {
        commit("updateSubsectorList", r);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },

  //Create Sector
  postSecuritySubsector({ rootState }, subsector) {
    return this.$axios
      .$post(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/subsector`,
        subsector
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        return null;
      });
  },
  //Update sector
  updateSecuritySubsector({ rootState }, { sub_sector_id, ...updateDetails }) {
    return this.$axios
      .$put(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/subsector/${sub_sector_id}`,
        updateDetails
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        throw e;
      });
  },
  //Delete sector
  deleteSecuritySubsector({ rootState }, subsector_id) {
    return this.$axios
      .$delete(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/subsector/${subsector_id}`
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      });
  },
  //Get prices
  getPrices({ commit, rootState }, security_id) {
    return this.$axios
      .$get(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/${security_id}/prices`,
        {
          params: {
            per_page: 0,
            limited_fields: true
          }
        }
      )
      .then((r) => {
        commit("updatePriceList", r);
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  getPricesByPagination({ commit, rootState }, { security_id, ...payload }) {
    return this.$axios
      .$get(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/${security_id}/prices`,
        {
          params: payload
        }
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  getCurrentPrice({ commit, rootState }, security_id) {
    return this.$axios
      .$get(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/${security_id}/price`
      )
      .then((r) => {
        commit("updateCurrentPrice", r);
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  //Post price
  postPrice({ commit, rootState }, { security_id, ...payload }) {
    return this.$axios
      .$post(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/${security_id}/price`,
        payload
      )
      .then((r) => {
        return {
          action: "accept"
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  updatePriceByRange({ commit, rootState }, { security_id, ...payload }) {
    return this.$axios
      .$post(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/${security_id}/prices/range-update`,
        payload
      )
      .then((r) => {
        return {
          action: "accept"
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteAddNewSecurity({ rootState }) {
    return this.$axios
      .$post(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security`,
        {}
      )
      .then((r) => {
        return {
          action: "accept",
          message: r.security_id
        };
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  },
  remoteGetSecurityById({ rootState, commit }, securityId) {
    return this.$axios
      .$get(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/${securityId}`,
        {}
      )
      .then((r) => {
        commit("addCurrentSecurityDetail", r);
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  },
  remoteUpdateCurrentSecurity(
    { commit, rootState, dispatch },
    { security_id, ...updateDetails }
  ) {
    if (!security_id)
      return {
        action: "error",
        message: "no security id"
      };
    return this.$axios
      .$put(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/${security_id}`,
        updateDetails
      )
      .then((r) => {
        commit("updateCurrentSecurityDetail", r);
        dispatch(
          "log/getSecurityActivityLog",
          { securityId: security_id },
          {
            root: true
          }
        );
        return {
          action: "accept"
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteUpdateSelectedSecurityMetrics({ commit, rootState }, newMetrics) {
    // commit("updateSelectedSecurityMetrics", payload);
    return this.$axios
      .$put(
        `/v1/member/${rootState.user.selectedShownEntity}/member_filter_list_page/security_list`,
        {
          member_filter_list_options_metrics: newMetrics.map(
            (met) => met.internal_name
          )
        }
      )
      .then((r) => {
        if (r.member_filter_list_options_metrics) {
          commit(
            "updateSelectedSecurityMetrics",
            r.member_filter_list_options_metrics
          );
        }
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteUpdateReferenceOrder({ commit }, references) {
    commit("updateReferenceListOrder", references);
  },
  remoteGetReferenceList(
    { commit, rootState },
    { security_id, ...filterOption }
  ) {
    if (!security_id)
      return {
        action: "error",
        message: "no security id"
      };
    return this.$axios
      .$get(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/${security_id}/references`,
        {
          params: {
            per_page: filterOption.per_page,
            page_offset: filterOption.page_offset
          }
        }
      )
      .then((r) => {
        if (filterOption.page_offset == 0) commit("resetReferenceList");
        if (r.references) commit("updateReferenceList", r.references);
        if (r.reference_count)
          commit("updateReferenceCount", r.reference_count);
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  async remoteGetReferences(
    { commit, rootState },
    { linkToId, linkToType, linkToGroup }
  ) {
    if (!linkToId)
      return {
        action: "error",
        message: "no link to id"
      };
    try {
      const response = await this.$axios.$get(
        `/v2/reference_file/entity/${rootState.user.selectedShownEntity}/link_to/${linkToId}/type/${linkToType}/group/${linkToGroup}`
      );
      if (response) {
        commit("updateReferences", response);
        commit("updateReferenceCount", response.length);
      }
      return {
        action: "accept",
        message: response
      };
    } catch (error) {
      console.error(error);
      return {
        action: "error",
        message: error.response.data.message
      };
    }
  },
  remoteDeleteReference({ rootState }, reference_id) {
    if (!reference_id)
      return {
        action: "error",
        message: "no reference id"
      };
    return this.$axios
      .$delete(
        `/v2/reference_file/entity/${rootState.user.selectedShownEntity}/reference/${reference_id}`
      )
      .then((r) => {
        return {
          action: "accept",
          message: "success"
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteSetReferenceFlag({ commit, rootState }, reference_id) {
    if (!reference_id)
      return {
        action: "error",
        message: "no reference id"
      };
    return this.$axios
      .$put(
        `/v2/reference_file/entity/${rootState.user.selectedShownEntity}/reference/${reference_id}/setflag`
      )
      .then((r) => {
        commit("updateReferenceFlag", reference_id);
        return {
          action: "accept",
          message: "success"
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteDownloadReference({ rootState }, reference_id) {
    if (!reference_id)
      return {
        action: "error",
        message: "no reference id"
      };
    return this.$axios
      .$get(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/reference/${reference_id}`
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteUpdateMultiReferences(
    { rootState },
    { security_id, ...updateDetails }
  ) {
    if (!security_id)
      return {
        action: "error",
        message: "no security id"
      };
    return this.$axios
      .$put(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/${security_id}/references`,
        updateDetails
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteOrderReferences({ rootState }, updatedReferences) {
    return this.$axios
      .$put(
        `/v2/reference_file/entity/${rootState.user.selectedShownEntity}/reference_order`,
        updatedReferences
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteGetSecurityStats({ commit, rootState }, security_id) {
    return this.$axios
      .$get(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/${security_id}/security-stats`
      )
      .then((r) => {
        commit("updateSecurityStats", r);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteGetOverview({ commit, rootState }, security_id) {
    if (!security_id)
      return {
        action: "error",
        message: "no reference id"
      };
    return this.$axios
      .$get(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/${security_id}/overviews`
      )
      .then((r) => {
        commit("updateOverview", r);
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteCreateOverview({ rootState }, { security_id, ...updateDetails }) {
    if (!security_id)
      return {
        action: "error",
        message: "no security id"
      };
    return this.$axios
      .$post(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/${security_id}/overview`,
        updateDetails
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  deleteSecurity({ rootState }, security_id) {
    return this.$axios
      .$delete(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/${security_id}`
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  downloadSecurityList({ rootState, getters }, payload) {
    payload.metrics = getters.getMetricNames;
    return this.$axios
      .$post(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/securities/download-list`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  downloadSecurityProfile({ rootState, getters }, payload) {
    payload.metrics = getters.getMetricNames;
    return this.$axios
      .$post(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/${payload.securityId}/security-profile`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  downloadSecurityPrice({ rootState, getters }, payload) {
    payload.metrics = getters.getMetricNames;
    return this.$axios
      .$post(
        `/v2/security/entity/${rootState.user.selectedShownEntity}/security/${payload.securityId}/download_price_history`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  downloadSecurityPublicationLog({ rootState, getters }, payload) {
    payload.metrics = getters.getMetricNames;
    return this.$axios
      .$post(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/${payload.securityId}/security-publication`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  downloadSecurityEngagementSnapshot({ rootState, getters }, payload) {
    payload.metrics = getters.getMetricNames;
    return this.$axios
      .$post(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/${payload.securityId}/engagement-snapshot`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteToggleSecurityLoading({ commit }, trueOrFalse) {
    commit("toggleSecurityLoading", trueOrFalse);
  },
  remoteToggleSecuritySorting({ commit }, trueOrFalse) {
    commit("toggleSecuritySorting", trueOrFalse);
  },
  remoteCreateNote({ rootState }, { security_id, ...notePayload }) {
    if (!security_id)
      return {
        action: "error",
        message: "no security id"
      };
    return this.$axios
      .$post(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/${security_id}/note`,
        notePayload
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteRemoveLoadedNotes({ commit }) {
    commit("updateNote", []);
  },
  remoteUpdateLoadedNotes({ commit }, arr) {
    commit("updateNote", arr);
  },
  remoteGetNote({ commit, rootState, state }, security_id) {
    if (!security_id)
      return {
        action: "error",
        message: "no security id"
      };
    if (
      state.noteList.length !== 0 &&
      state.noteList.length === state.totalNoteCount
    ) {
      return {
        action: "error",
        message: "all loaded"
      };
    }
    const params = {
      per_page: 20,
      page_offset: state.noteList.length
    };
    return this.$axios
      .$get(
        `/v2/security/entity/${rootState.user.selectedShownEntity}/security/${security_id}/notes`,
        {
          params: params
        }
      )
      .then((r) => {
        for (let i = 0; i < r.note_list.length; i++) {
          const note = r.note_list[i];
          note.note_post = note.note_post.filter((post) => {
            return post.post_content?.trim().length > 0;
          });
        }
        commit("updateNote", [...state.noteList, ...r.note_list]);
        commit("updateTotalNoteCount", r.total_note_count);
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteCreateNotePost({ rootState }, { note_id, ...notePostPayload }) {
    if (!note_id)
      return {
        action: "error",
        message: "no note id"
      };
    return this.$axios
      .$post(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/note/${note_id}/post`,
        notePostPayload
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteDeleteNotePost({ rootState }, post_id) {
    if (!post_id)
      return {
        action: "error",
        message: "no post id"
      };
    return this.$axios
      .$delete(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/notepost/${post_id}`
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteDeleteNote({ rootState }, note_id) {
    if (!note_id)
      return {
        action: "error",
        message: "no note id"
      };
    return this.$axios
      .$delete(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/note/${note_id}`
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteUpdateSelectedSecurityList({ commit, state, rootState }, newArray) {
    commit("updateSelectedSecurityList", newArray);
    const thresholdC = state.securityList.length / 2;
    let allOrNone = "all";
    let arrayList = newArray.map((securityObj) => securityObj.value.id);
    if (newArray.length > thresholdC) {
      const newArrayList = [];
      for (let i = 0; i < state.securityList.length; i++) {
        if (!arrayList.includes(state.securityList[i].value.id)) {
          newArrayList.push(state.securityList[i].value.id);
        }
      }
      arrayList = newArrayList;
    } else {
      allOrNone = "none";
    }
    return this.$axios
      .$put(
        `/v1/member/${rootState.user.selectedShownEntity}/member_filter_list_page/security_list`,
        {
          member_filter_list_options_target_type: allOrNone,
          member_filter_list_options_target_ids: arrayList
        }
      )
      .then((r) => {
        commit("updateSavedChanges", {
          type: r.member_filter_list_options_target_type || "all",
          exclude: r.member_filter_list_options_target_ids || []
        });
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteRemoveLoadedSecurityListDetails({ commit }) {
    commit("updateLoadedSecurityList", []);
  },
  remoteGetPublishedReports(
    { commit, state, rootState },
    { security_id, ...filterOption }
  ) {
    if (!security_id)
      return {
        action: "error",
        message: "no security id"
      };
    return this.$axios
      .$get(
        `/v2/reference_file/entity/${rootState.user.selectedShownEntity}/reports/${security_id}`,
        {
          params: {
            per_page: filterOption.per_page,
            page_offset: filterOption.page_offset
          }
        }
      )
      .then((r) => {
        let orderedReports = [];
        if (filterOption.page_offset > 0)
          orderedReports = state.publishedReports;
        // let reportCount = 0;
        if (r.report_list_ordered.length > 0) {
          for (let i = 0; i < r.report_list_ordered.length; i++) {
            const orderedReport = r.report_list_ordered[i];
            let findReport = null;

            findReport = r.security_reports.find((securityReport) => {
              if (
                securityReport.security_report_id ===
                  orderedReport.security_report_id ||
                securityReport.reference_id === orderedReport.security_report_id
              )
                return securityReport;
            });
            if (findReport) {
              orderedReports.push(findReport);
            }
          }
        }

        if (orderedReports) commit("updatePublishedReports", orderedReports);

        commit("updatePublishedReportCount", r.total_report_count);

        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteDeleteReport({ rootState }, { security_id, report_id }) {
    if (!report_id)
      return {
        action: "error",
        message: "no report id"
      };
    return this.$axios
      .$delete(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/report/${report_id}`,
        {
          params: {
            security_id: security_id
          }
        }
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteUpdateReportOrder(
    { commit, rootState },
    { security_id, security_reports, updated_report_list }
  ) {
    if (!security_id)
      return {
        action: "error",
        message: "no security id"
      };
    return this.$axios
      .$put(
        `/v2/reference_file/entity/${rootState.user.selectedShownEntity}/security_report/${security_id}/order`,
        {
          security_reports: security_reports
        }
      )
      .then((r) => {
        if (r.message === "success")
          commit("updatePublishedReports", updated_report_list);

        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteUpdateMultiReports({ rootState }, { security_id, ...updateDetails }) {
    if (!security_id)
      return {
        action: "error",
        message: "no security id"
      };
    return this.$axios
      .$put(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/${security_id}/reports`,
        updateDetails
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteGetSavedFilters({ rootState, commit, rootGetters }) {
    // NOTE api in member namespace but is for all 3 pages
    return this.$axios
      .$get(
        `v1/member/${rootState.user.selectedShownEntity}/member_filter_list_page/security_list`
      )
      .then((r) => {
        if (r.member_filter_list_options_metrics) {
          commit(
            "updateSelectedSecurityMetrics",
            r.member_filter_list_options_metrics
          );
        }
        const commitObj = {};
        const sort_by = rootGetters["app_store/objectNestedPropertyCheck"](
          r,
          ["member_filter_list_options_params", "security_sort_by"],
          null
        );
        if (sort_by) {
          commitObj["sort_by"] = sort_by;
        }
        const sort_type = rootGetters["app_store/objectNestedPropertyCheck"](
          r,
          ["member_filter_list_options_params", "security_sort_type"],
          null
        );
        if (sort_type) {
          commitObj["sort_type"] = sort_type;
        }
        commit("updateSavedSorted", commitObj);
        commit("updateSavedChanges", {
          type: r.member_filter_list_options_target_type || "all",
          exclude: r.member_filter_list_options_target_ids || []
        });
      })
      .catch((e) => {
        console.error(e);
        // throw e;
      });
  },
  remotUpdateSavedSorting(
    { rootState, rootGetters, commit },
    { sort_by, sort_type }
  ) {
    return this.$axios
      .$put(
        `/v1/member/${rootState.user.selectedShownEntity}/member_filter_list_page/security_list`,
        {
          member_filter_list_options_params: {
            security_sort_by: sort_by,
            security_sort_type: sort_type
          }
        }
      )
      .then((r) => {
        const commitObj = {};
        const new_sort_by = rootGetters["app_store/objectNestedPropertyCheck"](
          r,
          ["member_filter_list_options_params", "security_sort_by"],
          null
        );
        if (new_sort_by) {
          commitObj["sort_by"] = new_sort_by;
        }
        const new_sort_type = rootGetters[
          "app_store/objectNestedPropertyCheck"
        ](r, ["member_filter_list_options_params", "security_sort_type"], null);
        if (new_sort_type) {
          commitObj["sort_type"] = new_sort_type;
        }
        commit("updateSavedSorted", commitObj);
        return true;
      })
      .catch((e) => {
        console.error(e);
      });
  },
  async remoteGetLargeMentionedSecurities({ dispatch }, idArray) {
    const returnArray = [];
    const promiseArray = [];
    const listBreakThreshold = 33; // cannot be 0, will cause infinite loop
    for (let i = 0; i < idArray.length; i += listBreakThreshold) {
      const chunk = idArray.slice(i, i + listBreakThreshold);
      promiseArray.push(dispatch("remoteGetMentionedSecurityDetail", chunk));
    }
    const responses = await Promise.allSettled(promiseArray);
    responses.forEach((res) => {
      if (res.status !== "fulfilled") {
        console.error("could not get chunk from api");
        console.error(res);
        return;
      }
      if (!res.value) {
        console.error("did not get value from api");
        console.error(res);
        return;
      }
      returnArray.push(...res.value);
    });
    return returnArray;
  },
  remoteGetMentionedSecurityDetail({ rootState }, idArray) {
    return this.$axios
      .$get(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/securities-price`,
        {
          params: {
            security_ids: idArray.join("-|-")
          }
        }
      )
      .then((r) => {
        if (r.securities) return r.securities;
        else return [];
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  },
  remoteGetPriceUploadingStatus({ rootState, state }) {
    const jobId = this.$cookies.get(state.PRICE_JOB_ID);
    if (!jobId)
      return {
        action: "error",
        message: "no job id"
      };
    return this.$axios
      .$get(
        `/v2/job_log/entity/${rootState.user.selectedShownEntity}/job/${jobId}/check_job_status`
      )
      .then((r) => {
        if (r == "Done") this.$cookies.remove(state.PRICE_JOB_ID);
        return r;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteGetJobStatus({ rootState, state }, securityId) {
    return this.$axios
      .$post(
        `/v2/job_log/entity/${rootState.user.selectedShownEntity}/job_related_to/${securityId}/get_job_status`,
        {
          job_name: "upload_price_csv"
        }
      )
      .then((r) => {
        if (r == "Done") {
          this.$cookies.remove(state.PRICE_JOB_ID);
          return false;
        }
        return r;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteUploadPriceCSV({ rootState, state }, { security_id, ...payload }) {
    const formData = new FormData();
    if (payload.file) {
      formData.append("price_file", payload.file);
    }

    return this.$axios
      .$post(
        `/v2/security/entity/${rootState.user.selectedShownEntity}/security/${security_id}/upload_price`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
      .then((r) => {
        if (r && r.length > 1) {
          this.$cookies.set(state.PRICE_JOB_ID, r[1], {
            path: "/",
            maxAge: 60 * 60 * 2 // NOTE the token should be 24 hours according to response, but it doesnt even last that long so use 60 min for now
          });
        }
        return {
          success: true,
          response: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          success: false,
          error: e
        };
      });
  },
  remoteEmptyCurrentSecurity({ commit }) {
    commit("addCurrentSecurityDetail", {});
    return Promise.resolve(true);
  },
  remoteGetNamedRangeDetail(
    { rootState },
    { security_id, named_range, model_id }
  ) {
    return this.$axios
      .$get(
        `/v2/security/entity/${rootState.user.selectedShownEntity}/security/${security_id}/name-range`,
        {
          params: {
            name_range: named_range,
            model_id: model_id
          }
        }
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        console.error(e);
        return {
          message: "Invalid named range. Please try another one."
        };
      });
  },
  remoteGetRatingListForEntity({ rootState, commit }, general_rating_group) {
    return this.$axios
      .$get(
        `/v2/security/entity/${rootState.user.selectedShownEntity}/rating_list`,
        {
          params: {
            general_rating_group: general_rating_group
          }
        }
      )
      .then((r) => {
        commit("updateRatingList", r);
        if (general_rating_group === "recommendation")
          commit("updateRecommendationList", r);
        else if (general_rating_group === "esg")
          commit("updateEsgRatingList", r);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {
          message: "No rating list. Please try another one."
        };
      });
  },
  remoteGetBespokeRatingForEntity({ rootState }, payload) {
    const { change_security_id, general_rating_group } = payload;
    return this.$axios
      .$get(
        `/v2/security/entity/${rootState.user.selectedShownEntity}/change_security/${change_security_id}/bespoke_rating`,
        {
          params: {
            general_rating_group: general_rating_group
          }
        }
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        console.error(e);
        return {
          message: "No bespoke rating. Please try another one."
        };
      });
  },
  createRatingForEntity({ rootState }, payload) {
    const { file, params } = payload;
    if (!params.general_rating_text) {
      return {
        action: "error",
        message:
          "All menu items require a descriptor. Please add a descriptor before saving."
      };
    }
    let newFile = null;
    if (file && file.accepted) {
      newFile = new FormData();
      newFile.append("general_rating_icon", file);
    }
    delete params.uploadedFile;
    params.general_rating_icon = params.icon;
    return this.$axios
      .$post(
        `/v2/security/entity/${rootState.user.selectedShownEntity}/create_rating`,
        newFile,
        {
          params: { ...params }
        }
      )
      .then((r) => {
        if (params.general_rating_id) {
          return {
            ...r,
            original_rating_id: params.general_rating_id
          };
        }
        return r;
      })
      .catch((e) => {
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  reorderRatingForEntity({ rootState, commit }, payload) {
    return this.$axios
      .$post(
        `/v2/security/entity/${rootState.user.selectedShownEntity}/general_rating/rating_reorder`,
        {
          ...payload
        }
      )
      .then((r) => {
        commit("updateRatingList", r);
        return {
          action: "accept"
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  updateRatingForEntity({ rootState }, payload) {
    const { general_rating_id, uploadedFile } = payload;
    let file = null;
    payload.general_rating_icon_modify_flag = false;
    if (uploadedFile && uploadedFile.accepted) {
      file = new FormData();
      file.append("general_rating_icon", uploadedFile);
      delete payload.general_rating_icon;
      payload.general_rating_icon_modify_flag = true;
    } else if (!payload.general_rating_icon) {
      file = new FormData();
      file.append("general_rating_icon", null);
      payload.general_rating_icon_modify_flag = true;
    } else {
      delete payload.general_rating_icon;
    }
    delete payload.uploadedFile;
    return this.$axios
      .$put(
        `/v2/security/entity/${rootState.user.selectedShownEntity}/general_rating/${general_rating_id}/rating`,
        file,
        {
          params: { ...payload }
        }
      )
      .then((r) => {
        Promise.resolve(r);
        return r;
      })
      .catch((e) => {
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  deleteRatingForEntity({ rootState }, payload) {
    const { general_rating_id } = payload;
    return this.$axios
      .$delete(
        `/v2/security/entity/${rootState.user.selectedShownEntity}/general_rating/${general_rating_id}/rating`
      )
      .then((r) => {
        Promise.resolve(true);
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  mergeLocalItem({ state, commit }, newItem) {
    commit("updateRatingList", [...state.ratingList, newItem]);
  },
  // link rating to change
  createRatingLinkForEntity({ rootState }, payload) {
    const { change_security_id, general_rating_id, general_rating_group } =
      payload;

    return this.$axios
      .$post(
        `/v2/security/entity/${rootState.user.selectedShownEntity}/change_security/${change_security_id}/create_rating_link`,
        {
          general_rating_group,
          general_rating_id,
          link_type: "change_security"
        }
      )
      .then((r) => {
        return {
          action: "accept"
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  // /security/entity/{entity_id}/change_security/{change_security_id}/single_rating_link
  // link rating to change
  getRatingLinkForEntity({ rootState }, payload) {
    const { change_security_id, general_rating_group } = payload;

    return this.$axios
      .$get(
        `/v2/security/entity/${rootState.user.selectedShownEntity}/change_security/${change_security_id}/get_rating_link`,
        {
          params: {
            general_rating_group: general_rating_group
          }
        }
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  // /security/entity/{entity_id}/general_rating_link/{general_rating_link_id}/modify_rating_link
  deleteRatingLinkForEntity({ rootState }, payload) {
    const { general_rating_link_id } = payload;
    return this.$axios
      .$delete(
        `/v2/security/entity/${rootState.user.selectedShownEntity}/general_rating_link/${general_rating_link_id}/modify_rating_link`
      )
      .then((r) => {
        Promise.resolve(true);
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },

  updateRatingLinkForEntity({ rootState }, payload) {
    const { general_rating_id, general_rating_link_id } = payload;
    return this.$axios
      .$put(
        `/v2/security/entity/${rootState.user.selectedShownEntity}/general_rating_link/${general_rating_link_id}/modify_rating_link`,
        {
          general_rating_id,
          link_type: "change_security"
        }
      )
      .then((r) => {
        Promise.resolve(r);
        return r;
      })
      .catch((e) => {
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  // published
  // get published rating icon
  remoteGetPublishedRating({ rootState }, payload) {
    const { change_security_id, general_rating_group } = payload;
    return this.$axios
      .$get(
        `/v2/security/entity/${rootState.user.selectedShownEntity}/change_security/${change_security_id}/published_rating_icon`,
        {
          params: {
            general_rating_group: general_rating_group
          }
        }
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        console.error(e);
        return {
          message: "No bespoke rating. Please try another one."
        };
      });
  },
  UpdatePublishedRatingIcon({ rootState }, payload) {
    const { file, params, change_security_id } = payload;
    let newFile = null;
    if (file && file.accepted) {
      newFile = new FormData();
      newFile.append("general_rating_icon", file);
    }
    return this.$axios
      .$post(
        `/v2/security/entity/${rootState.user.selectedShownEntity}/change_security/${change_security_id}/published_rating_icon`,
        newFile,
        {
          params: { ...params }
        }
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  async remoteGetAllLatestReport({ rootState }, { securities, not_deleted }) {
    const promiseArray = securities.map((tmpSec) => {
      if (!tmpSec.security_id) return;
      return this.$axios.$get(
        `/v2/report/entity/${rootState.user.selectedShownEntity}/security/${tmpSec.security_id}/latest-reports`,
        {
          params: {
            publish_type: "change"
          }
        }
      );
    });
    const responseArray = await Promise.allSettled(promiseArray);
    const returnArray = [];
    responseArray.forEach((res, index) => {
      if (res.status !== "fulfilled") {
        console.error("could not get from api");
        console.error(res);
        return;
      }
      if (!res.value) {
        console.error("no value");
        console.error(res);
        return;
      }
      const tmpSec = securities[index];
      res.value["security_id"] = tmpSec.security_id;
      res.value["security_name"] = tmpSec.security_name;
      res.value["security_code"] = tmpSec.security_code;
      returnArray.push(res.value);
    });
    return returnArray;
  }
};
