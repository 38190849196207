<template>
  <div class="input-text-wrapper">
    <input-base
      class="input-text"
      :label="label"
      :has-value="hasValue"
      :is-focused="isFocused"
      :disabled="disabled || loading"
      :error="error"
      :success="success"
      :small="small"
      :dark="dark"
      :highlighted="highlighted"
      :character-limits="characterLimits"
      :input-text-length="modelValue?.length"
      @click.native="$refs?.input?.focus()"
    >
      <template slot="prepend">
        <div v-if="icon" :key="icon">
          <i :class="`prepend-icon la la-${icon}`"></i>
        </div>
      </template>
      <div :class="type === 'percentage' ? 'percentage-input-container' : ''">
        <input
          ref="input"
          autocomplete="off"
          :value="modelValue"
          :maxlength="characterLimits"
          tabindex="1"
          :disabled="disabled || success || loading"
          :class="{ dark, error, disabled, success, small }"
          :pattern="type === 'number' ? '[0-9.]' : undefined"
          :name="computedName"
          :max="maxNumber"
          @input="updateValue"
          @focus="isFocused = true"
          @blur="isFocused = false"
          @keypress.enter="$emit('submit')"
          @keypress="(e) => $emit('keypress', e)"
        />
      </div>

      <template slot="append">
        <div :key="clearable && hasValue">
          <div
            v-if="hasSuggest"
            :key="hasSuggest"
            ref="suggestText"
            class="suggest-text"
          >
            figure 1
          </div>
          <button-action
            v-if="clearable && hasValue"
            icon="close"
            icon-only
            warning-hover-light
            small
            @click="modelValue = ''"
          />
        </div>
      </template>
    </input-base>
  </div>
</template>

<script>
import InputBase from "./InputBase.vue";
import ButtonAction from "../buttons/ButtonAction.vue";

export default {
  components: {
    "input-base": InputBase,
    "button-action": ButtonAction
  },

  props: {
    label: {
      type: String,
      default: "",
      required: true
    },
    value: {
      type: [String, Number],
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    error: {
      type: [String, Boolean],
      default: ""
    },
    success: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    pattern: {
      type: String,
      default: ""
    },
    small: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    highlighted: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    characterLimits: {
      type: Number,
      default: null
    },
    isAutofocus: {
      type: Boolean,
      default: false
    },
    hasSuggest: {
      type: Boolean,
      default: false
    },
    isNaturalNumbers: {
      type: Boolean,
      default: false
    },
    maxNumber: {
      type: Number,
      default: null
    }
  },

  data: () => ({
    modelValue: "",
    isFocused: false,
    showPassword: false,
    isAutoFilled: false
  }),

  computed: {
    hasValue() {
      return !!this.modelValue;
    },
    isLabelUp() {
      return this.hasValue || this.isFocused || this.isAutoFilled;
    },
    computedName() {
      return this.name || this.label || "placeholder-name";
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.modelValue =
          this.type === "percentage" ? value.replace("%", "") : value;
      }
    },

    modelValue: {
      immediate: true,
      handler(value) {
        if (value != this.value) {
          this.$emit("input", value);
          this.$emit("reset-error");
        }
      }
    },
    isFocused(value) {
      if (value) {
        this.$emit("focus");
      } else {
        this.$emit("blur");
      }
    }
  },

  mounted() {
    this.$nextTick(async () => {
      this.isAutoFilled = await this.detectAutofill(this.$refs.input);
      if (this.isAutofocus && this.$refs.input) this.$refs.input.focus();
      // if (this.hasSuggest && this.$refs.input && this.$refs.suggestText)
      //   this.setupSuggestion();
    });
  },

  methods: {
    updateValue(e) {
      if (this.type === "number" || this.type === "percentage") {
        const regex = this.isNaturalNumbers
          ? /\d/g
          : this.type === "percentage"
          ? /-?(\d+(\.)?)?/g
          : /\d+(\.)?/g;
        let number = e.target.value.match(regex);
        if (number && number.length > 0) {
          number = number.join("");
          e.target.value = number;
        } else e.target.value = "";
        if (this.maxNumber && e.target.value > this.maxNumber)
          e.target.value = this.maxNumber;
      }
      this.modelValue = e.target.value;
    },
    detectAutofill(element) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(
            window
              .getComputedStyle(element, null)
              .getPropertyValue("appearance") === "menulist-button"
          );
        }, 600);
      });
    }
    // setupSuggestion() {
    //   const inputStyles = window.getComputedStyle(this.$refs.input);
    //   [
    //     "border",
    //     "boxSizing",
    //     "fontFamily",
    //     "fontSize",
    //     "fontWeight",
    //     "letterSpacing",
    //     "lineHeight",
    //     "padding",
    //     "textDecoration",
    //     "textIndent",
    //     "textTransform",
    //     "whiteSpace",
    //     "wordSpacing",
    //     "wordWrap"
    //   ].forEach((property) => {
    //     this.$refs.suggestText.style[property] = inputStyles[property];
    //   });
    //   this.$refs.suggestText.style.borderColor = "transparent";

    //   const parseValue = (v) =>
    //     v.endsWith("px") ? parseInt(v.slice(0, -2), 10) : 0;
    //   const borderWidth = parseValue(inputStyles.borderWidth);

    //   this.$refs.suggestText.style.width = `${
    //     this.$refs.input.clientWidth + 2 * borderWidth
    //   }px`;
    //   this.$refs.suggestText.style.height = `${
    //     this.$refs.input.clientHeight + 2 * borderWidth
    //   }px`;
    // }
  }
};
</script>

<style lang="sass">
.input-text-wrapper
  margin: 0
  .input-text
    cursor: text
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button
      -webkit-appearance: none
      margin: 0
    input[type=number]
      -moz-appearance: textfield
    .percentage-input-container::after
      content: '%'
      position: absolute
      right: 0
      top: 46%
      pointer-events: none
    input
      width: 100%
      height: 100%
      padding: 0px !important
      line-height: 1 !important
      border-radius: 0
      box-shadow: none
      outline: none
      color: rgba(0,0,0,1)
      transition: all 0.2s ease-in-out
      font-size: var(--font-size-small)
      border: none
      font-weight: 400
      background-color: transparent
      padding: 0px !important
      margin: 0px !important
      position: relative
      top: -2px
      &.dark
        color: rgba(255,255,255,1)
        &.disabled
          color: rgba(255,255,255,0.5) !important
        &.success
          color: rgba(var(--color-success), 1)
      &.small
        font-size: var(--font-size-small)
        padding: 0px 6px
      &.disabled
        color: rgba(0,0,0, .5) !important
      &.success
        color: rgba(var(--color-success), 1)
</style>
