import Vue from "vue";
// import XLSX from '@sheet/coredemo';
// import {
//     DiGraph
// } from '~/plugins/DiGraph';
const moment = require("moment-timezone");
const clone = require("rfdc")({
  proto: true
});
const XLSX = require("@sheet/core");
const S5SCalc = require("@sheet/formula");
S5SCalc.set_XLSX(XLSX);
import {
  TMP_STORAGE_FOR_MODEL_LOCAL_PREFIX,
  TMP_FILE_COPY_OPTION
} from "~/constants/central_db.js";
// const ssPreGraph = new DiGraph();
// const ssDeGraph = new DiGraph();

export const state = () => ({
  CREF_REGEX:
    /(^|[^._A-Z0-9])([$]?)([A-Z]{1,2}|[A-W][A-Z]{2}|X[A-E][A-Z]|XF[A-D])([$]?)([1-9]\d{0,5}|10[0-3]\d{4}|104[0-7]\d{3}|1048[0-4]\d{2}|10485[0-6]\d|104857[0-6])(?![_.(A-Za-z0-9])/g,
  DEFAULT_WORKSHEET: {},
  CDB_SHEET_NAME: "CDB Output",
  TMP_DEFAULT_CDB_SHEET: [],

  worksheets: {},
  deps: null,
  selectedWorksheetName: "",
  showCellObj: {},
  errorCells: {},
  worksheetsLoading: false,
  colCount: {},
  rowCount: {},
  feezeCells: {
    // Sheet1: {
    //     col: 'D',
    //     row: '5'
    // }
  },
  highlightCells: {
    row: [],
    col: []
  },
  savedEndHighlighCell: {},
  selectedCell: {},
  previousSelectedCell: {},
  calculatingSheets: false,
  doneList: [],
  undoneList: [],
  newMutation: true,
  spreadsheetBottomMenu: "",
  cellsUpdated: {},
  nameRangeReplaceDict: {},
  currentTimeZone: "Pacific/Auckland",
  timeZoneTimeFormat: "DD MMM YYYY",
  highlightCellGroup: [],
  savedHighlightCells: {},
  sheetIds: {},
  cdbIdMap: [],
  sheetGetUrl: {},
  spreadsheetSecurityId: ""
});

export const getters = {
  getCurrentWorksheet: (state) =>
    state.worksheets &&
    state.selectedWorksheetName &&
    state.worksheets.Sheets[state.selectedWorksheetName]
      ? state.worksheets.Sheets[state.selectedWorksheetName]
      : {},
  getCellValue: (state, getters) => {
    return (cellNo, sheetName) => {
      if (!sheetName) sheetName = state.selectedWorksheetName;
      const cellObj = getters.getCellObj(cellNo, sheetName);
      return cellObj && (cellObj.v || cellObj.v === 0) ? cellObj.v : "";
    };
  },
  getCellObj: (state, getters) => {
    return (cellNo, sheetName, rowIndex, colIndex) => {
      if (!sheetName) sheetName = state.selectedWorksheetName;
      if ((!rowIndex || !colIndex) && cellNo) {
        const tmpCell = getters.decodeCell(cellNo);
        rowIndex = tmpCell.r;
        colIndex = tmpCell.c;
      }
      if (
        rowIndex === null ||
        colIndex === null ||
        rowIndex === false ||
        colIndex === false
      )
        return null;
      return state.worksheets.Sheets[sheetName][rowIndex] &&
        state.worksheets.Sheets[sheetName][rowIndex][colIndex]
        ? state.worksheets.Sheets[sheetName][rowIndex][colIndex]
        : null;
      // return state.worksheets.Sheets[sheetName][cellNo] ? state.worksheets.Sheets[sheetName][cellNo] : null;
    };
  },
  getRowIndex: (state, getters, rootState) => {
    return (indexToCheck, tmpSheetName) => {
      const tmpRowLetter = XLSX.utils.encode_row(indexToCheck);
      let tmpHeight = rootState.ssViewpoints.SINGLE_CELL_H;
      let tmpHidden = false;
      if (
        state.worksheets.Sheets[tmpSheetName] &&
        state.worksheets.Sheets[tmpSheetName]["!rows"] &&
        state.worksheets.Sheets[tmpSheetName]["!rows"][indexToCheck]
      ) {
        if (
          state.worksheets.Sheets[tmpSheetName]["!rows"][indexToCheck].hpx &&
          state.worksheets.Sheets[tmpSheetName]["!rows"][indexToCheck].hpx >
            rootState.ssViewpoints.SINGLE_CELL_H
        ) {
          tmpHeight =
            state.worksheets.Sheets[tmpSheetName]["!rows"][indexToCheck].hpx;
        }
        if (
          state.worksheets.Sheets[tmpSheetName]["!rows"][indexToCheck].hidden
        ) {
          tmpHidden = true;
        }
      }

      return {
        rowIndex: indexToCheck,
        showRow: tmpRowLetter,
        actualRow: tmpRowLetter,
        rowHeight:
          tmpHeight > rootState.ssViewpoints.MAX_CELL_HEIGHT
            ? rootState.ssViewpoints.MAX_CELL_HEIGHT
            : tmpHeight,
        hidden: tmpHidden
      };
    };
  },
  getColIndex: (state, getters, rootState) => {
    return (indexToCheck, tmpSheetName) => {
      const tmpColLetter = XLSX.utils.encode_col(indexToCheck);
      let tmpWidth = rootState.ssViewpoints.SINGLE_CELL_W;
      let tmpHidden = false;
      if (
        state.worksheets.Sheets[tmpSheetName] &&
        state.worksheets.Sheets[tmpSheetName]["!cols"] &&
        state.worksheets.Sheets[tmpSheetName]["!cols"][indexToCheck]
      ) {
        if (
          state.worksheets.Sheets[tmpSheetName]["!cols"][indexToCheck].wpx &&
          state.worksheets.Sheets[tmpSheetName]["!cols"][indexToCheck].wpx >
            rootState.ssViewpoints.SINGLE_CELL_W
        ) {
          tmpWidth =
            state.worksheets.Sheets[tmpSheetName]["!cols"][indexToCheck].wpx;
        }
        if (
          state.worksheets.Sheets[tmpSheetName]["!cols"][indexToCheck].hidden
        ) {
          tmpHidden = true;
        }
      }

      return {
        colIndex: indexToCheck,
        showCol: tmpColLetter,
        actualCol: tmpColLetter,
        colWidth:
          tmpWidth > rootState.ssViewpoints.MAX_CELL_WIDTH
            ? rootState.ssViewpoints.MAX_CELL_WIDTH
            : tmpWidth,
        hidden: tmpHidden
      };
    };
  },
  getSelectedCellMovement: (state, getters, rootState, rootGetters) => {
    if (
      !rootGetters["app_store/objectNestedPropertyCheck"](
        state,
        ["selectedCell", "row", "rowIndex"],
        false
      ) &&
      !rootGetters["app_store/objectNestedPropertyCheck"](
        state,
        ["previousSelectedCell", "row", "rowIndex"],
        false
      ) &&
      !rootGetters["app_store/objectNestedPropertyCheck"](
        state,
        ["selectedCell", "col", "colIndex"],
        false
      ) &&
      !rootGetters["app_store/objectNestedPropertyCheck"](
        state,
        ["previousSelectedCell", "col", "colIndex"],
        false
      )
    )
      return false;
    // if there is no movement no change in formula
    if (
      state.selectedCell.row.rowIndex ===
        state.previousSelectedCell.row.rowIndex &&
      state.selectedCell.col.colIndex ===
        state.previousSelectedCell.col.colIndex
    )
      return false;
    return {
      row:
        state.selectedCell.row.rowIndex -
        state.previousSelectedCell.row.rowIndex,
      col:
        state.selectedCell.col.colIndex -
        state.previousSelectedCell.col.colIndex
    };
  },
  decodeRow: () => {
    return (rowToDecode) => XLSX.utils.decode_row(rowToDecode);
  },
  decodeCol: () => {
    return (colToDecode) => XLSX.utils.decode_col(colToDecode);
  },
  encodeRow: () => {
    return (rowToDecode) => XLSX.utils.encode_row(rowToDecode);
  },
  encodeCol: () => {
    return (colToDecode) => XLSX.utils.encode_col(colToDecode);
  },
  decodeCell: () => {
    return (cellId) => XLSX.utils.decode_cell(cellId);
  },
  encodeCell: () => {
    return (cell_address) => XLSX.utils.encode_cell(cell_address);
  },
  encodeRange: () => {
    return (range_obj) => XLSX.utils.encode_range(range_obj);
  },
  decodeRange: () => {
    return (range_obj) => XLSX.utils.decode_range(range_obj);
  },
  canUndoSpreadsheet: (state) => {
    return state.doneList.length > 0;
  },
  canRedoSpreadsheet: (state) => {
    return state.undoneList.length > 0;
  },
  /*
    getRefCellsArray: (state, getters, rootState, rootGetters) => {
        return (formulaToCheck, currentSheetName) => {
            const tmpRefCellArray = rootGetters['keyInput/relatedCellsFromFormula'](formulaToCheck, true, false);
            if (tmpRefCellArray && tmpRefCellArray.length > 0) {
                let refCellSheetName = '';
                let tmpCellRange = {};
                let refCellArray = {};
                let cellRangeCache = [];
                let tmpRangeToCheck = '';
                tmpRefCellArray.forEach((textToCheck) => {
                    if (cellRangeCache.indexOf(textToCheck) > -1) return; // already in list
                    cellRangeCache.push(textToCheck);
                    if (textToCheck.indexOf('!') > -1) {
                        // conntains sheet name
                        const splitArray = textToCheck.split('!');
                        if (splitArray.length !== 2) return; // invalid ref cell with sheetnames
                        refCellSheetName = splitArray[0].indexOf(' ') > -1 ? splitArray[0].slice(1, -1) : splitArray[0]; // rid the quotation mark
                        tmpRangeToCheck = splitArray[1];
                    } else {
                        refCellSheetName = currentSheetName;
                        tmpRangeToCheck = textToCheck;
                    }
                    if (!refCellArray[refCellSheetName]) refCellArray[refCellSheetName] = [];
                    tmpCellRange = XLSX.utils.decode_range(tmpRangeToCheck);
                    refCellArray[refCellSheetName].push(tmpCellRange);
                });
                return Object.freeze(refCellArray);
            } else {
                return false;
            }
        };
    },
    */
  /*
     workOutWorksheetForCalculate: (state, getters, rootState) => {
         rootState.webworkers.ssCalcWorker.postMessage({
             action: 'start data'
         });
         // const t0 = performance.now();
         let returnArray = {};
         let rememberDependents = {};
         for (const tmpSheetName in state.cellsUpdated) {
             for (let i = 0, loop1len = state.cellsUpdated[tmpSheetName].length; i < loop1len; i++) {
                 const tmpCellId = state.cellsUpdated[tmpSheetName][i];
                 const dfsGraph = ssPreGraph.dfs(`${tmpSheetName}!${tmpCellId}`);
                 for (let j = 0, loop2len = dfsGraph.length; j < loop2len; j++) {
                     const [tmpAddSheet, tmpAddCellId] = dfsGraph[j].split('!');
                     if (tmpAddSheet && tmpAddCellId) {
                         if (state.worksheets.Sheets[tmpAddSheet][tmpAddCellId]) {
                             if (!returnArray[tmpAddSheet])
                                 returnArray[tmpAddSheet] = {
                                     // "!ref": state.worksheets.Sheets['!ref'] ? state.worksheets.Sheets['!ref'] : default_ref
                                 };
                             returnArray[tmpAddSheet][tmpAddCellId] = {
                                 ...state.worksheets.Sheets[tmpAddSheet][tmpAddCellId]
                             };
                             rootState.webworkers.ssCalcWorker.postMessage({
                                 action: 'send data',
                                 sheet: tmpAddSheet,
                                 cell: tmpAddCellId,
                                 data: {
                                     // ...state.worksheets.Sheets[tmpAddSheet][tmpAddCellId]
                                     f: state.worksheets.Sheets[tmpAddSheet][tmpAddCellId].newF ? state.worksheets.Sheets[tmpAddSheet][tmpAddCellId].newF : '',
                                     v: state.worksheets.Sheets[tmpAddSheet][tmpAddCellId].v,
                                     t: state.worksheets.Sheets[tmpAddSheet][tmpAddCellId].t
                                 }
                             });
                         }

                         let dependents = {};
                         if (rememberDependents[dfsGraph[j]]) {
                             dependents = rememberDependents[dfsGraph[j]];
                             for (const dependentsSheet in dependents) {
                                 if (!returnArray[dependentsSheet])
                                     returnArray[dependentsSheet] = {
                                         // "!ref": state.worksheets.Sheets['!ref'] ? state.worksheets.Sheets['!ref'] : default_ref
                                     };
                                 for (let m = 0, loop4len = dependents.length; m < loop4len; m++) {
                                     const dependentCellId = dependents[dependentsSheet][m];
                                     if (!returnArray[dependentsSheet])
                                         returnArray[dependentsSheet] = {
                                             // "!ref": state.worksheets.Sheets['!ref'] ? state.worksheets.Sheets['!ref'] : default_ref
                                         };
                                     if (!returnArray[dependentsSheet][dependentCellId]) {
                                         returnArray[dependentsSheet][dependentCellId] = {
                                             v: state.worksheets.Sheets[dependentsSheet] && state.worksheets.Sheets[dependentsSheet][dependentCellId] && state.worksheets.Sheets[dependentsSheet][dependentCellId].v ? state.worksheets.Sheets[dependentsSheet][dependentCellId].v : 0,
                                             t: state.worksheets.Sheets[dependentsSheet][dependentCellId].t,
                                             dependents: true
                                         };
                                         rootState.webworkers.ssCalcWorker.postMessage({
                                             action: 'send data',
                                             sheet: dependentsSheet,
                                             cell: dependentCellId,
                                             data: {
                                                 v: state.worksheets.Sheets[dependentsSheet] && state.worksheets.Sheets[dependentsSheet][dependentCellId] && state.worksheets.Sheets[dependentsSheet][dependentCellId].v ? state.worksheets.Sheets[dependentsSheet][dependentCellId].v : 0,
                                                 t: state.worksheets.Sheets[dependentsSheet][dependentCellId].t,
                                                 dependents: true
                                             }
                                         });
                                     }
                                 }
                             }
                         } else {
                             const tmpDependents = ssDeGraph.getVertex(`${tmpAddSheet}!${tmpAddCellId}`);
                             if (!tmpDependents) continue;
                             for (let m = 0, loop4len = tmpDependents.length; m < loop4len; m++) {
                                 const [dependentsSheet, dependentCellId] = tmpDependents[m].split('!');
                                 if (!returnArray[dependentsSheet])
                                     returnArray[dependentsSheet] = {
                                         // "!ref": state.worksheets.Sheets['!ref'] ? state.worksheets.Sheets['!ref'] : default_ref
                                     };
                                 if (!returnArray[dependentsSheet][dependentCellId]) {
                                     if (state.worksheets.Sheets[dependentsSheet] && state.worksheets.Sheets[dependentsSheet][dependentCellId]) {
                                         returnArray[dependentsSheet][dependentCellId] = {
                                             v: state.worksheets.Sheets[dependentsSheet][dependentCellId].v ? state.worksheets.Sheets[dependentsSheet][dependentCellId].v : 0,
                                             t: state.worksheets.Sheets[dependentsSheet][dependentCellId].t ? state.worksheets.Sheets[dependentsSheet][dependentCellId].t : null,
                                             dependents: true
                                         };
                                     } else {
                                         returnArray[dependentsSheet][dependentCellId] = {
                                             v: 0,
                                             t: null,
                                             dependents: true
                                         };
                                     }
                                     rootState.webworkers.ssCalcWorker.postMessage({
                                         action: 'send data',
                                         sheet: dependentsSheet,
                                         cell: dependentCellId,
                                         data: {
                                             ...returnArray[dependentsSheet][dependentCellId]
                                         }
                                     });
                                 }
                                 if (!dependents[dependentsSheet]) dependents[dependentsSheet] = [];
                                 dependents[dependentsSheet].push(dependentCellId);
                             }
                             rememberDependents[dfsGraph[j]] = dependents;
                         }
                     }
                 }
             }
         }
         // console.log(returnArray);
         // const t1 = performance.now();
         // console.log("Call to doSomething took " + (t1 - t0) + " milliseconds.");
         return Object.freeze(returnArray);
     },
     */
  /*
     getIntDependency: () => {
         return (sheetName, cellId) => ssDeGraph.getVertex(`${sheetName}!${cellId}`);
     },
     getIntPrecedency: () => {
         return (sheetName, cellId) => ssPreGraph.getVertex(`${sheetName}!${cellId}`);
     },
     */
  getTextWidth: () => {
    return (text, font) => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = font;
      const metrics = context.measureText(text);
      return metrics.width;
    };
  },
  getNameRangeRegex: (state) => {
    if (Object.keys(state.nameRangeReplaceDict).length === 0) {
      return {
        tmpCheckNameRangeRegex: false
      };
    }
    const tmpCheckNameRangeRegex = new RegExp(
      Object.keys(state.nameRangeReplaceDict)
        .join("|")
        .replace(/[.*+?^${}()[\]\\]/g, "\\$&"),
      "gi"
    );
    return {
      tmpCheckNameRangeRegex,
      tmpCheckNameRangeRegexFunction: (matched, index, value) => {
        return state.nameRangeReplaceDict[matched.toUpperCase()];
      }
    };
  },
  clampRange: () => {
    return (range) => {
      if (range.e.r >= 1 << 20) range.e.r = (1 << 20) - 1;
      if (range.e.c >= 1 << 14) range.e.c = (1 << 14) - 1;
      return range;
    };
  },
  newRange: () => {
    return (ws, address) => {
      /* find the cell range */
      const range = XLSX.utils.decode_range(ws["!ref"]);
      const addr = XLSX.utils.decode_cell(address);

      /* extend the range to include the new cell */
      if (range.s.c > addr.c) range.s.c = addr.c;
      if (range.s.r > addr.r) range.s.r = addr.r;
      if (range.e.c < addr.c) range.e.c = addr.c;
      if (range.e.r < addr.r) range.e.r = addr.r;

      return range;
    };
  },
  getNextCell: (state, getters) => {
    return ({ criteria, fromCell, sheetName, direction }) => {
      let tmpCellV, tmpCellId, tmplastV, tmplastId;
      // const currentCellId = fromCell.col.actualCol + fromCell.row.actualRow;
      const currentCol = getters.encodeCol(fromCell.col);
      const currentRow = getters.encodeRow(fromCell.row);
      const currentCellId = currentCol.concat(currentRow);
      let currentCellValue = getters.getCellValue(currentCellId, sheetName);
      if (currentCellValue === 0) currentCellValue = true;
      switch (direction) {
        case "up":
          if (fromCell.row <= 0) return currentCellId;
          for (let i = fromCell.row - 1; i >= 0; i--) {
            tmpCellId = currentCol.concat(getters.encodeRow(i));
            tmpCellV = getters.getCellValue(tmpCellId, sheetName);
            if (tmpCellV === 0) tmpCellV = true;
            switch (criteria) {
              case "textEnd":
                if (!currentCellValue && tmpCellV) return tmpCellId;
                else if (!tmplastId) {
                  tmplastId = tmpCellId;
                  tmplastV = tmpCellV;
                } else if (!tmplastV && tmpCellV) return tmpCellId;
                else if (tmplastV && !tmpCellV) return tmplastId;
                else {
                  (tmplastId = tmpCellId), (tmplastV = tmpCellV);
                }
                break;
            }
          }
          break;
        case "down":
          if (fromCell.row >= state.rowCount[sheetName]) return currentCellId;
          for (let i = fromCell.row + 1; i < state.rowCount[sheetName]; i++) {
            tmpCellId = currentCol.concat(getters.encodeRow(i));
            tmpCellV = getters.getCellValue(tmpCellId, sheetName);
            if (tmpCellV === 0) tmpCellV = true;
            switch (criteria) {
              case "textEnd":
                if (!currentCellValue && tmpCellV) return tmpCellId;
                else if (!tmplastId) {
                  tmplastId = tmpCellId;
                  tmplastV = tmpCellV;
                } else if (!tmplastV && tmpCellV) return tmpCellId;
                else if (tmplastV && !tmpCellV) return tmplastId;
                else {
                  (tmplastId = tmpCellId), (tmplastV = tmpCellV);
                }
                break;
            }
          }
          break;
        case "left":
          if (fromCell.col <= 0) return currentCellId;
          for (let i = fromCell.col - 1; i >= 0; i--) {
            tmpCellId = getters.encodeCol(i).concat(currentRow);
            tmpCellV = getters.getCellValue(tmpCellId, sheetName);
            if (tmpCellV === 0) tmpCellV = true;
            switch (criteria) {
              case "textEnd":
                if (!currentCellValue && tmpCellV) return tmpCellId;
                else if (!tmplastId) {
                  tmplastId = tmpCellId;
                  tmplastV = tmpCellV;
                } else if (!tmplastV && tmpCellV) return tmpCellId;
                else if (tmplastV && !tmpCellV) return tmplastId;
                else {
                  (tmplastId = tmpCellId), (tmplastV = tmpCellV);
                }
                break;
            }
          }
          break;
        case "right":
          if (fromCell.col >= state.colCount[sheetName]) return currentCellId;
          for (
            let i = fromCell.col + 1;
            i < state.colCount[sheetName] + 2;
            i++
          ) {
            tmpCellId = getters.encodeCol(i).concat(currentRow);
            tmpCellV = getters.getCellValue(tmpCellId, sheetName);
            if (tmpCellV === 0) tmpCellV = true;
            switch (criteria) {
              case "textEnd":
                if (!currentCellValue && tmpCellV) return tmpCellId;
                else if (!tmplastId) {
                  tmplastId = tmpCellId;
                  tmplastV = tmpCellV;
                } else if (!tmplastV && tmpCellV) return tmpCellId;
                else if (tmplastV && !tmpCellV) return tmplastId;
                else {
                  (tmplastId = tmpCellId), (tmplastV = tmpCellV);
                }
                break;
            }
          }
          break;
      }
      return tmpCellId;
    };
  },
  highlightSingleCell: (state) =>
    (state.highlightCells.row.length === 1 &&
      state.highlightCells.col.length === 1) ||
    (state.highlightCells.col.length === 2 &&
      state.highlightCells.row.length === 2 &&
      state.highlightCells.col[0] === state.highlightCells.col[1] &&
      state.highlightCells.row[0] === state.highlightCells.row[1]),
  nameRangeMap: (state, getters) => {
    const allNamedRangesInWorkBook = state.worksheets.Workbook?.Names;
    const allNameRangeMap = new Map();
    for (let i = 0; i < allNamedRangesInWorkBook.length; i++) {
      const currentRangeObj = allNamedRangesInWorkBook[i];
      if (
        currentRangeObj.Name &&
        !currentRangeObj.Hidden &&
        currentRangeObj.Ref
      ) {
        const [sheetName, rangeText] = currentRangeObj.Ref.split("!");
        // sheetname with multiple words will have apostrophe at the beginnging and the end
        let newSheetName = sheetName;
        if (newSheetName.includes(" ")) {
          newSheetName = newSheetName.substr(1, newSheetName.length - 2);
        }
        // const sheetIndex = rootState.spreadsheet.worksheets.SheetNames.indexOf(sheetName);
        // if (sheetIndex < 0) continue;
        if (!state.worksheets.Sheets[newSheetName]) continue;
        const cellIndex = getters.decodeRange(rangeText);
        if (!cellIndex.s || !cellIndex.e) {
          console.error(`could not decode cell range: ${currentRangeObj.Ref}`);
          continue;
        }
        allNameRangeMap.set(currentRangeObj.Name.toLowerCase(), {
          sheetName: newSheetName,
          cells: cellIndex
        });
      }
    }
    return allNameRangeMap;
  }
};

export const mutations = {
  changeLoadingState(state, trueOfFalse) {
    state.worksheetsLoading = trueOfFalse;
  },
  updateWorksheetdata(state, sheetdata) {
    state.worksheets = Object.freeze(sheetdata);
  },
  setDefaultWorksheet(state, sheetdata) {
    state.DEFAULT_WORKSHEET = Object.freeze(sheetdata);
  },
  setErrorCells(state, errorCells) {
    state.errorCells = errorCells;
  },
  setSelectedSheet(state, sheetName) {
    state.selectedWorksheetName = sheetName;
  },
  /*
    calculateAllWorksheets(state, worksheetsForCalculation) {
        let tmpWorksheet = clone(state.worksheets);
        if (worksheetsForCalculation.Sheets) worksheetsForCalculation = worksheetsForCalculation.Sheets;
        for (const tmpSheetName in worksheetsForCalculation) {
            const worksheetObj = worksheetsForCalculation[tmpSheetName];
            for (const tmpCellId in worksheetObj) {
                if (tmpCellId[0] === '!') {
                    continue;
                }
                const tmpCellObj = worksheetObj[tmpCellId];
                if (tmpCellObj.dependents) continue;
                tmpWorksheet.Sheets[tmpSheetName][tmpCellId].t = tmpCellObj.t;
                if (tmpCellObj.t !== 'e') {
                    // no error
                    tmpWorksheet.Sheets[tmpSheetName][tmpCellId].v = tmpCellObj.v;
                    if ((tmpCellObj.v || tmpCellObj.v === 0) && (tmpWorksheet.Sheets[tmpSheetName][tmpCellId].w || tmpWorksheet.Sheets[tmpSheetName][tmpCellId].w === '')) {
                        delete tmpWorksheet.Sheets[tmpSheetName][tmpCellId].w;
                    }
                    let tmpUnixDate;
                    if (isNaN(tmpCellObj.v)) tmpUnixDate = moment.tz(tmpCellObj.v, state.timeZoneTimeFormat, true, state.currentTimeZone);
                    if (tmpCellObj.t === 'd' || (tmpUnixDate && tmpUnixDate.isValid())) {
                        // format the date:
                        if (!tmpUnixDate && tmpCellObj.t === 'd') tmpUnixDate = moment.tz(tmpCellObj.v, state.timeZoneTimeFormat, true, state.currentTimeZone);
                        tmpWorksheet.Sheets[tmpSheetName][tmpCellId].w = tmpUnixDate.format(state.timeZoneTimeFormat);
                    } else {
                        XLSX.utils.format_cell(tmpWorksheet.Sheets[tmpSheetName][tmpCellId]);
                    }
                } else {
                    tmpWorksheet.Sheets[tmpSheetName][tmpCellId].w = tmpCellObj.w;
                }
                // tmpWorksheet.Sheets[tmpSheetName][tmpCellId] = {
                //     ...tmpCellObj,
                //     // changed: false
                // };
            }
        }
        Vue.set(state, 'cellsUpdated', {});
        Vue.set(state, 'worksheets', Object.freeze(tmpWorksheet));
    },
    */
  updateColRowCount(state, { colCount, rowCount }) {
    state.rowCount = rowCount;
    state.colCount = colCount;
  },
  updateColCount(state, newColCount) {
    state.colCount[state.selectedWorksheetName] = newColCount;
  },
  updateRowCount(state, newRowCount) {
    state.rowCount[state.selectedWorksheetName] = newRowCount;
  },
  selectCell(state, cell) {
    Vue.set(state, "selectedCell", cell);
  },
  setPreviousSelectCell(state) {
    Vue.set(state, "previousSelectedCell", state.selectedCell);
  },
  changeHighlightCells(state, cellsArray) {
    // if(cellsArray.row.length > 0 && cellsArray.row[0] > cellsArray.row[1]) cellsArray.row.reverse();
    // if(cellsArray.col.length > 0 && cellsArray.col[0] > cellsArray.col[1]) cellsArray.col.reverse();
    Vue.set(state, "highlightCells", cellsArray);
  },
  updateCellValue(state, cellArray) {
    const tmpWorksheet = clone(state.worksheets);
    // clean up depscells as deep clone turns it into object instead of set
    for (const tmpSheetName in tmpWorksheet.Sheets) {
      if (tmpWorksheet.Sheets[tmpSheetName]["!ref"]) {
        const decodeRange = XLSX.utils.decode_range(
          tmpWorksheet.Sheets[tmpSheetName]["!ref"]
        );
        for (
          let tmpCol = decodeRange.s.c;
          tmpCol <= decodeRange.e.c;
          tmpCol++
        ) {
          for (
            let tmpRow = decodeRange.s.r;
            tmpRow <= decodeRange.e.r;
            tmpRow++
          ) {
            if (
              tmpWorksheet.Sheets[tmpSheetName][tmpRow] &&
              tmpWorksheet.Sheets[tmpSheetName][tmpRow][tmpCol] &&
              tmpWorksheet.Sheets[tmpSheetName][tmpRow][tmpCol]["depcells"]
            )
              delete tmpWorksheet.Sheets[tmpSheetName][tmpRow][tmpCol][
                "depcells"
              ];
          }
        }
      }
    }
    // === finish clean up
    let deps = S5SCalc.build_deps(tmpWorksheet);
    // remove the value from previous cells might need to change to update type ... etc
    const recordUpdatedCells = clone(state.cellsUpdated);
    for (let i = 0; i < cellArray.length; i++) {
      const {
        id,
        col,
        row,
        value,
        sheetName,
        style,
        format,
        isDrag = false
      } = cellArray[i];
      //console.log(col);
      //console.log(row);
      //console.log(id);
      //console.log(sheetName);
      //console.log(value);
      //console.log(clone(tmpWorksheet));
      //console.log(clone(deps));
      // console.log(state.deps);
      if (
        !tmpWorksheet.Sheets[sheetName][row] ||
        !tmpWorksheet.Sheets[sheetName][row][col] ||
        tmpWorksheet.Sheets[sheetName][row][col] !== value
      ) {
        if (typeof value === "string" && value.substring(0, 1) === "=") {
          deps = S5SCalc.update_formula(
            tmpWorksheet,
            sheetName,
            id,
            value.substring(1),
            deps
          );
        } else {
          deps = S5SCalc.update_value(tmpWorksheet, sheetName, id, value, deps);
        }
        // Vue.set(state, "deps", Object.freeze(deps));
      }
      if (format && Object.keys(format).length > 0)
        tmpWorksheet.Sheets[sheetName][row][col].z = format; // mostly when drag update
      if (isDrag) {
        // mostly when drag update
        tmpWorksheet.Sheets[sheetName][row][col].s = style;
      } else {
        tmpWorksheet.Sheets[sheetName][row][col].s = {
          ...tmpWorksheet.Sheets[sheetName][row][col].s,
          ...style
        };
      }

      if (!recordUpdatedCells[sheetName]) recordUpdatedCells[sheetName] = [id];
      else if (!recordUpdatedCells[sheetName][id])
        recordUpdatedCells[sheetName].push(id);
    }
    /*
        cellArray.forEach(({
            id,
            value,
            sheetName,
            // refCells,
            // newF,
            style,
            format,
            isDrag = false
        }) => {
            if (!tmpWorksheet.Sheets[sheetName][id]) {
                tmpWorksheet.Sheets[sheetName][id] = {};
            } else {
                // if (tmpWorksheet.Sheets[sheetName][id].w) delete tmpWorksheet.Sheets[sheetName][id].w;
                // if (tmpWorksheet.Sheets[sheetName][id].f) delete tmpWorksheet.Sheets[sheetName][id].f;
                if (tmpWorksheet.Sheets[sheetName][id].newF) delete tmpWorksheet.Sheets[sheetName][id].newF;
                if (tmpWorksheet.Sheets[sheetName][id].refCells) delete tmpWorksheet.Sheets[sheetName][id].refCells;
                // const tmpDepVertex = ssDeGraph.getVertex(`${sheetName}!${id}`);
                // if (tmpDepVertex) {
                //     for (let i = 0, vertexC = tmpDepVertex.length; i < vertexC; i++) {
                //         const tmpDepVertexToRemove = tmpDepVertex[i];
                //         ssPreGraph.removeEdge(tmpDepVertexToRemove, `${sheetName}!${id}`);
                //     }
                // }
                // ssDeGraph.removeVertex(`${sheetName}!${id}`);
            }
            // if (!tmpWorksheet.Sheets[sheetName][id].v) tmpWorksheet.Sheets[sheetName][id].v = null;
            if (format && Object.keys(format).length > 0) tmpWorksheet.Sheets[sheetName][id].z = format; // mostly when drag update
            if (isDrag) { // mostly when drag update
                tmpWorksheet.Sheets[sheetName][id].s = style;
            } else {
                tmpWorksheet.Sheets[sheetName][id].s = {
                    ...tmpWorksheet.Sheets[sheetName][id].s,
                    ...style
                };
            }

            // ssDeGraph.addVertex(`${sheetName}!${id}`);
            // if (!ssPreGraph.getVertex(`${sheetName}!${id}`)) {
            //     ssPreGraph.addVertex(`${sheetName}!${id}`);
            // }

            if (typeof value === 'string' && value.substring(0, 1) === '=') {
                tmpWorksheet.Sheets[sheetName][id]['f'] = value.substring(1);
                tmpWorksheet.Sheets[sheetName][id]['newF'] = newF.substring(1);
                tmpWorksheet.Sheets[sheetName][id].refCells = refCells;
                for (const tmpDependentSheet in refCells) {
                    refCells[tmpDependentSheet].forEach((tmpRangeObj) => {
                        for (let i = tmpRangeObj.s.c; i <= tmpRangeObj.e.c; i++) {
                            for (let j = tmpRangeObj.s.r; j <= tmpRangeObj.e.r; j++) {
                                const tmpDependentCellId = XLSX.utils.encode_cell({
                                    c: i,
                                    r: j
                                });
                                if (!ssDeGraph.getVertex(`${tmpDependentSheet}!${tmpDependentCellId}`)) {
                                    ssDeGraph.addVertex(`${tmpDependentSheet}!${tmpDependentCellId}`);
                                }
                                if (!ssPreGraph.getVertex(`${tmpDependentSheet}!${tmpDependentCellId}`)) {
                                    ssPreGraph.addVertex(`${tmpDependentSheet}!${tmpDependentCellId}`);
                                }
                                ssPreGraph.addEdge(`${tmpDependentSheet}!${tmpDependentCellId}`, `${sheetName}!${id}`);
                                ssDeGraph.addEdge(`${sheetName}!${id}`, `${tmpDependentSheet}!${tmpDependentCellId}`);
                            }
                        }
                    });
                }
                // ssPreGraph.printGraph();
            } else {
                if (isNaN(value)) {
                    if (value) {
                        const tryDate = moment.tz(value, state.timeZoneTimeFormat, true, state.currentTimeZone);
                        if (!tryDate.isValid()) {
                            tmpWorksheet.Sheets[sheetName][id]['v'] = value;
                            tmpWorksheet.Sheets[sheetName][id]['t'] = 's'; // might need to check for other types
                        } else {
                            tmpWorksheet.Sheets[sheetName][id]['v'] = value;
                            tmpWorksheet.Sheets[sheetName][id]['t'] = 'd';
                        }
                    } else if (!style) {
                        // delete cell
                        delete tmpWorksheet.Sheets[sheetName][id];
                    }
                } else {
                    if (value === null || value === "") {
                        if (tmpWorksheet.Sheets[sheetName][id].s) tmpWorksheet.Sheets[sheetName][id].v = value;
                        else delete tmpWorksheet.Sheets[sheetName][id];
                        if (tmpWorksheet.Sheets[sheetName][id].t == 'd') tmpWorksheet.Sheets[sheetName][id].t = 's';
                    } else {
                        tmpWorksheet.Sheets[sheetName][id]['v'] = parseFloat(value);
                        tmpWorksheet.Sheets[sheetName][id]['t'] = 'n';
                    }
                }
            }
            if (!recordUpdatedCells[sheetName]) recordUpdatedCells[sheetName] = [id];
            else if (!recordUpdatedCells[sheetName][id]) recordUpdatedCells[sheetName].push(id);
        });
        */
    const ws = tmpWorksheet.Sheets[state.selectedWorksheetName];
    if (ws["!ref"]) {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      const tmpCell = XLSX.utils.decode_cell(
        cellArray[cellArray.length - 1].id
      );
      if (range.e.c < tmpCell.c || range.e.r < tmpCell.r) {
        ws["!ref"] = "A0:" + cellArray[cellArray.length - 1].id;
      }
    } else {
      ws["!ref"] = "A0:" + cellArray[cellArray.length - 1].id;
    }
    // will need to workout different
    // console.log(ssPreGraph.detectCycle());
    Vue.set(state, "cellsUpdated", recordUpdatedCells);
    Vue.set(state, "worksheets", Object.freeze(tmpWorksheet));
  },
  updateCellValueNoCalculation(state, cellArray) {
    const tmpWorksheet = clone(state.worksheets);
    // clean up depscells as deep clone turns it into object instead of set
    for (const tmpSheetName in tmpWorksheet.Sheets) {
      if (tmpWorksheet.Sheets[tmpSheetName]["!ref"]) {
        const decodeRange = XLSX.utils.decode_range(
          tmpWorksheet.Sheets[tmpSheetName]["!ref"]
        );
        for (
          let tmpCol = decodeRange.s.c;
          tmpCol <= decodeRange.e.c;
          tmpCol++
        ) {
          for (
            let tmpRow = decodeRange.s.r;
            tmpRow <= decodeRange.e.r;
            tmpRow++
          ) {
            if (
              tmpWorksheet.Sheets[tmpSheetName][tmpRow] &&
              tmpWorksheet.Sheets[tmpSheetName][tmpRow][tmpCol] &&
              tmpWorksheet.Sheets[tmpSheetName][tmpRow][tmpCol]["depcells"]
            )
              delete tmpWorksheet.Sheets[tmpSheetName][tmpRow][tmpCol][
                "depcells"
              ];
          }
        }
      }
    }
    // === finish clean up
    // remove the value from previous cells might need to change to update type ... etc
    const recordUpdatedCells = clone(state.cellsUpdated);
    for (let i = 0; i < cellArray.length; i++) {
      const {
        id,
        col,
        row,
        value,
        sheetName,
        style,
        format,
        displayText,
        isDrag = false
      } = cellArray[i];
      if (!tmpWorksheet.Sheets[sheetName][row]) {
        tmpWorksheet.Sheets[sheetName][row] = {
          col: {
            v: value,
            w: displayText || value,
            s: style,
            z: format
          }
        };
      } else if (!tmpWorksheet.Sheets[sheetName][row][col]) {
        tmpWorksheet.Sheets[sheetName][row][col] = {
          v: value,
          w: displayText || value,
          s: style,
          z: format
        };
      }
      if (tmpWorksheet.Sheets[sheetName][row][col].v !== value) {
        tmpWorksheet.Sheets[sheetName][row][col].v = value;
        tmpWorksheet.Sheets[sheetName][row][col].w = displayText || value;
      }
      if (format && Object.keys(format).length > 0)
        tmpWorksheet.Sheets[sheetName][row][col].z = format; // mostly when drag update
      if (isDrag) {
        // mostly when drag update
        tmpWorksheet.Sheets[sheetName][row][col].s = style;
      } else {
        tmpWorksheet.Sheets[sheetName][row][col].s = {
          ...tmpWorksheet.Sheets[sheetName][row][col].s,
          ...style
        };
      }

      if (!recordUpdatedCells[sheetName]) recordUpdatedCells[sheetName] = [id];
      else if (!recordUpdatedCells[sheetName][id])
        recordUpdatedCells[sheetName].push(id);
    }
    const ws = tmpWorksheet.Sheets[state.selectedWorksheetName];
    if (ws["!ref"]) {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      const tmpCell = XLSX.utils.decode_cell(
        cellArray[cellArray.length - 1].id
      );
      if (range.e.c < tmpCell.c || range.e.r < tmpCell.r) {
        ws["!ref"] = "A0:" + cellArray[cellArray.length - 1].id;
      }
    } else {
      ws["!ref"] = "A0:" + cellArray[cellArray.length - 1].id;
    }
    Vue.set(state, "cellsUpdated", recordUpdatedCells);
    Vue.set(state, "worksheets", Object.freeze(tmpWorksheet));
  },
  toggleCalculating(state, trueOfFalse) {
    Vue.set(state, "calculatingSheets", trueOfFalse);
  },
  saveEndHighlightPoint(
    state,
    { startColIndex, endColIndex, startRowIndex, endRowIndex }
  ) {
    Vue.set(state, "savedEndHighlighCell", {
      s: {
        c: startColIndex,
        r: startRowIndex
      },
      e: {
        c: endColIndex,
        r: endRowIndex
      }
    });
  },
  addToDoneList(state, tmpMutation) {
    state.doneList.push(tmpMutation);
  },
  updateDoneList(state, newDoneList) {
    Vue.set(state, "doneList", newDoneList);
  },
  addToUndoneList(state, tmpMutation) {
    state.undoneList.push(tmpMutation);
  },
  updateUndoneList(state, newDoneList) {
    Vue.set(state, "undoneList", newDoneList);
  },
  changeNewMutation(state, trueOrFalse) {
    Vue.set(state, "newMutation", trueOrFalse);
  },
  updateCellAttr(state, arrayOfCells) {
    const tmpWorksheet = clone(state.worksheets);
    arrayOfCells.forEach(
      ({ value, cellId, row, col, sheetName, cellObjAttrToUpdate }) => {
        if (
          !tmpWorksheet.Sheets[sheetName][row] ||
          !tmpWorksheet.Sheets[sheetName][row][col]
        )
          tmpWorksheet.Sheets[sheetName][row][col] = {
            v: null,
            s: value
          };
        if (!tmpWorksheet.Sheets[sheetName][row][col][cellObjAttrToUpdate])
          tmpWorksheet.Sheets[sheetName][row][col][cellObjAttrToUpdate] = {};
        if (cellObjAttrToUpdate === "s") {
          if (
            value &&
            value.alignment &&
            !value.font &&
            !value.fill &&
            !value.border
          ) {
            if (
              !tmpWorksheet.Sheets[sheetName][row][col][cellObjAttrToUpdate]
                .alignment
            )
              tmpWorksheet.Sheets[sheetName][row][col][
                cellObjAttrToUpdate
              ].alignment = {};
            if (value.alignment.indent) {
              if (
                !tmpWorksheet.Sheets[sheetName][row][col][cellObjAttrToUpdate]
                  .alignment.indent
              )
                tmpWorksheet.Sheets[sheetName][row][col][
                  cellObjAttrToUpdate
                ].alignment.indent = 0;
              tmpWorksheet.Sheets[sheetName][row][col][
                cellObjAttrToUpdate
              ].alignment.indent += value.alignment.indent;
              if (
                tmpWorksheet.Sheets[sheetName][row][col][cellObjAttrToUpdate]
                  .alignment.indent < 0
              )
                tmpWorksheet.Sheets[sheetName][row][col][
                  cellObjAttrToUpdate
                ].alignment.indent = 0;
            } else {
              tmpWorksheet.Sheets[sheetName][row][col][
                cellObjAttrToUpdate
              ].alignment = {
                ...tmpWorksheet.Sheets[sheetName][row][col][cellObjAttrToUpdate]
                  .alignment,
                ...value.alignment
              };
            }
          } else {
            tmpWorksheet.Sheets[sheetName][row][col][cellObjAttrToUpdate] = {
              ...tmpWorksheet.Sheets[sheetName][row][col][cellObjAttrToUpdate],
              ...value
            };
          }
        } else if (cellObjAttrToUpdate === "z") {
          if (value == state.timeZoneTimeFormat) {
            const tmpUnixDate = moment.tz(
              tmpWorksheet.Sheets[sheetName][row][col].v,
              state.currentTimeZone
            );
            if (!isNaN(tmpUnixDate) && tmpUnixDate.isValid()) {
              tmpWorksheet.Sheets[sheetName][row][col].t = "d";
              if (!tmpWorksheet.Sheets[sheetName][row][col].v)
                tmpWorksheet.Sheets[sheetName][row][col].v = tmpUnixDate.format(
                  state.timeZoneTimeFormat
                );
              tmpWorksheet.Sheets[sheetName][row][col].w = tmpUnixDate.format(
                state.timeZoneTimeFormat
              );
            }
          } else {
            if (value == "General") {
              tmpWorksheet.Sheets[sheetName][row][col].t = "s";
            } else if (
              (value == "#,##0.0" || value.includes("%")) &&
              tmpWorksheet.Sheets[sheetName][row][col].v &&
              tmpWorksheet.Sheets[sheetName][row][col].v
                .toString()
                .includes("%")
            ) {
              tmpWorksheet.Sheets[sheetName][row][col].t = "n";
              tmpWorksheet.Sheets[sheetName][row][col].v =
                parseFloat(tmpWorksheet.Sheets[sheetName][row][col].v) / 100;
            } else {
              tmpWorksheet.Sheets[sheetName][row][col].t = "n";
            }
            if (tmpWorksheet.Sheets[sheetName][row][col].w)
              delete tmpWorksheet.Sheets[sheetName][row][col].w;
          }
          tmpWorksheet.Sheets[sheetName][row][col][cellObjAttrToUpdate] = value;
          XLSX.utils.format_cell(tmpWorksheet.Sheets[sheetName][row][col]);
        } else {
          tmpWorksheet.Sheets[sheetName][row][col][cellObjAttrToUpdate] = value;
        }
      }
    );
    const ws = tmpWorksheet.Sheets[state.selectedWorksheetName];
    if (ws["!ref"]) {
      var range = XLSX.utils.decode_range(ws["!ref"]);
      var tmpCell = XLSX.utils.decode_cell(
        arrayOfCells[arrayOfCells.length - 1].cellId
      );
      if (range.e.c < tmpCell.c || range.e.r < tmpCell.r) {
        ws["!ref"] = "A0:" + arrayOfCells[arrayOfCells.length - 1].cellId;
      }
    } else {
      ws["!ref"] = "A0:" + arrayOfCells[arrayOfCells.length - 1].cellId;
    }
    Vue.set(state, "worksheets", Object.freeze(tmpWorksheet));
  },
  updateSsBottomMenu(state, newMenu) {
    Vue.set(state, "spreadsheetBottomMenu", newMenu);
  },
  updateColAttr(state, { colIndex, newWidth }) {
    const tmpWorksheet = clone(state.worksheets);
    if (!tmpWorksheet.Sheets[state.selectedWorksheetName]["!cols"])
      tmpWorksheet.Sheets[state.selectedWorksheetName]["!cols"] = [];
    const colLength =
      tmpWorksheet.Sheets[state.selectedWorksheetName]["!cols"].length;
    if (colLength <= colIndex) {
      for (
        var i = colLength;
        i < state.colCount[state.selectedWorksheetName];
        i++
      ) {
        if (i == colIndex) {
          tmpWorksheet.Sheets[state.selectedWorksheetName]["!cols"].push({
            wpx: newWidth
          });
          break;
        } else {
          tmpWorksheet.Sheets[state.selectedWorksheetName]["!cols"].push(null);
        }
      }
    } else {
      for (let i = 0; i < colLength; i++) {
        if (i == colIndex) {
          tmpWorksheet.Sheets[state.selectedWorksheetName]["!cols"][i] = {
            ...tmpWorksheet.Sheets[state.selectedWorksheetName]["!cols"][i],
            ...{
              wpx: newWidth
            }
          };
          delete tmpWorksheet.Sheets[state.selectedWorksheetName]["!cols"][i]
            .width;
          delete tmpWorksheet.Sheets[state.selectedWorksheetName]["!cols"][i]
            .wch;
          break;
        }
      }
    }
    Vue.set(state, "worksheets", Object.freeze(tmpWorksheet));
  },
  updateRowAttr(state, { rowIndex, newHeight }) {
    const tmpWorksheet = clone(state.worksheets);
    if (!tmpWorksheet.Sheets[state.selectedWorksheetName]["!rows"])
      tmpWorksheet.Sheets[state.selectedWorksheetName]["!rows"] = [];
    const rowLength =
      tmpWorksheet.Sheets[state.selectedWorksheetName]["!rows"].length;
    if (rowLength <= rowIndex) {
      for (
        var i = rowLength;
        i < state.rowCount[state.selectedWorksheetName];
        i++
      ) {
        if (i == rowIndex) {
          tmpWorksheet.Sheets[state.selectedWorksheetName]["!rows"].push({
            hpx: newHeight
          });
          break;
        } else {
          tmpWorksheet.Sheets[state.selectedWorksheetName]["!rows"].push(null);
        }
      }
    } else {
      for (let i = 0; i < rowLength; i++) {
        if (i == rowIndex) {
          tmpWorksheet.Sheets[state.selectedWorksheetName]["!rows"][i] = {
            ...tmpWorksheet.Sheets[state.selectedWorksheetName]["!rows"][i],
            ...{
              hpx: newHeight
            }
          };
          delete tmpWorksheet.Sheets[state.selectedWorksheetName]["!rows"][i]
            .hpt;
          break;
        }
      }
    }
    Vue.set(state, "worksheets", Object.freeze(tmpWorksheet));
  },
  updateNameRange(state, { nameRangeIndex, nameRangeText }) {
    const newNameArray = clone(state.worksheets.Workkbook.Names);
    newNameArray[nameRangeIndex].Name = nameRangeText;
    Vue.set(state.worksheets.Workbook, "Names", newNameArray);
  },
  deleteNameRange(state, nameRangeIndex) {
    const newNameArray = clone(state.worksheets.Workbook.Names);
    newNameArray.splice(nameRangeIndex, 1);
    Vue.set(state.worksheets.Workbook, "Names", newNameArray);
  },
  insertNameRange(state, { highlightRange, nameRangeText }) {
    const newNameArray = clone(state.worksheets.Workbook.Names);
    newNameArray.push({
      Ref: highlightRange,
      Name: nameRangeText
    });
    Vue.set(state.worksheets.Workbook, "Names", newNameArray);
  },
  setNameRangeDict(state, newDict) {
    Vue.set(state, "nameRangeReplaceDict", newDict);
  },
  updateHighlightCellGroup(state, newGroup) {
    Vue.set(state, "highlightCellGroup", newGroup);
  },
  saveHighlightCells(state, highlightCells) {
    Vue.set(state, "savedHighlightCells", highlightCells);
  },
  updateDefaultCdbMetrics(state, newCDBMetrics) {
    Vue.set(state, "TMP_DEFAULT_CDB_SHEET", Object.freeze(newCDBMetrics));
  },
  updateSheetNameId(state, { sheetName, sheetId }) {
    Vue.set(state.sheetIds, sheetName, sheetId);
  },
  removeSheetNameIds(state, removeArray) {
    if (removeArray.length === 0) {
      Vue.set(state, "sheetIds", {});
    } else {
      /*
      // NOTE not used for now - to be implemented
      const newObj = clone(state.sheetIds);
      for (const key in newObj) {
        if (removeArray.includes(newObj[key])) {
          delete newObj[key];
        }
      }
      Vue.set(state.sheetIds, sheetName, sheetId);
      */
    }
  },
  updateCdbIdMap(state, newMap) {
    Vue.set(state, "cdbIdMap", Object.freeze(newMap));
  },
  updateSheetGetUrl(state, newUrls) {
    Vue.set(state, "sheetGetUrl", newUrls);
  },
  updateDependencyTree(state, deps) {
    Vue.set(state, "deps", Object.freeze(deps));
  },
  setSpreadsheetSID(state, sid) {
    Vue.set(state, "spreadsheetSecurityId", sid);
  },
  updateModelMetatdata(state, metadata) {
    for (const key in metadata) {
      Vue.set(state.worksheets.Workbook, key, metadata[key]);
    }
  }
};

export const actions = {
  remoteGetSecurityModelData({ commit, rootState }, model_id) {
    return this.$axios
      .$get(
        `/v1/security_model/entity/${rootState.user.selectedShownEntity}/model/${model_id}`
      )
      .then((r) => {
        // set security id for remote getting model
        if (r.security_id) {
          if (rootState.app_store.isDev && 2 < 1)
            commit("setSpreadsheetSID", "bd7dc7ea-0199-4f1b-8397-ee1e06771c24");
          else commit("setSpreadsheetSID", r.security_id);
        }
        const workbook = {
          Sheets: {},
          SheetNames: [],
          Workbook: r.model_metadata || { Names: [] }
        };
        if (!workbook.Workbook.Names) workbook.Workbook["Names"] = [];
        const tmpSheetEndCol = {};
        const tmpSheetEndRow = {};
        const orderedSheets = r.model_sheets
          .filter((sheetObj) => sheetObj.model_sheet_status === "active")
          .sort(
            (sheetObj1, sheetObj2) =>
              sheetObj1.model_sheet_order - sheetObj2.model_sheet_order
          );
        const tmpUrls = {};
        for (const sheetObj of orderedSheets) {
          commit("updateSheetNameId", {
            sheetName: sheetObj.model_sheet_name,
            sheetId: sheetObj.model_sheet_id
          });
          if (
            sheetObj.model_sheet_storage &&
            sheetObj.model_sheet_storage !== null
          )
            tmpUrls[sheetObj.model_sheet_name] = sheetObj.model_sheet_storage;
          workbook.Sheets[sheetObj.model_sheet_name] = {};
          workbook.SheetNames.push(sheetObj.model_sheet_name);
          tmpSheetEndCol[sheetObj.model_sheet_name] =
            rootState.ssViewpoints.MAX_COLS;
          tmpSheetEndRow[sheetObj.model_sheet_name] =
            rootState.ssViewpoints.MAX_ROWS;
        }
        if (Object.keys(tmpUrls).length > 0)
          commit("updateSheetGetUrl", tmpUrls);
        commit("setDefaultWorksheet", workbook);
        commit("updateWorksheetdata", workbook);
        // commit('setErrorCells', {});
        commit("updateColRowCount", {
          colCount: tmpSheetEndCol,
          rowCount: tmpSheetEndRow
        });
        commit(
          "setSelectedSheet",
          workbook.SheetNames[1] || workbook.SheetNames[0]
        );
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteGetCDBMetrics({
    commit,
    getters
    // rootState
  }) {
    // return true;
    return this.$axios
      .$get(`/v1/cdb/grouping/`)
      .then((r) => {
        // create cdb sheet
        // let cdbSheet = {
        //     "!cols": [null, {
        //         wpx: 200
        //     }]
        // };
        const cdbSheet = new Array(136);
        cdbSheet["!cols"] = [
          null,
          {
            wpx: 200
          }
        ];
        const cdbIds = [];
        let i, j, k, l, groupLen, paramsLen, metricLen, metricUnitLen;
        const startingCol = 1;
        const groupHeaderStyle = {
          bgColor: {
            rgb: "D0D3D4"
          },
          sz: 14,
          bold: true,
          color: {
            rgb: "000000"
          }
        };
        for (i = 0, groupLen = r.length; i < groupLen; i++) {
          if (r[i].grouping_status !== "active") continue;
          //group heading
          const tmpRowIndex = r[i].grouping_row_index;
          // const groupCellNumber = getters.encodeCell({
          //     r: tmpRowIndex,
          //     c: startingCol
          // });
          if (!cdbSheet[tmpRowIndex]) cdbSheet[tmpRowIndex] = [];
          cdbSheet[tmpRowIndex][startingCol] = {
            v: r[i].grouping_name,
            s: groupHeaderStyle
          };
          // group unit
          for (
            j = 0, paramsLen = r[i].grouping_params.length;
            j < paramsLen;
            j++
          ) {
            // const tmpGroupUnit = getters.encodeCell({
            //     r: r[i].grouping_row_index,
            //     c: startingCol + j + 1
            // });
            if (!cdbSheet[r[i].grouping_row_index])
              cdbSheet[r[i].grouping_row_index] = [];
            cdbSheet[r[i].grouping_row_index][startingCol + j + 1] = {
              v: r[i].grouping_params[j],
              s: groupHeaderStyle
            };
          }
          // metrics (each row)
          for (k = 0, metricLen = r[i].metrics.length; k < metricLen; k++) {
            if (r[i].metrics[k].metrics_status !== "active") continue;
            // const tmpMetricTitle = getters.encodeCell({
            //     r: r[i].metrics[k].metrics_row_index,
            //     c: startingCol
            // });
            if (!cdbSheet[r[i].metrics[k].metrics_row_index])
              cdbSheet[r[i].metrics[k].metrics_row_index] = [];
            cdbSheet[r[i].metrics[k].metrics_row_index][startingCol] = {
              v: r[i].metrics[k].metrics_name,
              s: {
                color: {
                  rbg:
                    r[i].metrics[k].metrics_style.indexOf("italic") >= 0
                      ? "808080"
                      : "FFFFFF"
                },
                bold: r[i].metrics[k].metrics_style.indexOf("bold") >= 0,
                italic: r[i].metrics[k].metrics_style.indexOf("italic") >= 0
              }
            };
            // each column
            const paramCells = [];
            for (
              l = 0, metricUnitLen = r[i].metrics[k].metrics_params.length;
              l < metricUnitLen;
              l++
            ) {
              paramCells.push(
                getters.encodeCell({
                  r: r[i].metrics[k].metrics_row_index,
                  c: startingCol + l + 1
                })
              );
            }
            cdbIds.push({
              metric_id: r[i].metrics[k].metrics_id,
              params: r[i].metrics[k].metrics_params,
              params_cells: paramCells
            });
          }
        }
        commit("updateDefaultCdbMetrics", cdbSheet);
        commit("updateCdbIdMap", cdbIds);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  async remoteGetSheetData({ commit, state, rootState, getters, dispatch }) {
    const promiseArray = [];
    // const tmpSheetNameArray = [];
    for (const sheetName in state.sheetGetUrl) {
      promiseArray.push(
        fetch(state.sheetGetUrl[sheetName], {
          method: "GET"
        })
      );
      // tmpSheetNameArray.push(sheetName);
    }
    commit("updateSheetGetUrl", {}); // clear url as it only last for 15 min
    return Promise.all(promiseArray)
      .then(async (values) => {
        // console.log(jsonData);
        // ssPreGraph.clearObj();
        // ssDeGraph.clearObj();
        let i, sheetLen;
        const tmpWorksheet = clone(state.worksheets);
        const tmpSheetEndCol = {};
        const tmpSheetEndRow = {};
        const tmpNameRangeDict = {};
        let tmpCheckNameRangeRegex = null;
        if (
          tmpWorksheet.Workbook.Names &&
          tmpWorksheet.Workbook.Names.length > 0
        ) {
          tmpWorksheet.Workbook.Names.forEach((tmpNameObj) => {
            tmpNameRangeDict[tmpNameObj.Name.toUpperCase() + ")"] =
              tmpNameObj.Ref + ")";
            tmpNameRangeDict[tmpNameObj.Name.toUpperCase() + ","] =
              tmpNameObj.Ref + ",";
          });
          // tmpNameRangeDict = Object.assign({}, ...workbook.Workbook.Names);
          tmpCheckNameRangeRegex = new RegExp(
            Object.keys(tmpNameRangeDict)
              .join("|")
              .replace(/[.*+?^${}()[\]\\]/g, "\\$&"),
            "gi"
          );
          commit("setNameRangeDict", tmpNameRangeDict);
        }
        for (
          i = 0, sheetLen = tmpWorksheet.SheetNames.length;
          i < sheetLen;
          i++
        ) {
          // const sheetObj = values[i];
          const jsonData =
            values.length > 0 && values[i] ? await values[i].json() : null;
          const tmpSheetName = tmpWorksheet.SheetNames[i];
          tmpSheetEndCol[tmpSheetName] = rootState.ssViewpoints.MAX_COLS;
          tmpSheetEndRow[tmpSheetName] = rootState.ssViewpoints.MAX_ROWS;
          switch (tmpSheetName) {
            case state.CDB_SHEET_NAME:
              tmpWorksheet.Sheets[state.CDB_SHEET_NAME] = clone(
                state.TMP_DEFAULT_CDB_SHEET
              );
              if (jsonData) {
                const cdbMetricsLen = state.cdbIdMap.length;
                for (let j = 0; j < cdbMetricsLen; j++) {
                  const paramsLen = state.cdbIdMap[j].params.length;
                  for (let k = 0; k < paramsLen; k++) {
                    const cellId = state.cdbIdMap[j].params_cells[k];
                    const cellDecode = getters.decodeCell(cellId);
                    const columnHeading = state.cdbIdMap[j].params[k];
                    tmpWorksheet.Sheets[tmpSheetName][cellDecode.r][
                      cellDecode.c
                    ] =
                      jsonData[j] &&
                      jsonData[j].op_sheet_row_values &&
                      jsonData[j].op_sheet_row_values[columnHeading]
                        ? jsonData[j].op_sheet_row_values[columnHeading]
                        : {
                            v: "-"
                          };
                  }
                }
              }
              break;
            default:
              if (jsonData) tmpWorksheet.Sheets[tmpSheetName] = jsonData;
              break;
          }
          // can put as common method
          if (tmpWorksheet.Sheets[tmpSheetName]["!ref"]) {
            const tmpRange = XLSX.utils.decode_range(
              tmpWorksheet.Sheets[tmpSheetName]["!ref"]
            );
            tmpSheetEndCol[tmpSheetName] = tmpRange.e.c;
            tmpSheetEndRow[tmpSheetName] = tmpRange.e.r;
          } else {
            tmpSheetEndCol[tmpSheetName] = rootState.ssViewpoints.MAX_COLS;
            tmpSheetEndRow[tmpSheetName] = rootState.ssViewpoints.MAX_ROWS;
            tmpWorksheet.Sheets[tmpSheetName]["!ref"] = getters.encodeRange({
              s: {
                r: 0,
                c: 0
              },
              e: {
                r: rootState.ssViewpoints.MAX_ROWS,
                c: rootState.ssViewpoints.MAX_COLS
              }
            });
          }
          /*
                for (let tmpCellName in tmpWorksheet.Sheets[tmpSheetName]) {
                    if (tmpCellName[0] === '!') {
                        continue;
                    }
                    const tmpCellNameNumber = XLSX.utils.decode_cell(tmpCellName);
                    if (tmpSheetEndCol[tmpSheetName] < tmpCellNameNumber.c) {
                        tmpSheetEndCol[tmpSheetName] = tmpCellNameNumber.c;
                    }
                    if (tmpSheetEndRow[tmpSheetName] < tmpCellNameNumber.r) {
                        tmpSheetEndRow[tmpSheetName] = tmpCellNameNumber.r;
                    }
                    if (!ssPreGraph.getVertex(`${tmpSheetName}!${tmpCellName}`)) {
                        ssPreGraph.addVertex(`${tmpSheetName}!${tmpCellName}`);
                        ssDeGraph.addVertex(`${tmpSheetName}!${tmpCellName}`);
                    }
                    if (tmpWorksheet.Sheets[tmpSheetName][tmpCellName].f) {
                        // replace name ranges
                        tmpWorksheet.Sheets[tmpSheetName][tmpCellName].newF = tmpCheckNameRangeRegex ? tmpWorksheet.Sheets[tmpSheetName][tmpCellName].f.replace(tmpCheckNameRangeRegex, (matched) => {
                            return tmpNameRangeDict[matched];
                        }) : tmpWorksheet.Sheets[tmpSheetName][tmpCellName].f;
                        tmpWorksheet.Sheets[tmpSheetName][tmpCellName].refCells = getters.getRefCellsArray(tmpWorksheet.Sheets[tmpSheetName][tmpCellName].newF, tmpSheetName);

                        for (const tmpDependentSheet in tmpWorksheet.Sheets[tmpSheetName][tmpCellName].refCells) {
                            tmpWorksheet.Sheets[tmpSheetName][tmpCellName].refCells[tmpDependentSheet].forEach((tmpRangeObj) => {
                                for (let i = tmpRangeObj.s.c; i <= tmpRangeObj.e.c; i++) {
                                    for (let j = tmpRangeObj.s.r; j <= tmpRangeObj.e.r; j++) {
                                        const tmpDependentCellId = XLSX.utils.encode_cell({
                                            c: i,
                                            r: j
                                        });
                                        if (!ssPreGraph.getVertex(`${tmpDependentSheet}!${tmpDependentCellId}`)) {
                                            ssPreGraph.addVertex(`${tmpDependentSheet}!${tmpDependentCellId}`);
                                            ssDeGraph.addVertex(`${tmpDependentSheet}!${tmpDependentCellId}`);
                                        }
                                        ssPreGraph.addEdge(`${tmpDependentSheet}!${tmpDependentCellId}`, `${tmpSheetName}!${tmpCellName}`);
                                        ssDeGraph.addEdge(`${tmpSheetName}!${tmpCellName}`, `${tmpDependentSheet}!${tmpDependentCellId}`);
                                    }
                                }
                            });
                        }
                    }
                    // if (tmpWorksheet.Sheets[tmpSheetName][tmpCellName].t && tmpWorksheet.Sheets[tmpSheetName][tmpCellName].t === 'd' && typeof tmpWorksheet.Sheets[tmpSheetName][tmpCellName].v !== 'string') {
                    //     tmpWorksheet.Sheets[tmpSheetName][tmpCellName].v = tmpWorksheet.Sheets[tmpSheetName][tmpCellName].v.toString();
                    // }
                }
                */
        }
        // let deps = S5SCalc.build_deps(tmpWorksheet);
        // console.log(tmpWorksheet);
        // console.log(deps);
        // tmpWorksheet.Sheets["test1"][1] = [];
        // tmpWorksheet.Sheets['test1'][1][2] = {};
        // deps = S5SCalc.build_deps(tmpWorksheet);
        // deps = S5SCalc.update_value(tmpWorksheet, "test1", "B3", 3, deps);

        // commit("updateDependencyTree", deps);
        commit("setDefaultWorksheet", tmpWorksheet);
        commit("updateWorksheetdata", tmpWorksheet);
        commit("updateColRowCount", {
          colCount: tmpSheetEndCol,
          rowCount: tmpSheetEndRow
        });
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteSetWorksheetData(
    { commit, rootState, getters, dispatch, state },
    payload
  ) {
    return new Promise((resolve) => {
      // ssPreGraph.clearObj();
      // ssDeGraph.clearObj();
      //set the webworker for calculation (done in middle ware now)
      // dispatch('webworkers/remoteSetMaxWorker',
      //     process.client && window.navigator.hardwareConcurrency ? window.navigator.hardwareConcurrency : 0, {
      //         root: true
      //     }).then(() => {
      //     dispatch('webworkers/remoteCreateWorker', {
      //         type: 'sscalc',
      //     }, {
      //         root: true
      //     });
      // });
      // commit('changeLoadingState', true);
      //const t1 = performance.now();
      const workbook = XLSX.read(payload, {
        type: "array",
        cellStyles: true,
        cellNF: true,
        cellDates: true,
        dense: true
        // dateNF: true
      });
      // add central database sheet
      if (!workbook.Sheets[state.CDB_SHEET_NAME]) {
        // cant use variable as need the right orders
        workbook.Sheets = {
          "CDB Output": clone(state.TMP_DEFAULT_CDB_SHEET),
          ...workbook.Sheets
        };
      }
      if (workbook.SheetNames.indexOf(state.CDB_SHEET_NAME) < 0) {
        // workbook.SheetNames = [
        //     'CDB Output',
        //     ...workbook.SheetNames
        // ];
        workbook.SheetNames.splice(0, 0, state.CDB_SHEET_NAME);
      }
      //====
      // let errorCells = {};
      // let tmpFormatedSheets = {};
      // let tmpCellHeight = {};
      // let tmpCellWidth = {};
      const tmpSheetEndCol = {};
      const tmpSheetEndRow = {};
      const tmpNameRangeDict = {};
      let tmpCheckNameRangeRegex = null;
      if (workbook.Workbook.Names && workbook.Workbook.Names.length > 0) {
        workbook.Workbook.Names.forEach((tmpNameObj) => {
          tmpNameRangeDict[tmpNameObj.Name.toUpperCase() + ")"] =
            tmpNameObj.Ref + ")";
          tmpNameRangeDict[tmpNameObj.Name.toUpperCase() + ","] =
            tmpNameObj.Ref + ",";
        });
        // tmpNameRangeDict = Object.assign({}, ...workbook.Workbook.Names);
        tmpCheckNameRangeRegex = new RegExp(
          Object.keys(tmpNameRangeDict)
            .join("|")
            .replace(/[.*+?^${}()[\]\\]/g, "\\$&"),
          "gi"
        );
        commit("setNameRangeDict", tmpNameRangeDict);
      }
      for (const tmpSheetName in workbook.Sheets) {
        // tmpFormatedSheets[tmpSheetName] = [];
        // tmpCellHeight[tmpSheetName] = {};
        // tmpCellWidth[tmpSheetName] = {};
        // tmpSheetEndCol[tmpSheetName] = rootState.ssViewpoints.MAX_COLS;
        // tmpSheetEndRow[tmpSheetName] = rootState.ssViewpoints.MAX_ROWS;
        // merges here
        //
        if (workbook.Sheets[tmpSheetName]["!ref"]) {
          const tmpRange = XLSX.utils.decode_range(
            workbook.Sheets[tmpSheetName]["!ref"]
          );
          tmpSheetEndCol[tmpSheetName] = tmpRange.e.c + 20;
          tmpSheetEndRow[tmpSheetName] = tmpRange.e.r + 20;
        } else {
          tmpSheetEndCol[tmpSheetName] = rootState.ssViewpoints.MAX_COLS;
          tmpSheetEndRow[tmpSheetName] = rootState.ssViewpoints.MAX_ROWS;
        }
        /*
                for (let tmpCellName in workbook.Sheets[tmpSheetName]) {
                    if (tmpCellName[0] === "!") {
                        continue;
                    }
                    const tmpCellNameNumber = XLSX.utils.decode_cell(tmpCellName);
                    if (tmpSheetEndCol[tmpSheetName] < tmpCellNameNumber.c) {
                        tmpSheetEndCol[tmpSheetName] = tmpCellNameNumber.c;
                    }

                    if (tmpSheetEndRow[tmpSheetName] < tmpCellNameNumber.r) {
                        tmpSheetEndRow[tmpSheetName] = tmpCellNameNumber.r;
                    }
                    if (!ssPreGraph.getVertex(`${tmpSheetName}!${tmpCellName}`)) {
                        ssPreGraph.addVertex(`${tmpSheetName}!${tmpCellName}`);
                        ssDeGraph.addVertex(`${tmpSheetName}!${tmpCellName}`);
                    }
                    if (workbook.Sheets[tmpSheetName][tmpCellName].f) {
                        // replace name ranges
                        workbook.Sheets[tmpSheetName][tmpCellName].newF = tmpCheckNameRangeRegex ? workbook.Sheets[tmpSheetName][tmpCellName].f.replace(tmpCheckNameRangeRegex, (matched) => {
                            return tmpNameRangeDict[matched];
                        }) : workbook.Sheets[tmpSheetName][tmpCellName].f;
                        workbook.Sheets[tmpSheetName][tmpCellName].refCells = getters.getRefCellsArray(workbook.Sheets[tmpSheetName][tmpCellName].newF, tmpSheetName);

                        for (const tmpDependentSheet in workbook.Sheets[tmpSheetName][tmpCellName].refCells) {
                            workbook.Sheets[tmpSheetName][tmpCellName].refCells[tmpDependentSheet].forEach((tmpRangeObj) => {
                                for (let i = tmpRangeObj.s.c; i <= tmpRangeObj.e.c; i++) {
                                    for (let j = tmpRangeObj.s.r; j <= tmpRangeObj.e.r; j++) {
                                        const tmpDependentCellId = XLSX.utils.encode_cell({
                                            c: i,
                                            r: j
                                        });
                                        if (!ssPreGraph.getVertex(`${tmpDependentSheet}!${tmpDependentCellId}`)) {
                                            ssPreGraph.addVertex(`${tmpDependentSheet}!${tmpDependentCellId}`);
                                            ssDeGraph.addVertex(`${tmpDependentSheet}!${tmpDependentCellId}`);
                                        }
                                        ssPreGraph.addEdge(`${tmpDependentSheet}!${tmpDependentCellId}`, `${tmpSheetName}!${tmpCellName}`);
                                        ssDeGraph.addEdge(`${tmpSheetName}!${tmpCellName}`, `${tmpDependentSheet}!${tmpDependentCellId}`);
                                    }
                                }
                            });
                        }
                    }
                    if (workbook.Sheets[tmpSheetName][tmpCellName].t && workbook.Sheets[tmpSheetName][tmpCellName].t === 'd' && typeof workbook.Sheets[tmpSheetName][tmpCellName].v !== 'string') {
                        workbook.Sheets[tmpSheetName][tmpCellName].v = workbook.Sheets[tmpSheetName][tmpCellName].v.toString();
                    }
                    if (workbook.Sheets[tmpSheetName][tmpCellName].s) {
                        // console.log('id', tmpCellName);
                        // console.log(workbook.Sheets[tmpSheetName][tmpCellName].s);
                    }
                }
                */
      }
      // const t2 = performance.now();
      // let deps = S5SCalc.build_deps(workbook);
      // commit("updateDependencyTree", deps);
      // const t3 = performance.now();
      // console.log(t3 - t2, "build");
      // deps = S5SCalc.update_value(workbook, "Financials", "D3", 0.5, deps);
      // const t4 = performance.now();
      // console.log(t4 - t3, 'calc');
      // console.log(workbook.Sheets["Main inputs"][4][6]);
      // console.log(workbook.Sheets.Financials[2][3]);
      // console.log(workbook.Sheets.Financials[2][2]);
      // console.log(workbook.Sheets.Operations[116][24]);

      // ssPreGraph.printGraph();
      commit("setDefaultWorksheet", workbook);
      commit("updateWorksheetdata", workbook);
      // commit('setErrorCells', errorCells);
      commit("updateColRowCount", {
        colCount: tmpSheetEndCol,
        rowCount: tmpSheetEndRow
      });
      commit(
        "setSelectedSheet",
        workbook.SheetNames[workbook.SheetNames.length - 1]
      );
      dispatch("remoteUpdateDataSource", "EXCEL");
      dispatch("cdbDataCheck");
      commit("changeLoadingState", true);
      resolve(true);
    });
  },
  remoteSetSelectedWorksheet({ commit }, payload) {
    commit("changeLoadingState", true);
    commit("setSelectedSheet", payload);
    commit("changeLoadingState", false);
  },
  remoteCalculateAllWorksheets({ commit, getters, rootState }) {
    //NOTE can see how to incorperate webworkers later
    return Promise.resolve(true);
    /*
    return new Promise((resolve) => {
      return;
      const tmpTree = getters.workOutWorksheetForCalculate; // not used but need to webworker
      let calcNodes = {};
      rootState.webworkers.ssCalcWorker.onmessage = (event) => {
        switch (event.data.action) {
          case "send node":
            if (event.data.sheet && event.data.cell && event.data.data) {
              if (!calcNodes[event.data.sheet])
                calcNodes[event.data.sheet] = {};
              if (!calcNodes[event.data.sheet][event.data.cell])
                calcNodes[event.data.sheet][event.data.cell] = event.data.data;
            }
            break;
          case "finish":
            commit("calculateAllWorksheets", calcNodes);
            break;
        }
      };
      rootState.webworkers.ssCalcWorker.postMessage({
        action: "calculate"
      });
      resolve(true);
    });
    */
  },
  /*
    saveAndCalculate({
        commit,
        getters,
    }, payload) {
        return new Promise((resolve) => {
            let resultArray = Array.isArray(payload) ? payload : [payload];
            commit('updateCellValue', resultArray.map((updateCell) => {
                if (typeof updateCell.value === 'string' && updateCell.value.substring(0, 1) === '=') {
                    const {
                        tmpCheckNameRangeRegex,
                        tmpCheckNameRangeRegexFunction
                    } = getters.getNameRangeRegex;
                    const newF = tmpCheckNameRangeRegex ? updateCell.value.replace(tmpCheckNameRangeRegex, tmpCheckNameRangeRegexFunction) : updateCell.value;
                    return {
                        ...updateCell,
                        newF,
                        refCells: getters.getRefCellsArray(newF, updateCell.sheetName)
                    };
                } else {
                    return updateCell;
                }
            }));
            const tmpTree = getters.workOutWorksheetForCalculate; // not used but need to webworker
            let calcNodes = {};
            rootState.webworkers.ssCalcWorker.onmessage = (event) => {
                switch (event.data.action) {
                    case 'send node':
                        if (event.data.sheet && event.data.cell && event.data.data) {
                            if (!calcNodes[event.data.sheet])
                                calcNodes[event.data.sheet] = {};
                            if (!calcNodes[event.data.sheet][event.data.cell])
                                calcNodes[event.data.sheet][event.data.cell] = event.data.data;
                        }
                        break;
                    case 'finish':
                        commit('calculateAllWorksheets', calcNodes);
                        break;
                }
            };
            rootState.webworkers.ssCalcWorker.postMessage({
                action: 'calculate'
            });
            resolve(true);
        });
    },
    */
  saveCellValue(
    {
      commit,
      // getters,
      dispatch
    },
    payload
  ) {
    return new Promise((resolve, reject) => {
      const resultArray = Array.isArray(payload) ? payload : [payload];
      // if has external api need to reset it since they upload new model from s3
      dispatch("remoteUpdateDataSource", "RANOS");
      commit("updateCellValue", resultArray);
      // commit('updateCellValue', resultArray.map((updateCell) => {
      //     if (typeof updateCell.value === 'string' && updateCell.value.substring(0, 1) === '=') {
      //         const {
      //             equationReplaceRegex,
      //             equationReplaceRegexFunction
      //         } = rootGetters['keyInput/EQUATION_REPLACE_REGEX'];
      //         if (equationReplaceRegex) {
      //             updateCell.value = updateCell.value.replace(equationReplaceRegex, equationReplaceRegexFunction);
      //         }
      //         const {
      //             tmpCheckNameRangeRegex,
      //             tmpCheckNameRangeRegexFunction
      //         } = getters.getNameRangeRegex;
      //         const newF = tmpCheckNameRangeRegex ? updateCell.value.replace(tmpCheckNameRangeRegex, tmpCheckNameRangeRegexFunction) : updateCell.value;
      //         return {
      //             ...updateCell,
      //             newF,
      //             refCells: getters.getRefCellsArray(newF, updateCell.sheetName)
      //         };
      //     } else if (typeof updateCell.value === 'string' && updateCell.value.slice(-1) == "%" && !isNaN(updateCell.value.slice(0, -1))) {
      //         return {
      //             ...updateCell,
      //             id: updateCell.id,
      //             sheetName: updateCell.sheetName,
      //             value: parseFloat(updateCell.value) / 100,
      //             format: '0.0%'
      //         };
      //     } else {
      //         return updateCell;
      //     }
      // }));
      resolve(true);
    });
  },
  remoteToggleLoading({ commit }, paylaod) {
    commit("changeLoadingState", paylaod);
  },
  async remoteWorkoutVisibility({ commit, state, rootState, dispatch }) {
    return new Promise((resolve) => {
      const tmpVisibleCol = {};
      const tmpVisibleRow = {};
      const tmpFreezeCol = {};
      const tmpFreezeRow = {};
      let freezeBool = true;
      const freezedColIndex = [];
      const freezedRowIndex = [];
      for (const tmpSheetName in state.colCount) {
        tmpVisibleCol[tmpSheetName] = [];
        tmpFreezeCol[tmpSheetName] = [];
        freezedColIndex[tmpSheetName] = 0;
        freezeBool = true;
        let tmpX = 0;
        const tmpColCheck =
          state.feezeCells[tmpSheetName] && state.feezeCells[tmpSheetName].col
            ? state.feezeCells[tmpSheetName].col
            : rootState.ssViewpoints.DEFAULT_FREEZE_COL;
        for (
          let i = 0;
          i < state.colCount[tmpSheetName] + rootState.ssViewpoints.COL_PADDING;
          i++
        ) {
          const tmpColLetter = XLSX.utils.encode_col(i);
          if (tmpColLetter === tmpColCheck) {
            freezedColIndex[tmpSheetName] = i;
            freezeBool = false;
          }
          let tmpWidth = rootState.ssViewpoints.SINGLE_CELL_W;
          let tmpColHidden = false;
          if (
            state.worksheets.Sheets[tmpSheetName] &&
            state.worksheets.Sheets[tmpSheetName]["!cols"] &&
            state.worksheets.Sheets[tmpSheetName]["!cols"][i]
          ) {
            if (
              state.worksheets.Sheets[tmpSheetName]["!cols"][i].wpx &&
              state.worksheets.Sheets[tmpSheetName]["!cols"][i].wpx >
                rootState.ssViewpoints.SINGLE_CELL_W
            ) {
              tmpWidth = state.worksheets.Sheets[tmpSheetName]["!cols"][i].wpx;
            }
            if (state.worksheets.Sheets[tmpSheetName]["!cols"][i].hidden) {
              tmpColHidden = true;
            }
          }

          const tmpObj = {
            colIndex: i,
            showCol: tmpColLetter,
            actualCol: tmpColLetter,
            colWidth:
              tmpWidth > rootState.ssViewpoints.MAX_CELL_WIDTH
                ? rootState.ssViewpoints.MAX_CELL_WIDTH
                : tmpWidth,
            hidden: tmpColHidden
          };
          if (freezeBool) {
            tmpFreezeCol[tmpSheetName].push(tmpObj);
          } else if (!tmpColHidden) {
            tmpVisibleCol[tmpSheetName].push(tmpObj);
            tmpX += tmpWidth;
          }
          if (tmpX >= rootState.ssViewpoints.viewWidth) {
            break;
          }
        }
        tmpVisibleRow[tmpSheetName] = [];
        tmpFreezeRow[tmpSheetName] = [];
        freezedRowIndex[tmpSheetName] = 0;
        freezeBool = true;
        const tmpRowCheck =
          state.feezeCells[tmpSheetName] && state.feezeCells[tmpSheetName].row
            ? state.feezeCells[tmpSheetName].row
            : rootState.ssViewpoints.DEFAULT_FREEZE_ROW;
        let tmpY = 0;
        for (
          let j = 0;
          j < state.rowCount[tmpSheetName] + rootState.ssViewpoints.ROW_PADDING;
          j++
        ) {
          const tmpRowLetter = XLSX.utils.encode_row(j);
          if (tmpRowLetter === tmpRowCheck) {
            freezedRowIndex[tmpSheetName] = j;
            freezeBool = false;
          }
          let tmpHeight = rootState.ssViewpoints.SINGLE_CELL_H;
          let tmpRowHidden = false;
          if (
            state.worksheets.Sheets[tmpSheetName] &&
            state.worksheets.Sheets[tmpSheetName]["!rows"] &&
            state.worksheets.Sheets[tmpSheetName]["!rows"][j]
          ) {
            if (
              state.worksheets.Sheets[tmpSheetName]["!rows"][j].hpx &&
              state.worksheets.Sheets[tmpSheetName]["!rows"][j].hpx >
                rootState.ssViewpoints.SINGLE_CELL_H
            ) {
              tmpHeight = state.worksheets.Sheets[tmpSheetName]["!rows"][j].hpx;
            }
            if (state.worksheets.Sheets[tmpSheetName]["!rows"][j].hidden) {
              tmpRowHidden = true;
            }
          }

          const tmpObj = {
            rowIndex: j,
            showRow: tmpRowLetter,
            actualRow: tmpRowLetter,
            rowHeight:
              tmpHeight > rootState.ssViewpoints.MAX_CELL_HEIGHT
                ? rootState.ssViewpoints.MAX_CELL_HEIGHT
                : tmpHeight,
            hidden: tmpRowHidden
          };
          if (freezeBool) {
            tmpFreezeRow[tmpSheetName].push(tmpObj);
          } else if (!tmpRowHidden) {
            tmpVisibleRow[tmpSheetName].push(tmpObj);
            tmpY += tmpHeight;
          }
          if (tmpY >= rootState.ssViewpoints.viewHeight) {
            break;
          }
        }
      }
      dispatch(
        "ssViewpoints/remoteUpdateFreezeColAndRow",
        {
          freezeCol: tmpFreezeCol,
          freezeRow: tmpFreezeRow
        },
        {
          root: true
        }
      );
      dispatch(
        "ssViewpoints/remoteUpdateVisibleColAndRow",
        {
          visibleCol: tmpVisibleCol,
          visibleRow: tmpVisibleRow
        },
        {
          root: true
        }
      );
      dispatch(
        "ssViewpoints/remoteUpdateFreezeCellIndex",
        {
          freezeColIndex: freezedColIndex,
          freezeRowIndex: freezedRowIndex
        },
        {
          root: true
        }
      );
      commit("changeLoadingState", false);
      resolve(true);
    });
  },
  async remoteWorkoutVisibleCol({
    commit,
    state,
    rootState,
    getters,
    dispatch
  }) {
    const tmpVisibleCol = {};
    tmpVisibleCol[state.selectedWorksheetName] = [];
    let tmpX = 0;
    for (
      let i = 0;
      i <
      rootState.ssViewpoints.visibleCols[state.selectedWorksheetName].length;
      i++
    ) {
      const tmpObj = getters.getColIndex(
        rootState.ssViewpoints.visibleCols[state.selectedWorksheetName][i]
          .colIndex,
        state.selectedWorksheetName
      );
      if (!tmpObj.hidden) {
        tmpVisibleCol[state.selectedWorksheetName].push(tmpObj);
        tmpX += tmpObj.colWidth;
      }
      if (tmpX >= rootState.ssViewpoints.viewWidth) {
        break;
      }
    }
    if (tmpX < rootState.ssViewpoints.viewWidth) {
      for (
        let j =
          tmpVisibleCol[state.selectedWorksheetName][
            tmpVisibleCol[state.selectedWorksheetName].length - 1
          ].colIndex + 1;
        j <=
        state.colCount[state.selectedWorksheetName] +
          rootState.ssViewpoints.COL_PADDING;
        j++
      ) {
        const tmpObj = getters.getColIndex(j, state.selectedWorksheetName);
        if (!tmpObj.hidden) {
          tmpVisibleCol[state.selectedWorksheetName].push(tmpObj);
          tmpX += tmpObj.colWidth;
        }
        if (tmpX >= rootState.ssViewpoints.viewWidth) {
          break;
        }
      }
    }
    dispatch(
      "ssViewpoints/remoteUpdateVisibleCol",
      {
        sheetName: state.selectedWorksheetName,
        visibleCol: tmpVisibleCol[state.selectedWorksheetName]
      },
      {
        root: true
      }
    );
  },
  async remoteWorkoutVisibleRow({
    commit,
    state,
    rootState,
    getters,
    dispatch
  }) {
    const tmpVisibleRow = {};
    tmpVisibleRow[state.selectedWorksheetName] = [];
    let tmpY = 0;
    for (
      let i = 0;
      i <
      rootState.ssViewpoints.visibleRows[state.selectedWorksheetName].length;
      i++
    ) {
      const tmpObj = getters.getRowIndex(
        rootState.ssViewpoints.visibleRows[state.selectedWorksheetName][i]
          .rowIndex,
        state.selectedWorksheetName
      );
      if (!tmpObj.hidden) {
        tmpVisibleRow[state.selectedWorksheetName].push(tmpObj);
        tmpY += tmpObj.rowHeight;
      }
      if (tmpY >= rootState.ssViewpoints.viewHeight) {
        break;
      }
    }
    if (tmpY < rootState.ssViewpoints.viewHeight) {
      for (
        let j =
          tmpVisibleRow[state.selectedWorksheetName][
            tmpVisibleRow[state.selectedWorksheetName].length - 1
          ].rowIndex + 1;
        j <=
        state.rowCount[state.selectedWorksheetName] +
          rootState.ssViewpoints.ROW_PADDING;
        j++
      ) {
        const tmpObj = getters.getRowIndex(j, state.selectedWorksheetName);
        if (!tmpObj.hidden) {
          tmpVisibleRow[state.selectedWorksheetName].push(tmpObj);
          tmpY += tmpObj.rowHeight;
        }
        if (tmpY >= rootState.ssViewpoints.viewHeight) {
          break;
        }
      }
    }
    dispatch(
      "ssViewpoints/remoteUpdateVisibleRow",
      {
        sheetName: state.selectedWorksheetName,
        visibleRow: tmpVisibleRow[state.selectedWorksheetName]
      },
      {
        root: true
      }
    );
  },
  async selectInputCell({ commit }, payload) {
    commit("selectCell", payload);
  },
  async remoteUpdatePreviousSelectCell({ commit }) {
    commit("setPreviousSelectCell");
  },
  async selectFirstHighlightCell({ commit, state }, payload) {
    const updateColIndex =
      payload.col.colIndex > state.colCount[state.selectedWorksheetName] + 1
        ? state.colCount[state.selectedWorksheetName] + 1
        : payload.col.colIndex;
    const updateRowIndex =
      payload.row.rowIndex > state.rowCount[state.selectedWorksheetName]
        ? state.rowCount[state.selectedWorksheetName]
        : payload.row.rowIndex;
    // if its the same one
    if (
      state.highlightCells.row.length === 1 &&
      state.highlightCells.row[0] === updateRowIndex &&
      state.highlightCells.col.length === 1 &&
      state.highlightCells.col[0] === updateColIndex
    )
      return false;
    commit("changeHighlightCells", {
      row: [updateRowIndex],
      col: [updateColIndex]
    });
    return true;
  },
  async removeHighlightCells({ commit, state }) {
    // if its already empty
    if (
      state.highlightCells.row.length === 0 &&
      state.highlightCells.col.length === 0
    )
      return false;
    commit("changeHighlightCells", {
      row: [],
      col: []
    });
    return true;
  },
  async workoutHighlightCells(
    { commit, state, rootGetters, rootState },
    point
  ) {
    const { row: tmpRowObj, col: tmpColObj } =
      rootGetters["ssViewpoints/workoutCursorCell"](point);
    if (!tmpColObj || !tmpRowObj) return false;
    // if its the same one
    if (
      state.highlightCells.row[0] === tmpRowObj.rowIndex &&
      state.highlightCells.col[0] === tmpColObj.colIndex
    )
      return false;
    if (
      state.highlightCells.row[1] &&
      state.highlightCells.row[1] === tmpRowObj.rowIndex &&
      state.highlightCells.col[1] &&
      state.highlightCells.col[1] === tmpColObj.colIndex
    )
      return false;
    if (
      tmpColObj.colIndex > state.colCount[state.selectedWorksheetName] + 1 ||
      tmpRowObj.rowIndex > state.rowCount[state.selectedWorksheetName]
    )
      return false;
    commit("changeHighlightCells", {
      row: [state.highlightCells.row[0], tmpRowObj.rowIndex],
      col: [state.highlightCells.col[0], tmpColObj.colIndex]
    });
    return true;
  },
  selectSecondHighlightCell({ commit, state, rootState }, payload) {
    if (payload.col.colIndex > state.colCount[state.selectedWorksheetName] + 1)
      payload.col.colIndex = state.colCount[state.selectedWorksheetName] + 1;
    if (payload.row.rowIndex > state.rowCount[state.selectedWorksheetName])
      payload.row.rowIndex = state.rowCount[state.selectedWorksheetName];
    if (
      state.highlightCells.row[1] &&
      state.highlightCells.row[1] === payload.row.rowIndex &&
      state.highlightCells.col[1] &&
      state.highlightCells.col[1] === payload.col.colIndex
    )
      return false;
    commit("changeHighlightCells", {
      row: [state.highlightCells.row[0], payload.row.rowIndex],
      col: [state.highlightCells.col[0], payload.col.colIndex]
    });
    return true;
  },
  async remoteToggleCalculating({ commit }, trueOfFalse) {
    commit("toggleCalculating", trueOfFalse);
    return true;
  },
  async remoteSaveEndHighlightPoint({ state, commit, rootGetters }) {
    if (
      state.highlightCells.row.length === 0 ||
      state.highlightCells.col.length === 0
    )
      return false;
    const { startColIndex, endColIndex, startRowIndex, endRowIndex } =
      rootGetters["ssViewpoints/highlightSquare"];
    commit("saveEndHighlightPoint", {
      startColIndex,
      endColIndex,
      startRowIndex,
      endRowIndex
    });
    return true;
  },
  async remoteUpdateCopyCellsFormula({ commit, state, getters, rootGetters }) {
    if (
      state.highlightCells.col.length < 2 ||
      state.highlightCells.row.length < 2
    )
      return false;
    const copyFromCells = []; // 2 dimentional array to workout what to copy from
    const numberOfCopyRows =
      state.savedEndHighlighCell.e.r - state.savedEndHighlighCell.s.r;
    const numberOfCopyCols =
      state.savedEndHighlighCell.e.c - state.savedEndHighlighCell.s.c;
    for (
      let tmpColIndex = state.savedEndHighlighCell.s.c;
      tmpColIndex <= state.savedEndHighlighCell.e.c;
      tmpColIndex++
    ) {
      const tmpRowArray = [];
      for (
        let tmpRowIndex = state.savedEndHighlighCell.s.r;
        tmpRowIndex <= state.savedEndHighlighCell.e.r;
        tmpRowIndex++
      ) {
        copyFromCellId = getters
          .encodeCol(tmpColIndex)
          .concat(getters.encodeRow(tmpRowIndex));
        const copyCellObj = getters.getCellObj(copyFromCellId);
        if (copyCellObj)
          tmpRowArray.push({
            copyFromCellId,
            copyCellObj,
            r: tmpRowIndex,
            c: tmpColIndex
          });
        else
          tmpRowArray.push({
            copyFromCellId,
            copyCellObj: false
          });
      }
      copyFromCells.push(tmpRowArray);
    }
    const copyCellArray = [];
    let copyFromCellId, currentCellId, copyRowCount;
    let copyColCount = 0;
    for (
      let tmpColIndex = state.highlightCells.col[0];
      tmpColIndex <=
      (state.highlightCells.col[1]
        ? state.highlightCells.col[1]
        : state.highlightCells.col[0]);
      tmpColIndex++
    ) {
      copyRowCount = 0;
      for (
        let tmpRowIndex = state.highlightCells.row[0];
        tmpRowIndex <=
        (state.highlightCells.row[1]
          ? state.highlightCells.row[1]
          : state.highlightCells.row[0]);
        tmpRowIndex++
      ) {
        if (
          tmpColIndex >= state.savedEndHighlighCell.s.c &&
          tmpColIndex <= state.savedEndHighlighCell.e.c &&
          tmpRowIndex >= state.savedEndHighlighCell.s.r &&
          tmpRowIndex <= state.savedEndHighlighCell.e.r
        )
          continue;
        const copyFromCell = copyFromCells[copyColCount][copyRowCount];
        if (copyFromCell) {
          currentCellId = getters
            .encodeCol(tmpColIndex)
            .concat(getters.encodeRow(tmpRowIndex));
          if (copyFromCell.copyCellObj) {
            let returnValue = copyFromCell.copyCellObj.v || "";
            if (copyFromCell.copyCellObj.v == 0)
              returnValue = copyFromCell.copyCellObj.v;
            if (copyFromCell.copyCellObj.f) {
              returnValue = "=".concat(
                rootGetters["keyInput/updateFormulaCells"](
                  copyFromCell.copyCellObj.f,
                  {
                    row: tmpRowIndex - copyFromCell.r,
                    col: tmpColIndex - copyFromCell.c
                  }
                )
              );
            }
            copyCellArray.push({
              id: currentCellId,
              row: tmpRowIndex,
              col: tmpColIndex,
              value: returnValue,
              sheetName: state.selectedWorksheetName,
              style: copyFromCell.copyCellObj.s
                ? copyFromCell.copyCellObj.s
                : {},
              format: copyFromCell.copyCellObj.z
                ? copyFromCell.copyCellObj.z
                : {}
            });
          } else {
            // update to empty cell
            copyCellArray.push({
              id: currentCellId,
              row: tmpRowIndex,
              col: tmpColIndex,
              value: "",
              sheetName: state.selectedWorksheetName,
              style: {},
              format: {}
            });
          }
        }
        copyRowCount++;
        if (copyRowCount > numberOfCopyRows) copyRowCount = 0;
      }
      copyColCount++;
      if (copyColCount > numberOfCopyCols) copyColCount = 0;
    }
    if (copyCellArray.length > 0) {
      commit("updateCellValue", copyCellArray);
      /*
            commit('updateCellValue', copyCellArray.map((updateCell) => {
                updateCell.isDrag = true;
                if (typeof updateCell.value === 'string' && updateCell.value.substring(0, 1) === '=') {
                    const {
                        tmpCheckNameRangeRegex,
                        tmpCheckNameRangeRegexFunction
                    } = getters.getNameRangeRegex;
                    const newF = tmpCheckNameRangeRegex ? updateCell.value.replace(tmpCheckNameRangeRegex, tmpCheckNameRangeRegexFunction) : updateCell.value;
                    return {
                        ...updateCell,
                        newF,
                        refCells: getters.getRefCellsArray(newF, updateCell.sheetName),
                    };
                } else {
                    return updateCell;
                }
            }));
            */
    }
    return true;
  },
  async remoteSetCentralDatabaseSheet({ commit, rootState }) {
    const remoteDatabaseData = await this.$axios.$get(
      "/column-name/" + rootState.auth.user.entity_data[0].entity.entity_name
    );
    return false;
  },
  async remoteAddNewSheet({ commit, state, rootState }, newSheetName) {
    if (state.worksheets.SheetNames.indexOf(newSheetName) < 0) {
      const newData = clone(state.worksheets);
      newData.SheetNames.push(newSheetName);
      newData.Sheets[newSheetName] = {};
      commit("updateWorksheetdata", newData);
      commit("updateColRowCount", {
        colCount: {
          ...state.colCount,
          [newSheetName]: rootState.ssViewpoints.MAX_COLS
        },
        rowCount: {
          ...state.rowCount,
          [newSheetName]: rootState.ssViewpoints.MAX_ROWS
        }
      });
      return true;
    } else {
      return false;
    }
  },
  remoteAddToDoneList({ commit, state }, payload) {
    commit("addToDoneList", payload);
    // if new mutation is added then can not redo
    if (state.undoneList.length > 0 && state.newMutation) {
      commit("updateUndoneList", []);
    }
  },
  remoteUndo({ commit, state, getters, rootState }) {
    //reset all data
    commit("updateWorksheetdata", state.DEFAULT_WORKSHEET);
    const newList = clone(state.doneList);
    let keepLooping = true;
    while (keepLooping && newList.length > 0) {
      const tmpMutation = newList.pop();
      if (tmpMutation && tmpMutation.type) {
        commit("addToUndoneList", tmpMutation);
        keepLooping = false;
      }
    }
    commit("changeNewMutation", false);
    let lastSheetName = "";
    if (newList.length > 0) {
      newList.forEach((remainMutation) => {
        if (remainMutation && remainMutation.type) {
          commit(
            `${remainMutation.type.replace("spreadsheet/", "")}`,
            remainMutation.payload
          );
          lastSheetName = remainMutation.payload.sheetName;
        }
      });
    }
    /*
        if (lastSheetName && lastSheetName !== state.selectedWorksheetName) commit('setSelectedSheet', lastSheetName);
        const tmpTree = getters.workOutWorksheetForCalculate; // not used but need for webworker
        let calcNodes = {};
        rootState.webworkers.ssCalcWorker.onmessage = (event) => {
            switch (event.data.action) {
                case 'send node':
                    if (event.data.sheet && event.data.cell && event.data.data) {
                        if (!calcNodes[event.data.sheet])
                            calcNodes[event.data.sheet] = {};
                        if (!calcNodes[event.data.sheet][event.data.cell])
                            calcNodes[event.data.sheet][event.data.cell] = event.data.data;
                    }
                    break;
                case 'finish':
                    commit('calculateAllWorksheets', calcNodes);
                    commit('changeNewMutation', true);
                    // newList.push({
                    //     type: 'spreadsheet/calculateAllWorksheets'
                    // });
                    commit('updateDoneList', newList);
                    break;
            }
        };
        rootState.webworkers.ssCalcWorker.postMessage({
            action: 'calculate'
        });
        */
  },
  remoteRedo({ commit, state, getters, rootState }) {
    const newList = clone(state.undoneList);
    //workout what mutations are needed - keep poping until the second calculate
    let keepLoop = true;
    commit("changeNewMutation", false);
    let lastSheetName = "";
    while (keepLoop && newList.length > 0) {
      const tmpMutation = newList.pop();
      if (tmpMutation && tmpMutation.type) {
        commit(
          `${tmpMutation.type.replace("spreadsheet/", "")}`,
          tmpMutation.payload
        );
        lastSheetName = tmpMutation.payload.sheetName;
        keepLoop = false;
      }
    }
    if (lastSheetName && lastSheetName !== state.selectedWorksheetName)
      commit("setSelectedSheet", lastSheetName);
    /*
        const tmpTree = getters.workOutWorksheetForCalculate;
        let calcNodes = {};
        rootState.webworkers.ssCalcWorker.onmessage = (event) => {
            switch (event.data.action) {
                case 'send node':
                    if (event.data.sheet && event.data.cell && event.data.data) {
                        if (!calcNodes[event.data.sheet])
                            calcNodes[event.data.sheet] = {};
                        if (!calcNodes[event.data.sheet][event.data.cell])
                            calcNodes[event.data.sheet][event.data.cell] = event.data.data;
                    }
                    break;
                case 'finish':
                    commit('calculateAllWorksheets', calcNodes);
                    commit('changeNewMutation', true);
                    commit('updateUndoneList', newList);
                    break;
            }
        };
        rootState.webworkers.ssCalcWorker.postMessage({
            action: 'calculate'
        });
        */
  },
  remoteUpdateCellsType(
    { commit, getters, state, rootGetters, rootState },
    { startColIndex, endColIndex, startRowIndex, endRowIndex, changeType }
  ) {
    // let cellTypeToUpdate = [];
    const cellAttrToUpdate = [];
    for (
      let tmpColIndex = startColIndex;
      tmpColIndex <= endColIndex;
      tmpColIndex++
    ) {
      const tmpColNumber = getters.encodeCol(tmpColIndex);
      for (
        let tmpRowIndex = startRowIndex;
        tmpRowIndex <= endRowIndex;
        tmpRowIndex++
      ) {
        const currentCellId = tmpColNumber.concat(
          getters.encodeRow(tmpRowIndex)
        );
        const tmpCellObj = getters.getCellObj(currentCellId);
        let isNumber, numberOfDp;
        if (tmpCellObj && tmpCellObj.v) {
          switch (changeType) {
            case "number":
              isNumber = !isNaN(tmpCellObj.v);
              if (isNumber && (!tmpCellObj.t || tmpCellObj.t !== "n")) {
                cellAttrToUpdate.push({
                  value: "n",
                  cellId: currentCellId,
                  sheetName: state.selectedWorksheetName,
                  cellObjAttrToUpdate: "t"
                });
              } else if (!isNumber && tmpCellObj.t === "n") {
                cellAttrToUpdate.push({
                  type: "s",
                  cellId: currentCellId,
                  sheetName: state.selectedWorksheetName,
                  cellObjAttrToUpdate: "t"
                });
              }
              break;
            case "reduceDecimal":
              if (isNaN(tmpCellObj.v)) continue;
              if (tmpCellObj.t !== "n") {
                cellAttrToUpdate.push({
                  value: "n",
                  cellId: currentCellId,
                  sheetName: state.selectedWorksheetName,
                  cellObjAttrToUpdate: "t"
                });
              }
              if (tmpCellObj.z && tmpCellObj.z.includes("%")) {
                numberOfDp = rootGetters["keyInput/getNumberOfDecimals"](
                  tmpCellObj.w.replace("%", "") || tmpCellObj.v
                );
                if (numberOfDp >= 1) {
                  cellAttrToUpdate.push({
                    cellId: currentCellId,
                    value: parseInt("0").toFixed(numberOfDp - 1) + "%",
                    sheetName: state.selectedWorksheetName,
                    cellObjAttrToUpdate: "z"
                  });
                }
              } else {
                numberOfDp = rootGetters["keyInput/getNumberOfDecimals"](
                  tmpCellObj.w || tmpCellObj.v
                );
                if (numberOfDp >= 1) {
                  cellAttrToUpdate.push({
                    cellId: currentCellId,
                    value: parseInt("0")
                      .toFixed(numberOfDp - 1)
                      .toString(),
                    sheetName: state.selectedWorksheetName,
                    cellObjAttrToUpdate: "z"
                  });
                }
              }
              break;
            case "increaseDecimal":
              if (isNaN(tmpCellObj.v)) continue;
              if (tmpCellObj.t !== "n") {
                cellAttrToUpdate.push({
                  value: "n",
                  cellId: currentCellId,
                  sheetName: state.selectedWorksheetName,
                  cellObjAttrToUpdate: "t"
                });
              }
              if (tmpCellObj.z && tmpCellObj.z.includes("%")) {
                numberOfDp = rootGetters["keyInput/getNumberOfDecimals"](
                  tmpCellObj.w.replace("%", "") || tmpCellObj.v
                );
                if (numberOfDp < 5) {
                  cellAttrToUpdate.push({
                    cellId: currentCellId,
                    value: parseInt("0").toFixed(numberOfDp + 1) + "%",
                    sheetName: state.selectedWorksheetName,
                    cellObjAttrToUpdate: "z"
                  });
                }
              } else {
                numberOfDp = rootGetters["keyInput/getNumberOfDecimals"](
                  tmpCellObj.w || tmpCellObj.v
                );
                if (numberOfDp < 5) {
                  cellAttrToUpdate.push({
                    cellId: currentCellId,
                    value: parseInt("0")
                      .toFixed(numberOfDp + 1)
                      .toString(),
                    sheetName: state.selectedWorksheetName,
                    cellObjAttrToUpdate: "z"
                  });
                }
              }
              break;
          }
        } else {
          // the object does not exist
          cellAttrToUpdate.push({
            value: "n",
            cellId: currentCellId,
            sheetName: state.selectedWorksheetName,
            cellObjAttrToUpdate: "t"
          });
        }
      }
    }
    /*
        if (cellAttrToUpdate.length > 0) {
            commit('updateCellAttr', cellAttrToUpdate);
            const tmpTree = getters.workOutWorksheetForCalculate; // not used but need for webworker
            let calcNodes = {};
            rootState.webworkers.ssCalcWorker.onmessage = (event) => {
                switch (event.data.action) {
                    case 'send node':
                        if (event.data.sheet && event.data.cell && event.data.data) {
                            if (!calcNodes[event.data.sheet])
                                calcNodes[event.data.sheet] = {};
                            if (!calcNodes[event.data.sheet][event.data.cell])
                                calcNodes[event.data.sheet][event.data.cell] = event.data.data;
                        }
                        break;
                    case 'finish':
                        commit('calculateAllWorksheets', calcNodes);
                        break;
                }
            };
        }
        */
  },
  async remoteUpdateBottomMenu({ commit }, payload) {
    commit("updateSsBottomMenu", payload);
    return true;
  },
  remoteAccessToken({ commit, rootState }, { code }) {
    return this.$axios
      .$post("/authtoken", {
        code: code
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  remoteUpdateCellsStyle(
    { commit, getters, state, rootGetters },
    { startColIndex, endColIndex, startRowIndex, endRowIndex, style }
  ) {
    const cellAttrToUpdate = [];
    for (
      let tmpColIndex = startColIndex;
      tmpColIndex <= endColIndex;
      tmpColIndex++
    ) {
      const tmpColNumber = getters.encodeCol(tmpColIndex);
      for (
        let tmpRowIndex = startRowIndex;
        tmpRowIndex <= endRowIndex;
        tmpRowIndex++
      ) {
        const currentCellId = tmpColNumber.concat(
          getters.encodeRow(tmpRowIndex)
        );
        const tmpCellObj = getters.getCellObj(currentCellId);
        if (
          tmpCellObj &&
          tmpCellObj.t != "s" &&
          style.alignment &&
          (style.alignment.overflowText ||
            style.alignment.wrapText ||
            style.alignment.clipText)
        ) {
          continue;
        } else if (
          style.alignment &&
          style.alignment.indent &&
          style.alignment.indent > 0 &&
          tmpCellObj &&
          tmpCellObj.s &&
          tmpCellObj.s.alignment &&
          tmpCellObj.s.alignment.indent &&
          tmpCellObj.s.alignment.indent >= 4.8
        ) {
          return;
        } else if (
          style.border &&
          !style.fill &&
          !style.font &&
          !style.alignment
        ) {
          const tmpBorder = {};
          if (tmpRowIndex == startRowIndex) {
            tmpBorder.top = style.top;
          }
          if (tmpRowIndex == endRowIndex) {
            tmpBorder.bottom = style.bottom;
          }
          if (tmpColIndex == startColIndex) {
            tmpBorder.left = style.left;
          }
          if (tmpColIndex == endColIndex) {
            tmpBorder.right = style.right;
          }
          cellAttrToUpdate.push({
            value: tmpBorder,
            cellId: currentCellId,
            row: tmpRowIndex,
            col: tmpColIndex,
            sheetName: state.selectedWorksheetName,
            cellObjAttrToUpdate: "s"
          });
        } else {
          cellAttrToUpdate.push({
            value: style,
            cellId: currentCellId,
            row: tmpRowIndex,
            col: tmpColIndex,
            sheetName: state.selectedWorksheetName,
            cellObjAttrToUpdate: "s"
          });
        }
      }
    }
    if (cellAttrToUpdate.length > 0) {
      commit("updateCellAttr", cellAttrToUpdate);
    }
  },
  async updateCol({ commit }, { startCol, endCol, newWidth }) {
    for (var colIndex = startCol; colIndex <= endCol; colIndex++) {
      commit("updateColAttr", {
        colIndex,
        newWidth
      });
    }
    return true;
  },
  async updateRow({ commit }, { startRow, endRow, newHeight }) {
    for (var rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
      commit("updateRowAttr", {
        rowIndex,
        newHeight
      });
    }
    return true;
  },
  remoteGenerateFile({ state }) {
    // let tmpWork = JSON.parse(JSON.stringify(state.worksheets.Sheets));
    // for (const tmpSheetName in tmpWork) {
    //     for (const tmpCellName in tmpWork[tmpSheetName]) {
    //         if (tmpWork[tmpSheetName][tmpCellName].f) delete tmpWork[tmpSheetName][tmpCellName].f;
    //     }
    // }
    XLSX.writeFile(
      {
        SheetNames: state.worksheets.SheetNames,
        Sheets: state.worksheets.Sheets
      },
      "hello.xlsx"
    );
  },
  async remoteUpdateNameRange(
    { commit, state },
    { highlightRange, nameRangeText, nameRangeIndex }
  ) {
    const tmpNameRangeDict = clone(state.nameRangeReplaceDict);
    const newSheetData = clone(state.worksheets);
    let originalName, tmpCheckNameRangeMethod, tmpCheckNameRangeRegex;
    let updatedValues = false;
    if (nameRangeIndex > -1 && nameRangeText.length !== 0) {
      originalName = state.worksheets.Workbook.Names[nameRangeIndex].Name;
      delete tmpNameRangeDict[originalName + ")"];
      delete tmpNameRangeDict[originalName + ","];
      tmpNameRangeDict[nameRangeText.toUpperCase() + ")"] =
        highlightRange + ")";
      tmpNameRangeDict[nameRangeText.toUpperCase() + ","] =
        highlightRange + ",";
      commit("updateNameRange", {
        nameRangeIndex,
        nameRangeText
      });
      //update all formula with the range name to the new one
      tmpCheckNameRangeRegex = new RegExp(originalName + "[),]", "gi");
      tmpCheckNameRangeMethod = (match) => {
        updatedValues = true;
        switch (match) {
          case originalName + ")":
            return nameRangeText + ")";
          case originalName + ",":
            return nameRangeText + ",";
        }
      };
    } else if (nameRangeText.length === 0 && nameRangeIndex > -1) {
      if (state.worksheets.Workbook.Names.length === 0) return;
      originalName = state.worksheets.Workbook.Names[nameRangeIndex].Name;
      const originalRange = state.worksheets.Workbook.Names[nameRangeIndex].Ref;
      //update all formula with the range name to the range
      tmpCheckNameRangeRegex = new RegExp(originalName + "[),]", "gi");
      tmpCheckNameRangeMethod = (match) => {
        updatedValues = true;
        switch (match) {
          case originalName + ")":
            return originalRange + ")";
          case originalName + ",":
            return originalRange + ",";
        }
      };
      commit("deleteNameRange", {
        nameRangeIndex
      });
      delete tmpNameRangeDict[originalName + ")"];
      delete tmpNameRangeDict[originalName + ","];
    } else {
      commit("insertNameRange", {
        highlightRange,
        nameRangeText
      });
      tmpNameRangeDict[nameRangeText.toUpperCase() + ")"] =
        highlightRange + ")";
      tmpNameRangeDict[nameRangeText.toUpperCase() + ","] =
        highlightRange + ",";
      tmpCheckNameRangeRegex = new RegExp(
        highlightRange.replace(/[!$]/g, "\\$&") + "[),]",
        "gi"
      );
      tmpCheckNameRangeMethod = (match) => {
        updatedValues = true;
        switch (match) {
          case highlightRange + ")":
            return nameRangeText + ")";
          case highlightRange + ",":
            return nameRangeText + ",";
        }
      };
    }
    commit("setNameRangeDict", tmpNameRangeDict);
    for (const tmpSheetName in newSheetData.Sheets) {
      for (const tmpCellId in newSheetData.Sheets[tmpSheetName]) {
        if (newSheetData.Sheets[tmpSheetName][tmpCellId].f) {
          newSheetData.Sheets[tmpSheetName][tmpCellId].f = newSheetData.Sheets[
            tmpSheetName
          ][tmpCellId].f.replace(
            tmpCheckNameRangeRegex,
            tmpCheckNameRangeMethod
          );
        }
      }
    }
    if (updatedValues) {
      // if a cell formula need to be updated
      commit("updateWorksheetdata", newSheetData);
    }
  },
  /*
        inserts `num_cols` cols BEFORE specified `start_col`
        - ws         = worksheet object
        - start_col  = starting col (0-indexed) | default 0
        - num_cols   = number of cols to add    | default 1
        - opts       = options:
          + fill     = set to true to "fill" cell styles and formula
    */
  insertCols(
    { commit, getters, state },
    { start_col = 0, num_cols = 1, opts = {} }
  ) {
    const tmpWorksheet = clone(state.worksheets);
    const ws = tmpWorksheet.Sheets[state.selectedWorksheetName];
    if (!ws) throw new Error("operation expects a worksheet");
    if (!ws["!ref"]) ws["!ref"] = "A1";

    var range = XLSX.utils.decode_range(ws["!ref"]);
    var R = 0,
      C = 0;

    var update_formula = function ($0, $1, $2, $3, $4, $5) {
      var rowIndex = XLSX.utils.decode_row($5),
        colIndex = XLSX.utils.decode_col($3);
      if (!opts.fill ? colIndex >= start_col : C >= start_col)
        colIndex += num_cols;
      return (
        $1 +
        ($2 == "$" ? $2 + $3 : XLSX.utils.encode_col(colIndex)) +
        ($4 == "$" ? $4 + $5 : XLSX.utils.encode_row(rowIndex))
      );
    };

    var cur_cell, nex_cell, new_cell;
    /* move cells and update formula */
    /* cells after the insert */
    for (C = range.e.c; C >= start_col; --C) {
      for (R = range.s.r; R <= range.e.r; ++R) {
        cur_cell = XLSX.utils.encode_cell({
          r: R,
          c: C
        });
        nex_cell = XLSX.utils.encode_cell({
          r: R,
          c: C + num_cols
        });
        if (!ws[cur_cell]) {
          delete ws[nex_cell];
          continue;
        }
        if (opts.fill && (ws[cur_cell].s || ws[cur_cell].f)) {
          new_cell = {};
          if (ws[cur_cell].f) {
            new_cell.f = ws[cur_cell].f;
            new_cell.t = ws[cur_cell].t;
          } else {
            new_cell.t = "z";
          }
        }
        if (ws[cur_cell].f)
          ws[cur_cell].f = ws[cur_cell].f.replace(
            this.CREF_REGEX,
            update_formula
          );
        ws[nex_cell] = ws[cur_cell];
        if (opts.fill) {
          ws[cur_cell] = new_cell;
        }
        if (range.e.c < C + num_cols) range.e.c = C + num_cols;
      }
    }

    /* newly created space */
    if (!opts.fill)
      for (C = start_col; C < start_col + num_cols; ++C) {
        for (R = range.s.r; R <= range.e.r; ++R) {
          cur_cell = XLSX.utils.encode_cell({
            r: R,
            c: C
          });
          delete ws[cur_cell];
        }
      }

    /* cells before insert */
    for (C = 0; C < start_col; ++C) {
      for (R = range.s.r; R <= range.e.r; ++R) {
        cur_cell = XLSX.utils.encode_cell({
          r: R,
          c: C
        });
        if (ws[cur_cell] && ws[cur_cell].f)
          ws[cur_cell].f = ws[cur_cell].f.replace(
            this.CREF_REGEX,
            update_formula
          );
      }
    }

    /* write new range */
    ws["!ref"] = XLSX.utils.encode_range(getters.clampRange(range));

    /* merge cells */
    if (ws["!merges"])
      ws["!merges"].forEach(function (merge, idx) {
        var merge_range;
        switch (typeof merge) {
          case "string":
            merge_range = XLSX.utils.decode_range(merge);
            break;
          case "object":
            merge_range = merge;
            break;
          default:
            throw new Error("Unexpected merge ref " + merge);
        }
        if (merge_range.s.c >= start_col) merge_range.s.c += num_cols;
        if (merge_range.e.c >= start_col) merge_range.e.c += num_cols;
        getters.clampRange(merge_range);
        ws["!merges"][idx] = merge_range;
      });

    /* cols */
    var col_load = [start_col, 0];
    for (C = 0; C < num_cols; ++C) col_load.push(void 0);
    if (ws["!cols"]) ws["!cols"].splice.apply(ws["!cols"], col_load);

    commit("updateWorksheetdata", tmpWorksheet);
  },
  /*
        deletes `num_cols` cols STARTING WITH `start_col`
        - ws         = worksheet object
        - start_col  = starting col (0-indexed) | default 0
        - num_cols   = number of cols to delete | default 1
    */
  deleteCols({ commit, getters, state }, { start_col = 0, num_cols = 1 }) {
    const tmpWorksheet = clone(state.worksheets);
    const ws = tmpWorksheet.Sheets[state.selectedWorksheetName];
    if (!ws) throw new Error("operation expects a worksheet");
    if (!ws["!ref"]) ws["!ref"] = "A1";

    /* extract original range */
    var range = XLSX.utils.decode_range(ws["!ref"]);
    var R = 0,
      C = 0;

    if (start_col > range.e.c) {
      return;
    } else {
      for (var i = start_col; i <= start_col + num_cols; i++) {
        if (i > range.e.c) {
          num_cols--;
        }
      }
    }

    var update_formula = function ($0, $1, $2, $3, $4, $5) {
      var row_index = XLSX.utils.decode_row($5),
        col_index = XLSX.utils.decode_col($3);
      if (col_index >= start_col) {
        col_index -= num_cols;
        if (col_index < start_col) return "#REF!";
      }
      return (
        $1 +
        ($2 == "$" ? $2 + $3 : XLSX.utils.encode_col(col_index)) +
        ($4 == "$" ? $4 + $5 : XLSX.utils.encode_row(row_index))
      );
    };

    var cur_cell, pre_cell;
    /* move cells and update formula */
    /* cells after the delete */
    for (C = start_col + num_cols; C <= range.e.c; ++C) {
      for (R = range.s.r; R <= range.e.r; ++R) {
        cur_cell = XLSX.utils.encode_cell({
          r: R,
          c: C
        });
        pre_cell = XLSX.utils.encode_cell({
          r: R,
          c: C - num_cols
        });
        if (!ws[cur_cell]) {
          delete ws[pre_cell];
          continue;
        }
        if (ws[cur_cell].f)
          ws[cur_cell].f = ws[cur_cell].f.replace(
            this.CREF_REGEX,
            update_formula
          );
        ws[pre_cell] = ws[cur_cell];
      }
    }
    /* deleted cells */
    for (C = range.e.c; C > range.e.c - num_cols; --C) {
      for (R = range.s.r; R <= range.e.r; ++R) {
        cur_cell = XLSX.utils.encode_cell({
          r: R,
          c: C
        });
        delete ws[cur_cell];
      }
    }
    /* cells before the delete */
    for (C = 0; C < start_col; ++C) {
      for (R = range.s.r; R <= range.e.r; ++R) {
        cur_cell = XLSX.utils.encode_cell({
          r: R,
          c: C
        });
        if (ws[cur_cell] && ws[cur_cell].f)
          ws[cur_cell].f = ws[cur_cell].f.replace(
            this.CREF_REGEX,
            update_formula
          );
      }
    }

    /* write new range */
    range.e.c -= num_cols;
    if (range.e.c < 0) range.e.c = 0;
    if (range.e.c < range.s.c) range.e.c = range.s.c;
    ws["!ref"] = XLSX.utils.encode_range(getters.clampRange(range));

    /* merge cells */
    if (ws["!merges"])
      ws["!merges"].forEach(function (merge, idx) {
        var merge_range;
        switch (typeof merge) {
          case "string":
            merge_range = XLSX.utils.decode_range(merge);
            break;
          case "object":
            merge_range = merge;
            break;
          default:
            throw new Error("Unexpected merge ref " + merge);
        }
        if (merge_range.s.c >= start_col) {
          merge_range.s.c = Math.max(merge_range.s.c - num_cols, start_col);
          if (merge_range.e.c < start_col + num_cols) {
            delete ws["!merges"][idx];
            return;
          }
          merge_range.e.c -= num_cols;
          if (merge_range.e.c < merge_range.s.c) {
            delete ws["!merges"][idx];
            return;
          }
        } else if (merge_range.e.c >= start_col) merge_range.e.c = Math.max(merge_range.e.c - num_cols, start_col);
        getters.clampRange(merge_range);
        ws["!merges"][idx] = merge_range;
      });
    if (ws["!merges"])
      ws["!merges"] = ws["!merges"].filter(function (x) {
        return !!x;
      });

    /* cols */
    if (ws["!cols"]) ws["!cols"].splice(start_col, num_cols);

    commit("updateWorksheetdata", tmpWorksheet);
  },
  /*
        inserts `num_rows` rows BEFORE specified `start_row`
        - ws         = worksheet object
        - start_row  = starting row (0-indexed) | default 0
        - num_rows   = number of rows to add    | default 1
        - opts       = options:
          + fill     = set to true to "fill" cell styles and formula
    */
  insertRows(
    { commit, getters, state },
    { start_row = 0, num_rows = 1, opts = {} }
  ) {
    const tmpWorksheet = clone(state.worksheets);
    const ws = tmpWorksheet.Sheets[state.selectedWorksheetName];
    if (!ws) throw new Error("operation expects a worksheet");
    if (!ws["!ref"]) ws["!ref"] = "A1";

    /* extract original range */
    var range = XLSX.utils.decode_range(ws["!ref"]);
    var R = 0,
      C = 0;

    var update_formula = function ($0, $1, $2, $3, $4, $5) {
      var row_index = XLSX.utils.decode_row($5),
        col_index = XLSX.utils.decode_col($3);
      if (!opts.fill ? row_index >= start_row : R >= start_row)
        row_index += num_rows;
      return (
        $1 +
        ($2 == "$" ? $2 + $3 : XLSX.utils.encode_col(col_index)) +
        ($4 == "$" ? $4 + $5 : XLSX.utils.encode_row(row_index))
      );
    };

    var cur_cell, nex_cell, new_cell;
    /* move cells and update formulae */

    /* cells after the insert */
    for (R = range.e.r; R >= start_row; --R) {
      for (C = range.s.c; C <= range.e.c; ++C) {
        cur_cell = XLSX.utils.encode_cell({
          r: R,
          c: C
        });
        nex_cell = XLSX.utils.encode_cell({
          r: R + num_rows,
          c: C
        });
        if (!ws[cur_cell]) {
          delete ws[nex_cell];
          continue;
        }
        if (opts.fill && (ws[cur_cell].s || ws[cur_cell].f)) {
          new_cell = {};
          if (ws[cur_cell].f) {
            new_cell.f = ws[cur_cell].f;
            new_cell.t = ws[cur_cell].t;
          } else {
            new_cell.t = "z";
          }
        }
        if (ws[cur_cell].f)
          ws[cur_cell].f = ws[cur_cell].f.replace(
            this.CREF_REGEX,
            update_formula
          );
        ws[nex_cell] = ws[cur_cell];
        if (opts.fill) {
          ws[cur_cell] = new_cell;
        }
        if (range.e.r < R + num_rows) range.e.r = R + num_rows;
      }
    }

    /* newly created space */
    if (!opts.fill)
      for (R = start_row; R < start_row + num_rows; ++R) {
        for (C = range.s.c; C <= range.e.c; ++C) {
          cur_cell = XLSX.utils.encode_cell({
            r: R,
            c: C
          });
          delete ws[cur_cell];
        }
      }

    /* cells before insert */
    for (R = 0; R < start_row; ++R) {
      for (C = range.s.c; C <= range.e.c; ++C) {
        cur_cell = XLSX.utils.encode_cell({
          r: R,
          c: C
        });
        if (ws[cur_cell] && ws[cur_cell].f)
          ws[cur_cell].f = ws[cur_cell].f.replace(
            this.CREF_REGEX,
            update_formula
          );
      }
    }

    /* write new range */
    ws["!ref"] = XLSX.utils.encode_range(getters.clampRange(range));

    /* merge cells */
    if (ws["!merges"])
      ws["!merges"].forEach(function (merge, idx) {
        var merge_range;
        switch (typeof merge) {
          case "string":
            merge_range = XLSX.utils.decode_range(merge);
            break;
          case "object":
            merge_range = merge;
            break;
          default:
            throw new Error("Unexpected merge ref " + merge);
        }
        if (merge_range.s.r >= start_row) merge_range.s.r += num_rows;
        if (merge_range.e.r >= start_row) merge_range.e.r += num_rows;
        getters.clampRange(merge_range);
        ws["!merges"][idx] = merge_range;
      });

    /* rows */
    var row_load = [start_row, 0];
    for (R = 0; R < num_rows; ++R) row_load.push(void 0);
    if (ws["!rows"]) ws["!rows"].splice.apply(ws["!rows"], row_load);

    commit("updateWorksheetdata", tmpWorksheet);
  },
  /*
        deletes `num_rows` rows STARTING WITH `start_row`
        - ws         = worksheet object
        - start_row  = starting row (0-indexed) | default 0
        - num_rows   = number of rows to delete | default 1
    */
  deleteRows({ commit, getters, state }, { start_row = 0, num_rows = 1 }) {
    const tmpWorksheet = clone(state.worksheets);
    const ws = tmpWorksheet.Sheets[state.selectedWorksheetName];
    if (!ws) throw new Error("operation expects a worksheet");
    if (!ws["!ref"]) ws["!ref"] = "A1";

    /* extract original range */
    var range = XLSX.utils.decode_range(ws["!ref"]);
    var R = 0,
      C = 0;

    if (start_row > range.e.r) {
      return;
    } else {
      for (var i = start_row; i <= start_row + num_rows; i++) {
        if (i > range.e.r) {
          num_rows--;
        }
      }
    }

    var update_formula = function ($0, $1, $2, $3, $4, $5) {
      var row_index = XLSX.utils.decode_row($5),
        col_index = XLSX.utils.decode_col($3);
      if (row_index >= start_row) {
        row_index -= num_rows;
        if (row_index < start_row) return "#REF!";
      }
      return (
        $1 +
        ($2 == "$" ? $2 + $3 : XLSX.utils.encode_col(col_index)) +
        ($4 == "$" ? $4 + $5 : XLSX.utils.encode_row(row_index))
      );
    };

    var cur_cell, pre_cell;
    /* move cells and update formula */
    /* cells after the delete */
    for (R = start_row + num_rows; R <= range.e.r; ++R) {
      for (C = range.s.c; C <= range.e.c; ++C) {
        cur_cell = XLSX.utils.encode_cell({
          r: R,
          c: C
        });
        pre_cell = XLSX.utils.encode_cell({
          r: R - num_rows,
          c: C
        });
        if (!ws[cur_cell]) {
          delete ws[pre_cell];
          continue;
        }
        if (ws[cur_cell].f)
          ws[cur_cell].f = ws[cur_cell].f.replace(
            this.CREF_REGEX,
            update_formula
          );
        ws[pre_cell] = ws[cur_cell];
      }
    }
    /* deleted cells */
    for (R = range.e.r; R > range.e.r - num_rows; --R) {
      for (C = range.s.c; C <= range.e.c; ++C) {
        cur_cell = XLSX.utils.encode_cell({
          r: R,
          c: C
        });
        delete ws[cur_cell];
      }
    }
    /* cells before the delete */
    for (R = 0; R < start_row; ++R) {
      for (C = range.s.c; C <= range.e.c; ++C) {
        cur_cell = XLSX.utils.encode_cell({
          r: R,
          c: C
        });
        if (ws[cur_cell] && ws[cur_cell].f)
          ws[cur_cell].f = ws[cur_cell].f.replace(
            this.CREF_REGEX,
            update_formula
          );
      }
    }
    /* write new range */
    range.e.r -= num_rows;
    if (range.e.r < 0) range.e.r = 0;
    if (range.e.r < range.s.r) range.e.r = range.s.r;
    ws["!ref"] = XLSX.utils.encode_range(getters.clampRange(range));

    /* merge cells */
    if (ws["!merges"])
      ws["!merges"].forEach(function (merge, idx) {
        var merge_range;
        switch (typeof merge) {
          case "string":
            merge_range = XLSX.utils.decode_range(merge);
            break;
          case "object":
            merge_range = merge;
            break;
          default:
            throw new Error("Unexpected merge ref " + merge);
        }
        if (merge_range.s.r >= start_row) {
          merge_range.s.r = Math.max(merge_range.s.r - num_rows, start_row);
          if (merge_range.e.r < start_row + num_rows) {
            delete ws["!merges"][idx];
            return;
          }
        } else if (merge_range.e.r >= start_row) merge_range.e.r = Math.max(merge_range.e.r - num_rows, start_row);
        getters.clampRange(merge_range);
        ws["!merges"][idx] = merge_range;
      });
    if (ws["!merges"])
      ws["!merges"] = ws["!merges"].filter(function (x) {
        return !!x;
      });

    /* rows */
    if (ws["!rows"]) ws["!rows"].splice(start_row, num_rows);

    commit("updateWorksheetdata", tmpWorksheet);
  },
  async remoteDeleteHighlightedCells({ commit, getters, rootGetters, state }) {
    const updateArray = [];
    const newEl = {};
    if (
      state.highlightCells.row.length > 1 &&
      state.highlightCells.row[0] > state.highlightCells.row[1]
    ) {
      newEl.row = [...state.highlightCells.row].reverse();
    } else {
      newEl.row = [...state.highlightCells.row];
    }
    if (
      state.highlightCells.col.length > 1 &&
      state.highlightCells.col[0] > state.highlightCells.col[1]
    ) {
      newEl.col = [...state.highlightCells.col].reverse();
    } else {
      newEl.col = [...state.highlightCells.col];
    }
    for (
      let i = newEl.col[0];
      i <= (newEl.col[1] ? newEl.col[1] : newEl.col[0]);
      i++
    ) {
      for (
        let j = newEl.row[0];
        j <= (newEl.row[1] ? newEl.row[1] : newEl.row[0]);
        j++
      ) {
        const currentCellId = getters.encodeCol(i).concat(getters.encodeRow(j));
        if (getters.getCellObj(currentCellId)) {
          updateArray.push({
            id: currentCellId,
            row: j,
            col: i,
            sheetName: state.selectedWorksheetName,
            style: {}
          });
        }
      }
    }
    if (state.highlightCellGroup.length > 0) {
      state.highlightCellGroup.forEach((highlightCell) => {
        for (
          let i = highlightCell.col[0];
          i <=
          (highlightCell.col[1] ? highlightCell.col[1] : highlightCell.col[0]);
          i++
        ) {
          for (
            let j = highlightCell.row[0];
            j <=
            (highlightCell.row[1]
              ? highlightCell.row[1]
              : highlightCell.row[0]);
            j++
          ) {
            const currentCellId = getters
              .encodeCol(i)
              .concat(getters.encodeRow(j));
            if (getters.getCellObj(currentCellId)) {
              updateArray.push({
                id: currentCellId,
                row: j,
                col: i,
                value: "",
                sheetName: state.selectedWorksheetName,
                style: {}
              });
            }
          }
        }
      });
    }
    if (updateArray.length > 0) {
      commit("updateCellValue", updateArray);
      return true;
    }
    return false;
  },
  hideCols({ commit, getters, state }, { start_col = 0, num_cols = 1 }) {
    const tmpWorksheet = clone(state.worksheets);
    const ws = tmpWorksheet.Sheets[state.selectedWorksheetName];
    if (!ws) throw new Error("operation expects a worksheet");

    if (!ws["!cols"]) ws["!cols"] = [];
    for (let i = 0; i <= num_cols; i++) {
      if (ws["!cols"][start_col + i] && ws["!cols"][start_col + i].hidden) {
        ws["!cols"][start_col + i].hidden = true;
      } else {
        ws["!cols"][start_col + i] = {
          ...ws["!cols"][start_col + i],
          ...{
            hidden: true
          }
        };
      }
    }

    commit("updateWorksheetdata", tmpWorksheet);
  },
  unhideCols({ commit, getters, state }, { start_col, end_col }) {
    const tmpWorksheet = clone(state.worksheets);
    const ws = tmpWorksheet.Sheets[state.selectedWorksheetName];
    if (!ws) throw new Error("operation expects a worksheet");

    if (!ws["!cols"]) ws["!cols"] = [];
    for (var i = 0; i <= end_col - start_col; i++) {
      if (ws["!cols"][start_col + i] && ws["!cols"][start_col + i].hidden) {
        ws["!cols"][start_col + i].hidden = false;
      }
    }

    commit("updateWorksheetdata", tmpWorksheet);
  },
  hideRows({ commit, getters, state }, { start_row = 0, num_rows = 1 }) {
    const tmpWorksheet = clone(state.worksheets);
    const ws = tmpWorksheet.Sheets[state.selectedWorksheetName];
    if (!ws) throw new Error("operation expects a worksheet");

    if (!ws["!rows"]) ws["!rows"] = [];
    for (var i = 0; i <= num_rows; i++) {
      if (ws["!rows"][start_row + i] && ws["!rows"][start_row + i].hidden) {
        ws["!rows"][start_row + i].hidden = true;
      } else {
        ws["!rows"][start_row + i] = {
          ...ws["!rows"][start_row + i],
          ...{
            hidden: true
          }
        };
      }
    }

    commit("updateWorksheetdata", tmpWorksheet);
  },
  unhideRows({ commit, getters, state }, { start_row, end_row }) {
    const tmpWorksheet = clone(state.worksheets);
    const ws = tmpWorksheet.Sheets[state.selectedWorksheetName];
    if (!ws) throw new Error("operation expects a worksheet");

    if (!ws["!rows"]) ws["!rows"] = [];
    for (var i = 0; i <= end_row - start_row; i++) {
      if (ws["!rows"][start_row + i] && ws["!rows"][start_row + i].hidden) {
        ws["!rows"][start_row + i].hidden = false;
      }
    }
    commit("updateWorksheetdata", tmpWorksheet);
  },
  remoteUpdateColRowCount({ commit }, { newColCount, newRowCount }) {
    commit("updateColRowCount", {
      colCount: newColCount,
      rowCount: newRowCount
    });
  },
  remoteUpdateColCount({ commit }, newColCount) {
    commit("updateColCount", newColCount);
  },
  remoteUpdateRowCount({ commit }, newRowCount) {
    commit("updateRowCount", newRowCount);
  },
  remoteUpdateHighlightCellGroup({ commit, getters, state }, cmdOrCtrl) {
    let newGroup = clone(state.highlightCellGroup);
    if (cmdOrCtrl) {
      //check highlight cell exist and remove same one
      var isExist = false;
      const newEl = {};
      if (
        state.highlightCells.row.length > 1 &&
        state.highlightCells.row[0] > state.highlightCells.row[1]
      ) {
        newEl.row = [...state.highlightCells.row].reverse();
      } else {
        newEl.row = [...state.highlightCells.row];
      }
      if (
        state.highlightCells.col.length > 1 &&
        state.highlightCells.col[0] > state.highlightCells.col[1]
      ) {
        newEl.col = [...state.highlightCells.col].reverse();
      } else {
        newEl.col = [...state.highlightCells.col];
      }
      if (newGroup.length > 0) {
        for (var i = 0; i < newGroup.length; i++) {
          if (
            newGroup[i].row[0] == newEl.row[0] &&
            newGroup[i].col[0] == newEl.col[0] &&
            newGroup[i].row[1] == newEl.row[1] &&
            newGroup[i].col[1] == newEl.col[1]
          ) {
            isExist = true;
            newGroup.splice(i, 1);
            i--;
            break;
          } else if (
            newGroup[i].row[0] <= newEl.row[0] &&
            (newEl.row[1]
              ? newEl.row[1] <= newGroup[i].row[1]
              : newEl.row[0] <= newGroup[i].row[1]) &&
            newGroup[i].col[0] <= newEl.col[0] &&
            (newEl.col[1]
              ? newEl.col[1] <= newGroup[i].col[1]
              : newEl.col[0] <= newGroup[i].col[1])
          ) {
            if (
              newGroup[i].col[1] &&
              newGroup[i].col[0] == newGroup[i].col[1]
            ) {
              var tmpEleBefore = {
                row: [newGroup[i].row[0], newEl.row[0] - 1],
                col: newGroup[i].col
              };
              var tmpEleAfter = {
                row: [
                  newEl.row[1] ? newEl.row[1] + 1 : newEl.row[0] + 1,
                  newGroup[i].row[1]
                ],
                col: newGroup[i].col
              };
              if (tmpEleBefore.row[0] == tmpEleBefore.row[1]) {
                tmpEleBefore.row.pop();
                if (tmpEleBefore.col.length > 1) tmpEleBefore.col.pop();
              }
              if (tmpEleAfter.row[0] == tmpEleAfter.row[1]) {
                tmpEleAfter.row.pop();
                if (tmpEleAfter.col.length > 1) tmpEleAfter.col.pop();
              }
              if (
                newGroup[i].row[0] < newEl.row[0] &&
                newGroup[i].row[1] >
                  (newEl.row[1] ? newEl.row[1] : newEl.row[0])
              ) {
                newGroup.push(tmpEleAfter);
                newGroup.push(tmpEleBefore);
              } else if (newGroup[i].row[0] == newEl.row[0]) {
                newGroup.push(tmpEleAfter);
              } else if (
                newGroup[i].row[1] == newEl.row[1] ? newEl.row[1] : newEl.row[0]
              ) {
                newGroup.push(tmpEleBefore);
              }
              isExist = true;
              newGroup.splice(i, 1);
              i--;
              break;
            } else if (
              newGroup[i].row[1] &&
              newGroup[i].row[0] == newGroup[i].row[1]
            ) {
              const tmpEleBefore = {
                row: newGroup[i].row,
                col: [newGroup[i].col[0], newEl.col[0] - 1]
              };
              const tmpEleAfter = {
                row: newGroup[i].row,
                col: [
                  newEl.col[1] ? newEl.col[1] + 1 : newEl.col[0] + 1,
                  newGroup[i].col[1]
                ]
              };
              if (tmpEleBefore.col[0] == tmpEleBefore.col[1]) {
                if (tmpEleBefore.row.length > 1) tmpEleBefore.row.pop();
                tmpEleBefore.col.pop();
              }
              if (tmpEleAfter.col[0] == tmpEleAfter.col[1]) {
                if (tmpEleAfter.row.length > 1) tmpEleAfter.row.pop();
                tmpEleAfter.col.pop();
              }
              if (
                newGroup[i].col[0] < newEl.col[0] &&
                newGroup[i].col[1] >
                  (newEl.col[1] ? newEl.col[1] : newEl.col[0])
              ) {
                newGroup.push(tmpEleAfter);
                newGroup.push(tmpEleBefore);
              } else if (newGroup[i].col[0] == newEl.col[0]) {
                newGroup.push(tmpEleAfter);
              } else if (
                newGroup[i].col[1] == newEl.col[1] ? newEl.col[1] : newEl.col[0]
              ) {
                newGroup.push(tmpEleBefore);
              }
              isExist = true;
              newGroup.splice(i, 1);
              i--;
              break;
            } else if (
              newGroup[i].row[1] &&
              newGroup[i].row[1] > newGroup[i].row[0]
            ) {
              if (newGroup[i].row[0] < newEl.row[0]) {
                var tmpEleRowBefore = {
                  row: [newGroup[i].row[0], newEl.row[0] - 1],
                  col: newGroup[i].col
                };
                newGroup.push(tmpEleRowBefore);
              }
              if (
                newGroup[i].row[1] >
                (newEl.row[1] ? newEl.row[1] : newEl.row[0])
              ) {
                var tmpEleRowAfter = {
                  row: [
                    newEl.row[1] ? newEl.row[1] + 1 : newEl.row[0] + 1,
                    newGroup[i].row[1]
                  ],
                  col: newGroup[i].col
                };
                newGroup.push(tmpEleRowAfter);
              }
              if (newGroup[i].col[0] < newEl.col[0]) {
                var tmpEleColBefore = {
                  row: newEl.row,
                  col: [newGroup[i].col[0], newEl.col[0] - 1]
                };
                newGroup.push(tmpEleColBefore);
              }
              if (
                newGroup[i].col[1] >
                (newEl.col[1] ? newEl.col[1] : newEl.col[0])
              ) {
                var tmpEleColAfter = {
                  row: newEl.row,
                  col: [
                    newEl.col[1] ? newEl.col[1] + 1 : newEl.col[0] + 1,
                    newGroup[i].col[1]
                  ]
                };
                newGroup.push(tmpEleColAfter);
              }
              isExist = true;
              newGroup.splice(i, 1);
              i--;
              break;
            }
          }
        }
      }
      if (!isExist) {
        newGroup.push(newEl);
      }
    } else {
      newGroup = [];
    }
    commit("updateHighlightCellGroup", newGroup);
  },
  remoteUpdateCellsFormat(
    { commit, getters, state },
    { startColIndex, endColIndex, startRowIndex, endRowIndex, format }
  ) {
    const cellAttrToUpdate = [];
    for (
      let tmpColIndex = startColIndex;
      tmpColIndex <= endColIndex;
      tmpColIndex++
    ) {
      // const tmpColNumber = getters.encodeCol(tmpColIndex);
      for (
        let tmpRowIndex = startRowIndex;
        tmpRowIndex <= endRowIndex;
        tmpRowIndex++
      ) {
        // const currentCellId = tmpColNumber.concat(getters.encodeRow(tmpRowIndex));
        const currentCellId = getters.encodeCell({
          c: tmpColIndex,
          r: tmpRowIndex
        });
        const tmpCellObj = getters.getCellObj(currentCellId);
        if (
          tmpCellObj &&
          format == "#,##0.0" &&
          tmpCellObj.v &&
          isNaN(tmpCellObj.v)
        )
          return false;
        cellAttrToUpdate.push({
          value: format,
          cellId: currentCellId,
          row: tmpRowIndex,
          col: tmpColIndex,
          sheetName: state.selectedWorksheetName,
          cellObjAttrToUpdate: "z"
        });
      }
    }
    if (cellAttrToUpdate.length > 0) {
      commit("updateCellAttr", cellAttrToUpdate);
    }
  },
  remoteSaveHighlightCells({ commit, rootGetters }) {
    commit(
      "saveHighlightCells",
      rootGetters["ssViewpoints/highlightSquare"].highlightCells
    );
  },
  remoteUpdateModelMetadata({ commit, state }, { metadata }) {
    // currently only has metatdata in this api
    commit("updateModelMetatdata", metadata);
    /* NOTE use remoteSaveWholeModel for now
    return this.$axios
      .$put(
        `/v1/security_model/entity/${rootState.user.selectedShownEntity}/model/${model_id}`,
        {
          model_metadata: JSON.stringify(metadata),
        }
      )
      .catch((e) => {
        console.error(e);
        return false;
      });
      */
  },
  async remoteSaveWholeModel(
    { rootState, state, dispatch, getters, commit },
    model_id
  ) {
    // NOTE: might need to come back and use webworker (comlink or comlink-fetch) instead, but will need to use XMLRequest instead of AXIOS. - L - 24/07/20
    try {
      // refresh token just in case
      // await dispatch("user/refreshToken", false, {
      //     root: true
      // });
      const workbookData = {};
      if (state.worksheets.Workbook.Names)
        workbookData["Names"] = state.worksheets.Workbook.Names;
      if (state.worksheets.Workbook.external_api)
        workbookData["external_api"] = state.worksheets.Workbook.external_api;
      if (state.worksheets.Workbook.esg)
        workbookData["esg"] = state.worksheets.Workbook.esg;
      workbookData["data_source"] = state.worksheets.Workbook.data_source;
      if (Object.keys(workbookData).length > 0) {
        await this.$axios.$put(
          `/v1/security_model/entity/${rootState.user.selectedShownEntity}/model/${model_id}`,
          {
            model_metadata: JSON.stringify(workbookData)
          }
        );
      }
      // check for additional info and pass it to other tab
      if (state.worksheets.Workbook.additional_info)
        workbookData["additional_info"] =
          state.worksheets.Workbook.additional_info;
      // ====
      const sheetIds = clone(state.sheetIds);
      //const TMP_CHUNK_SZ = this.$auth.ctx.isDev ? 750000 : 2000000;
      const newSheetArray = [];
      for (let i = 0; i < state.worksheets.SheetNames.length; i++) {
        const SHEET_NAME = state.worksheets.SheetNames[i];
        if (!sheetIds[SHEET_NAME]) {
          newSheetArray.push({
            model_sheet_name: SHEET_NAME,
            model_sheet_order: i,
            model_sheet_cdb: SHEET_NAME === state.CDB_SHEET_NAME ? true : false
          });
        } else {
          delete sheetIds[SHEET_NAME]; // removed the ones already have so at the end can removes the ones we dont have
        }
      }
      const tmpAddSheetObj =
        newSheetArray.length > 0
          ? this.$axios.$post(
              `/v1/security_model/entity/${rootState.user.selectedShownEntity}/model/${model_id}/sheet`,
              {
                model_sheets: newSheetArray
              }
            )
          : Promise.resolve(true);
      const postValues = Object.values(sheetIds);
      const tmpDeleteSheetObj =
        postValues.length > 0
          ? this.$axios.$put(
              `/v1/security_model/entity/${rootState.user.selectedShownEntity}/model/${model_id}/sheet/delete`,
              {
                model_sheet_ids: postValues
              }
            )
          : Promise.resolve(true);
      await tmpAddSheetObj; // wait for this async
      await tmpDeleteSheetObj; // wait for this async
      commit("removeSheetNameIds", []);
      const sheetDataPromise = [];
      const sheetsUrlObjArrayRes = await this.$axios.get(
        `/v1/security_model/entity/${rootState.user.selectedShownEntity}/model/${model_id}/sheets`
      );
      for (let i = 0; i < sheetsUrlObjArrayRes.data.length; i++) {
        // should catch why no URL
        if (
          sheetsUrlObjArrayRes.data[i].model_sheet_name &&
          sheetsUrlObjArrayRes.data[i].model_sheet_id
        )
          commit("updateSheetNameId", {
            sheetName: sheetsUrlObjArrayRes.data[i].model_sheet_name,
            sheetId: sheetsUrlObjArrayRes.data[i].model_sheet_id
          });
        if (!sheetsUrlObjArrayRes.data[i].model_sheet_upload_url) continue;
        const SHEET_NAME = sheetsUrlObjArrayRes.data[i].model_sheet_name;
        const IS_CDB = SHEET_NAME === state.CDB_SHEET_NAME;
        const sheetMetaData = [];
        let newDataString = "";
        switch (IS_CDB) {
          case true: {
            const cdbExist = state.worksheets.Sheets[SHEET_NAME];
            const cdbMetricsLen = state.cdbIdMap.length;
            for (let j = 0; j < cdbMetricsLen; j++) {
              const rowValues = {};
              const paramsLen = state.cdbIdMap[j].params.length;
              for (let k = 0; k < paramsLen; k++) {
                const cellId = state.cdbIdMap[j].params_cells[k];
                const { r: tmpRow, c: tmpCol } = getters.decodeCell(cellId);
                let cellR = {
                  v: "-"
                };
                if (
                  cdbExist &&
                  state.worksheets.Sheets[SHEET_NAME][tmpRow] &&
                  state.worksheets.Sheets[SHEET_NAME][tmpRow][tmpCol]
                ) {
                  if (state.worksheets.Sheets[SHEET_NAME][tmpRow][tmpCol].v) {
                    cellR = state.worksheets.Sheets[SHEET_NAME][tmpRow][tmpCol];
                  } else {
                    cellR = {
                      ...cellR,
                      ...state.worksheets.Sheets[SHEET_NAME][tmpRow][tmpCol]
                    };
                  }
                }
                rowValues[state.cdbIdMap[j].params[k]] = cellR;
              }
              sheetMetaData.push({
                metrics_id: state.cdbIdMap[j].metric_id,
                op_sheet_row_values: rowValues
              });
            }
            newDataString = JSON.stringify(sheetMetaData);
            // == upload sheet js model as welll so they can query it ===
            const tmpSheetData = JSON.stringify({
              ...state.worksheets.Sheets[SHEET_NAME]
            });
            if (tmpSheetData) {
              sheetMetaData.push(
                dispatch(
                  "app_store/remoteUploadToS3PresignedUrl",
                  {
                    textToUpload: tmpSheetData,
                    presignedUrl:
                      sheetsUrlObjArrayRes.data[i].cdb_model_bucket_upload_url
                        .url,
                    presignedFields:
                      sheetsUrlObjArrayRes.data[i].cdb_model_bucket_upload_url
                        .fields,
                    fileType: "application/json"
                  },
                  {
                    root: true
                  }
                )
              );
            }
            // === end ===
            break;
          }
          default:
            if (state.worksheets.Sheets[SHEET_NAME]) {
              // use spread notation to because stringify would omit keys when its a mixture of array index and object key
              newDataString = JSON.stringify({
                ...state.worksheets.Sheets[SHEET_NAME]
              });
            }
            break;
        }
        if (newDataString === "") continue;
        sheetDataPromise.push(
          dispatch(
            "app_store/remoteUploadToS3PresignedUrl",
            {
              textToUpload: newDataString,
              presignedUrl:
                sheetsUrlObjArrayRes.data[i].model_sheet_upload_url.url,
              presignedFields:
                sheetsUrlObjArrayRes.data[i].model_sheet_upload_url.fields,
              fileType: "application/json"
            },
            { root: true }
          )
        );
      }
      // upload the original model if exist
      const new_file_name = `${model_id}_original`;
      // NOTE: please make this the last request as will be reading the result of the promise
      const storageKey = `${TMP_STORAGE_FOR_MODEL_LOCAL_PREFIX}${model_id}`;
      sheetDataPromise.push(
        dispatch(
          "app_store/remoteMoveTempStorageFile",
          {
            copy_option: TMP_FILE_COPY_OPTION,
            storageFor: storageKey,
            new_storage_name: `${rootState.user.selectedShownEntity}/${state.spreadsheetSecurityId}/${model_id}/${new_file_name}`
          },
          { root: true }
        )
      );
      const promiseRes = await Promise.all(sheetDataPromise);
      const savedOriginalModel = promiseRes[promiseRes.length - 1];
      if (savedOriginalModel && savedOriginalModel.result) {
        // update model column with storage id if applicable
        const storedInfo = rootState.app_store.tempUploadFile[storageKey];
        if (!storedInfo.file_type) throw new Error("did not store file type");
        const original_model_file_storage = `${new_file_name}.${storedInfo.file_type}`;
        await this.$axios.$put(
          `/v1/security_model/entity/${rootState.user.selectedShownEntity}/model/${model_id}`,
          {
            original_model_file_storage
          }
        );
        workbookData["original_model_file_storage"] =
          original_model_file_storage;
      }
      localStorage.setItem(
        "model_draft_updated",
        JSON.stringify({
          model_id,
          model_metadata: workbookData
        })
      );
      localStorage.removeItem("model_draft_updated");
      return Promise.resolve(true);
    } catch (e) {
      console.error(e);
      return Promise.reject(false);
    }
  },
  async remoteUploadCdbSheet(
    { rootState, state, getters, dispatch },
    model_id
  ) {
    // made for mst because they upload market cap separately, but might be usable for others
    try {
      if (
        !state.worksheets ||
        !state.worksheets.Sheets ||
        !state.worksheets.Sheets[rootState.spreadsheet.CDB_SHEET_NAME]
      )
        throw new Error("no cdb data to save");
      const SHEET_NAME = rootState.spreadsheet.CDB_SHEET_NAME;
      const cdbMetricsLen = state.cdbIdMap.length;
      if (cdbMetricsLen === 0) throw new Error("no cdb map");
      const sheetMetaData = [];
      let newDataString = "";
      for (let j = 0; j < cdbMetricsLen; j++) {
        const rowValues = {};
        const paramsLen = state.cdbIdMap[j].params.length;
        for (let k = 0; k < paramsLen; k++) {
          const cellId = state.cdbIdMap[j].params_cells[k];
          const { r: tmpRow, c: tmpCol } = getters.decodeCell(cellId);
          let cellR = {
            v: "-"
          };
          if (
            state.worksheets.Sheets[SHEET_NAME][tmpRow] &&
            state.worksheets.Sheets[SHEET_NAME][tmpRow][tmpCol]
          ) {
            if (state.worksheets.Sheets[SHEET_NAME][tmpRow][tmpCol].v) {
              cellR = state.worksheets.Sheets[SHEET_NAME][tmpRow][tmpCol];
            } else {
              cellR = {
                ...cellR,
                ...state.worksheets.Sheets[SHEET_NAME][tmpRow][tmpCol]
              };
            }
          }
          rowValues[state.cdbIdMap[j].params[k]] = cellR;
        }
        sheetMetaData.push({
          metrics_id: state.cdbIdMap[j].metric_id,
          op_sheet_row_values: rowValues
        });
      }
      // get the presign url, currently for all sheets, can create api to get a url for just cdb in the future
      const sheetsUrlObjArrayRes = await this.$axios.$get(
        `/v1/security_model/entity/${rootState.user.selectedShownEntity}/model/${model_id}/sheets`
      );
      const cdb_presign_data = sheetsUrlObjArrayRes.find(
        (tmpObj) =>
          tmpObj.model_sheet_cdb && tmpObj.model_sheet_status === "active"
      );
      if (!cdb_presign_data) throw new Error("could not get cdb presign url");
      newDataString = JSON.stringify(sheetMetaData);
      // == upload sheet js model as welll so they can query it ===
      const tmpSheetData = JSON.stringify({
        ...state.worksheets.Sheets[SHEET_NAME]
      });
      if (tmpSheetData) {
        dispatch(
          "app_store/remoteUploadToS3PresignedUrl",
          {
            textToUpload: tmpSheetData,
            presignedUrl: cdb_presign_data.cdb_model_bucket_upload_url.url,
            presignedFields:
              cdb_presign_data.cdb_model_bucket_upload_url.fields,
            fileType: "application/json"
          },
          {
            root: true
          }
        );
        dispatch(
          "app_store/remoteUploadToS3PresignedUrl",
          {
            textToUpload: newDataString,
            presignedUrl: cdb_presign_data.model_sheet_upload_url.url,
            presignedFields: cdb_presign_data.model_sheet_upload_url.fields,
            fileType: "application/json"
          },
          { root: true }
        );
        return true;
      }
      throw new Error("unknow error");
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  remoteUpdateDataSource({ dispatch, rootGetters }, newSource) {
    // change where the model is last updated from
    const currentDataSource = rootGetters[
      "app_store/objectNestedPropertyCheck"
    ](state, ["worksheets", "Workbook", "data_source"], null);
    if (!currentDataSource || currentDataSource !== newSource) {
      // NOTE this is not saved in db untill model is saved
      dispatch("remoteUpdateModelMetadata", {
        metadata: {
          data_source: newSource
        }
      });
    }
  },
  async remoteEmptyWorksheetData({ commit, state, dispatch }) {
    const workbook = {
      Sheets: {},
      SheetNames: [state.CDB_SHEET_NAME],
      Workbook: { Names: [] }
    };
    workbook.Sheets[state.CDB_SHEET_NAME] = {};
    // NOTE dont need to update sheet id or colune and row count as it should be there
    commit("setSelectedSheet", state.CDB_SHEET_NAME);
    commit("updateSheetGetUrl", {});
    commit("updateWorksheetdata", workbook);
    return await dispatch("remoteGetSheetData");
  },
  async cdbDataCheck({ state, rootGetters, dispatch }) {
    if (rootGetters["reportTemplate/isMstAccessTemplate"]) {
      return await dispatch(
        "entity_configs/mst_access/modelDataToCdb",
        {},
        { root: true }
      );
    } else if (rootGetters["reportTemplate/isMAFinancialTemplate"]) {
      return await dispatch(
        "entity_configs/mafinancial/modelDataToCdb",
        {},
        { root: true }
      );
    }
    return true;
  }
};
