<template>
  <div
    id="entity-app-container"
    :class="{ tablet: isTablet, mobile: isMobile }"
  >
    <entity-menu />
    <div class="entity-app">
      <circular-loader :loaded="!appLoading">
        <div class="entity-content">
          <transition mode="out-in" name="page">
            <nuxt :key="$route.path" />
          </transition>
        </div>
      </circular-loader>
    </div>
  </div>
</template>

<script>
import { LOGINCOOKIETRIGGERVALUE } from "~/constants/commonConstants.js";
import { mapGetters, mapState } from "vuex";
import Menu from "~/components/new-platform-molecules/layout/entity-menu/MenuBar.vue";
import debounce from "tiny-debounce";
import CircularLoader from "~/components/new-platform/loaders/CircularLoaderWrapper";

export default {
  components: {
    "entity-menu": Menu,
    "circular-loader": CircularLoader
  },
  middleware: "entityCheck-new",

  data() {
    return {
      searchInput: "",
      toggleFlag: false,
      logoFile: null,
      errorTitle: "",
      errorText: "",
      isCtrlClicked: false
    };
  },
  computed: {
    ...mapGetters({
      isTablet: "app_store/isTablet",
      isMobile: "app_store/isMobile",
      isLimitedAccess: "user/isLimitedAccess",
      shouldResizeRoute: "app_store/shouldResizeRoute"
    }),
    ...mapState({
      isRefreshingToken: (state) => state.user.isRefreshingToken,
      limitedAccess: (state) => state.app_store.limitedAccess,
      resizeCount: (state) => state.app_store.resizeCount,
      windowWidth: (state) => state.app_store.windowWidth,
      windowHeight: (state) => state.app_store.windowHeight,
      TABLET_CRITERIA_WIDTH: (state) => state.app_store.TABLET_CRITERIA_WIDTH,
      appExpanded: (state) => state.app_store.appExpanded,
      memberList: (state) => state.member.memberList,
      appLoading: (state) => state.app_store.appLoading
    })
  },
  created() {
    window.addEventListener("storage", this.onTokenRefresh);
    this.$nextTick(function () {
      this.$store.dispatch(
        "app_store/remoteUpdateWindowWidth",
        window.innerWidth
      );
      this.$store.dispatch(
        "app_store/remoteUpdateWindowHeight",
        window.innerHeight
      );
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.onTokenRefresh);
    window.removeEventListener("resize", this.onResize);
  },
  async mounted() {
    // if (this.limitedAccess) {
    //   if (this.limitedAccess == "member") {
    //     this.showMemberError();
    //   }
    //   if (this.limitedAccess == "change") {
    //     this.showChangeError();
    //   }
    //   // if (this.limitedAccess == "rgpt") {
    //   //   this.showRgptError();
    //   // }
    //   this.$store.dispatch("app_store/limitAccess", "");
    // }
    await this.searchResult();
  },
  methods: {
    onResize: debounce(function () {
      // check device ratio
      this.$store.dispatch("app_store/remoteSetDevicePixelRatio");
      // ====
      if (!this.shouldResizeRoute(this.$route.name)) return;

      // if (
      //   (this.windowWidth > this.TABLET_CRITERIA_WIDTH &&
      //     window.innerWidth < this.TABLET_CRITERIA_WIDTH) ||
      //   (this.windowWidth < this.TABLET_CRITERIA_WIDTH &&
      //     window.innerWidth > this.TABLET_CRITERIA_WIDTH)
      // ) {
      this.$store.dispatch(
        "app_store/remoteUpdateWindowHeight",
        window.innerHeight
      );
      this.$store.dispatch(
        "app_store/remoteUpdateWindowWidth",
        window.innerWidth
      );
      //only update the width and height if the count increase, this is so that it compares to the original size as that is what the style is set to
      // }
    }, 500),
    async searchResult() {
      const excludeLoadingSecurityRoute = new Set(["member"]);
      const exludeLoadingMemberRoute = new Set(["memberlist"]);
      if (!excludeLoadingSecurityRoute.has(this.$route.name)) {
        // NOTE: get all securities for securit popover for these pages
        await this.$store.dispatch("security/getTotalSecuritiesList");
      }

      if (!this.isLimitedAccess) {
        if (!exludeLoadingMemberRoute.has(this.$route.name))
          await this.$store.dispatch("member/remoteGetTotalMemberList");
      }
    },
    onTokenRefresh(ev) {
      if (
        ev.key === LOGINCOOKIETRIGGERVALUE &&
        ev.newValue &&
        !this.isRefreshingToken
      ) {
        // when token is refreshed from another page
        this.$store.dispatch("user/remoteUpdateToken");
      }
    }
  }
};
</script>

<style lang="sass">
@import url("https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css")
@import url("https://unpkg.com/mono-icons@1.3.1/iconfont/icons.css")
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap')


:root
  --color-blue-light: 108,113,159
  --color-blue: 13, 30, 44
  --color-gray: 235, 235, 235
  --color-blue-gray: 237, 240, 243
  --color-success: 0, 178, 43
  --color-error: 185, 28, 38
  --color-orange: 255, 121, 7
  --color-card-gray: 248, 248, 248

  --color-black: 0, 0, 0
  --color-white: 255, 255, 255
  --color-hover-grey: 230, 235, 240
  --color-hover-grey-primary: rgba(230, 235, 240, 1)
  --color-black-dashed-border: 32, 61, 82

  --font-size-extra-extra-small: 9px
  --font-size-extra-small: 10px
  --font-size-small: 12px
  --font-size-regular: 14px
  --font-size-large: 16px
  --font-size-extra-large: 18px
  --font-size-extra-extra-large: 24px
  --font-weight-regular: 400
  --font-weight-button: 400

  --gap-8: 8px
  --gap-6: 6px
  --gap-4: 4px

  --padding-4: 4px
  --padding-6: 6px
  --padding-8: 8px

  --margin-8: 8px
  --margin-6: 6px
  --margin-4: 4px

  --card-content-padding-6: 6px
  --card-content-padding-8: 8px
  --card-content-padding-4: 4px

  --content-height: calc(100vh - 2 * var(--dashboard-gap) - var(--menu-height) - 1px)
  --content-width: calc(100vw - 2 * var(--dashboard-gap))
  --content-min-height: 700px
  --content-left-panel-width: minmax(580px, 1fr)
  --menu-height: calc(55px + 0.4vw + 0.5vh)
  --dashboard-gap: calc(4px + 0.2 * (1vh + 1vw))

  --button-action-color: rgba(var(--color-blue-light),0.12)
  --button-action-color-hover: rgba(var(--color-blue-light),0.25)
  --button-action-color-text: rgba(var(--color-blue),0.8)
  --button-action-color-text-hover: rgba(var(--color-blue),1)
  --button-action-height: 30px
  --button-action-radius: 4px

  --global-select-hovered: rgba(var(--color-hover-grey), 0.5)
  // --global-select-focused: rgba(var(--color-blue-light),0.12)

  --input-height: 38px

  --card-padding: calc(1px + 0.4 * (1vh + 1vw))
  --card-radius: 8px

  --dialog-card-width-900: 900px
  --dialog-card-width-540: 540px
  --dialog-card-width-450: 450px

  --border-color: rgba(var(--color-black),0.12)
  --blur: 6px

// SCROLL BARS
body::-webkit-scrollbar
  background-color: rgb(var(--color-white))
  width: 11px

body::-webkit-scrollbar-track
  background-color: rgb(var(--color-white))

body::-webkit-scrollbar-thumb
  background-color: #babac0
  border-radius: 16px
  border: 4px solid rgb(var(--color-white))

body::-webkit-scrollbar-button
  display: none

.form-control::-webkit-scrollbar
  // width: 5px !important
  height: 100% !important
  background-color: rgb(var(--color-white)) !important

.scroll-area::-webkit-scrollbar
  background-color: rgba(var(--color-blue-light), 0) !important
  width: 5px !important

.scroll-area::-webkit-scrollbar-track
  background-color: rgba(var(--color-blue-light), 0) !important

.scroll-area::-webkit-scrollbar-thumb
  background-color: #babac0 !important
  border-radius: 16px !important
  // border: 4px solid rgb(var(--color-white)) !important

.scroll-area::-webkit-scrollbar-button
  display: none !important

  // .reorder-select-list
  //   .scroll-box::-webkit-scrollbar
  //     background-color: rgba(var(--color-blue-light), 0.6) !important

.scroll-box
  height: auto
  // min-height: 80px
  max-height: 300px
  border-radius: var(--button-action-radius)
  border: 1px solid var(--color-border)
  background-color: rgba(var(--color-blue-light), 0)
  // background-color: rgb(var(--color-white))
  padding: var(--gap-6)
  overflow-y: auto

// APP, GLOBAL
body
  max-width: 100vw
  background-color: rgb(var(--color-gray))
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important
  box-sizing: border-box
  font-size: var(--font-size-regular)
  scrollbar-gutter: stable !important
  overflow-x: hidden

#entity-app-container
  font-size: var(--font-size-regular) !important
  .entity-app
    padding: 0 !important
    background: transparent !important
    min-height: calc(100vh - var(--menu-height)) !important
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important

  .entity-content
    height: calc(100vh - var(--menu-height))
    width: 100%
    padding: var(--dashboard-gap)
  &.mobile
    .entity-content
      padding-top: var(--menu-height)
      min-height: 100vh
  &.tablet
    .view-dashboard-content
      padding-bottom: var(--dashboard-gap)

.input-simple
  width: 100%
  border: none
  // border: 1px solid var(--border-color)
  border: 1px solid rgba(var(--color-white))
  padding: 5px
  color: var(--color-text)
  border-radius: 4px
  box-shadow: none
  font-size: var(--font-size-small)
  font-weight: 400
  transition: all 0.2s ease-in-out
  &:focus
    outline: none
  &:hover, &:focus
    border-color: rgba(var(--color-blue), 0.5)
  &.disabled
    background-color: rgba(var(--color-blue-light), .05) !important
    color: rgba(0,0,0, .5) !important
    pointer-events: none
//
// .search-dialog-wrapper
//   width: 100%
//   margin-bottom: calc(var(--dashboard-gap))
//   padding: calc(var(--dashboard-gap) * 0.7)
//   border-radius: 6px
//   background-color: rgba(var(--color-blue-light), 0.06)
//   display: inline-flex
//   gap: var(--dashboard-gap)
//   flex-flow: row wrap
//   > *
//     flex: 1 1 0
//     max-width: 100%
//     min-width: 190px
//     margin-bottom: 0

.search-dialog-wrapper
  width: 100%
  // padding: calc(var(--dashboard-gap)) calc(var(--dashboard-gap)) 0 calc(var(--dashboard-gap))
  border-radius: 6px
  // background-color: rgba(var(--color-blue-light), 0.06)
  display: grid
  grid-template-columns: 1fr 1fr 1fr 1fr
  grid-template-rows: 1fr 1fr
  gap: var(--gap-6)

.form
  padding: var(--padding-8)
  background-color: rgba(var(--color-blue-light), 0.06)
  display: grid
  grid-gap: var(--gap-6)

// PANEL TYPOGRAPHY
.view-panel-title
  font-size: var(--font-size-regular) !important
  font-weight: 600 !important
  // padding: 0 !important
  line-height: 1.5
  text-transform: capitalize
  display: flex
  align-items: center
  gap: var(--gap-6)
  border-left: 4px solid
  border-color: rgba(var(--color-orange), 1)
  padding-left: 4px
  .counter
    display: inline-block
    padding: 2px 6px
    border-radius: 6px
    font-size: var(--font-size-regular)
    font-weight: 600
    border: 1px solid rgba(0,0,0,.1)

.view-panel-subtitle
  font-size: var(--font-size-small) !important
  font-weight: 600
  color: var(--color-blue-dark)

.view-panel-description
  font-size: var(--font-size-small) !important
  font-weight: var(--font-weight-regular) !important
  color: rgba(var(--color-black),0.7) !important

.tips
  font-size: var(--font-size-extra-small) !important
  color: rgba(var(--color-black),0.5) !important
  text-align: center
  &.left
    text-align: left
  &.right
    text-align: right

.view-panel-input-description
  font-size: var(--font-size-extra-small) !important
  font-weight: var(--font-weight-regular) !important
  color: rgba(var(--color-black),0.5) !important
  // padding: 8px !important
  margin: 0px


// VIEW CONTENT
.view-dashboard-content
  width: 100%
  min-height: var(--content-height)
  overflow: hidden
  //padding-bottom: var(--dashboard-gap)
  &.two-columns
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: var(--dashboard-gap)

.view-list-content
  width: 100%
  background-color: rgb(var(--color-white))
  height: var(--content-height)
  overflow: hidden
  border-radius: var(--card-radius)
  padding: 0px
  >.header
    height: 60px
    border-bottom: 1px solid rgba(0,0,0,0.05)
    display: flex
    justify-content: space-between
    align-items: center
    gap: var(--gap-8)
    padding: 0 var(--padding-8)
    >div
      display: flex
      align-items: center
      gap: var(--gap-8)
  .virtual-scroller
    height: calc(var(--content-height) - 85px)
    width: 100%
    user-select: none !important
    position: relative !important
  // .circular-loader-wrapper
  //   height: 20px


// SPECIFIC (TO MOVE)
.view-download-files-expanded-panel
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
  gap: var(--gap-8)
  .add-file
    border: 1px dashed rgba(var(--color-black) 0.1)
    border-radius: 4px
    padding: var(--dashboard-gap)
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    gap: 12px
    transition: all 0.2s ease-in-out
    background-color: rgba(var(--color-blue-light), 0.06)
    cursor: pointer
    &:hover
      border-color: rgba(var(--color-blue), 0.2)
      background-color: rgba(var(--color-blue-light), 0.18)
      .icon
        color: var(--color-blue)
      span
        color: var(--color-blue)
    .icon
      font-size: 24px
      color: rgba(var(--color-black), 0.3)
      transition: all 0.2s ease-in-out
    span
      font-size: 11px
      font-weight: 500
      color: rgba(var(--color-black), 0.5)
      text-align: center
      transition: all 0.2s ease-in-out

// ANIMATIONS

@keyframes shake
  10%, 90%
    transform: translate3d(-1px, 0, 0)

  20%, 80%
    transform: translate3d(2px, 0, 0)

  30%, 50%, 70%
    transform: translate3d(-4px, 0, 0)

  40%, 60%
    transform: translate3d(4px, 0, 0)

@keyframes shakeBig
  10%, 90%
    transform: translate3d(-2px, 0, 0)

  20%, 80%
    transform: translate3d(4px, 0, 0)

  30%, 50%, 70%
    transform: translate3d(-8px, 0, 0)

  40%, 60%
    transform: translate3d(8px, 0, 0)


.fade-enter-active,
.fade-leave-active
  transition: opacity 0.2s ease-in-out

.fade-enter,
.fade-leave-to
  opacity: 0


.fade-deleyed-enter-active,
.fade-deleyed-leave-active
  transition: opacity 0.2s ease-in-out

.fade-deleyed-enter-active
  transition-delay: .65s

.fade-deleyed-enter,
.fade-deleyed-leave-to
  opacity: 0

.page-enter-active,
.page-leave-active
  transition: all 0.35s ease-in-out
.page-enter
  transform: translateX(50vw)
  opacity: 0
.page-leave-to
  transform: translateX(-50vw)
  opacity: 0

.fade-show-up-top-enter-active,
.fade-show-up-top-leave-active
  transition: all 0.35s ease-in-out

.fade-show-up-top-enter
  transform: translateY(10px)
  opacity: 0
.fade-show-up-top-leave-to
  transform: translateY(-10px)
  opacity: 0


.dropdown-enter-active, .dropdown-leave-active
  transition: all .2s ease-in-out

.dropdown-enter
  transform: translateY(-20px)
  opacity: 0
.dropdown-leave-to
  transform: translateY(20px)
  opacity: 0


.value-enter-active, .value-leave-active
  transition: all .2s ease-in-out

.value-enter
  transform: translateY(5px)
  opacity: 0
.value-leave-to
  transform: translateY(5px)
  opacity: 0
</style>
