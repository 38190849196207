const cdbMatchingTableJson = require("~/assets/json/ranos-security-model-matching-table.json");
export const getters = {
  computedFrontPageOptions: () => (firstPageSelections) => {
    const maFinancialOptions = [
      {
        id: "single_security_template",
        value: "Single security template"
      },
      {
        id: "multiple_security_template",
        value: "Multiple security template"
      },
      {
        id: "email_template",
        value: "Email only template"
      },
      {
        id: "blank_template",
        value: "Special report template"
      }
    ];
    return maFinancialOptions.map((obj) => ({
      id: obj.id,
      value: obj.value,
      isSelected: firstPageSelections
        ? firstPageSelections.includes(obj.id)
        : false,
      disabled: true,
      canMove: false
    }));
  },
  estimatedChanges: (state, getters, rootState, rootGetters) => {
    return (securityObj) => {
      const rowsToReturn = [
        {
          key: "title-row",
          type: "header",
          cells: [
            {
              value: "ESTIMATE CHANGES"
            },
            {
              value: "t+1"
            },
            {
              value: "t+2"
            },
            {
              value: "t+3"
            }
          ]
        },
        {
          key: 0,
          cells: [
            {
              value: "NPAT - new"
            },
            {
              matchingName: "adjusted_net_profit_generated_after_tax",
              matchingColumn: "t+1"
            },
            {
              matchingName: "adjusted_net_profit_generated_after_tax",
              matchingColumn: "t+2"
            },
            {
              matchingName: "adjusted_net_profit_generated_after_tax",
              matchingColumn: "t+3"
            }
          ]
        },
        {
          key: 1,
          cells: [
            {
              value: "NPAT - old"
            },
            {
              matchingName: "adjusted_net_profit_generated_after_tax",
              dataType: "prior",
              matchingColumn: "t+1"
            },
            {
              matchingName: "adjusted_net_profit_generated_after_tax",
              dataType: "prior",
              matchingColumn: "t+2"
            },
            {
              matchingName: "adjusted_net_profit_generated_after_tax",
              dataType: "prior",
              matchingColumn: "t+3"
            }
          ]
        },
        {
          key: 2,
          cells: [
            {
              value: "Change"
            },
            {
              value: "-"
            },
            {
              value: "-"
            },
            {
              value: "-"
            }
          ]
        },
        {
          key: 3,
          cells: [
            {
              value: "EPS (Dil.) (#) - new"
            },
            {
              matchingName: "eps_normalised",
              matchingColumn: "t+1"
            },
            {
              matchingName: "eps_normalised",
              matchingColumn: "t+2"
            },
            {
              matchingName: "eps_normalised",
              matchingColumn: "t+3"
            }
          ]
        },
        {
          key: 4,
          cells: [
            {
              value: "EPS (Dil.) (#) - old"
            },
            {
              matchingName: "eps_normalised",
              dataType: "prior",
              matchingColumn: "t+1"
            },
            {
              matchingName: "eps_normalised",
              dataType: "prior",
              matchingColumn: "t+2"
            },
            {
              matchingName: "eps_normalised",
              dataType: "prior",
              matchingColumn: "t+3"
            }
          ]
        },
        {
          key: 5,
          cells: [
            {
              value: "Change"
            },
            {
              value: "-"
            },
            {
              value: "-"
            },
            {
              value: "-"
            }
          ]
        },
        {
          key: 6,
          cells: [
            {
              value: "DPS (#) - new"
            },
            {
              matchingName: "declared_post-tax_dividend_per_share",
              matchingColumn: "t+1"
            },
            {
              matchingName: "declared_post-tax_dividend_per_share",
              matchingColumn: "t+2"
            },
            {
              matchingName: "declared_post-tax_dividend_per_share",
              matchingColumn: "t+3"
            }
          ]
        },
        {
          key: 7,
          cells: [
            {
              value: "DPS (#) - old"
            },
            {
              matchingName: "declared_post-tax_dividend_per_share",
              dataType: "prior",
              matchingColumn: "t+1"
            },
            {
              matchingName: "declared_post-tax_dividend_per_share",
              dataType: "prior",
              matchingColumn: "t+2"
            },
            {
              matchingName: "declared_post-tax_dividend_per_share",
              dataType: "prior",
              matchingColumn: "t+3"
            }
          ]
        },
        {
          key: 8,
          cells: [
            {
              value: "Change"
            },
            {
              value: "-"
            },
            {
              value: "-"
            },
            {
              value: "-"
            }
          ]
        }
      ];
      // const rowsToReturn = rootGetters["reportTemplate/getReportTableData"](
      //   securityObj,
      //   maFinancialRows
      // );
      // cant use the function directly  as the prior calculation increments the counter so its getting wrong column, need to call the function in the loop
      const rowsLength = rowsToReturn.length;
      if (rowsLength > 0) {
        // work out the changes for the rows
        for (let i = 0; i < rowsLength; i++) {
          const rowObj = rowsToReturn[i];
          if (!rowObj.cells || rowObj.cells.length === 0) continue;
          if (!rowObj.cells[0] || !rowObj.cells[0].value) continue;
          if (rowObj.cells[0].value === "Change") {
            // if its a change row get the index of the last 2 rows and compare the value
            const newRowIndex = i - 2;
            const oldRowIndex = i - 1;
            if (!rowsToReturn[newRowIndex]) {
              console.error(`could not find the new row index ${newRowIndex}`);
              console.error(rowsToReturn);
              continue;
            }
            if (!rowsToReturn[oldRowIndex]) {
              console.error(`could not find the old row index ${oldRowIndex}`);
              console.error(rowsToReturn);
              continue;
            }
            const newRowObj = rowsToReturn[newRowIndex];
            const oldRowObj = rowsToReturn[oldRowIndex];
            if (!newRowObj.cells || newRowObj.cells.length === 0) {
              console.error(`new cell row ${newRowIndex} has no cells`);
              console.error(rowsToReturn);
              continue;
            }
            if (!oldRowObj.cells || oldRowObj.cells.length === 0) {
              console.error(`old cell row ${oldRowIndex} has no cells`);
              console.error(rowsToReturn);
              continue;
            }
            // loop through all the cells, NOTE starts with 1 as the first cell is title
            for (
              let tmpCellIndex = 1;
              tmpCellIndex < newRowObj.cells.length;
              tmpCellIndex++
            ) {
              if (!oldRowObj.cells[tmpCellIndex]) continue;
              const newCell = newRowObj.cells[tmpCellIndex];
              const oldCell = oldRowObj.cells[tmpCellIndex];
              if (
                !newCell.value ||
                !oldCell.value ||
                newCell.value === "-" ||
                oldCell.value === "-"
              )
                continue;
              rowObj.cells[tmpCellIndex].value = rootGetters[
                "change/getPercentageChange"
              ](oldCell.value, newCell.value);
            }
            // end if change row
          } else {
            // get the data in normal row, need to do this row by row as the getReportTableData prior cdb data has auto increment, so need to rerun the function to reset the counter
            const newRow = rootGetters["reportTemplate/getReportTableData"](
              securityObj,
              [rowObj]
            );
            rowsToReturn[i] = newRow[0];
          }
        }
      }
      return rowsToReturn;
    };
  },
  maFinancialCdbMatchingTable: (state, getters, rootState, rootGetters) => {
    if (process.env.BASE_URL.indexOf("local") > -1) {
      return (
        cdbMatchingTableJson["946efedb-1cf5-4436-b422-f318f4b780cd"]
          ?.matchingTable?.namedRanges || {}
      );
    }
    const entity_id = rootGetters["user/currentEntity"]?.entity_id;
    if (!entity_id) return {};
    return cdbMatchingTableJson[entity_id]?.matchingTable?.namedRanges || {};
  }
};

export const actions = {
  async modelDataToCdb({ commit, getters, rootState, rootGetters, dispatch }) {
    if (!getters.maFinancialCdbMatchingTable)
      throw Error("no cdb matching table");
    if (!rootState.spreadsheet.worksheets) throw Error("no model uploaded");
    await dispatch("cdbSheet/remoteGetCDBMetrics", null, {
      root: true
    });
    // START get matching table data from model uploaded using name ranges
    // loop through all interested name ranges
    const singleMetricsValue = new Map();
    const multipleMetricsValue = new Map();
    const colFinYearMap = new Map();
    const allNameRangeMap = rootGetters["spreadsheet/nameRangeMap"];
    for (let i = 0; i < getters.maFinancialCdbMatchingTable.length; i++) {
      const currentRange = getters.maFinancialCdbMatchingTable[i];
      const cellRange = allNameRangeMap.get(currentRange.name.toLowerCase());
      if (!cellRange) continue;
      if (!currentRange.rows) continue;
      // if the name range exist in the model
      const reverseRowNameTranlate = new Map();
      const possibleRowName = [];
      const allSingleMetrics = new Set();
      for (let i = 0; i < currentRange.rows.length; i++) {
        const allRows = currentRange.rows[i];
        if (!allRows.matches || !allRows.name) {
          console.error(`no matches or name set for row ${i}`);
          continue;
        }
        if (allRows.type && allRows.type === "single_metrics")
          allSingleMetrics.add(allRows.name);
        allRows.matches.forEach((tmpMatchName) => {
          const lowerCased = tmpMatchName.toLowerCase();
          reverseRowNameTranlate.set(lowerCased, allRows.name);
          possibleRowName.push(lowerCased);
        });
      }
      // loop through the first row from the range to see where the column begins and also workout the years column
      const interestedRowMap = new Map();
      const ws = rootState.spreadsheet.worksheets.Sheets[cellRange.sheetName];
      if (!ws["!cols"]) ws["!cols"] = {};
      if (!ws["!rows"]) ws["!rows"] = {};
      // NOTE: assume the first column of the name range always include the cell indicator from bluematrix
      const indicatorColumn = cellRange.cells?.s?.c || 0;
      // NOTE: assume that bluematrix format use this indicator as the row to provide A or F
      const ACCONTING_YEAR_INDICATOR = "fstatus";
      // NOTE: assume that bluematrix format use this indicator as the row to provide if its FY report
      const REPORTING_PERIOD_INDICATOR = "fperiod";
      // NOTE: assume that bluematrix format use this indicator as the row to provide if its Year
      // const YEAR_ROW_INDICATOR = "begin";
      // NOTE: assume that bluematrix format for single metric are always 3rd column from the start
      const SINGLE_METRICS_COL = indicatorColumn + 3;
      let financailYearCheckRow, financialPeriodCheckRow;
      // loop through all the rows of the first column and find the rows that is of interest
      for (
        let tmpRow = cellRange.cells.s.r;
        tmpRow <= cellRange.cells.e.r;
        tmpRow++
      ) {
        const cellValue = rootGetters["spreadsheet/getCellObj"](
          false,
          cellRange.sheetName,
          tmpRow,
          indicatorColumn
        );
        if (!cellValue || !cellValue.v) continue;
        const nameToCheck =
          cellValue.w?.toLowerCase().trim() || cellValue.v.toLowerCase().trim();
        if (!nameToCheck) continue;
        if (reverseRowNameTranlate.has(nameToCheck)) {
          const tmpRowName = reverseRowNameTranlate.get(nameToCheck);

          // if its single metrics get the value
          if (allSingleMetrics.has(tmpRowName)) {
            const singleMetricsCellValue = rootGetters[
              "spreadsheet/getCellObj"
            ](false, cellRange.sheetName, tmpRow, SINGLE_METRICS_COL);
            if (singleMetricsCellValue) {
              singleMetricsValue.set(tmpRowName, singleMetricsCellValue);
            }
          } else {
            interestedRowMap.set(tmpRowName, tmpRow);
          }
        }
        // get the financial year row
        if (nameToCheck === ACCONTING_YEAR_INDICATOR)
          financailYearCheckRow = tmpRow;
        // get the financial period row
        if (nameToCheck === REPORTING_PERIOD_INDICATOR)
          financialPeriodCheckRow = tmpRow;
      }
      // debug
      // reverseRowNameTranlate.forEach((value) => {
      //   if (!interestedRowMap.has(value)) {
      //     console.log(value);
      //   }
      // });
      // end debug
      if (!financailYearCheckRow) {
        // need the financail row to determin where is t+1
        console.error(
          "could not find the financial row indicator to determine F or A"
        );
        // continue;
      }
      if (!financialPeriodCheckRow) {
        // need the financail row to determin where is t+1
        console.error(
          "could not find the financial period row indicator to determine FY"
        );
        // continue;
      }
      // loop through all the financial year row to determine where to start
      // NOTE: assume that bluematrix format uses F and A
      for (let col = cellRange.cells.s.c; col <= cellRange.cells.e.c; col++) {
        const financialPeriodCell = rootGetters["spreadsheet/getCellObj"](
          false,
          cellRange.sheetName,
          financialPeriodCheckRow,
          col
        );
        if (!financialPeriodCell) {
          continue;
        }
        const financialPeriodCellValue =
          financialPeriodCell.w || financialPeriodCell.v;
        const financialYearCell = rootGetters["spreadsheet/getCellObj"](
          false,
          cellRange.sheetName,
          financailYearCheckRow,
          col
        );
        if (!financialYearCell) {
          continue;
        }
        const financialYearCellValue =
          financialYearCell.w || financialYearCell.v;
        // use the first full year (FY) forecast (F) as T+1 then 3 columns before is t - 1, because of the sequence (1H, 2H, FY)
        if (
          ["FY", "CY"].includes(financialPeriodCellValue) &&
          financialYearCellValue == "F"
        ) {
          colFinYearMap.set("t+1", col);
          const loopingYears = new Map([
            ["t-5", col - 15],
            ["t-4", col - 12],
            ["t-3", col - 9],
            ["t-2", col - 6],
            ["t-1", col - 3],
            ["t+2", col + 3],
            ["t+3", col + 6],
            ["t+4", col + 9],
            ["t+5", col + 12]
          ]);
          loopingYears.forEach((yCol, yValue) => {
            const tmpCheckCell = rootGetters["spreadsheet/getCellObj"](
              false,
              cellRange.sheetName,
              financailYearCheckRow,
              yCol
            );
            if (!tmpCheckCell) {
              console.log(
                `cant find cell col:${yCol} row:${financailYearCheckRow}`
              );
              return;
            }
            const tmpCheckCellValue = tmpCheckCell.w || tmpCheckCell.v;
            if (["F", "A"].includes(tmpCheckCellValue)) {
              colFinYearMap.set(yValue, yCol);
            }
          });
          // colFinYearMap.set("t-5", col - 15);
          // colFinYearMap.set("t-4", col - 12);
          // colFinYearMap.set("t-3", col - 9);
          // colFinYearMap.set("t-2", col - 6);
          // colFinYearMap.set("t-1", col - 3);
          // colFinYearMap.set("t+2", col + 3);
          // colFinYearMap.set("t+3", col + 6);
          // colFinYearMap.set("t+4", col + 9);
          // colFinYearMap.set("t+5", col + 12);
          // use the current financial year to set the metrics
          const yearMetricsArray = [
            "Current financial year",
            "Financial year-end"
          ];
          yearMetricsArray.forEach((tmpMetric) => {
            if (interestedRowMap.has(tmpMetric)) {
              const yearEndCell = rootGetters["spreadsheet/getCellObj"](
                false,
                cellRange.sheetName,
                interestedRowMap.get(tmpMetric),
                col
              );
              if (yearEndCell) {
                singleMetricsValue.set(tmpMetric, yearEndCell);
              }
            }
          });
          break;
        }
      }
      // use the years col and interested rows to get all the multiple years value
      interestedRowMap.forEach((row, metricsName) => {
        const resultMap = new Map();
        colFinYearMap.forEach((col, reporting_period) => {
          const cellValue = rootGetters["spreadsheet/getCellObj"](
            false,
            cellRange.sheetName,
            row,
            col
          );
          if (cellValue) {
            resultMap.set(reporting_period, cellValue);
          } else {
            // if the cell does not exist use 0 value
            resultMap.set(reporting_period, {
              v: 0
            });
          }
        });
        multipleMetricsValue.set(metricsName, resultMap);
      });
    }
    // END get matching table data from model uploaded using name ranges
    // START use the data gotten to apply value or calculation then update the CDB sheet
    // NOTE use an object just in case there are other stuff we need
    const checkCellNumericValue = function (cellObj) {
      if (cellObj.v) return cellObj.v;
      const numberToParse = cellObj.w || "0";
      if (!numberToParse || numberToParse === "-") return 0;
      return rootGetters["app_store/getDecimalNumberValueFromString"](
        numberToParse
      );
    };
    const getCellValue = function (metricsToFind, tmpData, fyear) {
      if (!tmpData.has(metricsToFind)) return "-";
      const metricCell = tmpData.get(metricsToFind);
      const metricValue = metricCell.get(fyear) || "-";
      // debug
      // if (metricsToFind === "COMIT003") {
      //   console.log("---");
      //   console.log(metricsToFind);
      //   console.log(fyear);
      //   console.log(metricCell.get(fyear));
      //   console.warn("DEBUGPRINT[12]: mafinancial.js:234: metricValue=", metricValue)
      //   console.log("---");
      // }
      // debug
      return metricValue;
    };
    const getCellValueSum = function (metricsArray, tmpData, fyear) {
      return metricsArray.reduce((currentSum, metricToFind) => {
        if (!tmpData.has(metricToFind)) return currentSum;
        const metricCell = tmpData.get(metricToFind);
        if (!metricCell.has(fyear)) return currentSum;
        const metricValue = checkCellNumericValue(metricCell.get(fyear));
        // debug
        // if (metricToFind === "INDCF001" || metricToFind === "PROCF001") {
        //   console.log("---");
        //   console.log(metricToFind);
        //   console.log(fyear);
        //   console.log(metricCell.get(fyear));
        //   console.warn("DEBUGPRINT[12]: mafinancial.js:234: metricValue=", metricValue)
        //   console.warn("DEBUGPRINT[13]: mafinancial.js:236: currentSum=", currentSum)
        //   console.log("---");
        // }
        // debug
        return currentSum + metricValue;
      }, 0);
    };
    const dataToGetArray = [
      {
        internalName: "target_price",
        singleMetric: "TARGET"
      },
      {
        internalName: "financial_year-end",
        singleMetric: "Financial year-end"
      },
      {
        internalName: "current_financial_year",
        singleMetric: "Current financial year"
      },
      {
        internalName: "dividend_return",
        singleMetric: "Dividend return"
      },
      {
        internalName: "operating_revenues_generated",
        dataFunc: getCellValueSum,
        matchingMetrics: ["COMIT001", "COMIT002", "PROPL001"]
      },
      {
        internalName: "operating_expenses_incurred",
        dataFunc: getCellValueSum,
        matchingMetrics: ["COMIT114", "COMIT115"]
      },
      {
        internalName: "operating_ebitda_generated",
        dataFunc: getCellValue,
        matchingMetric: "COMIT003"
      },
      {
        internalName: "depreciation_and_amortisation_incurred",
        dataFunc: getCellValueSum,
        matchingMetrics: ["COMIT004", "COMIT005"]
      },
      {
        internalName: "operating_ebit_generated",
        dataFunc: getCellValue,
        matchingMetric: "COMIT006"
      },
      {
        internalName: "debt_interest_incurred",
        dataFunc: getCellValue,
        matchingMetric: "COMIT008"
      },
      {
        internalName: "cash_interest_generated",
        dataFunc: getCellValue,
        matchingMetric: "COMIT007"
      },
      {
        internalName: "net_profit_generated_before_tax",
        dataFunc: getCellValue,
        matchingMetric: "COMIT010"
      },
      {
        internalName: "corporate_tax_incurred",
        dataFunc: getCellValue,
        matchingMetric: "COMIT011"
      },
      {
        internalName: "minority_interest_incurred",
        dataFunc: getCellValue,
        matchingMetric: "COMIT012"
      },
      {
        internalName: "equity_accounted_earnings_generated",
        dataFunc: getCellValue,
        matchingMetric: "PROPL002"
      },
      {
        internalName: "adjusted_net_profit_generated_after_tax",
        dataFunc: getCellValue,
        matchingMetric: "COMIT013"
      },
      {
        internalName: "other_revenues_generated_and_expenses_incurred",
        dataFunc: getCellValueSum,
        matchingMetrics: ["COMIT014", "COMIT015"]
      },
      {
        internalName: "reported_net_profit_generated_after_tax",
        dataFunc: getCellValue,
        matchingMetric: "COMIT017"
      },
      {
        internalName: "ebitda_margin",
        dataFunc: getCellValue,
        matchingMetric: "COMIT081"
      },
      {
        internalName: "ebit_margin",
        dataFunc: getCellValue,
        matchingMetric: "COMIT082"
      },
      {
        internalName: "effective_tax_rate",
        dataFunc: getCellValue,
        matchingMetric: "COMIT068"
      },
      {
        internalName: "eps_normalised",
        dataFunc: getCellValue,
        matchingMetric: "COMIT072"
      },
      {
        internalName: "cash_and_equivalents",
        dataFunc: getCellValue,
        matchingMetric: "COMIT018"
      },
      {
        internalName: "debtors_and_inventory",
        dataFunc: getCellValueSum,
        matchingMetrics: ["COMIT019", "COMIT020"]
      },
      {
        internalName: "fixed_and_intangible_assets",
        dataFunc: getCellValueSum,
        matchingMetrics: [
          "COMIT023",
          "COMIT025",
          "COMIT027",
          "COMIT028",
          "PROBS001"
        ]
      },
      {
        internalName: "investments",
        dataFunc: getCellValueSum,
        matchingMetrics: ["COMIT024", "PROBS002"]
      },
      {
        internalName: "other_assets",
        dataFunc: getCellValue,
        matchingMetric: "COMIT021"
      },
      {
        internalName: "total_assets",
        dataFunc: getCellValue,
        matchingMetric: "COMIT030"
      },
      {
        internalName: "debt_and_equivalents",
        dataFunc: getCellValueSum,
        matchingMetrics: ["COMIT031", "COMIT037"]
      },
      {
        internalName: "creditors",
        dataFunc: getCellValueSum,
        matchingMetrics: ["COMIT032", "PROBS003"]
      },
      {
        internalName: "minority_interests",
        dataFunc: getCellValueSum,
        matchingMetrics: ["COMIT045", "COMIT046"]
      },
      {
        internalName: "other_liabilities",
        dataFunc: getCellValueSum,
        matchingMetrics: ["COMIT033", "COMIT034", "COMIT036", "COMIT038"]
      },
      {
        internalName: "shareholders_equity",
        dataFunc: getCellValue,
        matchingMetric: "COMIT047"
      },
      {
        internalName: "total_liabilities_and_equity",
        dataFunc: getCellValueSum,
        matchingMetrics: ["COMIT040", "COMIT047"]
      },
      {
        internalName: "net_debt_to_capital",
        dataFunc: getCellValue,
        matchingMetric: "COMIT088"
      },
      {
        internalName: "interest_cover",
        dataFunc: getCellValue,
        matchingMetric: "COMIT089"
      },
      {
        internalName: "working_capital_to_revenue",
        dataFunc: getCellValue,
        matchingMetric: "COMIT091"
      },
      {
        internalName: "return_on_capital_employed",
        dataFunc: getCellValue,
        matchingMetric: "COMIT123"
      },
      {
        internalName: "operating_ebitda_received",
        dataFunc: getCellValueSum,
        matchingMetrics: ["INDCF001", "PROCF001"]
      },
      {
        internalName: "net_interest_paid",
        dataFunc: getCellValueSum,
        matchingMetrics: ["COMIT050", "PROCF002", "PROCF003"]
      },
      {
        internalName: "corporate_tax_paid",
        dataFunc: getCellValue,
        matchingMetric: "COMIT051"
      },
      {
        internalName: "change_in_working_capital",
        dataFunc: getCellValueSum,
        matchingMetrics: ["COMIT052", "COMIT53"]
      },
      {
        internalName: "operating_cashflow_received",
        dataFunc: getCellValue,
        matchingMetric: "COMIT054"
      },
      {
        internalName: "capital_expenditure_paid",
        dataFunc: getCellValueSum,
        matchingMetrics: ["COMIT055", "COMIT056"]
      },
      {
        internalName: "free_cashflow_received",
        dataFunc: getCellValueSum,
        matchingMetrics: ["COMIT054", "COMIT055", "COMIT056"]
      },
      {
        internalName: "divestments_made",
        dataFunc: getCellValue,
        matchingMetric: "COMIT057"
      },
      {
        internalName: "other_cash_items_received_and_paid",
        dataFunc: getCellValueSum,
        matchingMetrics: ["COMIT058", "COMIT063"]
      },
      {
        internalName: "distributable_free_cashflow_received",
        dataFunc: getCellValue,
        matchingMetric: "COMIT067"
      },
      {
        internalName: "dividends_paid",
        dataFunc: getCellValue,
        matchingMetric: "COMIT061"
      },
      {
        internalName: "change_in_cash_and_equivalents",
        dataFunc: getCellValue,
        matchingMetric: "COMIT066"
      },
      {
        internalName: "change_in_debt_and_equivalents",
        dataFunc: getCellValue,
        matchingMetric: "COMIT062"
      },
      {
        internalName: "equity_issued",
        dataFunc: getCellValue,
        matchingMetric: "COMIT060"
      },
      {
        internalName: "proportion_of_imputed_dividends",
        dataFunc: getCellValue,
        matchingMetric: "COMIT080"
      },
      {
        internalName: "declared_post-tax_dividend_per_share",
        dataFunc: getCellValue,
        matchingMetric: "COMIT078"
      },
      {
        internalName: "dividend_payout",
        dataFunc: getCellValue,
        matchingMetric: "COMIT079"
      },
      {
        internalName: "cash_conversion",
        dataFunc: getCellValue,
        matchingMetric: "COMIT092"
      },
      {
        internalName: "shares_on_issue",
        singleMetric: "Shares on issue"
      },
      {
        internalName: "share_price_valuation",
        singleMetric: "DCF"
      },
      {
        internalName: "long_run_growth_rate_(nominal)",
        singleMetric: "DTR"
      },
      {
        internalName: "long_run_growth_rate_(nominal)",
        singleMetric: "DTR"
      },
      {
        internalName: "risk_free_rate",
        singleMetric: "BR"
      },
      {
        internalName: "equity_beta",
        singleMetric: "BETA"
      },
      {
        internalName: "equity_market_risk_premium",
        singleMetric: "ERP"
      },
      {
        internalName: "weighted_average_cost_of_capital",
        singleMetric: "WACC"
      }
    ];
    const cellUpdateArray = [];
    for (let i = 0; i < dataToGetArray.length; i++) {
      const cdbRowToMatch = dataToGetArray[i];
      if (!cdbRowToMatch.internalName) {
        console.error("did not provide internal name", cdbRowToMatch);
        continue;
      }
      const internal_name = cdbRowToMatch.internalName;
      const cdbObj =
        rootGetters["cdbSheet/cdbMetricsNameDictionary"][internal_name] || null;
      if (!cdbObj) {
        console.error(`could not get cdb information for ${internal_name}`);
        continue;
      }
      const cellObj = rootState.spreadsheet.cdbIdMap.find(
        (tmpObj) => tmpObj.metric_id == cdbObj.metrics_id
      );
      if (!cellObj) {
        console.error(
          `could not find cdb metric id to cell mapping for ${internal_name}`
        );
        continue;
      }
      if (cdbRowToMatch.singleMetric) {
        // just get a single value
        const newValue = singleMetricsValue.get(cdbRowToMatch.singleMetric);
        if (!newValue) {
          console.error(
            `could not find new value for single metric ${cdbRowToMatch.singleMetric}`
          );
        }

        if (!newValue) continue;
        const updateCellIndex = cellObj.params?.indexOf("t");
        if (updateCellIndex < 0) {
          console.error(
            `could not find the cell object for year t of ${internal_name}`
          );
          continue;
        }
        const updateCell = cellObj.params_cells[updateCellIndex];
        if (!updateCell) {
          console.error(
            `could not map cell index to actually number for t of ${internal_name}`
          );
          continue;
        }
        const cell_decode = rootGetters["spreadsheet/decodeCell"](updateCell);
        if (!cell_decode) {
          console.error(`could decode cell for ${updateCell}`);
          continue;
        }
        const singlePushingObj = {
          id: updateCell,
          col: cell_decode.c,
          row: cell_decode.r,
          value: newValue.v || "-",
          displayText: newValue.w || newValue.v || "-",
          format: newValue.z || false,
          sheetName: rootState.spreadsheet.CDB_SHEET_NAME
        };
        cellUpdateArray.push(singlePushingObj);
        // end single value
      } else if (cdbRowToMatch.matchingMetrics) {
        // need to use data function to calculate the values
        if (colFinYearMap.size < 1) {
          console.error(`financial years map is empty`);
          continue;
        }
        colFinYearMap.forEach((colIndex, fyear) => {
          const newValue = cdbRowToMatch.dataFunc(
            cdbRowToMatch.matchingMetrics,
            multipleMetricsValue,
            fyear
          );
          // if (!newValue) return;
          const updateCellIndex = cellObj.params?.indexOf(fyear);
          if (updateCellIndex < 0) {
            console.error(
              `could not find the cell object for year t of ${internal_name} and financial year ${fyear}`
            );
            return;
          }
          const updateCell = cellObj.params_cells[updateCellIndex];
          if (!updateCell) {
            console.error(
              `could not map cell index to actually number for ${fyear} of ${internal_name}`
            );
            return;
          }
          const cell_decode = rootGetters["spreadsheet/decodeCell"](updateCell);
          if (!cell_decode) {
            console.error(`could decode cell for ${updateCell}`);
            return;
          }
          let pushingObj;
          if (!newValue) {
            pushingObj = {
              id: updateCell,
              col: cell_decode.c,
              row: cell_decode.r,
              value: 0,
              displayText: "0.0",
              sheetName: rootState.spreadsheet.CDB_SHEET_NAME
            };
          } else {
            pushingObj = {
              id: updateCell,
              col: cell_decode.c,
              row: cell_decode.r,
              value: newValue,
              sheetName: rootState.spreadsheet.CDB_SHEET_NAME
            };
          }
          cellUpdateArray.push(pushingObj);
        });
        // end calculate values
      } else if (cdbRowToMatch.matchingMetric) {
        // just matches value with year
        if (colFinYearMap.size < 1) {
          console.error(`financial years map is empty`);
          continue;
        }
        colFinYearMap.forEach((colIndex, fyear) => {
          const newValue = cdbRowToMatch.dataFunc(
            cdbRowToMatch.matchingMetric,
            multipleMetricsValue,
            fyear
          );
          if (!newValue) return;
          const updateCellIndex = cellObj.params?.indexOf(fyear);
          if (updateCellIndex < 0) {
            console.error(
              `could not find the cell object for year t of ${internal_name} and financial year ${fyear}`
            );
            return;
          }
          const updateCell = cellObj.params_cells[updateCellIndex];
          if (!updateCell) {
            console.error(
              `could not map cell index to actually number for ${fyear} of ${internal_name}`
            );
            return;
          }
          const cell_decode = rootGetters["spreadsheet/decodeCell"](updateCell);
          if (!cell_decode) {
            console.error(`could decode cell for ${updateCell}`);
            return;
          }
          const pushingObj = {
            id: updateCell,
            col: cell_decode.c,
            row: cell_decode.r,
            value: newValue.v,
            displayText: newValue.w,
            format: newValue.z || false,
            sheetName: rootState.spreadsheet.CDB_SHEET_NAME
          };
          cellUpdateArray.push(pushingObj);
        });
        // end matching metrics
      }
    }
    if (cellUpdateArray.length > 0)
      commit("spreadsheet/updateCellValueNoCalculation", cellUpdateArray, {
        root: true
      });
    return true;
    // END use the data gotten to apply value or calculation then update the CDB sheet
  }
};
