<template>
  <div v-if="!hidden">
    <nuxt-link
      v-ripple="15"
      :to="disabled ? '#' : href"
      class="main-menu-item"
      :class="{ active: active, disabled: disabled }"
      @click.native="handleClick"
    >
      <slot></slot>
    </nuxt-link>

    <error-dialog
      ref="errorDialog"
      :error-title="'Contact Administrator'"
      :error-text="disabledMessage"
    />
  </div>
</template>

<script>
import ErrorDialog from "~/components/new-platform/dialogs/ErrorDialog";
export default {
  components: {
    "error-dialog": ErrorDialog
  },

  props: {
    href: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledMessage: {
      type: String,
      default: ""
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    dialogError: false
  }),

  methods: {
    handleClick() {
      if (this.disabled) {
        this.$refs.errorDialog.show();
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.main-menu-item
  font-size: var(--font-size-regular)
  color: rgba(255,255,255,0.65)
  padding: 4px 8px
  border-radius: var(--button-action-radius)
  transition: all 0.2s ease-in-out
  text-decoration: none !important
  font-weight: var(--font-weight-regular)
  &:hover
    background-color: rgba(255,255,255,0.07)
    color: rgba(255,255,255,0.8)
  &.active
    background-color: rgba(255,255,255,0.15)
    color: rgba(255,255,255,1)
  &.disabled
    opacity: 0.5

.dialog-error
  max-width: var(--dialog-card-width-540) !important
</style>
