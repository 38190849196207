<template>
  <dialog-wrapper v-model="dialogError" @close="dialogError = false">
    <card class="dialog-error" no-line>
      <template slot="header">
        <div class="view-panel-title">{{ errorTitle }}</div>
        <button-action small icon-only icon="close" @click="close" />
      </template>
      <div class="content">
        <p class="view-panel-description">{{ errorText }}</p>
      </div>
    </card>
  </dialog-wrapper>
</template>

<script>
import Card from "~/components/new-platform/cards/PlatformCard";
import DialogWrapper from "~/components/new-platform/containers/DialogWrapper";
import ButtonAction from "~/components/new-platform/buttons/ButtonAction";
export default {
  components: {
    card: Card,
    "dialog-wrapper": DialogWrapper,
    "button-action": ButtonAction
  },

  props: {
    errorTitle: {
      type: String,
      default: ""
    },
    errorText: {
      type: String,
      default: ""
    }
  },

  data: () => ({
    dialogError: false
  }),

  methods: {
    show() {
      this.dialogError = true;
    },
    close() {
      this.dialogError = false;
    }
  }
};
</script>

<style lang="sass" scoped>
.dialog-error
  max-width: var(--dialog-card-width-540) !important
</style>
