<template>
  <div id="entity-menu" :class="{ mobile: isMobile }">
    <div>
      <!-- <nuxt-link
        v-if="!isMobile"
        v-ripple="30"
        to="/entity-new/"
        class="menu-logo"
      > -->
      <img
        v-if="!isMobile"
        :src="
          currentEntityDetail.notification_negative_icon ??
          '~/assets/logos/logo_Negative.png'
        "
        alt="Entity logo"
        class="menu-logo"
      />
      <!-- </nuxt-link> -->
      <img v-else src="/logos/logo_Icon_negative.png" class="logo-ranos" />
    </div>

    <div v-if="!isMobile" class="entity-menu-items">
      <div class="menu-items">
        <menu-item
          v-for="(navText, mappingKey) in navMap"
          :key="`nav-item-${mappingKey}`"
          :active="getActiveNavTab(mappingKey)"
          :href="hrefLink(mappingKey)"
          :disabled="!aviableNavItems.includes(mappingKey)"
          :disabled-message="navMapErrors[mappingKey]"
        >
          {{ navText }}
        </menu-item>
      </div>
      <error-popover
        v-if="isLimitedAccess || !isAuthorised('access_ai_environment', true)"
        ref="error-popover"
        container-id="entity-menu-items-container"
        target-button-id="nav-item-rgpt"
        :is-dismiss-able="true"
        :title="errorTitle"
        :description="errorText"
      />
    </div>
    <div v-if="!eurozOrGeniumLayout" class="entity-search">
      <menu-search />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ErrorPopover from "~/components/popovers/ErrorPopover";
import MenuItem from "./MenuItem.vue";
import MenuSearch from "./MenuSearch.vue";

export default {
  components: {
    "error-popover": ErrorPopover,
    "menu-item": MenuItem,
    "menu-search": MenuSearch
  },
  data: () => ({
    navMapErrors: {
      memberlist:
        "Member pages are restricted to administrators. Please contact an administrator directly or through the Entity page to request the appropriate access.",
      changelist:
        "Change pages are restricted to administrators and editors. Please contact an administrator directly or through the Entity page to request the appropriate access.",
      rgpt: "RGPT (AI Beta) requires authorisation. Please contact an administrator directly or through the Entity page to request the appropriate access."
    },
    errorTitle: "",
    errorText: ""
  }),
  computed: {
    eurozOrGeniumLayout() {
      return this.shouldUseEurozLayout || this.shouldUseGeniumLayout;
    },
    navMap() {
      if (this.eurozOrGeniumLayout) {
        return {
          home: "Home",
          entity: "Entity",
          rgpt: "RGPT",
          publicationlist: "Library"
        };
      } else {
        return {
          home: "Home",
          entity: "Entity",
          memberlist: "Members",
          securitylist: "Securities",
          changelist: "Changes",
          rgpt: "RGPT",
          publicationlist: "Library"
        };
      }
    },
    getActiveNavTab() {
      return (routeToMatch) => {
        switch (routeToMatch) {
          case "entity":
            return this.$route.name === "entity-new";
          case "memberlist":
            return (
              this.$route.name === "memberlist-new" ||
              this.$route.name === "member-new" ||
              this.$route.name === "managemembers-new"
            );
          case "changelist":
            return (
              this.$route.name === "changelist-new" ||
              this.$route.name === "change-new"
            );
          case "securitylist":
            return (
              this.$route.name === "securitylist-new" ||
              this.$route.name === "security-new"
            );
          case "publicationlist":
            return this.$route.name === "publicationlist-new";
          case "rgpt":
            return this.$route.name === "rgpt-new";
          default:
            break;
        }
      };
    },
    ...mapGetters({
      currentEntity: "user/currentEntity",
      isLimitedAccess: "user/isLimitedAccess",
      isEditor: "user/isEditor",
      isViewer: "user/isViewer",
      isMemberEditor: "user/isMemberEditor",
      isAuthorised: "user/isAuthorised",
      isTablet: "app_store/isTablet",
      isMobile: "app_store/isMobile",
      shouldUseEurozLayout: "entity_configs/eurozhartleys/shouldUseEurozLayout",
      shouldUseGeniumLayout: "entity_configs/genium/shouldUseGeniumLayout"
    }),
    ...mapState({
      currentEntityDetail: (state) => state.user.currentEntityDetail
    }),

    aviableNavItems() {
      return Object.keys(this.navMap).filter((key) => {
        if (!this.isAuthorised("access_ai_environment", true)) {
          if (key === "rgpt" || key === "pgpt-new") {
            return false;
          }
        }
        if (this.isViewer) {
          if (
            key === "memberlist" ||
            key === "changelist" ||
            key === "memberlist-new" ||
            key === "changelist-new"
          ) {
            return false;
          }
        }
        if (this.isMemberEditor) {
          if (key === "changelist" || key === "changelist-new") {
            return false;
          }
        }
        if (this.isEditor) {
          if (key === "memberlist" || key === "memberlist-new") {
            return false;
          }
        }
        return true;
      });
    }
  },
  methods: {
    hrefLink(val) {
      if (!this.aviableNavItems.includes(val)) {
        return "#";
      } else if (val === "home") {
        return "/";
      } else {
        return "/" + val + "-new/";
      }
    },
    showMemberError() {
      this.errorTitle = "Contact Administrator";
      this.errorText =
        "Member pages are restricted to administrators. Please contact an administrator directly or through the Entity page to request the appropriate access.";
      this.$refs["error-popover"].show();
    },
    showChangeError() {
      this.errorTitle = "Contact Administrator";
      this.errorText =
        "Change pages are restricted to administrators and editors. Please contact an administrator directly or through the Entity page to request the appropriate access.";
      this.$refs["error-popover"].show();
    },
    showRgptError() {
      this.errorTitle = "Contact Administrator";
      this.errorText =
        "RGPT (AI Beta) requires authorisation. Please contact an administrator directly or through the Entity page to request the appropriate access.";
      this.$refs["error-popover"].show();
    }
  }
};
</script>

<style lang="sass" scoped>
#entity-menu
  display: grid
  grid-template-columns: auto 1fr 240px
  grid-gap: calc(2 * var(--dashboard-gap))
  height: var(--menu-height)
  padding: 0px var(--dashboard-gap)
  margin: 0
  align-items: center
  background-color: rgb(var(--color-background))
  z-index: 10
  &.mobile
    grid-template-columns: auto 1fr
    position: fixed
    width: 100%
    top: 0
    left: 0
    align-items: center
  .logo-ranos
    max-height: 28px
  .entity-menu-items
    position: relative
    .menu-items
      display: flex
      align-items: center
      gap: 8px

  .menu-logo
    height: calc(var(--menu-height) / 1.5)
    display: flex
    align-items: center
    justify-content: center
    padding: 6px
    border-radius: 4px
    // background-color: rgb(var(--color-white),0.06)
    transition: all 0.2s ease-in-out
    // &:hover
    //   background-color: rgb(var(--color-white),0.12)
    //   img
    //     transform: scale(.95)
    img
      max-height: 100%
      max-width: 100px
      transition: all 0.2s ease-in-out
</style>
