/*
    Publication setup store contains interaction and storage for the V2 API setup_publications/{entity_id}/{change_id}
    this is meant enable a delayed state for a publication
 */

import {
  DELAYED_PUBLICATION_DELAYED,
  DELAYED_PUBLICATION_IMMEDIATE,
  DelayedPublication,
  FormatDateForAPublicationGivenDelayedPublicationTimezone,
  FormatDateForAScheduledChangePopoverGivenDelayedPublicationTimezone
} from "assets/js/delayed_publication";

export const state = () => ({
  delayedPublications: new Map() // Key Value map key is the change id
});

export const mutations = {
  setDelayedPublicationDetails(state, data) {
    state.delayedPublications.set(data.changeId, data);
  },
  clearDelayedPublication(state, changeId) {
    state.delayedPublications.delete(changeId);
  }
};

export const actions = {
  async getDelayedPublicationDetails({ commit }, { entityId, changeId }) {
    try {
      const { data } = await this.$axios.get(
        `/v2/setup_publication/${entityId}/${changeId}`
      );
      data.changeId = changeId;
      commit("setDelayedPublicationDetails", data);
    } catch (error) {
      console.error(error);
    }
  },

  async updateDelayedPublicationDetails({ commit }, { changeId, payload }) {
    commit("setDelayedPublicationDetails", payload);
    return true;
  },

  /*
    Call made once the user "Publishes" a change
   */
  async schedulePublication({ commit }, { entityId, changeId, payload }) {
    try {
      await this.$axios.post(
        `/v2/setup_publication/${entityId}/${changeId}`,
        payload
      );
      payload.changeId = changeId;
      return true;
    } catch (error) {
      console.error(error);
    }
  },

  async deleteDelayedPublicationDetails({ commit }, { entityId, changeId }) {
    try {
      await this.$axios.delete(`/v2/setup_publication/${entityId}/${changeId}`);
      commit("clearDelayedPublication", null);
    } catch (error) {
      console.error(error);
    }
  },

  async clearDelayedPublication({ rootState, dispatch }) {
    console.log("🚀 ~ clearDelayedPublication ~ clearDelayedPublication:");
    // Update the sate
    await dispatch("publication_setup/updateDelayedPublicationDetails", {
      changeId: rootState.change.currentChangeDetail.change_id,
      payload: new DelayedPublicationDetails(
        "",
        "",
        null,
        null,
        rootState.change.currentChangeDetail.change_id
      )
    });
    const response = await dispatch("change/remoteUpdateCurrentChange", {
      change_id: rootState.change.currentChangeDetail.change_id,
      change_planned_publication: "",
      change_planned_publication_timezone: ""
    });
    console.log("🚀 ~ clearDelayedPublication ~ response:", response);
    return response;
  }
};

export const getters = {
  delayedPublicationDetails: (state) => {
    return (changeId) => {
      if (state.delayedPublications.has(changeId)) {
        return state.delayedPublications.get(changeId);
      } else {
        return null;
      }
    };
  },
  /*
    Delayed publication state is a more developer friendly model that contains convenience properties such as status and isDelayed
   */
  getDelayedPublicationState: (state) => {
    return (changeId) => {
      if (state.delayedPublications.has(changeId)) {
        const delayedPub = state.delayedPublications.get(changeId);
        const delayedPublicationState = new DelayedPublication();
        delayedPublicationState.setTimezoneFromDB(delayedPub.timezone);
        delayedPublicationState.setDateTime(delayedPub.datetime);
        if (
          delayedPublicationState.timezone == null ||
          delayedPublicationState.timezone === ""
        ) {
          delayedPublicationState.setStatus(DELAYED_PUBLICATION_IMMEDIATE);
        } else {
          delayedPublicationState.setStatus(DELAYED_PUBLICATION_DELAYED);
        }
        return delayedPublicationState;
      }
      return new DelayedPublication();
    };
  },
  getFormattedDelayedPublicationDate: (state) => {
    return (changeId) => {
      const delayedPublication = state.delayedPublications.get(changeId);
      if (delayedPublication != null) {
        // Return a nicely formatted display time or date to the front end, the rate returned to the front end will include the timezone component
        if (
          delayedPublication.datetime != null &&
          delayedPublication.timezone != null &&
          typeof delayedPublication.datetime != "undefined" &&
          delayedPublication.datetime.length > 0
        ) {
          return FormatDateForAPublicationGivenDelayedPublicationTimezone(
            delayedPublication.datetime,
            delayedPublication.timezone
          );
        } else {
          return null;
        }
      }
      return null;
    };
  },
  getFormattedDateForScheduledChangePopover: (state) => {
    return (changeId) => {
      const delayedPublication = state.delayedPublications.get(changeId);
      if (delayedPublication != null) {
        // Return a nicely formatted display time or date to the front end, the rate returned to the front end will include the timezone component
        if (
          delayedPublication.datetime != null &&
          delayedPublication.timezone != null
        ) {
          return FormatDateForAScheduledChangePopoverGivenDelayedPublicationTimezone(
            delayedPublication.datetime,
            delayedPublication.timezone
          );
        } else {
          return null;
        }
      }
      return null;
    };
  }
};

export class DelayedPublicationDetails {
  constructor(timezone, datetime, planned_by, planned_on, changeId) {
    this.timezone = timezone;
    this.datetime = datetime;
    this.planned_by = planned_by; // Only returned in the GET response
    this.planned_on = planned_on; // Only returned in the GET request
    this.changeId = changeId;
  }
}
