import Vue from "vue";
import html2canvas from "html2canvas";
const clone = require("rfdc")({
  proto: true
});
// import moment from "moment";
// const moment = require("moment-timezone");

export const state = () => ({
  FRONT_PG_KEY: "front page",
  TABLE_OF_CONTENT: "table of content",
  DISCLAIMER: "disclaimer",
  OTHER_DISCLAIMER: "other disclaimer",
  hasUndo: {},
  hasRedo: {},
  isOnTrackChanges: false,
  PDF_OPTION: [
    "paperWidth=8.27",
    "paperHeight=11.69",
    "marginTop=0.35",
    "marginBottom=0",
    "marginLeft=0.34",
    "marginRight=0.34",
    "pdfFormat="
  ],
  EMAIL_PDF_OPTION: [
    "paperWidth=8.27",
    "paperHeight=11.69",
    "marginTop=0.3",
    "marginBottom=0.3",
    "marginLeft=0.34",
    "marginRight=0.34",
    "pdfFormat="
  ],
  MST_PDF_OPTION: [
    "paperWidth=8.27",
    "paperHeight=11.69",
    "marginTop=0.3",
    "marginBottom=0",
    "marginLeft=0.2",
    "marginRight=0.2",
    "pdfFormat="
  ],
  MST_EMAIL_PDF_OPTION: [
    "paperWidth=8.27",
    "paperHeight=11.69",
    "marginTop=0.35",
    "marginBottom=0.3",
    "marginLeft=0.2",
    "marginRight=0.2",
    "pdfFormat="
  ],
  keyFinancialMetricsMultiple: [
    {
      key: 128,
      type: "header",
      cells: [
        {
          value: "Key financial metrics",
          cellClass: "key-financial-metrics-title"
        },
        {
          value: "Recommend",
          cellClass: "key-financial-metrics-Recommend"
        },
        {
          value: "Price",
          cellClass: "key-financial-metrics-price"
        },
        {
          value: "Target price",
          cellClass: "key-financial-metrics-target-price"
        },
        {
          value: "Projected return",
          cellClass: "key-financial-metrics-projected-return"
        },
        {
          value: "Price to earnings",
          cellClass: "key-financial-metrics-price-to-earnings"
        },
        // {
        //     value: "EPS growth"
        // },
        {
          value: "Dividend yield",
          cellClass: "key-financial-metrics-dividend-yield"
        }
        // {
        //     value: "DPS declared"
        // }
      ]
    }
  ],
  maFinancialKeyFinancialMetricsMultiple: [
    {
      key: 128,
      type: "header",
      cells: [
        {
          value: "Key metrics",
          cellClass: "key-financial-metrics-title"
        },
        {
          value: "Rating",
          cellClass: "key-financial-metrics-Recommend"
        },
        {
          value: "Price",
          cellClass: "key-financial-metrics-price"
        },
        {
          value: "Target price",
          cellClass: "key-financial-metrics-target-price"
        },
        {
          value: "12mth return",
          cellClass: "key-financial-metrics-projected-return"
        }
      ]
    }
  ],
  jardenKeyFinancialMetricsMultiple: [
    {
      key: 140,
      type: "header",
      cells: [
        {
          value: "Key financial metrics",
          cellClass: "key-financial-metrics-title"
        },
        {
          value: "Rating",
          cellClass: "key-financial-metrics-rating"
        },
        {
          value: "Price",
          cellClass: "key-financial-metrics-price"
        },
        {
          value: "Target price",
          cellClass: "key-financial-metrics-target-price"
        },
        {
          value: "Projected return",
          cellClass: "key-financial-metrics-projected-return"
        },
        {
          value: "Price to earnings",
          cellClass: "key-financial-metrics-price-to-earnings"
        },
        // {
        //     value: "EPS growth"
        // },
        {
          value: "Dividend yield",
          cellClass: "key-financial-metrics-dividend-yield"
        }
        // {
        //     value: "DPS declared"
        // }
      ]
    }
  ],
  keyFinancialMetrics: [
    {
      key: 1, // used for v-for loop
      type: "header",
      cells: [
        {
          value: "Key financial metrics",
          cellClass: "key-financial-metrics-title"
        },
        {
          value: "unit",
          cellClass: "key-financial-metrics-unit"
        },
        {
          value: "t-1",
          cellClass: "key-financial-metrics-t-1-title"
        },
        {
          value: "t+1",
          cellClass: "key-financial-metrics-t1-title"
        },
        {
          value: "t+2",
          cellClass: "key-financial-metrics-t2-title"
        },
        {
          value: "t+3",
          cellClass: "key-financial-metrics-t3-title"
        }
      ]
    },
    {
      key: 2, // used for v-for loop
      cells: [
        {
          value: "Operating revenues generated",
          cellClass: "operating-revenues-generated-title"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "unit",
          cellClass: "operating-revenues-generated-unit"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t-1",
          cellClass: "operating-revenues-generated-t-1"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t+1",
          cellClass: "operating-revenues-generated-t1"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t+2",
          cellClass: "operating-revenues-generated-t2"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t+3",
          cellClass: "operating-revenues-generated-t3"
        }
      ]
    },
    {
      key: 3, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Operating EBITDA generated",
          cellClass: "operating-ebitda-generated-title"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "unit",
          cellClass: "operating-ebitda-generated-unit"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t-1",
          cellClass: "operating-ebitda-generated-t-1"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t+1",
          cellClass: "operating-ebitda-generated-t1"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t+2",
          cellClass: "operating-ebitda-generated-t2"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t+3",
          cellClass: "operating-ebitda-generated-t3"
        }
      ]
    },
    {
      key: 4, // used for v-for loop
      cells: [
        {
          value: "Adjusted net profit generated after tax",
          cellClass: "adjusted-net-profit-generated-after-tax-title"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "unit",
          cellClass: "adjusted-net-profit-generated-after-tax-unit"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t-1",
          cellClass: "adjusted-net-profit-generated-after-tax-t-1"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t+1",
          cellClass: "adjusted-net-profit-generated-after-tax-t1"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t+2",
          cellClass: "adjusted-net-profit-generated-after-tax-t2"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t+3",
          cellClass: "adjusted-net-profit-generated-after-tax-t3"
        }
      ]
    },
    {
      key: 5, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "EPS normalised",
          cellClass: "eps-normalised-title"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "unit",
          cellClass: "eps-normalised-unit"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t-1",
          cellClass: "eps-normalised-t-1"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+1",
          cellClass: "eps-normalised-t1"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+2",
          cellClass: "eps-normalised-t2"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+3",
          cellClass: "eps-normalised-t3"
        }
      ]
    },
    {
      key: 6, // used for v-for loop
      cells: [
        {
          value: "EPS growth",
          cellClass: "eps-growth-title"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "unit",
          cellClass: "eps-growth-unit"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "t-1",
          cellClass: "eps-growth-t-1"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "t+1",
          cellClass: "eps-growth-t1"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "t+2",
          cellClass: "eps-growth-t2"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "t+3",
          cellClass: "eps-growth-t3"
        }
      ]
    },
    {
      key: 7, // used for v-for loop
      cells: [
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-1"
        },
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-2"
        },
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-3"
        },
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-4"
        },
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-5"
        },
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-6"
        }
      ]
    },
    {
      key: 8, // used for v-for loop
      cells: [
        {
          value: "Enterprise value to EBITDA",
          cellClass: "enterprise-value-to-ebitda-title"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "unit",
          cellClass: "enterprise-value-to-ebitda-unit"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t-1",
          cellClass: "enterprise-value-to-ebitda-t-1"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t+1",
          cellClass: "enterprise-value-to-ebitda-t1"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t+2",
          cellClass: "enterprise-value-to-ebitda-t2"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t+3",
          cellClass: "enterprise-value-to-ebitda-t3"
        }
      ]
    },
    {
      key: 9, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Price to earnings",
          cellClass: "price-to-earnings-title"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "unit",
          cellClass: "price-to-earnings-unit"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t-1",
          cellClass: "price-to-earnings-t-1"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t+1",
          cellClass: "price-to-earnings-t1"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t+2",
          cellClass: "price-to-earnings-t2"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t+3",
          cellClass: "price-to-earnings-t3"
        }
      ]
    },
    {
      key: 10, // used for v-for loop
      cells: [
        {
          value: "Price to free cashflow",
          cellClass: "price-to-free-cashflow-title"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "unit",
          cellClass: "price-to-free-cashflow-unit"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t-1",
          cellClass: "price-to-free-cashflow-t-1"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t+1",
          cellClass: "price-to-free-cashflow-t1"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t+2",
          cellClass: "price-to-free-cashflow-t2"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t+3",
          cellClass: "price-to-free-cashflow-t3"
        }
      ]
    },
    {
      key: 11, // used for v-for loop
      cells: [
        {
          value: "Pre-tax dividend yield",
          cellClass: "pre-tax-dividend-yield-title"
        },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "unit",
          cellClass: "pre-tax-dividend-yield-unit"
        },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "t-1",
          cellClass: "pre-tax-dividend-yield-t-1"
        },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "t+1",
          cellClass: "pre-tax-dividend-yield-t1"
        },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "t+2",
          cellClass: "pre-tax-dividend-yield-t2"
        },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "t+3",
          cellClass: "pre-tax-dividend-yield-t3"
        }
      ]
    },
    {
      key: 12, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Post-tax dividend yield",
          cellClass: "post-tax-dividend-yield-title"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "unit",
          cellClass: "post-tax-dividend-yield-unit"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t-1",
          cellClass: "post-tax-dividend-yield-t-1"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t+1",
          cellClass: "post-tax-dividend-yield-t1"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t+2",
          cellClass: "post-tax-dividend-yield-t2"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t+3",
          cellClass: "post-tax-dividend-yield-t3"
        }
      ]
    },
    {
      key: 13, // used for v-for loop
      type: "footer",
      cells: [
        {
          colSpan: 6,
          value: "Source: Company data, {{EntityName}}"
        }
      ]
    }
  ],
  keyFinancialMetrics_MAFinancial_main: [
    {
      key: 1, // used for v-for loop
      type: "header",
      cells: [
        {
          value: "Key metrics",
          cellClass: "key-financial-metrics-title"
        },
        // {
        //   value: "unit",
        //   cellClass: "key-financial-metrics-unit"
        // },
        {
          value: "t-1",
          cellClass: "key-financial-metrics-t-1-title"
        },
        {
          value: "t+1",
          cellClass: "key-financial-metrics-t1-title"
        },
        {
          value: "t+2",
          cellClass: "key-financial-metrics-t2-title"
        },
        {
          value: "t+3",
          cellClass: "key-financial-metrics-t3-title"
        }
      ]
    },
    {
      key: 2, // used for v-for loop
      cells: [
        {
          value: "Operating revenues generated",
          cellClass: "operating-revenues-generated-title"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t-1",
          cellClass: "operating-revenues-generated-t-1"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t+1",
          cellClass: "operating-revenues-generated-t1"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t+2",
          cellClass: "operating-revenues-generated-t2"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t+3",
          cellClass: "operating-revenues-generated-t3"
        }
      ]
    },
    {
      key: 3, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Operating EBITDA generated",
          cellClass: "operating-ebitda-generated-title"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t-1",
          cellClass: "operating-ebitda-generated-t-1"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t+1",
          cellClass: "operating-ebitda-generated-t1"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t+2",
          cellClass: "operating-ebitda-generated-t2"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t+3",
          cellClass: "operating-ebitda-generated-t3"
        }
      ]
    },
    {
      key: 4, // used for v-for loop
      cells: [
        {
          value: "Adjusted net profit generated after tax",
          cellClass: "adjusted-net-profit-generated-after-tax-title"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t-1",
          cellClass: "adjusted-net-profit-generated-after-tax-t-1"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t+1",
          cellClass: "adjusted-net-profit-generated-after-tax-t1"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t+2",
          cellClass: "adjusted-net-profit-generated-after-tax-t2"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t+3",
          cellClass: "adjusted-net-profit-generated-after-tax-t3"
        }
      ]
    },
    {
      key: 5, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "EPS normalised",
          cellClass: "eps-normalised-title"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t-1",
          cellClass: "eps-normalised-t-1"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+1",
          cellClass: "eps-normalised-t1"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+2",
          cellClass: "eps-normalised-t2"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+3",
          cellClass: "eps-normalised-t3"
        }
      ]
    },
    {
      key: 6, // used for v-for loop
      cells: [
        {
          value: "EPS growth",
          cellClass: "eps-growth-title"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "t-1",
          cellClass: "eps-growth-t-1"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "t+1",
          cellClass: "eps-growth-t1"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "t+2",
          cellClass: "eps-growth-t2"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "t+3",
          cellClass: "eps-growth-t3"
        }
      ]
    },
    {
      key: 7, // used for v-for loop
      cells: [
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-1"
        },
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-2"
        },
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-3"
        },
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-4"
        },
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-5"
        }
      ]
    },
    {
      key: 8, // used for v-for loop
      cells: [
        {
          value: "Enterprise value to EBITDA",
          cellClass: "enterprise-value-to-ebitda-title"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t-1",
          cellClass: "enterprise-value-to-ebitda-t-1"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t+1",
          cellClass: "enterprise-value-to-ebitda-t1"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t+2",
          cellClass: "enterprise-value-to-ebitda-t2"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t+3",
          cellClass: "enterprise-value-to-ebitda-t3"
        }
      ]
    },
    {
      key: 9, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Price to earnings",
          cellClass: "price-to-earnings-title"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t-1",
          cellClass: "price-to-earnings-t-1"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t+1",
          cellClass: "price-to-earnings-t1"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t+2",
          cellClass: "price-to-earnings-t2"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t+3",
          cellClass: "price-to-earnings-t3"
        }
      ]
    },
    {
      key: 10, // used for v-for loop
      cells: [
        {
          value: "Price to free cashflow",
          cellClass: "price-to-free-cashflow-title"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t-1",
          cellClass: "price-to-free-cashflow-t-1"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t+1",
          cellClass: "price-to-free-cashflow-t1"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t+2",
          cellClass: "price-to-free-cashflow-t2"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t+3",
          cellClass: "price-to-free-cashflow-t3"
        }
      ]
    },
    {
      key: 11, // used for v-for loop
      cells: [
        {
          value: "Pre-tax dividend yield",
          cellClass: "pre-tax-dividend-yield-title"
        },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "t-1",
          cellClass: "pre-tax-dividend-yield-t-1"
        },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "t+1",
          cellClass: "pre-tax-dividend-yield-t1"
        },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "t+2",
          cellClass: "pre-tax-dividend-yield-t2"
        },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "t+3",
          cellClass: "pre-tax-dividend-yield-t3"
        }
      ]
    },
    {
      key: 12, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Post-tax dividend yield",
          cellClass: "post-tax-dividend-yield-title"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t-1",
          cellClass: "post-tax-dividend-yield-t-1"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t+1",
          cellClass: "post-tax-dividend-yield-t1"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t+2",
          cellClass: "post-tax-dividend-yield-t2"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t+3",
          cellClass: "post-tax-dividend-yield-t3"
        }
      ]
    }
    // {
    //   key: 13, // used for v-for loop
    //   type: "footer",
    //   cells: [
    //     {
    //       colSpan: 6,
    //       value: "-"
    //     }
    //   ]
    // }
  ],
  keyFinancialMetricsGTN: [
    {
      key: 1, // used for v-for loop
      type: "header",
      cells: [
        {
          value: "Key Financial Highlights",
          cellClass: "key-financial-metrics-title-gtn"
        }
      ]
    },
    {
      key: 1, // used for v-for loop
      type: "header",
      cells: [
        {
          value: "Key financial metrics",
          cellClass: "key-financial-metrics-title"
        },
        {
          value: "unit",
          cellClass: "key-financial-metrics-unit"
        },
        {
          value: "t-1",
          cellClass: "key-financial-metrics-t-1-title"
        },
        {
          value: "t+1",
          cellClass: "key-financial-metrics-t1-title"
        },
        {
          value: "t+2",
          cellClass: "key-financial-metrics-t2-title"
        },
        {
          value: "t+3",
          cellClass: "key-financial-metrics-t3-title"
        }
      ]
    },
    {
      key: 2, // used for v-for loop
      cells: [
        {
          value: "Operating revenues generated",
          cellClass: "operating-revenues-generated-title"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "unit",
          cellClass: "operating-revenues-generated-unit"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t-1",
          cellClass: "operating-revenues-generated-t-1"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t+1",
          cellClass: "operating-revenues-generated-t1"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t+2",
          cellClass: "operating-revenues-generated-t2"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t+3",
          cellClass: "operating-revenues-generated-t3"
        }
      ]
    },
    {
      key: 3, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Operating EBITDA generated",
          cellClass: "operating-ebitda-generated-title"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "unit",
          cellClass: "operating-ebitda-generated-unit"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t-1",
          cellClass: "operating-ebitda-generated-t-1"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t+1",
          cellClass: "operating-ebitda-generated-t1"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t+2",
          cellClass: "operating-ebitda-generated-t2"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t+3",
          cellClass: "operating-ebitda-generated-t3"
        }
      ]
    },
    {
      key: 4, // used for v-for loop
      cells: [
        {
          value: "Adjusted net profit generated after tax",
          cellClass: "adjusted-net-profit-generated-after-tax-title"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "unit",
          cellClass: "adjusted-net-profit-generated-after-tax-unit"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t-1",
          cellClass: "adjusted-net-profit-generated-after-tax-t-1"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t+1",
          cellClass: "adjusted-net-profit-generated-after-tax-t1"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t+2",
          cellClass: "adjusted-net-profit-generated-after-tax-t2"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t+3",
          cellClass: "adjusted-net-profit-generated-after-tax-t3"
        }
      ]
    },
    {
      key: 5, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "EPS normalised",
          cellClass: "eps-normalised-title"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "unit",
          cellClass: "eps-normalised-unit"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t-1",
          cellClass: "eps-normalised-t-1"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+1",
          cellClass: "eps-normalised-t1"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+2",
          cellClass: "eps-normalised-t2"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+3",
          cellClass: "eps-normalised-t3"
        }
      ]
    },
    {
      key: 6, // used for v-for loop
      cells: [
        {
          value: "EPS growth",
          cellClass: "eps-growth-title"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "unit",
          cellClass: "eps-growth-unit"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "t-1",
          cellClass: "eps-growth-t-1"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "t+1",
          cellClass: "eps-growth-t1"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "t+2",
          cellClass: "eps-growth-t2"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "t+3",
          cellClass: "eps-growth-t3"
        }
      ]
    },
    {
      key: 7, // used for v-for loop
      cells: [
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-1"
        },
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-2"
        },
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-3"
        },
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-4"
        },
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-5"
        },
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-6"
        }
      ]
    },
    {
      key: 8, // used for v-for loop
      cells: [
        {
          value: "Enterprise value to EBITDA",
          cellClass: "enterprise-value-to-ebitda-title"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "unit",
          cellClass: "enterprise-value-to-ebitda-unit"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t-1",
          cellClass: "enterprise-value-to-ebitda-t-1"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t+1",
          cellClass: "enterprise-value-to-ebitda-t1"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t+2",
          cellClass: "enterprise-value-to-ebitda-t2"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t+3",
          cellClass: "enterprise-value-to-ebitda-t3"
        }
      ]
    },
    {
      key: 9, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Price to earnings",
          cellClass: "price-to-earnings-title"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "unit",
          cellClass: "price-to-earnings-unit"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t-1",
          cellClass: "price-to-earnings-t-1"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t+1",
          cellClass: "price-to-earnings-t1"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t+2",
          cellClass: "price-to-earnings-t2"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t+3",
          cellClass: "price-to-earnings-t3"
        }
      ]
    },
    {
      key: 10, // used for v-for loop
      cells: [
        {
          value: "Price to free cashflow",
          cellClass: "price-to-free-cashflow-title"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "unit",
          cellClass: "price-to-free-cashflow-unit"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t-1",
          cellClass: "price-to-free-cashflow-t-1"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t+1",
          cellClass: "price-to-free-cashflow-t1"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t+2",
          cellClass: "price-to-free-cashflow-t2"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t+3",
          cellClass: "price-to-free-cashflow-t3"
        }
      ]
    },
    {
      key: 11, // used for v-for loop
      cells: [
        {
          value: "Pre-tax dividend yield",
          cellClass: "pre-tax-dividend-yield-title"
        },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "unit",
          cellClass: "pre-tax-dividend-yield-unit"
        },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "t-1",
          cellClass: "pre-tax-dividend-yield-t-1"
        },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "t+1",
          cellClass: "pre-tax-dividend-yield-t1"
        },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "t+2",
          cellClass: "pre-tax-dividend-yield-t2"
        },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "t+3",
          cellClass: "pre-tax-dividend-yield-t3"
        }
      ]
    },
    {
      key: 12, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Post-tax dividend yield",
          cellClass: "post-tax-dividend-yield-title"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "unit",
          cellClass: "post-tax-dividend-yield-unit"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t-1",
          cellClass: "post-tax-dividend-yield-t-1"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t+1",
          cellClass: "post-tax-dividend-yield-t1"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t+2",
          cellClass: "post-tax-dividend-yield-t2"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t+3",
          cellClass: "post-tax-dividend-yield-t3"
        }
      ]
    },
    {
      key: 13, // used for v-for loop
      type: "footer",
      cells: [
        {
          colSpan: 6,
          value: "Source: Company data, {{EntityName}}"
        }
      ]
    }
  ],
  jardenKeyFinancialMetrics: [
    {
      key: 1, // used for v-for loop
      type: "header",
      cells: [
        {
          value: "Key financial metrics",
          cellClass: "key-financial-metrics-title"
        },
        // {
        //     value: "unit"
        // },
        {
          value: "t-1",
          cellClass: "key-financial-metrics-t-1-title"
        },
        {
          value: "t+1",
          cellClass: "key-financial-metrics-t1-title"
        },
        {
          value: "t+2",
          cellClass: "key-financial-metrics-t2-title"
        },
        {
          value: "t+3",
          cellClass: "key-financial-metrics-t3-title"
        }
      ]
    },
    {
      key: 2, // used for v-for loop
      cells: [
        {
          value: "Operating revenues",
          matchingName: "operating_revenues_generated",
          matchingColumn: "unit",
          cellClass: "operating-revenues-generated-unit"
        },
        // {
        //     matchingName: "operating_revenues_generated",
        //     matchingColumn: "unit",
        // },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t-1",
          cellClass: "operating-revenues-generated-t-1"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t+1",
          cellClass: "operating-revenues-generated-t1"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t+2",
          cellClass: "operating-revenues-generated-t2"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t+3",
          cellClass: "operating-revenues-generated-t3"
        }
      ]
    },
    {
      key: 3, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Operating EBITDA",
          matchingName: "operating_ebitda_generated",
          matchingColumn: "unit",
          cellClass: "operating-ebitda-generated-unit"
        },
        // {
        //     matchingName: "operating_ebitda_generated",
        //     matchingColumn: "unit",
        // },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t-1",
          cellClass: "operating-ebitda-generated-t-1"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t+1",
          cellClass: "operating-ebitda-generated-t1"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t+2",
          cellClass: "operating-ebitda-generated-t2"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t+3",
          cellClass: "operating-ebitda-generated-t3"
        }
      ]
    },
    {
      key: 4, // used for v-for loop
      cells: [
        {
          value: "Adjusted NPAT",
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "unit",
          cellClass: "adjusted-net-profit-generated-after-tax-unit"
        },
        // {
        //     matchingName: "adjusted_net_profit_generated_after_tax",
        //     matchingColumn: "unit",
        // },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t-1",
          cellClass: "adjusted-net-profit-generated-after-tax-t-1"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t+1",
          cellClass: "adjusted-net-profit-generated-after-tax-t1"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t+2",
          cellClass: "adjusted-net-profit-generated-after-tax-t2"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t+3",
          cellClass: "adjusted-net-profit-generated-after-tax-t3"
        }
      ]
    },
    {
      key: 5, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "EPS normalised",
          matchingName: "eps_normalised",
          matchingColumn: "unit",
          cellClass: "eps-normalised-unit"
        },
        // {
        //     matchingName: "eps_normalised",
        //     matchingColumn: "unit",
        // },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t-1",
          cellClass: "eps-normalised-t-1"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+1",
          cellClass: "eps-normalised-t1"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+2",
          cellClass: "eps-normalised-t2"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+3",
          cellClass: "eps-normalised-t3"
        }
      ]
    },
    {
      key: 6, // used for v-for loop
      cells: [
        {
          value: "EPS growth",
          matchingName: "eps_growth",
          matchingColumn: "unit",
          cellClass: "eps-growth-unit"
        },
        // {
        //     matchingName: "eps_growth",
        //     matchingColumn: "unit",
        // },
        {
          matchingName: "eps_growth",
          matchingColumn: "t-1",
          cellClass: "eps-growth-t-1"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "t+1",
          cellClass: "eps-growth-t1"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "t+2",
          cellClass: "eps-growth-t2"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "t+3",
          cellClass: "eps-growth-t3"
        }
      ]
    },
    {
      key: 7, // used for v-for loop
      cells: [
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-1"
        },
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-2"
        },
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-3"
        },
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-4"
        },
        {
          value: "",
          cellClass: "key-financial-metrics-empty-cell-5"
        }
      ]
    },
    {
      key: 8, // used for v-for loop
      cells: [
        {
          value: "EV/EBITDA",
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "unit",
          cellClass: "enterprise-value-to-ebitda-unit"
        },
        // {
        //     matchingName: "enterprise_value_to_ebitda",
        //     matchingColumn: "unit",
        // },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t-1",
          cellClass: "enterprise-value-to-ebitda-t-1"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t+1",
          cellClass: "enterprise-value-to-ebitda-t1"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t+2",
          cellClass: "enterprise-value-to-ebitda-t2"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t+3",
          cellClass: "enterprise-value-to-ebitda-t3"
        }
      ]
    },
    {
      key: 9, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "P/E",
          matchingName: "price_to_earnings",
          matchingColumn: "unit",
          cellClass: "price-to-earnings-unit"
        },
        // {
        //     matchingName: "price_to_earnings",
        //     matchingColumn: "unit",
        // },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t-1",
          cellClass: "price-to-earnings-t-1"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t+1",
          cellClass: "price-to-earnings-t1"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t+2",
          cellClass: "price-to-earnings-t2"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t+3",
          cellClass: "price-to-earnings-t3"
        }
      ]
    },
    {
      key: 10, // used for v-for loop
      cells: [
        {
          value: "P/FCF",
          matchingName: "price_to_free_cashflow",
          matchingColumn: "unit",
          cellClass: "price-to-free-cashflow-unit"
        },
        // {
        //     matchingName: "price_to_free_cashflow",
        //     matchingColumn: "unit",
        // },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t-1",
          cellClass: "price-to-free-cashflow-t-1"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t+1",
          cellClass: "price-to-free-cashflow-t1"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t+2",
          cellClass: "price-to-free-cashflow-t2"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t+3",
          cellClass: "price-to-free-cashflow-t3"
        }
      ]
    },
    {
      key: 11, // used for v-for loop
      cells: [
        {
          value: "Gross yield",
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "unit",
          cellClass: "pre-tax-dividend-yield-unit"
        },
        // {
        //     matchingName: "tax_dividend_yield",
        //     matchingColumn: "unit",
        // },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "t-1",
          cellClass: "pre-tax-dividend-yield-t-1"
        },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "t+1",
          cellClass: "pre-tax-dividend-yield-t1"
        },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "t+2",
          cellClass: "pre-tax-dividend-yield-t2"
        },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "t+3",
          cellClass: "pre-tax-dividend-yield-t3"
        }
      ]
    },
    {
      key: 12, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Net yield",
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "unit",
          cellClass: "post-tax-dividend-yield-unit"
        },
        // {
        //     matchingName: "tax_dividend_yield",
        //     matchingColumn: "unit",
        // },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t-1",
          cellClass: "post-tax-dividend-yield-t-1"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t+1",
          cellClass: "post-tax-dividend-yield-t1"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t+2",
          cellClass: "post-tax-dividend-yield-t2"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t+3",
          cellClass: "post-tax-dividend-yield-t3"
        }
      ]
    },
    {
      key: 13, // used for v-for loop
      type: "footer",
      cells: [
        {
          colSpan: 5,
          value: "Source: Company data, {{EntityName}}"
        }
      ]
    }
  ],
  investmentPerformance: [
    {
      key: 14, // used for v-for loop
      type: "header",
      cells: [
        {
          value: "Performance",
          cellClass: "investment-performance-title"
        }
      ]
    },
    {
      key: 15, // used for v-for loop
      cells: [
        {
          value: "Drag-n-drop or copy-paste an image (JPEG or PNG format)",
          cellClass: "drag-n-drop performance-drag-n-drop"
        }
      ]
    },
    {
      key: 16, // used for v-for loop
      type: "footer",
      cells: [
        {
          value: "Source: {{EntityName}}",
          cellClass: "performance-source"
        }
      ]
    }
  ],
  investmentPerformanceForMA: [
    {
      key: 14, // used for v-for loop
      type: "header",
      cells: [
        {
          value: "SHARE PRICE PERFORMANCE",
          cellClass: "investment-performance-title"
        }
      ]
    },
    {
      key: 15, // used for v-for loop
      cells: [
        {
          value: "Drag-n-drop or copy-paste an image (JPEG or PNG format)",
          cellClass: "drag-n-drop performance-drag-n-drop"
        }
      ]
    },
    {
      key: 16, // used for v-for loop
      type: "footer",
      cells: [
        {
          extraValue: "Note: All figures are in AUD unless otherwise specified",
          value: "Source: Company data, FactSet, MA Moelis Australia",
          cellClass: "performance-source"
        }
      ]
    }
  ],
  incomeStatement: [
    {
      key: 17, // used for v-for loop
      type: "header",
      cells: [
        {
          value: "Income statement",
          cellClass: "income-statement-title"
        },
        {
          value: "unit",
          cellClass: "income-statement-unit"
        },
        {
          value: "t-1",
          cellClass: "income-statement-t-1"
        },
        {
          value: "t+1",
          cellClass: "income-statement-t1"
        },
        {
          value: "t+2",
          cellClass: "income-statement-t2"
        }
      ]
    },
    {
      key: 18, // used for v-for loop
      cells: [
        {
          value: "Operating revenues generated",
          cellClass: "operating-revenues-generated-title"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "unit",
          cellClass: "operating-revenues-generated-unit"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t-1",
          cellClass: "operating-revenues-generated-t-1"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t+1",
          cellClass: "operating-revenues-generated-t1"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t+2",
          cellClass: "operating-revenues-generated-t2"
        }
      ]
    },
    {
      key: 19, // used for v-for loop
      cells: [
        {
          value: "Operating expenses incurred",
          cellClass: "operating-expenses-incurred-title"
        },
        {
          matchingName: "operating_expenses_incurred",
          matchingColumn: "unit",
          cellClass: "operating-expenses-incurred-unit"
        },
        {
          matchingName: "operating_expenses_incurred",
          matchingColumn: "t-1",
          cellClass: "operating-expenses-incurred-t-1"
        },
        {
          matchingName: "operating_expenses_incurred",
          matchingColumn: "t+1",
          cellClass: "operating-expenses-incurred-t1"
        },
        {
          matchingName: "operating_expenses_incurred",
          matchingColumn: "t+2",
          cellClass: "operating-expenses-incurred-t2"
        }
      ]
    },
    {
      key: 20, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Operating EBITDA generated",
          cellClass: "operating-ebitda-generated-title"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "unit",
          cellClass: "operating-ebitda-generated-unit"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t-1",
          cellClass: "operating-ebitda-generated-t-1"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t+1",
          cellClass: "operating-ebitda-generated-t1"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t+2",
          cellClass: "operating-ebitda-generated-t2"
        }
      ]
    },
    {
      key: 21, // used for v-for loop
      cells: [
        {
          value: "Depreciation and amortisation incurred",
          cellClass: "depreciation-and-amortisation-incurred-title"
        },
        {
          matchingName: "depreciation_and_amortisation_incurred",
          matchingColumn: "unit",
          cellClass: "depreciation-and-amortisation-incurred-unit"
        },
        {
          matchingName: "depreciation_and_amortisation_incurred",
          matchingColumn: "t-1",
          cellClass: "depreciation-and-amortisation-incurred-t-1"
        },
        {
          matchingName: "depreciation_and_amortisation_incurred",
          matchingColumn: "t+1",
          cellClass: "depreciation-and-amortisation-incurred-t1"
        },
        {
          matchingName: "depreciation_and_amortisation_incurred",
          matchingColumn: "t+2",
          cellClass: "depreciation-and-amortisation-incurred-t2"
        }
      ]
    },
    {
      key: 22, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Operating EBIT generated",
          cellClass: "operating-ebit-generated-title"
        },
        {
          matchingName: "operating_ebit_generated",
          matchingColumn: "unit",
          cellClass: "operating-ebit-generated-unit"
        },
        {
          matchingName: "operating_ebit_generated",
          matchingColumn: "t-1",
          cellClass: "operating-ebit-generated-t-1"
        },
        {
          matchingName: "operating_ebit_generated",
          matchingColumn: "t+1",
          cellClass: "operating-ebit-generated-t1"
        },
        {
          matchingName: "operating_ebit_generated",
          matchingColumn: "t+2",
          cellClass: "operating-ebit-generated-t2"
        }
      ]
    },
    {
      key: 23, // used for v-for loop
      cells: [
        {
          value: "Debt interest incurred",
          cellClass: "debt-interest-incurred-title"
        },
        {
          matchingName: "debt_interest_incurred",
          matchingColumn: "unit",
          cellClass: "debt-interest-incurred-unit"
        },
        {
          matchingName: "debt_interest_incurred",
          matchingColumn: "t-1",
          cellClass: "debt-interest-incurred-t-1"
        },
        {
          matchingName: "debt_interest_incurred",
          matchingColumn: "t+1",
          cellClass: "debt-interest-incurred-t1"
        },
        {
          matchingName: "debt_interest_incurred",
          matchingColumn: "t+2",
          cellClass: "debt-interest-incurred-t2"
        }
      ]
    },
    {
      key: 24, // used for v-for loop
      cells: [
        {
          value: "Cash interest generated",
          cellClass: "cash-interest-generated-title"
        },
        {
          matchingName: "cash_interest_generated",
          matchingColumn: "unit",
          cellClass: "cash-interest-generated-unit"
        },
        {
          matchingName: "cash_interest_generated",
          matchingColumn: "t-1",
          cellClass: "cash-interest-generated-t-1"
        },
        {
          matchingName: "cash_interest_generated",
          matchingColumn: "t+1",
          cellClass: "cash-interest-generated-t1"
        },
        {
          matchingName: "cash_interest_generated",
          matchingColumn: "t+2",
          cellClass: "cash-interest-generated-t2"
        }
      ]
    },
    {
      key: 25, // used for v-for loop
      cells: [
        {
          value: "Investment income generated",
          cellClass: "investment-income-generated-title"
        },
        {
          matchingName: "investment_income_generated",
          matchingColumn: "unit",
          cellClass: "investment-income-generated-unit"
        },
        {
          matchingName: "investment_income_generated",
          matchingColumn: "t-1",
          cellClass: "investment-income-generated-t-1"
        },
        {
          matchingName: "investment_income_generated",
          matchingColumn: "t+1",
          cellClass: "investment-income-generated-t1"
        },
        {
          matchingName: "investment_income_generated",
          matchingColumn: "t+2",
          cellClass: "investment-income-generated-t2"
        }
      ]
    },
    {
      key: 26, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Net profit generated before tax",
          cellClass: "net-profit-generated-before-tax-title"
        },
        {
          matchingName: "net_profit_generated_before_tax",
          matchingColumn: "unit",
          cellClass: "net-profit-generated-before-tax-unit"
        },
        {
          matchingName: "net_profit_generated_before_tax",
          matchingColumn: "t-1",
          cellClass: "net-profit-generated-before-tax-t-1"
        },
        {
          matchingName: "net_profit_generated_before_tax",
          matchingColumn: "t+1",
          cellClass: "net-profit-generated-before-tax-t1"
        },
        {
          matchingName: "net_profit_generated_before_tax",
          matchingColumn: "t+2",
          cellClass: "net-profit-generated-before-tax-t2"
        }
      ]
    },
    {
      key: 27, // used for v-for loop
      cells: [
        {
          value: "Corporate tax incurred",
          cellClass: "corporate-tax-incurred-title"
        },
        {
          matchingName: "corporate_tax_incurred",
          matchingColumn: "unit",
          cellClass: "corporate-tax-incurred-unit"
        },
        {
          matchingName: "corporate_tax_incurred",
          matchingColumn: "t-1",
          cellClass: "corporate-tax-incurred-t-1"
        },
        {
          matchingName: "corporate_tax_incurred",
          matchingColumn: "t+1",
          cellClass: "corporate-tax-incurred-t1"
        },
        {
          matchingName: "corporate_tax_incurred",
          matchingColumn: "t+2",
          cellClass: "corporate-tax-incurred-t2"
        }
      ]
    },
    {
      key: 28, // used for v-for loop
      cells: [
        {
          value: "Minority interest incurred",
          cellClass: "minority-interest-incurred-title"
        },
        {
          matchingName: "minority_interest_incurred",
          matchingColumn: "unit",
          cellClass: "minority-interest-incurred-unit"
        },
        {
          matchingName: "minority_interest_incurred",
          matchingColumn: "t-1",
          cellClass: "minority-interest-incurred-t-1"
        },
        {
          matchingName: "minority_interest_incurred",
          matchingColumn: "t+1",
          cellClass: "minority-interest-incurred-t1"
        },
        {
          matchingName: "minority_interest_incurred",
          matchingColumn: "t+2",
          cellClass: "minority-interest-incurred-t2"
        }
      ]
    },
    {
      key: 29, // used for v-for loop
      cells: [
        {
          value: "Equity accounted earnings generated",
          cellClass: "equity-accounted-earnings-generated-title"
        },
        {
          matchingName: "equity_accounted_earnings_generated",
          matchingColumn: "unit",
          cellClass: "equity-accounted-earnings-generated-unit"
        },
        {
          matchingName: "equity_accounted_earnings_generated",
          matchingColumn: "t-1",
          cellClass: "equity-accounted-earnings-generated-t-1"
        },
        {
          matchingName: "equity_accounted_earnings_generated",
          matchingColumn: "t+1",
          cellClass: "equity-accounted-earnings-generated-t1"
        },
        {
          matchingName: "equity_accounted_earnings_generated",
          matchingColumn: "t+2",
          cellClass: "equity-accounted-earnings-generated-t2"
        }
      ]
    },
    {
      key: 30, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Adjusted net profit generated after tax",
          cellClass: "adjusted-net-profit-generated-after-tax-title"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "unit",
          cellClass: "adjusted-net-profit-generated-after-tax-unit"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t-1",
          cellClass: "adjusted-net-profit-generated-after-tax-t-1"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t+1",
          cellClass: "adjusted-net-profit-generated-after-tax-t1"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t+2",
          cellClass: "adjusted-net-profit-generated-after-tax-t2"
        }
      ]
    },
    {
      key: 31, // used for v-for loop
      cells: [
        {
          value: "Change in value of fixed and intangible assets",
          cellClass: "change-in-value-of-fixed-and-intangible-assets-title"
        },
        {
          matchingName: "change_in_value_of_fixed_and_intangible_assets",
          matchingColumn: "unit",
          cellClass: "change-in-value-of-fixed-and-intangible-assets-unit"
        },
        {
          matchingName: "change_in_value_of_fixed_and_intangible_assets",
          matchingColumn: "t-1",
          cellClass: "change-in-value-of-fixed-and-intangible-assets-t-1"
        },
        {
          matchingName: "change_in_value_of_fixed_and_intangible_assets",
          matchingColumn: "t+1",
          cellClass: "change-in-value-of-fixed-and-intangible-assets-t1"
        },
        {
          matchingName: "change_in_value_of_fixed_and_intangible_assets",
          matchingColumn: "t+2",
          cellClass: "change-in-value-of-fixed-and-intangible-assets-t2"
        }
      ]
    },
    {
      key: 32, // used for v-for loop
      cells: [
        {
          value: "Other revenues generated and expenses incurred",
          cellClass: "other-revenues-generated-and-expenses-incurred-title"
        },
        {
          matchingName: "other_revenues_generated_and_expenses_incurred",
          matchingColumn: "unit",
          cellClass: "other-revenues-generated-and-expenses-incurred-unit"
        },
        {
          matchingName: "other_revenues_generated_and_expenses_incurred",
          matchingColumn: "t-1",
          cellClass: "other-revenues-generated-and-expenses-incurred-t-1"
        },
        {
          matchingName: "other_revenues_generated_and_expenses_incurred",
          matchingColumn: "t+1",
          cellClass: "other-revenues-generated-and-expenses-incurred-t1"
        },
        {
          matchingName: "other_revenues_generated_and_expenses_incurred",
          matchingColumn: "t+2",
          cellClass: "other-revenues-generated-and-expenses-incurred-t2"
        }
      ]
    },
    {
      key: 33, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Reported net profit generated after tax",
          cellClass: "reported-net-profit-generated-after-tax-title"
        },
        {
          matchingName: "reported_net_profit_generated_after_tax",
          matchingColumn: "unit",
          cellClass: "reported-net-profit-generated-after-tax-unit"
        },
        {
          matchingName: "reported_net_profit_generated_after_tax",
          matchingColumn: "t-1",
          cellClass: "reported-net-profit-generated-after-tax-t-1"
        },
        {
          matchingName: "reported_net_profit_generated_after_tax",
          matchingColumn: "t+1",
          cellClass: "reported-net-profit-generated-after-tax-t1"
        },
        {
          matchingName: "reported_net_profit_generated_after_tax",
          matchingColumn: "t+2",
          cellClass: "reported-net-profit-generated-after-tax-t2"
        }
      ]
    },
    {
      key: 34, // used for v-for loop
      cells: [
        {
          value: "",
          cellClass: "income-statement-empty-cell-1"
        },
        {
          value: "",
          cellClass: "income-statement-empty-cell-2"
        },
        {
          value: "",
          cellClass: "income-statement-empty-cell-3"
        },
        {
          value: "",
          cellClass: "income-statement-empty-cell-4"
        },
        {
          value: "",
          cellClass: "income-statement-empty-cell-5"
        }
      ]
    },
    {
      key: 35, // used for v-for loop
      cells: [
        {
          value: "Revenue growth",
          cellClass: "revenue-growth-title"
        },
        {
          matchingName: "revenue_growth",
          matchingColumn: "unit",
          cellClass: "revenue-growth-unit"
        },
        {
          matchingName: "revenue_growth",
          matchingColumn: "t-1",
          cellClass: "revenue-growth-t-1"
        },
        {
          matchingName: "revenue_growth",
          matchingColumn: "t+1",
          cellClass: "revenue-growth-t1"
        },
        {
          matchingName: "revenue_growth",
          matchingColumn: "t+2",
          cellClass: "revenue-growth-t2"
        }
      ]
    },
    {
      key: 36, // used for v-for loop
      cells: [
        {
          value: "EBITDA margin",
          cellClass: "ebitda-margin-title"
        },
        {
          matchingName: "ebitda_margin",
          matchingColumn: "unit",
          cellClass: "ebitda-margin-unit"
        },
        {
          matchingName: "ebitda_margin",
          matchingColumn: "t-1",
          cellClass: "ebitda-margin-t-1"
        },
        {
          matchingName: "ebitda_margin",
          matchingColumn: "t+1",
          cellClass: "ebitda-margin-t1"
        },
        {
          matchingName: "ebitda_margin",
          matchingColumn: "t+2",
          cellClass: "ebitda-margin-t2"
        }
      ]
    },
    {
      key: 37, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "EPS normalised",
          cellClass: "eps-normalised-title"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "unit",
          cellClass: "eps-normalised-unit"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t-1",
          cellClass: "eps-normalised-t-1"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+1",
          cellClass: "eps-normalised-t1"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+2",
          cellClass: "eps-normalised-t2"
        }
      ]
    },
    {
      key: 38, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "EPS growth",
          cellClass: "eps-growth-title"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "unit",
          cellClass: "eps-growth-unit"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "t-1",
          cellClass: "eps-growth-t-1"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "t+1",
          cellClass: "eps-growth-t1"
        },
        {
          matchingName: "eps_growth",
          matchingColumn: "t+2",
          cellClass: "eps-growth-t2"
        }
      ]
    }
  ],
  pricingMetrics: [
    {
      key: 39, // used for v-for loop
      type: "header",
      cells: [
        {
          value: "Pricing metrics",
          cellClass: "pricing-metrics-title"
        },
        {
          value: "unit",
          cellClass: "pricing-metrics-unit"
        },
        {
          value: "",
          cellClass: "pricing-metrics-value"
        }
      ]
    },
    {
      key: 40, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Market capitalisation",
          cellClass: "market-capitalisation-title"
        },
        {
          matchingName: "market_capitalisation",
          matchingColumn: "unit",
          cellClass: "market-capitalisation-unit"
        },
        {
          matchingName: "market_capitalisation",
          matchingColumn: "t",
          cellClass: "market-capitalisation-t"
        }
      ]
    },
    {
      key: 41, // used for v-for loop
      cells: [
        {
          value: "Enterprise value",
          cellClass: "enterprise-value-title"
        },
        {
          matchingName: "enterprise_value",
          matchingColumn: "unit",
          cellClass: "enterprise-value-unit"
        },
        {
          matchingName: "enterprise_value",
          matchingColumn: "t",
          cellClass: "enterprise-value-t"
        }
      ]
    },
    {
      key: 42, // used for v-for loop
      cells: [
        {
          value: "",
          cellClass: "pricing-metrics-empty-cell-1"
        },
        {
          value: "",
          cellClass: "pricing-metrics-empty-cell-2"
        },
        {
          value: "",
          cellClass: "pricing-metrics-empty-cell-3"
        }
      ]
    },
    {
      key: 43, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Price",
          cellClass: "price-title"
        },
        {
          matchingName: "price",
          matchingColumn: "unit",
          cellClass: "price-unit"
        },
        {
          matchingName: "price",
          matchingColumn: "t",
          cellClass: "price-t"
        }
      ]
    },
    {
      key: 44, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Target price",
          cellClass: "target-price-title"
        },
        {
          matchingName: "target_price",
          matchingColumn: "unit",
          cellClass: "target-price-unit"
        },
        {
          matchingName: "target_price",
          matchingColumn: "t",
          cellClass: "target-price-t"
        }
      ]
    },
    {
      key: 45, // used for v-for loop
      cells: [
        {
          value: "",
          cellClass: "pricing-metrics-empty-cell-4"
        },
        {
          value: "",
          cellClass: "pricing-metrics-empty-cell-5"
        },
        {
          value: "",
          cellClass: "pricing-metrics-empty-cell-6"
        }
      ]
    },
    {
      key: 46, // used for v-for loop
      cells: [
        {
          value: "Capital return",
          cellClass: "capital-return-title"
        },
        {
          matchingName: "capital_return",
          matchingColumn: "unit",
          cellClass: "capital-return-unit"
        },
        {
          matchingName: "capital_return",
          matchingColumn: "t",
          cellClass: "capital-return-t"
        }
      ]
    },
    {
      key: 47, // used for v-for loop
      cells: [
        {
          value: "Dividend return",
          cellClass: "dividend-return-title"
        },
        {
          matchingName: "dividend_return",
          matchingColumn: "unit",
          cellClass: "dividend-return-unit"
        },
        {
          matchingName: "dividend_return",
          matchingColumn: "t",
          cellClass: "dividend-return-t"
        }
      ]
    },
    {
      key: 48, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Total investment return",
          cellClass: "total-investment-return-title"
        },
        {
          matchingName: "total_investment_return",
          matchingColumn: "unit",
          cellClass: "total-investment-return-unit"
        },
        {
          matchingName: "total_investment_return",
          matchingColumn: "t",
          cellClass: "total-investment-return-t"
        }
      ]
    },
    {
      key: 49, // used for v-for loop
      cells: [
        {
          value: "",
          cellClass: "pricing-metrics-empty-cell-7"
        },
        {
          value: "",
          cellClass: "pricing-metrics-empty-cell-8"
        },
        {
          value: "",
          cellClass: "pricing-metrics-empty-cell-9"
        }
      ]
    },
    {
      key: 50, // used for v-for loop
      cells: [
        {
          value: "Price to revenue",
          cellClass: "price-to-revenue-title"
        },
        {
          matchingName: "price_to_revenue",
          matchingColumn: "unit",
          cellClass: "price-to-revenue-unit"
        },
        {
          matchingName: "price_to_revenue",
          matchingColumn: "t+1",
          cellClass: "price-to-revenue-t1"
        }
      ]
    },
    {
      key: 51, // used for v-for loop
      cells: [
        {
          value: "Enterprise value to EBITDA",
          cellClass: "enterprise-value-to-ebitda-title"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "unit",
          cellClass: "enterprise-value-to-ebitda-unit"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t+1",
          cellClass: "enterprise-value-to-ebitda-t1"
        }
      ]
    },
    {
      key: 52, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Price to earnings",
          cellClass: "price-to-earnings-title"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "unit",
          cellClass: "price-to-earnings-unit"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t+1",
          cellClass: "price-to-earnings-t1"
        }
      ]
    },
    {
      key: 53, // used for v-for loop
      cells: [
        {
          value: "Price to earnings growth",
          cellClass: "price-to-earnings-growth-title"
        },
        {
          matchingName: "price_to_earnings_growth",
          matchingColumn: "unit",
          cellClass: "price-to-earnings-growth-unit"
        },
        {
          matchingName: "price_to_earnings_growth",
          matchingColumn: "t+1",
          cellClass: "price-to-earnings-growth-t1"
        }
      ]
    },
    {
      key: 54, // used for v-for loop
      cells: [
        {
          value: "Price to book",
          cellClass: "price-to-book-title"
        },
        {
          matchingName: "price_to_book",
          matchingColumn: "unit",
          cellClass: "price-to-book-unit"
        },
        {
          matchingName: "price_to_book",
          matchingColumn: "t+1",
          cellClass: "price-to-book-t1"
        }
      ]
    },
    {
      key: 55, // used for v-for loop
      cells: [
        {
          value: "Price to free cashflow",
          cellClass: "price-to-free-cashflow-title"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "unit",
          cellClass: "price-to-free-cashflow-unit"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t+1",
          cellClass: "price-to-free-cashflow-t1"
        }
      ]
    },
    {
      key: 56, // used for v-for loop
      cells: [
        {
          value: "Pre-tax dividend yield",
          cellClass: "tax-dividend-yield-title"
        },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "unit",
          cellClass: "tax-dividend-yield-unit"
        },
        {
          matchingName: "pre-tax_dividend_yield",
          matchingColumn: "t+1",
          cellClass: "tax-dividend-yield-t1"
        }
      ]
    },
    {
      key: 57, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Post-tax dividend yield",
          cellClass: "post-tax-dividend-yield-title"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "unit",
          cellClass: "post-tax-dividend-yield-unit"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t+1",
          cellClass: "post-tax-dividend-yield-t1"
        }
      ]
    },
    {
      key: 58, // used for v-for loop
      cells: [
        {
          value: "",
          cellClass: "pricing-metrics-empty-cell-10"
        },
        {
          value: "",
          cellClass: "pricing-metrics-empty-cell-11"
        },
        {
          value: "",
          cellClass: "pricing-metrics-empty-cell-12"
        }
      ]
    },
    {
      key: 59, // used for v-for loop
      cells: [
        {
          value: "Financial year-end",
          cellClass: "financial-year-end-title"
        },
        {
          matchingName: "financial_year-end",
          matchingColumn: "unit",
          cellClass: "financial-year-end-unit"
        },
        {
          matchingName: "financial_year-end",
          matchingColumn: "t",
          cellClass: "financial-year-end-t"
        }
      ]
    },
    {
      key: 60, // used for v-for loop
      cells: [
        {
          value: "Current financial year",
          cellClass: "current-financial-year-title"
        },
        {
          matchingName: "current_financial_year",
          matchingColumn: "unit",
          cellClass: "current-financial-year-unit"
        },
        {
          matchingName: "current_financial_year",
          matchingColumn: "t",
          cellClass: "current-financial-year-t"
        }
      ]
    }
  ],
  balanceSheet: [
    {
      key: 61, // used for v-for loop
      type: "header",
      cells: [
        {
          value: "Balance sheet",
          cellClass: "balance-sheet-title"
        },
        {
          value: "unit",
          cellClass: "balance-sheet-unit"
        },
        {
          value: "t-1",
          cellClass: "balance-sheet-t-1"
        },
        {
          value: "t+1",
          cellClass: "balance-sheet-t1"
        },
        {
          value: "t+2",
          cellClass: "balance-sheet-t2"
        }
      ]
    },
    {
      key: 62, // used for v-for loop
      cells: [
        {
          value: "Cash and equivalents",
          cellClass: "cash-and-equivalents-title"
        },
        {
          matchingName: "cash_and_equivalents",
          matchingColumn: "unit",
          cellClass: "cash-and-equivalents-unit"
        },
        {
          matchingName: "cash_and_equivalents",
          matchingColumn: "t-1",
          cellClass: "cash-and-equivalents-t-1"
        },
        {
          matchingName: "cash_and_equivalents",
          matchingColumn: "t+1",
          cellClass: "cash-and-equivalents-t1"
        },
        {
          matchingName: "cash_and_equivalents",
          matchingColumn: "t+2",
          cellClass: "cash-and-equivalents-t2"
        }
      ]
    },
    {
      key: 63, // used for v-for loop
      cells: [
        {
          value: "Debtors and inventory",
          cellClass: "debtors-and-inventory-title"
        },
        {
          matchingName: "debtors_and_inventory",
          matchingColumn: "unit",
          cellClass: "debtors-and-inventory-unit"
        },
        {
          matchingName: "debtors_and_inventory",
          matchingColumn: "t-1",
          cellClass: "debtors-and-inventory-t-1"
        },
        {
          matchingName: "debtors_and_inventory",
          matchingColumn: "t+1",
          cellClass: "debtors-and-inventory-t1"
        },
        {
          matchingName: "debtors_and_inventory",
          matchingColumn: "t+2",
          cellClass: "debtors-and-inventory-t2"
        }
      ]
    },
    {
      key: 64, // used for v-for loop
      cells: [
        {
          value: "Fixed and intangible assets",
          cellClass: "fixed-and-intangible-assets-title"
        },
        {
          matchingName: "fixed_and_intangible_assets",
          matchingColumn: "unit",
          cellClass: "fixed-and-intangible-assets-unit"
        },
        {
          matchingName: "fixed_and_intangible_assets",
          matchingColumn: "t-1",
          cellClass: "fixed-and-intangible-assets-t-1"
        },
        {
          matchingName: "fixed_and_intangible_assets",
          matchingColumn: "t+1",
          cellClass: "fixed-and-intangible-assets-t1"
        },
        {
          matchingName: "fixed_and_intangible_assets",
          matchingColumn: "t+2",
          cellClass: "fixed-and-intangible-assets-t2"
        }
      ]
    },
    {
      key: 65, // used for v-for loop
      cells: [
        {
          value: "Investments",
          cellClass: "investments-title"
        },
        {
          matchingName: "investments",
          matchingColumn: "unit",
          cellClass: "investments-title"
        },
        {
          matchingName: "investments",
          matchingColumn: "t-1",
          cellClass: "investments-title"
        },
        {
          matchingName: "investments",
          matchingColumn: "t+1",
          cellClass: "investments-title"
        },
        {
          matchingName: "investments",
          matchingColumn: "t+2",
          cellClass: "investments-title"
        }
      ]
    },
    {
      key: 66, // used for v-for loop
      cells: [
        {
          value: "Tax assets",
          cellClass: "tax-assets-title"
        },
        {
          matchingName: "tax_assets",
          matchingColumn: "unit",
          cellClass: "tax-assets-unit"
        },
        {
          matchingName: "tax_assets",
          matchingColumn: "t-1",
          cellClass: "tax-assets-t-1"
        },
        {
          matchingName: "tax_assets",
          matchingColumn: "t+1",
          cellClass: "tax-assets-t1"
        },
        {
          matchingName: "tax_assets",
          matchingColumn: "t+2",
          cellClass: "tax-assets-t2"
        }
      ]
    },
    {
      key: 127, // used for v-for loop
      cells: [
        {
          value: "Other assets",
          cellClass: "other-assets-title"
        },
        {
          matchingName: "other_assets",
          matchingColumn: "unit",
          cellClass: "other-assets-unit"
        },
        {
          matchingName: "other_assets",
          matchingColumn: "t-1",
          cellClass: "other-assets-t-1"
        },
        {
          matchingName: "other_assets",
          matchingColumn: "t+1",
          cellClass: "other-assets-t1"
        },
        {
          matchingName: "other_assets",
          matchingColumn: "t+2",
          cellClass: "other-assets-t2"
        }
      ]
    },
    {
      key: 67, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Total assets",
          cellClass: "total-assets-title"
        },
        {
          matchingName: "total_assets",
          matchingColumn: "unit",
          cellClass: "total-assets-unit"
        },
        {
          matchingName: "total_assets",
          matchingColumn: "t-1",
          cellClass: "total-assets-t-1"
        },
        {
          matchingName: "total_assets",
          matchingColumn: "t+1",
          cellClass: "total-assets-t1"
        },
        {
          matchingName: "total_assets",
          matchingColumn: "t+2",
          cellClass: "total-assets-t2"
        }
      ]
    },
    {
      key: 68, // used for v-for loop
      cells: [
        {
          value: "Debt and equivalents",
          cellClass: "debt-and-equivalents-title"
        },
        {
          matchingName: "debt_and_equivalents",
          matchingColumn: "unit",
          cellClass: "debt-and-equivalents-unit"
        },
        {
          matchingName: "debt_and_equivalents",
          matchingColumn: "t-1",
          cellClass: "debt-and-equivalents-t-1"
        },
        {
          matchingName: "debt_and_equivalents",
          matchingColumn: "t+1",
          cellClass: "debt-and-equivalents-t1"
        },
        {
          matchingName: "debt_and_equivalents",
          matchingColumn: "t+2",
          cellClass: "debt-and-equivalents-t2"
        }
      ]
    },
    {
      key: 69, // used for v-for loop
      cells: [
        {
          value: "Creditors",
          cellClass: "creditors-title"
        },
        {
          matchingName: "creditors",
          matchingColumn: "unit",
          cellClass: "creditors-unit"
        },
        {
          matchingName: "creditors",
          matchingColumn: "t-1",
          cellClass: "creditors-t-1"
        },
        {
          matchingName: "creditors",
          matchingColumn: "t+1",
          cellClass: "creditors-t1"
        },
        {
          matchingName: "creditors",
          matchingColumn: "t+2",
          cellClass: "creditors-t2"
        }
      ]
    },
    {
      key: 70, // used for v-for loop
      cells: [
        {
          value: "Minority interests",
          cellClass: "minority-interests-title"
        },
        {
          matchingName: "minority_interests",
          matchingColumn: "unit",
          cellClass: "minority-interests-unit"
        },
        {
          matchingName: "minority_interests",
          matchingColumn: "t-1",
          cellClass: "minority-interests-t-1"
        },
        {
          matchingName: "minority_interests",
          matchingColumn: "t+1",
          cellClass: "minority-interests-t1"
        },
        {
          matchingName: "minority_interests",
          matchingColumn: "t+2",
          cellClass: "minority-interests-t2"
        }
      ]
    },
    {
      key: 71, // used for v-for loop
      cells: [
        {
          value: "Tax liabilities",
          cellClass: "tax-liabilities-title"
        },
        {
          matchingName: "tax_liabilities",
          matchingColumn: "unit",
          cellClass: "tax-liabilities-unit"
        },
        {
          matchingName: "tax_liabilities",
          matchingColumn: "t-1",
          cellClass: "tax-liabilities-t-1"
        },
        {
          matchingName: "tax_liabilities",
          matchingColumn: "t+1",
          cellClass: "tax-liabilities-t1"
        },
        {
          matchingName: "tax_liabilities",
          matchingColumn: "t+2",
          cellClass: "tax-liabilities-t2"
        }
      ]
    },
    {
      key: 72, // used for v-for loop
      cells: [
        {
          value: "Other liabilities",
          cellClass: "other-liabilities-title"
        },
        {
          matchingName: "other_liabilities",
          matchingColumn: "unit",
          cellClass: "other-liabilities-unit"
        },
        {
          matchingName: "other_liabilities",
          matchingColumn: "t-1",
          cellClass: "other-liabilities-t-1"
        },
        {
          matchingName: "other_liabilities",
          matchingColumn: "t+1",
          cellClass: "other-liabilities-t1"
        },
        {
          matchingName: "other_liabilities",
          matchingColumn: "t+2",
          cellClass: "other-liabilities-t2"
        }
      ]
    },
    {
      key: 73, // used for v-for loop
      cells: [
        {
          value: "Shareholders' equity",
          cellClass: "shareholders-equity-title"
        },
        {
          matchingName: "shareholders_equity",
          matchingColumn: "unit",
          cellClass: "shareholders-equity-unit"
        },
        {
          matchingName: "shareholders_equity",
          matchingColumn: "t-1",
          cellClass: "shareholders-equity-t-1"
        },
        {
          matchingName: "shareholders_equity",
          matchingColumn: "t+1",
          cellClass: "shareholders-equity-t1"
        },
        {
          matchingName: "shareholders_equity",
          matchingColumn: "t+2",
          cellClass: "shareholders-equity-t2"
        }
      ]
    },
    {
      key: 74, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Total liabilities and equity",
          cellClass: "total-liabilities-and-equity-title"
        },
        {
          matchingName: "total_liabilities_and_equity",
          matchingColumn: "unit",
          cellClass: "total-liabilities-and-equity-unit"
        },
        {
          matchingName: "total_liabilities_and_equity",
          matchingColumn: "t-1",
          cellClass: "total-liabilities-and-equity-t-1"
        },
        {
          matchingName: "total_liabilities_and_equity",
          matchingColumn: "t+1",
          cellClass: "total-liabilities-and-equity-t1"
        },
        {
          matchingName: "total_liabilities_and_equity",
          matchingColumn: "t+2",
          cellClass: "total-liabilities-and-equity-t2"
        }
      ]
    },
    {
      key: 75, // used for v-for loop
      cells: [
        {
          value: "",
          cellClass: "balance-sheet-empty-cell-1"
        },
        {
          value: "",
          cellClass: "balance-sheet-empty-cell-2"
        },
        {
          value: "",
          cellClass: "balance-sheet-empty-cell-3"
        },
        {
          value: "",
          cellClass: "balance-sheet-empty-cell-4"
        },
        {
          value: "",
          cellClass: "balance-sheet-empty-cell-5"
        }
      ]
    },
    {
      key: 76, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Net debt to capital",
          cellClass: "net-debt-to-capital-title"
        },
        {
          matchingName: "net_debt_to_capital",
          matchingColumn: "unit",
          cellClass: "net-debt-to-capital-unit"
        },
        {
          matchingName: "net_debt_to_capital",
          matchingColumn: "t-1",
          cellClass: "net-debt-to-capital-t-1"
        },
        {
          matchingName: "net_debt_to_capital",
          matchingColumn: "t+1",
          cellClass: "net-debt-to-capital-t1"
        },
        {
          matchingName: "net_debt_to_capital",
          matchingColumn: "t+2",
          cellClass: "net-debt-to-capital-t2"
        }
      ]
    },
    {
      key: 77, // used for v-for loop
      cells: [
        {
          value: "Interest cover",
          cellClass: "interest-cover-title"
        },
        {
          matchingName: "interest_cover",
          matchingColumn: "unit",
          cellClass: "interest-cover-unit"
        },
        {
          matchingName: "interest_cover",
          matchingColumn: "t-1",
          cellClass: "interest-cover-t-1"
        },
        {
          matchingName: "interest_cover",
          matchingColumn: "t+1",
          cellClass: "interest-cover-t1"
        },
        {
          matchingName: "interest_cover",
          matchingColumn: "t+2",
          cellClass: "interest-cover-t2"
        }
      ]
    },
    {
      key: 78, // used for v-for loop
      cells: [
        {
          value: "Debtor days",
          cellClass: "debtor-days-title"
        },
        {
          matchingName: "debtor_days",
          matchingColumn: "unit",
          cellClass: "debtor-days-unit"
        },
        {
          matchingName: "debtor_days",
          matchingColumn: "t-1",
          cellClass: "debtor-days-t-1"
        },
        {
          matchingName: "debtor_days",
          matchingColumn: "t+1",
          cellClass: "debtor-days-t1"
        },
        {
          matchingName: "debtor_days",
          matchingColumn: "t+2",
          cellClass: "debtor-days-t2"
        }
      ]
    },
    {
      key: 79, // used for v-for loop
      cells: [
        {
          value: "Creditor days",
          cellClass: "creditor-days-title"
        },
        {
          matchingName: "creditor_days",
          matchingColumn: "unit",
          cellClass: "creditor-days-unit"
        },
        {
          matchingName: "creditor_days",
          matchingColumn: "t-1",
          cellClass: "creditor-days-t-1"
        },
        {
          matchingName: "creditor_days",
          matchingColumn: "t+1",
          cellClass: "creditor-days-t1"
        },
        {
          matchingName: "creditor_days",
          matchingColumn: "t+2",
          cellClass: "creditor-days-t2"
        }
      ]
    },
    {
      key: 80, // used for v-for loop
      cells: [
        {
          value: "Working capital to revenue",
          cellClass: "working-capital-to-revenue-title"
        },
        {
          matchingName: "working_capital_to_revenue",
          matchingColumn: "unit",
          cellClass: "working-capital-to-revenue-unit"
        },
        {
          matchingName: "working_capital_to_revenue",
          matchingColumn: "t-1",
          cellClass: "working-capital-to-revenue-t-1"
        },
        {
          matchingName: "working_capital_to_revenue",
          matchingColumn: "t+1",
          cellClass: "working-capital-to-revenue-t1"
        },
        {
          matchingName: "working_capital_to_revenue",
          matchingColumn: "t+2",
          cellClass: "working-capital-to-revenue-t2"
        }
      ]
    },
    {
      key: 81, // used for v-for loop
      cells: [
        {
          value: "Return on capital employed",
          cellClass: "return-on-capital-employed-title"
        },
        {
          matchingName: "return_on_capital_employed",
          matchingColumn: "unit",
          cellClass: "return-on-capital-employed-unit"
        },
        {
          matchingName: "return_on_capital_employed",
          matchingColumn: "t-1",
          cellClass: "return-on-capital-employed-t-1"
        },
        {
          matchingName: "return_on_capital_employed",
          matchingColumn: "t+1",
          cellClass: "return-on-capital-employed-t1"
        },
        {
          matchingName: "return_on_capital_employed",
          matchingColumn: "t+2",
          cellClass: "return-on-capital-employed-t2"
        }
      ]
    }
  ],
  valuationMetrics: [
    {
      key: 82, // used for v-for loop
      type: "header",
      cells: [
        {
          value: "Valuation metrics",
          cellClass: "valuation-metrics-title"
        },
        {
          value: "unit",
          cellClass: "valuation-metrics-unit"
        },
        {
          value: "",
          cellClass: "valuation-metrics-value"
        }
      ]
    },
    {
      key: 83, // used for v-for loop
      cells: [
        {
          value: "Explicit cashflow valuation",
          cellClass: "explicit-cashflow-valuation-title"
        },
        {
          matchingName: "explicit_cashflow_valuation",
          matchingColumn: "unit",
          cellClass: "explicit-cashflow-valuation-unit"
        },
        {
          matchingName: "explicit_cashflow_valuation",
          matchingColumn: "t",
          cellClass: "explicit-cashflow-valuation-t"
        }
      ]
    },
    {
      key: 84, // used for v-for loop
      cells: [
        {
          value: "Other assets, liabilities and options",
          cellClass: "other-assets-liabilities-and-options-title"
        },
        {
          matchingName: "other_assets,_liabilities_and_options",
          matchingColumn: "unit",
          cellClass: "other-assets-liabilities-and-options-unit"
        },
        {
          matchingName: "other_assets,_liabilities_and_options",
          matchingColumn: "t",
          cellClass: "other-assets-liabilities-and-options-t"
        }
      ]
    },
    {
      key: 85, // used for v-for loop
      cells: [
        {
          value: "Terminal valuation",
          cellClass: "terminal-valuation-title"
        },
        {
          matchingName: "terminal_valuation",
          matchingColumn: "unit",
          cellClass: "terminal-valuation-unit"
        },
        {
          matchingName: "terminal_valuation",
          matchingColumn: "t",
          cellClass: "terminal-valuation-t"
        }
      ]
    },
    {
      key: 86, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Enterprise valuation",
          cellClass: "enterprise-valuation-title"
        },
        {
          matchingName: "enterprise_valuation",
          matchingColumn: "unit",
          cellClass: "enterprise-valuation-unit"
        },
        {
          matchingName: "enterprise_valuation",
          matchingColumn: "t",
          cellClass: "enterprise-valuation-t"
        }
      ]
    },
    {
      key: 87, // used for v-for loop
      cells: [
        {
          value: "Net debt",
          cellClass: "net-debt-title"
        },
        {
          matchingName: "net_debt",
          matchingColumn: "unit",
          cellClass: "net-debt-unit"
        },
        {
          matchingName: "net_debt",
          matchingColumn: "t",
          cellClass: "net-debt-t"
        }
      ]
    },
    {
      key: 88, // used for v-for loop
      type: "row-bold",

      cells: [
        {
          value: "Equity valuation",
          cellClass: "equity-valuation-title"
        },
        {
          matchingName: "equity_valuation",
          matchingColumn: "unit",
          cellClass: "equity-valuation-unit"
        },
        {
          matchingName: "equity_valuation",
          matchingColumn: "t",
          cellClass: "equity-valuation-t"
        }
      ]
    },
    {
      key: 89, // used for v-for loop
      cells: [
        {
          value: "Shares on issue",
          cellClass: "shares-on-issue-title"
        },
        {
          matchingName: "shares_on_issue",
          matchingColumn: "unit",
          cellClass: "shares-on-issue-unit"
        },
        {
          matchingName: "shares_on_issue",
          matchingColumn: "t",
          cellClass: "shares-on-issue-t"
        }
      ]
    },
    {
      key: 90, // used for v-for loop
      cells: [
        {
          value: "Share price valuation",
          cellClass: "share-price-valuation-title"
        },
        {
          matchingName: "share_price_valuation",
          matchingColumn: "unit",
          cellClass: "share-price-valuation-unit"
        },
        {
          matchingName: "share_price_valuation",
          matchingColumn: "t",
          cellClass: "share-price-valuation-t"
        }
      ]
    },
    {
      key: 91, // used for v-for loop
      cells: [
        {
          value: "",
          cellClass: "valuation-metrics-empty-cell-1"
        },
        {
          value: "",
          cellClass: "valuation-metrics-empty-cell-2"
        },
        {
          value: "",
          cellClass: "valuation-metrics-empty-cell-3"
        }
      ]
    },
    {
      key: 92, // used for v-for loop
      cells: [
        {
          value: "Target debt to capital ratio",
          cellClass: "target-debt-to-capital-ratio-title"
        },
        {
          matchingName: "target_debt_to_capital_ratio",
          matchingColumn: "unit",
          cellClass: "target-debt-to-capital-ratio-unit"
        },
        {
          matchingName: "target_debt_to_capital_ratio",
          matchingColumn: "t",
          cellClass: "target-debt-to-capital-ratio-t"
        }
      ]
    },
    {
      key: 93, // used for v-for loop
      cells: [
        {
          value: "Corporate tax rate",
          cellClass: "corporate-tax-rate-title"
        },
        {
          matchingName: "corporate_tax_rate",
          matchingColumn: "unit",
          cellClass: "corporate-tax-rate-unit"
        },
        {
          matchingName: "corporate_tax_rate",
          matchingColumn: "t",
          cellClass: "corporate-tax-rate-t"
        }
      ]
    },
    {
      key: 94, // used for v-for loop
      cells: [
        {
          value: "Long run growth rate (nominal)",
          cellClass: "long-run-growth-rate-nominal-title"
        },
        {
          matchingName: "long_run_growth_rate_(nominal)",
          matchingColumn: "unit",
          cellClass: "long-run-growth-rate-nominal-unit"
        },
        {
          matchingName: "long_run_growth_rate_(nominal)",
          matchingColumn: "t",
          cellClass: "long-run-growth-rate-nominal-t"
        }
      ]
    },
    {
      key: 95, // used for v-for loop
      cells: [
        {
          value: "Risk free rate",
          cellClass: "risk-free-rate-title"
        },
        {
          matchingName: "risk_free_rate",
          matchingColumn: "unit",
          cellClass: "risk-free-rate-unit"
        },
        {
          matchingName: "risk_free_rate",
          matchingColumn: "t",
          cellClass: "risk-free-rate-t"
        }
      ]
    },
    {
      key: 96, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Asset beta",
          cellClass: "asset-beta-title"
        },
        {
          matchingName: "asset_beta",
          matchingColumn: "unit",
          cellClass: "asset-beta-unit"
        },
        {
          matchingName: "asset_beta",
          matchingColumn: "t",
          cellClass: "asset-beta-t"
        }
      ]
    },
    {
      key: 97, // used for v-for loop
      cells: [
        {
          value: "Equity beta",
          cellClass: "equity-beta-title"
        },
        {
          matchingName: "equity_beta",
          matchingColumn: "unit",
          cellClass: "equity-beta-unit"
        },
        {
          matchingName: "equity_beta",
          matchingColumn: "t",
          cellClass: "equity-beta-t"
        }
      ]
    },
    {
      key: 98, // used for v-for loop
      cells: [
        {
          value: "Equity market risk premium",
          cellClass: "equity-market-risk-premium-title"
        },
        {
          matchingName: "equity_market_risk_premium",
          matchingColumn: "unit",
          cellClass: "equity-market-risk-premium-unit"
        },
        {
          matchingName: "equity_market_risk_premium",
          matchingColumn: "t",
          cellClass: "equity-market-risk-premium-t"
        }
      ]
    },
    {
      key: 99, // used for v-for loop
      cells: [
        {
          value: "Other risk premium",
          cellClass: "other-risk-premium-title"
        },
        {
          matchingName: "other_risk_premium",
          matchingColumn: "unit",
          cellClass: "other-risk-premium-unit"
        },
        {
          matchingName: "other_risk_premium",
          matchingColumn: "t",
          cellClass: "other-risk-premium-t"
        }
      ]
    },
    {
      key: 100, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Cost of equity",
          cellClass: "cost-of-equity-title"
        },
        {
          matchingName: "cost_of_equity",
          matchingColumn: "unit",
          cellClass: "cost-of-equity-unit"
        },
        {
          matchingName: "cost_of_equity",
          matchingColumn: "t",
          cellClass: "cost-of-equity-t"
        }
      ]
    },
    {
      key: 101, // used for v-for loop
      cells: [
        {
          value: "Debt premium",
          cellClass: "debt-premium-title"
        },
        {
          matchingName: "debt_premium",
          matchingColumn: "unit",
          cellClass: "debt-premium-unit"
        },
        {
          matchingName: "debt_premium",
          matchingColumn: "t",
          cellClass: "debt-premium-t"
        }
      ]
    },
    {
      key: 102, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Cost of debt",
          cellClass: "cost-of-debt-title"
        },
        {
          matchingName: "cost_of_debt",
          matchingColumn: "unit",
          cellClass: "cost-of-debt-unit"
        },
        {
          matchingName: "cost_of_debt",
          matchingColumn: "t",
          cellClass: "cost-of-debt-t"
        }
      ]
    },
    {
      key: 103, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Weighted average cost of capital",
          cellClass: "weighted-average-cost-of-capital-title"
        },
        {
          matchingName: "weighted_average_cost_of_capital",
          matchingColumn: "unit",
          cellClass: "weighted-average-cost-of-capital-unit"
        },
        {
          matchingName: "weighted_average_cost_of_capital",
          matchingColumn: "t",
          cellClass: "weighted-average-cost-of-capital-t"
        }
      ]
    }
  ],
  cashflowStatement: [
    {
      key: 103, // used for v-for loop
      type: "header",
      cells: [
        {
          value: "Cashflow statement",
          cellClass: "cashflow-statement-title"
        },
        {
          value: "unit",
          cellClass: "cashflow-statement-unit"
        },
        {
          value: "t-1",
          cellClass: "cashflow-statement-t-1"
        },
        {
          value: "t+1",
          cellClass: "cashflow-statement-t1"
        },
        {
          value: "t+2",
          cellClass: "cashflow-statement-t2"
        }
      ]
    },
    {
      key: 104, // used for v-for loop
      cells: [
        {
          value: "Operating EBITDA received",
          cellClass: "operating-ebitda-received-title"
        },
        {
          matchingName: "operating_ebitda_received",
          matchingColumn: "unit",
          cellClass: "operating-ebitda-received-unit"
        },
        {
          matchingName: "operating_ebitda_received",
          matchingColumn: "t-1",
          cellClass: "operating-ebitda-received-t-1"
        },
        {
          matchingName: "operating_ebitda_received",
          matchingColumn: "t+1",
          cellClass: "operating-ebitda-received-t1"
        },
        {
          matchingName: "operating_ebitda_received",
          matchingColumn: "t+2",
          cellClass: "operating-ebitda-received-t2"
        }
      ]
    },
    {
      key: 105, // used for v-for loop
      cells: [
        {
          value: "Net interest paid",
          cellClass: "net-interest-paid-title"
        },
        {
          matchingName: "net_interest_paid",
          matchingColumn: "unit",
          cellClass: "net-interest-paid-unit"
        },
        {
          matchingName: "net_interest_paid",
          matchingColumn: "t-1",
          cellClass: "net-interest-paid-t-1"
        },
        {
          matchingName: "net_interest_paid",
          matchingColumn: "t+1",
          cellClass: "net-interest-paid-t1"
        },
        {
          matchingName: "net_interest_paid",
          matchingColumn: "t+2",
          cellClass: "net-interest-paid-t2"
        }
      ]
    },
    {
      key: 106, // used for v-for loop
      cells: [
        {
          value: "Corporate tax paid",
          cellClass: "corporate-tax-paid-title"
        },
        {
          matchingName: "corporate_tax_paid",
          matchingColumn: "unit",
          cellClass: "corporate-tax-paid-unit"
        },
        {
          matchingName: "corporate_tax_paid",
          matchingColumn: "t-1",
          cellClass: "corporate-tax-paid-t-1"
        },
        {
          matchingName: "corporate_tax_paid",
          matchingColumn: "t+1",
          cellClass: "corporate-tax-paid-t1"
        },
        {
          matchingName: "corporate_tax_paid",
          matchingColumn: "t+2",
          cellClass: "corporate-tax-paid-t2"
        }
      ]
    },
    {
      key: 107, // used for v-for loop
      cells: [
        {
          value: "Change in working capital",
          cellClass: "change-in-working-capital-title"
        },
        {
          matchingName: "change_in_working_capital",
          matchingColumn: "unit",
          cellClass: "change-in-working-capital-unit"
        },
        {
          matchingName: "change_in_working_capital",
          matchingColumn: "t-1",
          cellClass: "change-in-working-capital-t-1"
        },
        {
          matchingName: "change_in_working_capital",
          matchingColumn: "t+1",
          cellClass: "change-in-working-capital-t1"
        },
        {
          matchingName: "change_in_working_capital",
          matchingColumn: "t+2",
          cellClass: "change-in-working-capital-t2"
        }
      ]
    },
    {
      key: 108, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Operating cashflow received",
          cellClass: "operating-cashflow-received-title"
        },
        {
          matchingName: "operating_cashflow_received",
          matchingColumn: "unit",
          cellClass: "operating-cashflow-received-unit"
        },
        {
          matchingName: "operating_cashflow_received",
          matchingColumn: "t-1",
          cellClass: "operating-cashflow-received-t-1"
        },
        {
          matchingName: "operating_cashflow_received",
          matchingColumn: "t+1",
          cellClass: "operating-cashflow-received-t1"
        },
        {
          matchingName: "operating_cashflow_received",
          matchingColumn: "t+2",
          cellClass: "operating-cashflow-received-t2"
        }
      ]
    },
    {
      key: 109, // used for v-for loop
      cells: [
        {
          value: "Capital expenditure paid",
          cellClass: "capital-expenditure-paid-title"
        },
        {
          matchingName: "capital_expenditure_paid",
          matchingColumn: "unit",
          cellClass: "capital-expenditure-paid-unit"
        },
        {
          matchingName: "capital_expenditure_paid",
          matchingColumn: "t-1",
          cellClass: "capital-expenditure-paid-t-1"
        },
        {
          matchingName: "capital_expenditure_paid",
          matchingColumn: "t+1",
          cellClass: "capital-expenditure-paid-t1"
        },
        {
          matchingName: "capital_expenditure_paid",
          matchingColumn: "t+2",
          cellClass: "capital-expenditure-paid-t2"
        }
      ]
    },
    {
      key: 110, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Free cashflow received",
          cellClass: "free-cashflow-received-title"
        },
        {
          matchingName: "free_cashflow_received",
          matchingColumn: "unit",
          cellClass: "free-cashflow-received-unit"
        },
        {
          matchingName: "free_cashflow_received",
          matchingColumn: "t-1",
          cellClass: "free-cashflow-received-t-1"
        },
        {
          matchingName: "free_cashflow_received",
          matchingColumn: "t+1",
          cellClass: "free-cashflow-received-t1"
        },
        {
          matchingName: "free_cashflow_received",
          matchingColumn: "t+2",
          cellClass: "free-cashflow-received-t2"
        }
      ]
    },
    {
      key: 111, // used for v-for loop
      cells: [
        {
          value: "Investments made",
          cellClass: "investments-made-title"
        },
        {
          matchingName: "investments_made",
          matchingColumn: "unit",
          cellClass: "investments-made-unit"
        },
        {
          matchingName: "investments_made",
          matchingColumn: "t-1",
          cellClass: "investments-made-t-1"
        },
        {
          matchingName: "investments_made",
          matchingColumn: "t+1",
          cellClass: "investments-made-t1"
        },
        {
          matchingName: "investments_made",
          matchingColumn: "t+2",
          cellClass: "investments-made-t2"
        }
      ]
    },
    {
      key: 111, // used for v-for loop
      cells: [
        {
          value: "Divestments made",
          cellClass: "divestments-made-title"
        },
        {
          matchingName: "divestments_made",
          matchingColumn: "unit",
          cellClass: "divestments-made-unit"
        },
        {
          matchingName: "divestments_made",
          matchingColumn: "t-1",
          cellClass: "divestments-made-t-1"
        },
        {
          matchingName: "divestments_made",
          matchingColumn: "t+1",
          cellClass: "divestments-made-t1"
        },
        {
          matchingName: "divestments_made",
          matchingColumn: "t+2",
          cellClass: "divestments-made-t2"
        }
      ]
    },
    {
      key: 112, // used for v-for loop
      cells: [
        {
          value: "Other cash items received and paid",
          cellClass: "other-cash-items-received-and-paid-title"
        },
        {
          matchingName: "other_cash_items_received_and_paid",
          matchingColumn: "unit",
          cellClass: "other-cash-items-received-and-paid-unit"
        },
        {
          matchingName: "other_cash_items_received_and_paid",
          matchingColumn: "t-1",
          cellClass: "other-cash-items-received-and-paid-t-2"
        },
        {
          matchingName: "other_cash_items_received_and_paid",
          matchingColumn: "t+1",
          cellClass: "other-cash-items-received-and-paid-t1"
        },
        {
          matchingName: "other_cash_items_received_and_paid",
          matchingColumn: "t+2",
          cellClass: "other-cash-items-received-and-paid-t2"
        }
      ]
    },
    {
      key: 113, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Distributable free cashflow received",
          cellClass: "distributable-free-cashflow-received-title"
        },
        {
          matchingName: "distributable_free_cashflow_received",
          matchingColumn: "unit",
          cellClass: "distributable-free-cashflow-received-unit"
        },
        {
          matchingName: "distributable_free_cashflow_received",
          matchingColumn: "t-1",
          cellClass: "distributable-free-cashflow-received-t-1"
        },
        {
          matchingName: "distributable_free_cashflow_received",
          matchingColumn: "t+1",
          cellClass: "distributable-free-cashflow-received-t1"
        },
        {
          matchingName: "distributable_free_cashflow_received",
          matchingColumn: "t+2",
          cellClass: "distributable-free-cashflow-received-t2"
        }
      ]
    },
    {
      key: 114, // used for v-for loop
      cells: [
        {
          value: "Dividends paid",
          cellClass: "dividends-paid-title"
        },
        {
          matchingName: "dividends_paid",
          matchingColumn: "unit",
          cellClass: "dividends-paid-unit"
        },
        {
          matchingName: "dividends_paid",
          matchingColumn: "t-1",
          cellClass: "dividends-paid-t-1"
        },
        {
          matchingName: "dividends_paid",
          matchingColumn: "t+1",
          cellClass: "dividends-paid-t1"
        },
        {
          matchingName: "dividends_paid",
          matchingColumn: "t+2",
          cellClass: "dividends-paid-t2"
        }
      ]
    },
    {
      key: 115, // used for v-for loop
      cells: [
        {
          value: "Change in cash and equivalents",
          cellClass: "change-in-cash-and-equivalents-title"
        },
        {
          matchingName: "change_in_cash_and_equivalents",
          matchingColumn: "unit",
          cellClass: "change-in-cash-and-equivalents-unit"
        },
        {
          matchingName: "change_in_cash_and_equivalents",
          matchingColumn: "t-1",
          cellClass: "change-in-cash-and-equivalents-t-1"
        },
        {
          matchingName: "change_in_cash_and_equivalents",
          matchingColumn: "t+1",
          cellClass: "change-in-cash-and-equivalents-t1"
        },
        {
          matchingName: "change_in_cash_and_equivalents",
          matchingColumn: "t+2",
          cellClass: "change-in-cash-and-equivalents-t2"
        }
      ]
    },
    {
      key: 116, // used for v-for loop
      cells: [
        {
          value: "Change in debt and equivalents",
          cellClass: "change-in-debt-and-equivalents-title"
        },
        {
          matchingName: "change_in_debt_and_equivalents",
          matchingColumn: "unit",
          cellClass: "change-in-debt-and-equivalents-unit"
        },
        {
          matchingName: "change_in_debt_and_equivalents",
          matchingColumn: "t-1",
          cellClass: "change-in-debt-and-equivalents-t-1"
        },
        {
          matchingName: "change_in_debt_and_equivalents",
          matchingColumn: "t+1",
          cellClass: "change-in-debt-and-equivalents-t1"
        },
        {
          matchingName: "change_in_debt_and_equivalents",
          matchingColumn: "t+2",
          cellClass: "change-in-debt-and-equivalents-t2"
        }
      ]
    },
    {
      key: 117, // used for v-for loop
      cells: [
        {
          value: "",
          cellClass: "cashflow-statement-empty-cell-1"
        },
        {
          value: "",
          cellClass: "cashflow-statement-empty-cell-2"
        },
        {
          value: "",
          cellClass: "cashflow-statement-empty-cell-3"
        },
        {
          value: "",
          cellClass: "cashflow-statement-empty-cell-4"
        },
        {
          value: "",
          cellClass: "cashflow-statement-empty-cell-5"
        }
      ]
    },
    {
      key: 118, // used for v-for loop
      cells: [
        {
          value: "Proportion of imputed dividends",
          cellClass: "proportion-of-imputed-dividends-title"
        },
        {
          matchingName: "proportion_of_imputed_dividends",
          matchingColumn: "unit",
          cellClass: "proportion-of-imputed-dividends-unit"
        },
        {
          matchingName: "proportion_of_imputed_dividends",
          matchingColumn: "t-1",
          cellClass: "proportion-of-imputed-dividends-t-1"
        },
        {
          matchingName: "proportion_of_imputed_dividends",
          matchingColumn: "t+1",
          cellClass: "proportion-of-imputed-dividends-t1"
        },
        {
          matchingName: "proportion_of_imputed_dividends",
          matchingColumn: "t+2",
          cellClass: "proportion-of-imputed-dividends-t2"
        }
      ]
    },
    {
      key: 119, // used for v-for loop
      cells: [
        {
          value: "Declared post-tax interim dividend per share",
          cellClass: "declared-post-tax-interim-dividend-per-share-title"
        },
        {
          matchingName: "declared_post-tax_interim_dividend_per_share",
          matchingColumn: "unit",
          cellClass: "declared-post-tax-interim-dividend-per-share-unit"
        },
        {
          matchingName: "declared_post-tax_interim_dividend_per_share",
          matchingColumn: "t-1",
          cellClass: "declared-post-tax-interim-dividend-per-share-t-1"
        },
        {
          matchingName: "declared_post-tax_interim_dividend_per_share",
          matchingColumn: "t+1",
          cellClass: "declared-post-tax-interim-dividend-per-share-t1"
        },
        {
          matchingName: "declared_post-tax_interim_dividend_per_share",
          matchingColumn: "t+2",
          cellClass: "declared-post-tax-interim-dividend-per-share-t2"
        }
      ]
    },
    {
      key: 120, // used for v-for loop
      cells: [
        {
          value: "Declared post-tax final dividend per share",
          cellClass: "declared-post-tax-final-dividend-per-share-title"
        },
        {
          matchingName: "declared_post-tax_final_dividend_per_share",
          matchingColumn: "unit",
          cellClass: "declared-post-tax-final-dividend-per-share-unit"
        },
        {
          matchingName: "declared_post-tax_final_dividend_per_share",
          matchingColumn: "t-1",
          cellClass: "declared-post-tax-final-dividend-per-share-t-1"
        },
        {
          matchingName: "declared_post-tax_final_dividend_per_share",
          matchingColumn: "t+1",
          cellClass: "declared-post-tax-final-dividend-per-share-t1"
        },
        {
          matchingName: "declared_post-tax_final_dividend_per_share",
          matchingColumn: "t+2",
          cellClass: "declared-post-tax-final-dividend-per-share-t2"
        }
      ]
    },
    {
      key: 121, // used for v-for loop
      cells: [
        {
          value: "Declared post-tax special dividend per share",
          cellClass: "declared-post-tax-special-dividend-per-share-title"
        },
        {
          matchingName: "declared_post-tax_special_dividend_per_share",
          matchingColumn: "unit",
          cellClass: "declared-post-tax-special-dividend-per-share-unit"
        },
        {
          matchingName: "declared_post-tax_special_dividend_per_share",
          matchingColumn: "t-1",
          cellClass: "declared-post-tax-special-dividend-per-share-t-1"
        },
        {
          matchingName: "declared_post-tax_special_dividend_per_share",
          matchingColumn: "t+1",
          cellClass: "declared-post-tax-special-dividend-per-share-t1"
        },
        {
          matchingName: "declared_post-tax_special_dividend_per_share",
          matchingColumn: "t+2",
          cellClass: "declared-post-tax-special-dividend-per-share-t2"
        }
      ]
    },
    {
      key: 122, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Declared post-tax dividend per share",
          cellClass: "declared-post-tax-dividend-per-share-title"
        },
        {
          matchingName: "declared_post-tax_dividend_per_share",
          matchingColumn: "unit",
          cellClass: "declared-post-tax-dividend-per-share-unit"
        },
        {
          matchingName: "declared_post-tax_dividend_per_share",
          matchingColumn: "t-1",
          cellClass: "declared-post-tax-dividend-per-share-t-1"
        },
        {
          matchingName: "declared_post-tax_dividend_per_share",
          matchingColumn: "t+1",
          cellClass: "declared-post-tax-dividend-per-share-t1"
        },
        {
          matchingName: "declared_post-tax_dividend_per_share",
          matchingColumn: "t+2",
          cellClass: "declared-post-tax-dividend-per-share-t2"
        }
      ]
    },
    {
      key: 123, // used for v-for loop
      cells: [
        {
          value: "Dividend payout",
          cellClass: "dividend-payout-title"
        },
        {
          matchingName: "dividend_payout",
          matchingColumn: "unit",
          cellClass: "dividend-payout-unit"
        },
        {
          matchingName: "dividend_payout",
          matchingColumn: "t-1",
          cellClass: "dividend-payout-t-1"
        },
        {
          matchingName: "dividend_payout",
          matchingColumn: "t+1",
          cellClass: "dividend-payout-t1"
        },
        {
          matchingName: "dividend_payout",
          matchingColumn: "t+2",
          cellClass: "dividend-payout-t2"
        }
      ]
    },
    {
      key: 124, // used for v-for loop
      cells: [
        {
          value: "Dividend cover",
          cellClass: "dividend-cover-title"
        },
        {
          matchingName: "dividend_cover",
          matchingColumn: "unit",
          cellClass: "dividend-cover-unit"
        },
        {
          matchingName: "dividend_cover",
          matchingColumn: "t-1",
          cellClass: "dividend-cover-t-1"
        },
        {
          matchingName: "dividend_cover",
          matchingColumn: "t+1",
          cellClass: "dividend-cover-t1"
        },
        {
          matchingName: "dividend_cover",
          matchingColumn: "t+2",
          cellClass: "dividend-cover-t2"
        }
      ]
    },
    {
      key: 125, // used for v-for loop
      cells: [
        {
          value: "Cash conversion",
          cellClass: "cash-conversion-title"
        },
        {
          matchingName: "cash_conversion",
          matchingColumn: "unit",
          cellClass: "cash-conversion-unit"
        },
        {
          matchingName: "cash_conversion",
          matchingColumn: "t-1",
          cellClass: "cash-conversion-t-1"
        },
        {
          matchingName: "cash_conversion",
          matchingColumn: "t+1",
          cellClass: "cash-conversion-t1"
        },
        {
          matchingName: "cash_conversion",
          matchingColumn: "t+2",
          cellClass: "cash-conversion-t2"
        }
      ]
    },
    {
      key: 126, // used for v-for loop
      type: "footer",
      cells: [
        {
          colSpan: 5,
          value: "Security: {{SecurityName}}"
        }
      ]
    }
  ],
  cashflowStatementForOxcap: [
    {
      key: 103, // used for v-for loop
      type: "header",
      cells: [
        {
          value: "Cashflow statement",
          cellClass: "cashflow-statement-title"
        },
        {
          value: "unit",
          cellClass: "cashflow-statement-unit"
        },
        {
          value: "t-1",
          cellClass: "cashflow-statement-t-1"
        },
        {
          value: "t+1",
          cellClass: "cashflow-statement-t1"
        },
        {
          value: "t+2",
          cellClass: "cashflow-statement-t2"
        }
      ]
    },
    {
      key: 104, // used for v-for loop
      cells: [
        {
          value: "Operating EBITDA received",
          cellClass: "operating-ebitda-received-title"
        },
        {
          matchingName: "operating_ebitda_received",
          matchingColumn: "unit",
          cellClass: "operating-ebitda-received-unit"
        },
        {
          matchingName: "operating_ebitda_received",
          matchingColumn: "t-1",
          cellClass: "operating-ebitda-received-t-1"
        },
        {
          matchingName: "operating_ebitda_received",
          matchingColumn: "t+1",
          cellClass: "operating-ebitda-received-t1"
        },
        {
          matchingName: "operating_ebitda_received",
          matchingColumn: "t+2",
          cellClass: "operating-ebitda-received-t2"
        }
      ]
    },
    {
      key: 105, // used for v-for loop
      cells: [
        {
          value: "Net interest paid",
          cellClass: "net-interest-paid-title"
        },
        {
          matchingName: "net_interest_paid",
          matchingColumn: "unit",
          cellClass: "net-interest-paid-unit"
        },
        {
          matchingName: "net_interest_paid",
          matchingColumn: "t-1",
          cellClass: "net-interest-paid-t-1"
        },
        {
          matchingName: "net_interest_paid",
          matchingColumn: "t+1",
          cellClass: "net-interest-paid-t1"
        },
        {
          matchingName: "net_interest_paid",
          matchingColumn: "t+2",
          cellClass: "net-interest-paid-t2"
        }
      ]
    },
    {
      key: 106, // used for v-for loop
      cells: [
        {
          value: "Corporate tax paid",
          cellClass: "corporate-tax-paid-title"
        },
        {
          matchingName: "corporate_tax_paid",
          matchingColumn: "unit",
          cellClass: "corporate-tax-paid-unit"
        },
        {
          matchingName: "corporate_tax_paid",
          matchingColumn: "t-1",
          cellClass: "corporate-tax-paid-t-1"
        },
        {
          matchingName: "corporate_tax_paid",
          matchingColumn: "t+1",
          cellClass: "corporate-tax-paid-t1"
        },
        {
          matchingName: "corporate_tax_paid",
          matchingColumn: "t+2",
          cellClass: "corporate-tax-paid-t2"
        }
      ]
    },
    {
      key: 107, // used for v-for loop
      cells: [
        {
          value: "Change in working capital",
          cellClass: "change-in-working-capital-title"
        },
        {
          matchingName: "change_in_working_capital",
          matchingColumn: "unit",
          cellClass: "change-in-working-capital-unit"
        },
        {
          matchingName: "change_in_working_capital",
          matchingColumn: "t-1",
          cellClass: "change-in-working-capital-t-1"
        },
        {
          matchingName: "change_in_working_capital",
          matchingColumn: "t+1",
          cellClass: "change-in-working-capital-t1"
        },
        {
          matchingName: "change_in_working_capital",
          matchingColumn: "t+2",
          cellClass: "change-in-working-capital-t2"
        }
      ]
    },
    {
      key: 108, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Operating cashflow received",
          cellClass: "operating-cashflow-received-title"
        },
        {
          matchingName: "operating_cashflow_received",
          matchingColumn: "unit",
          cellClass: "operating-cashflow-received-unit"
        },
        {
          matchingName: "operating_cashflow_received",
          matchingColumn: "t-1",
          cellClass: "operating-cashflow-received-t-1"
        },
        {
          matchingName: "operating_cashflow_received",
          matchingColumn: "t+1",
          cellClass: "operating-cashflow-received-t1"
        },
        {
          matchingName: "operating_cashflow_received",
          matchingColumn: "t+2",
          cellClass: "operating-cashflow-received-t2"
        }
      ]
    },
    {
      key: 109, // used for v-for loop
      cells: [
        {
          value: "Capital expenditure paid",
          cellClass: "capital-expenditure-paid-title"
        },
        {
          matchingName: "capital_expenditure_paid",
          matchingColumn: "unit",
          cellClass: "capital-expenditure-paid-unit"
        },
        {
          matchingName: "capital_expenditure_paid",
          matchingColumn: "t-1",
          cellClass: "capital-expenditure-paid-t-1"
        },
        {
          matchingName: "capital_expenditure_paid",
          matchingColumn: "t+1",
          cellClass: "capital-expenditure-paid-t1"
        },
        {
          matchingName: "capital_expenditure_paid",
          matchingColumn: "t+2",
          cellClass: "capital-expenditure-paid-t2"
        }
      ]
    },
    {
      key: 110, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Free cashflow received",
          cellClass: "free-cashflow-received-title"
        },
        {
          matchingName: "free_cashflow_received",
          matchingColumn: "unit",
          cellClass: "free-cashflow-received-unit"
        },
        {
          matchingName: "free_cashflow_received",
          matchingColumn: "t-1",
          cellClass: "free-cashflow-received-t-1"
        },
        {
          matchingName: "free_cashflow_received",
          matchingColumn: "t+1",
          cellClass: "free-cashflow-received-t1"
        },
        {
          matchingName: "free_cashflow_received",
          matchingColumn: "t+2",
          cellClass: "free-cashflow-received-t2"
        }
      ]
    },
    {
      key: 111, // used for v-for loop
      cells: [
        {
          value: "Investments made",
          cellClass: "investments-made-title"
        },
        {
          matchingName: "investments_made",
          matchingColumn: "unit",
          cellClass: "investments-made-unit"
        },
        {
          matchingName: "investments_made",
          matchingColumn: "t-1",
          cellClass: "investments-made-t-1"
        },
        {
          matchingName: "investments_made",
          matchingColumn: "t+1",
          cellClass: "investments-made-t1"
        },
        {
          matchingName: "investments_made",
          matchingColumn: "t+2",
          cellClass: "investments-made-t2"
        }
      ]
    },
    {
      key: 111, // used for v-for loop
      cells: [
        {
          value: "Divestments made",
          cellClass: "divestments-made-title"
        },
        {
          matchingName: "divestments_made",
          matchingColumn: "unit",
          cellClass: "divestments-made-unit"
        },
        {
          matchingName: "divestments_made",
          matchingColumn: "t-1",
          cellClass: "divestments-made-t-1"
        },
        {
          matchingName: "divestments_made",
          matchingColumn: "t+1",
          cellClass: "divestments-made-t1"
        },
        {
          matchingName: "divestments_made",
          matchingColumn: "t+2",
          cellClass: "divestments-made-t2"
        }
      ]
    },
    {
      key: 112, // used for v-for loop
      cells: [
        {
          value: "Other cash items received and paid",
          cellClass: "other-cash-items-received-and-paid-title"
        },
        {
          matchingName: "other_cash_items_received_and_paid",
          matchingColumn: "unit",
          cellClass: "other-cash-items-received-and-paid-unit"
        },
        {
          matchingName: "other_cash_items_received_and_paid",
          matchingColumn: "t-1",
          cellClass: "other-cash-items-received-and-paid-t-2"
        },
        {
          matchingName: "other_cash_items_received_and_paid",
          matchingColumn: "t+1",
          cellClass: "other-cash-items-received-and-paid-t1"
        },
        {
          matchingName: "other_cash_items_received_and_paid",
          matchingColumn: "t+2",
          cellClass: "other-cash-items-received-and-paid-t2"
        }
      ]
    },
    {
      key: 113, // used for v-for loop
      type: "row-bold",
      cells: [
        {
          value: "Distributable free cashflow received",
          cellClass: "distributable-free-cashflow-received-title"
        },
        {
          matchingName: "distributable_free_cashflow_received",
          matchingColumn: "unit",
          cellClass: "distributable-free-cashflow-received-unit"
        },
        {
          matchingName: "distributable_free_cashflow_received",
          matchingColumn: "t-1",
          cellClass: "distributable-free-cashflow-received-t-1"
        },
        {
          matchingName: "distributable_free_cashflow_received",
          matchingColumn: "t+1",
          cellClass: "distributable-free-cashflow-received-t1"
        },
        {
          matchingName: "distributable_free_cashflow_received",
          matchingColumn: "t+2",
          cellClass: "distributable-free-cashflow-received-t2"
        }
      ]
    },
    {
      key: 114, // used for v-for loop
      cells: [
        {
          value: "Dividends paid",
          cellClass: "dividends-paid-title"
        },
        {
          matchingName: "dividends_paid",
          matchingColumn: "unit",
          cellClass: "dividends-paid-unit"
        },
        {
          matchingName: "dividends_paid",
          matchingColumn: "t-1",
          cellClass: "dividends-paid-t-1"
        },
        {
          matchingName: "dividends_paid",
          matchingColumn: "t+1",
          cellClass: "dividends-paid-t1"
        },
        {
          matchingName: "dividends_paid",
          matchingColumn: "t+2",
          cellClass: "dividends-paid-t2"
        }
      ]
    },
    {
      key: 115, // used for v-for loop
      cells: [
        {
          value: "Change in cash and equivalents",
          cellClass: "change-in-cash-and-equivalents-title"
        },
        {
          matchingName: "change_in_cash_and_equivalents",
          matchingColumn: "unit",
          cellClass: "change-in-cash-and-equivalents-unit"
        },
        {
          matchingName: "change_in_cash_and_equivalents",
          matchingColumn: "t-1",
          cellClass: "change-in-cash-and-equivalents-t-1"
        },
        {
          matchingName: "change_in_cash_and_equivalents",
          matchingColumn: "t+1",
          cellClass: "change-in-cash-and-equivalents-t1"
        },
        {
          matchingName: "change_in_cash_and_equivalents",
          matchingColumn: "t+2",
          cellClass: "change-in-cash-and-equivalents-t2"
        }
      ]
    },
    {
      key: 116, // used for v-for loop
      cells: [
        {
          value: "Change in debt and equivalents",
          cellClass: "change-in-debt-and-equivalents-title"
        },
        {
          matchingName: "change_in_debt_and_equivalents",
          matchingColumn: "unit",
          cellClass: "change-in-debt-and-equivalents-unit"
        },
        {
          matchingName: "change_in_debt_and_equivalents",
          matchingColumn: "t-1",
          cellClass: "change-in-debt-and-equivalents-t-1"
        },
        {
          matchingName: "change_in_debt_and_equivalents",
          matchingColumn: "t+1",
          cellClass: "change-in-debt-and-equivalents-t1"
        },
        {
          matchingName: "change_in_debt_and_equivalents",
          matchingColumn: "t+2",
          cellClass: "change-in-debt-and-equivalents-t2"
        }
      ]
    },
    {
      key: 117, // used for v-for loop
      cells: [
        {
          value: "",
          cellClass: "cashflow-statement-empty-cell-1"
        },
        {
          value: "",
          cellClass: "cashflow-statement-empty-cell-2"
        },
        {
          value: "",
          cellClass: "cashflow-statement-empty-cell-3"
        },
        {
          value: "",
          cellClass: "cashflow-statement-empty-cell-4"
        },
        {
          value: "",
          cellClass: "cashflow-statement-empty-cell-5"
        }
      ]
    }
    // {
    //   key: 126, // used for v-for loop
    //   type: "footer",
    //   cells: [
    //     {
    //       colSpan: 5,
    //       value: "Security: {{SecurityName}}"
    //     }
    //   ]
    // }
  ],
  epsRevisions: [
    {
      key: 129, // used for v-for loop
      type: "header",
      cells: [
        {
          colSpan: 4,
          value: "EPS revisions",
          cellClass: "eps-revisions-title"
        }
      ]
    },
    {
      key: 130, // used for v-for loop
      cells: [
        {
          value: "",
          cellClass: "eps-revisions-empty-title"
        },
        {
          value: "New",
          cellClass: "eps-revisions-new-title"
        },
        {
          value: "Prior",
          cellClass: "eps-revisions-prior-title"
        },
        {
          value: "Cons",
          cellClass: "eps-revisions-cons-title"
        }
      ]
    },
    {
      key: 131, // used for v-for loop
      type: "firstCell",
      cells: [
        {
          value: "t+1",
          cellClass: "eps-normalised-t1-title"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+1",
          cellClass: "eps-normalised-t1-new"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+1",
          dataType: "prior",
          cellClass: "eps-normalised-t1-prior"
        },
        {
          value: "",
          cellClass: "eps-normalised-t1-cons"
        }
      ]
    },
    {
      key: 132, // used for v-for loop
      type: "firstCell",
      cells: [
        {
          value: "t+2",
          cellClass: "eps-normalised-t2-title"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+2",
          cellClass: "eps-normalised-t2-new"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+2",
          dataType: "prior",
          cellClass: "eps-normalised-t2-prior"
        },
        {
          value: "",
          cellClass: "eps-normalised-t2-cons"
        }
      ]
    },
    {
      key: 133, // used for v-for loop
      type: "firstCell",
      cells: [
        {
          value: "t+3",
          cellClass: "eps-normalised-t3-title"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+3",
          cellClass: "eps-normalised-t3-new"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+3",
          dataType: "prior",
          cellClass: "eps-normalised-t3-prior"
        },
        {
          value: "",
          cellClass: "eps-normalised-t3-cons"
        }
      ]
    }
  ],
  snapshot: [
    {
      key: 134, // used for v-for loop
      type: "header",
      cells: [
        {
          colSpan: 2,
          value: "Snapshot",
          cellClass: "snapshot-title"
        }
      ]
    },
    {
      key: 135, // used for v-for loop
      cells: [
        {
          value: "Market cap (m)",
          cellClass: "snapshot-market-title"
        },
        {
          value: "",
          cellClass: "snapshot-market-cap"
        }
      ]
    },
    {
      key: 136, // used for v-for loop
      cells: [
        {
          value: "52 week range",
          cellClass: "snapshot-week-range-title"
        },
        {
          value: "",
          cellClass: "snapshot-week-range"
        }
      ]
    },
    {
      key: 137, // used for v-for loop
      cells: [
        {
          value: "Daily volume",
          cellClass: "snapshot-daily-volume-title"
        },
        {
          value: "",
          cellClass: "snapshot-daily-volume"
        }
      ]
    },
    {
      key: 138, // used for v-for loop
      cells: [
        {
          value: "Free float",
          cellClass: "snapshot-free-float-title"
        },
        {
          value: "",
          cellClass: "snapshot-free-float"
        }
      ]
    },
    {
      key: 139, // used for v-for loop
      cells: [
        {
          value: "Franking",
          cellClass: "snapshot-franking-title"
        },
        {
          value: "",
          cellClass: "snapshot-franking"
        }
      ]
    }
  ],
  keyMetrics: [
    {
      key: 140,
      type: "header",
      cells: [
        {
          colSpan: 2,
          value: "Key metrics",
          cellClass: "key-metrics-title"
        }
      ]
    },
    {
      key: 141,
      cells: [
        {
          value: "Financial year-end",
          cellClass: "financial-year-end-title"
        },
        {
          matchingName: "financial_year-end",
          matchingColumn: "t",
          cellClass: "financial-year-end-t"
        }
      ]
    },
    {
      key: 142,
      cells: [
        {
          value: "Current financial year",
          cellClass: "current-financial-year-title"
        },
        {
          matchingName: "current_financial_year",
          matchingColumn: "t",
          cellClass: "current-financial-year-t"
        }
      ]
    },
    {
      key: 143,
      cells: [
        {
          value: "Shares on issue (#)",
          cellClass: "shares-on-issue-title"
        },
        {
          matchingName: "shares_on_issue",
          matchingColumn: "t",
          cellClass: "shares-on-issue-t"
        }
      ]
    },
    {
      key: 144,
      cells: [
        {
          value: "Market capitalisation (A$m)",
          cellClass: "market-capitalisation-title"
        },
        {
          matchingName: "market_capitalisation",
          matchingColumn: "t",
          cellClass: "market-capitalisation-t"
        }
      ]
    },
    {
      key: 145,
      cells: [
        {
          value: "Net debt (A$m)",
          cellClass: "net-debt-title"
        },
        {
          matchingName: "net_debt",
          matchingColumn: "t",
          cellClass: "net-debt-t"
        }
      ]
    },
    {
      key: 145,
      cells: [
        {
          value: "Enterprise value (A$m)",
          cellClass: "enterprise-value-title"
        },
        {
          matchingName: "enterprise_value",
          matchingColumn: "t",
          cellClass: "enterprise-value-t"
        }
      ]
    }
  ],
  keyForecastsAssumptions: [
    {
      key: 146,
      type: "header",
      cells: [
        {
          colSpan: 4,
          value: "Key forecasts and assumptions",
          cellClass: "key-forecasts-assumptions-title"
        }
      ]
    },
    // {
    //   key: 147,
    //   cells: [
    //     {
    //       value: "",
    //       cellClass: "key-forecasts-assumptions-year-title",
    //     },
    //     {
    //       value: "FY22A",
    //       cellClass: "key-forecasts-assumptions-year-FY22A",
    //     },
    //     {
    //       value: "FY23F",
    //       cellClass: "key-forecasts-assumptions-year-FY23F",
    //     },
    //     {
    //       value: "FY24F",
    //       cellClass: "key-forecasts-assumptions-year-FY24F",
    //     },
    //   ],
    // },
    {
      key: 148,
      cells: [
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-title-1"
        },
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-FY22A-1"
        },
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-FY23F-1"
        },
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-FY24F-1"
        }
      ]
    },
    {
      key: 149,
      cells: [
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-title-2"
        },
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-FY22A-2"
        },
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-FY23F-2"
        },
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-FY24F-2"
        }
      ]
    },
    {
      key: 150,
      cells: [
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-title-3"
        },
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-FY22A-3"
        },
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-FY23F-3"
        },
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-FY24F-3"
        }
      ]
    },
    {
      key: 151,
      cells: [
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-title-4"
        },
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-FY22A-4"
        },
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-FY23F-4"
        },
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-FY24F-4"
        }
      ]
    },
    {
      key: 152,
      cells: [
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-title-5"
        },
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-FY22A-5"
        },
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-FY23F-5"
        },
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-FY24F-5"
        }
      ]
    },
    {
      key: 153,
      cells: [
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-title-6"
        },
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-FY22A-6"
        },
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-FY23F-6"
        },
        {
          value: "-",
          cellClass: "key-forecasts-assumptions-year-FY24F-6"
        }
      ]
    }
  ],
  keyFinancials: [
    {
      key: 154,
      type: "header",
      cells: [
        {
          colSpan: 4,
          value: "Key financials",
          cellClass: "key-financials-title"
        }
      ]
    },
    {
      key: 155,
      cells: [
        {
          value: "",
          cellClass: "key-financials-year-title"
        },
        {
          value: "FY22A",
          cellClass: "key-financials-year-FY22A"
        },
        {
          value: "FY23F",
          cellClass: "key-financials-year-FY23F"
        },
        {
          value: "FY24F",
          cellClass: "key-financials-year-FY24F"
        }
      ]
    },
    {
      key: 156,
      cells: [
        {
          value: "Revenue (A$m)",
          cellClass: "key-financials-revenue-title"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t-1",
          cellClass: "key-financials-revenue-FY22A"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t+1",
          cellClass: "key-financials-revenue-FY23F"
        },
        {
          matchingName: "operating_revenues_generated",
          matchingColumn: "t+2",
          cellClass: "key-financials-revenue-FY24F"
        }
      ]
    },
    {
      key: 157,
      cells: [
        {
          value: "Revenue growth",
          cellClass: "key-financials-revenue-growth-title"
        },
        {
          matchingName: "revenue_growth",
          matchingColumn: "t-1",
          cellClass: "key-financials-revenue-growth-FY22A"
        },
        {
          matchingName: "revenue_growth",
          matchingColumn: "t+1",
          cellClass: "key-financials-revenue-growth-FY23F"
        },
        {
          matchingName: "revenue_growth",
          matchingColumn: "t+2",
          cellClass: "key-financials-revenue-growth-FY24F"
        }
      ]
    },
    {
      key: 158,
      type: "row-bold",
      cells: [
        {
          value: "EBITDA (A$m)",
          cellClass: "key-financials-EBITDA-title"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t-1",
          cellClass: "key-financials-EBITDA-FY22A"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t+1",
          cellClass: "key-financials-EBITDA-FY23F"
        },
        {
          matchingName: "operating_ebitda_generated",
          matchingColumn: "t+2",
          cellClass: "key-financials-EBITDA-FY24F"
        }
      ]
    },
    {
      key: 159,
      cells: [
        {
          value: "EBITDA margin",
          cellClass: "key-financials-EBITDA-margin-title"
        },
        {
          matchingName: "ebitda_margin",
          matchingColumn: "t-1",
          cellClass: "key-financials-EBITDA-margin-FY22A"
        },
        {
          matchingName: "ebitda_margin",
          matchingColumn: "t+1",
          cellClass: "key-financials-EBITDA-margin-FY23F"
        },
        {
          matchingName: "ebitda_margin",
          matchingColumn: "t+2",
          cellClass: "key-financials-EBITDA-margin-FY24F"
        }
      ]
    },
    {
      key: 160,
      cells: [
        {
          value: "EV/EBITDA (x)",
          cellClass: "key-financials-EV-EBITDA-title"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t-1",
          cellClass: "key-financials-EV-EBITDA-FY22A"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t+1",
          cellClass: "key-financials-EV-EBITDA-FY23F"
        },
        {
          matchingName: "enterprise_value_to_ebitda",
          matchingColumn: "t+2",
          cellClass: "key-financials-EV-EBITDA-FY24F"
        }
      ]
    },
    {
      key: 161,
      type: "row-bold",
      cells: [
        {
          value: "NPAT (A$m)",
          cellClass: "key-financials-NPAT-title"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t-1",
          cellClass: "key-financials-NPAT-FY22A"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t+1",
          cellClass: "key-financials-NPAT-FY23F"
        },
        {
          matchingName: "adjusted_net_profit_generated_after_tax",
          matchingColumn: "t+2",
          cellClass: "key-financials-NPAT-FY24F"
        }
      ]
    },
    {
      key: 162,
      cells: [
        {
          value: "EPS norm (Ac)",
          cellClass: "key-financials-EPS-norm-title"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t-1",
          cellClass: "key-financials-EPS-norm-FY22A"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+1",
          cellClass: "key-financials-EPS-norm-FY23F"
        },
        {
          matchingName: "eps_normalised",
          matchingColumn: "t+2",
          cellClass: "key-financials-EPS-norm-FY24F"
        }
      ]
    },
    {
      key: 163,
      cells: [
        {
          value: "P/E (x)",
          cellClass: "key-financials-P-E-title"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t-1",
          cellClass: "key-financials-P-E-FY22A"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t+1",
          cellClass: "key-financials-P-E-FY23F"
        },
        {
          matchingName: "price_to_earnings",
          matchingColumn: "t+2",
          cellClass: "key-financials-P-E-FY24F"
        }
      ]
    },
    {
      key: 164,
      cells: [
        {
          value: "Cashflow (A$m)",
          cellClass: "key-financials-cashflow-title"
        },
        {
          matchingName: "operating_cashflow_received",
          matchingColumn: "t-1",
          cellClass: "key-financials-cashflow-FY22A"
        },
        {
          matchingName: "operating_cashflow_received",
          matchingColumn: "t+1",
          cellClass: "key-financials-cashflow-FY23F"
        },
        {
          matchingName: "operating_cashflow_received",
          matchingColumn: "t+2",
          cellClass: "key-financials-cashflow-FY24F"
        }
      ]
    },
    {
      key: 165,
      cells: [
        {
          value: "P/FCF (x)",
          cellClass: "key-financials-P-FCF-title"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t-1",
          cellClass: "key-financials-P-FCF-FY22A"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t+1",
          cellClass: "key-financials-P-FCF-FY23F"
        },
        {
          matchingName: "price_to_free_cashflow",
          matchingColumn: "t+2",
          cellClass: "key-financials-P-FCF-FY24F"
        }
      ]
    },
    {
      key: 166,
      cells: [
        {
          value: "DPS (Ac)",
          cellClass: "key-financials-DPS-title"
        },
        {
          matchingName: "declared_post-tax_dividend_per_share",
          matchingColumn: "t-1",
          cellClass: "key-financials-DPS-FY22A"
        },
        {
          matchingName: "declared_post-tax_dividend_per_share",
          matchingColumn: "t+1",
          cellClass: "key-financials-DPS-FY23F"
        },
        {
          matchingName: "declared_post-tax_dividend_per_share",
          matchingColumn: "t+2",
          cellClass: "key-financials-DPS-FY24F"
        }
      ]
    },
    {
      key: 167,
      cells: [
        {
          value: "Dividend yield (A$m)",
          cellClass: "key-financials-dividend-yield-title"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t-1",
          cellClass: "key-financials-dividend-yield-FY22A"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t+1",
          cellClass: "key-financials-dividend-yield-FY23F"
        },
        {
          matchingName: "post-tax_dividend_yield",
          matchingColumn: "t+2",
          cellClass: "key-financials-dividend-yield-FY24F"
        }
      ]
    },
    {
      key: 168,
      cells: [
        {
          value: "Net debt to capital",
          cellClass: "key-financials-net-debt-to-capital-title"
        },
        {
          matchingName: "net_debt_to_capital",
          matchingColumn: "t-1",
          cellClass: "key-financials-net-debt-to-capital-FY22A"
        },
        {
          matchingName: "net_debt_to_capital",
          matchingColumn: "t+1",
          cellClass: "key-financials-net-debt-to-capital-FY23F"
        },
        {
          matchingName: "net_debt_to_capital",
          matchingColumn: "t+2",
          cellClass: "key-financials-net-debt-to-capital-FY24F"
        }
      ]
    },
    {
      key: 169,
      cells: [
        {
          value: "Interest cover (x)",
          cellClass: "key-financials-interest-cover-title"
        },
        {
          matchingName: "interest_cover",
          matchingColumn: "t-1",
          cellClass: "key-financials-interest-cover-FY22A"
        },
        {
          matchingName: "interest_cover",
          matchingColumn: "t+1",
          cellClass: "key-financials-interest-cover-FY23F"
        },
        {
          matchingName: "interest_cover",
          matchingColumn: "t+2",
          cellClass: "key-financials-interest-cover-FY24F"
        }
      ]
    }
  ],
  emptyTable44: [
    {
      key: 170,
      type: "empty-table",
      rows: 70,
      cols: 9,
      separatorIndex: 5
    }
  ],
  previousReportVersionLinks: [],
  sidebarEmptyTable: [
    {
      key: 171,
      type: "empty-table",
      rows: 32,
      cols: 4
      // separatorIndex: 2,
    }
  ],
  sidebarEmptyTable20: [
    {
      key: 171,
      type: "empty-table",
      rows: 20,
      cols: 2
      // separatorIndex: 2,
    }
  ],
  emptyTable55: [
    {
      key: 172,
      type: "empty-table",
      rows: 70,
      cols: 11,
      separatorIndex: 6
    }
  ],
  emptyTable44ForOxcap: [
    {
      key: 1001,
      type: "empty-table",
      rows: 63,
      cols: 9,
      separatorIndex: 5
    }
  ],
  emptyTable55ForOxcap: [
    {
      key: 1002,
      type: "empty-table",
      rows: 63,
      cols: 11,
      separatorIndex: 6
    }
  ],
  emptyTableForOxcapSidebar: [
    {
      key: 1002,
      type: "empty-table",
      rows: 12,
      cols: 4
    }
  ],
  oxcapSidebarImageContainerFirst: [
    {
      key: 174, // used for v-for loop
      type: "header",
      cells: [
        {
          value: "Title",
          cellClass: "oxcap-sidebar-image-container-title title-first"
        }
      ]
    },
    {
      key: 175, // used for v-for loop
      cells: [
        {
          value: "Drag-n-drop or copy-paste an image (JPEG or PNG format)",
          cellClass: "drag-n-drop oxcap-drag-n-drop img-first"
        }
      ]
    },
    {
      key: 176, // used for v-for loop
      type: "footer",
      cells: [
        {
          value: "Source:",
          cellClass: "oxcap-sidebar-image-container-source source-first"
        }
      ]
    }
  ],
  oxcapSidebarImageContainerSecond: [
    {
      key: 174, // used for v-for loop
      type: "header",
      cells: [
        {
          value: "Title",
          cellClass: "oxcap-sidebar-image-container-title title-second"
        }
      ]
    },
    {
      key: 175, // used for v-for loop
      cells: [
        {
          value: "Drag-n-drop or copy-paste an image (JPEG or PNG format)",
          cellClass: "drag-n-drop oxcap-drag-n-drop img-second"
        }
      ]
    },
    {
      key: 176, // used for v-for loop
      type: "footer",
      cells: [
        {
          value: "Source:",
          cellClass: "oxcap-sidebar-image-container-source source-second"
        }
      ]
    }
  ],
  geniumSidebarEmptyTable: [
    {
      key: 175,
      type: "empty-table",
      rows: 31,
      cols: 2
    }
  ],
  gtnSidebarEmptyTable: [
    {
      key: 275,
      type: "empty-table",
      rows: 6,
      cols: 2
    }
  ],
  insideEmptyTable: [
    {
      key: 173,
      type: "empty-table",
      rows: 24,
      cols: 3,
      isEmptyValue: true
    }
  ],
  sharePrice: [
    {
      key: 174, // used for v-for loop
      type: "header",
      cells: [
        {
          value: "Share Price",
          cellClass: "mst-access-share-price-title"
        }
      ]
    },
    {
      key: 175, // used for v-for loop
      cells: [
        {
          value: "Drag-n-drop or copy-paste an image (JPEG or PNG format)",
          cellClass: "drag-n-drop mst-access-share-price-drag-n-drop"
        }
      ]
    },
    {
      key: 176, // used for v-for loop
      type: "footer",
      cells: [
        {
          value: "Source: {{EntityName}}",
          cellClass: "mst-access-share-price-source"
        }
      ]
    }
  ],
  sharePriceForHovde: [
    {
      key: 174, // used for v-for loop
      type: "header",
      cells: [
        {
          value: "Price Performance",
          cellClass: "hovde-share-price-title"
        }
      ]
    },
    {
      key: 175, // used for v-for loop
      cells: [
        {
          value: "Drag-n-drop or copy-paste an image (JPEG or PNG format)",
          cellClass: "drag-n-drop hovde-share-price-drag-n-drop"
        }
      ]
    }
    // {
    //   key: 176, // used for v-for loop
    //   type: "footer",
    //   cells: [
    //     {
    //       value: "Source: {{EntityName}}",
    //       cellClass: "hovde-share-price-source"
    //     }
    //   ]
    // }
  ],
  sharePriceMstFinancial: [
    {
      key: 174, // used for v-for loop
      type: "header",
      cells: [
        {
          value: "Share Price",
          cellClass: "mst-financial-share-price-title"
        }
      ]
    },
    {
      key: 175, // used for v-for loop
      cells: [
        {
          value: "Drag-n-drop or copy-paste an image (JPEG or PNG format)",
          cellClass: "drag-n-drop mst-financial-share-price-drag-n-drop"
        }
      ]
    },
    {
      key: 176, // used for v-for loop
      type: "footer",
      cells: [
        {
          value: "Source: {{EntityName}}",
          cellClass: "mst-financial-share-price-source"
        }
      ]
    }
  ],
  MAFinancialMarketDataTable: [
    {
      key: 177,
      type: "header",
      cells: [
        {
          colSpan: 2,
          value: "MARKET DATA",
          cellClass: "sidebar-table-title"
        }
      ]
    },
    {
      key: 178,
      cells: [
        {
          value: "Ticker",
          cellClass: "ticker-label"
        },
        {
          value: "{{ headerData.securityCode }}",
          cellClass: "ticker-value"
        }
      ]
    },
    {
      key: 179,
      cells: [
        {
          value: "Market Cap",
          cellClass: "market-cap-label"
        },
        {
          value: "-",
          cellClass: "market-cap-value"
        }
      ]
    },
    {
      key: 180,
      cells: [
        {
          value: "Free Float (%)",
          cellClass: "free-float-label"
        },
        {
          value: "-",
          cellClass: "free-float-value"
        }
      ]
    },
    {
      key: 181,
      cells: [
        {
          value: "Enterprise Value",
          cellClass: "enterprise-value-label"
        },
        {
          value: "-",
          cellClass: "enterprise-value-value"
        }
      ]
    },
    {
      key: 182,
      cells: [
        {
          value: "Shares on issue",
          cellClass: "shares-issue-label"
        },
        {
          value: "-",
          cellClass: "shares-issue-value"
        }
      ]
    },
    {
      key: 183,
      cells: [
        {
          value: "52-wk Range",
          cellClass: "range-label"
        },
        {
          value: "-",
          cellClass: "range-value"
        }
      ]
    }
  ],
  mstAccessAdditionalTable: [
    {
      key: 177,
      type: "header",
      cells: [
        {
          colSpan: 3,
          value: "Additional Resources",
          cellClass: "mst-access-additional-title"
        }
      ]
    },
    {
      key: 178,
      cells: [
        {
          colSpan: 3,
          value: "-",
          cellClass: "mst-access-additional-col-1"
        }
      ]
    },
    {
      key: 179,
      cells: [
        {
          value: "-",
          colSpan: 3,
          cellClass: "mst-access-additional-col-2"
        }
      ]
    },
    {
      key: 180,
      cells: [
        {
          value: "-",
          colSpan: 3,
          cellClass: "mst-access-additional-col-3"
        }
      ]
    }
  ],
  mstAccessEmptyTable: [
    {
      key: 177,
      type: "header",
      cells: [
        {
          colSpan: 3,
          value: "Upcoming Catalysts / Next News",
          cellClass: "mst-access-empty-title"
        }
      ]
    },
    {
      key: 178,
      cells: [
        {
          value: "Period",
          colSpan: 1,
          cellClass: "mst-access-empty-col-1 period"
        },
        {
          value: " ",
          colSpan: 2,
          cellClass: "mst-access-empty-col-2"
        }
      ]
    },
    {
      key: 179,
      cells: [
        {
          value: "-",
          colSpan: 1,
          cellClass: "mst-access-empty-col-3"
        },
        {
          value: "-",
          colSpan: 2,
          cellClass: "mst-access-empty-col-4"
        }
      ]
    },
    {
      key: 180,
      cells: [
        {
          value: "-",
          colSpan: 1,
          cellClass: "mst-access-empty-col-5"
        },
        {
          value: "-",
          colSpan: 2,
          cellClass: "mst-access-empty-col-6"
        }
      ]
    },
    {
      key: 181,
      cells: [
        {
          value: "-",
          colSpan: 1,
          cellClass: "mst-access-empty-col-7"
        },
        {
          value: "-",
          colSpan: 2,
          cellClass: "mst-access-empty-col-8"
        }
      ]
    },
    {
      key: 182,
      cells: [
        {
          value: "-",
          colSpan: 1,
          cellClass: "mst-access-empty-col-9"
        },
        {
          value: "-",
          colSpan: 2,
          cellClass: "mst-access-empty-col-10"
        }
      ]
    },
    {
      key: 183,
      cells: [
        {
          value: "-",
          colSpan: 1,
          cellClass: "mst-access-empty-col-11"
        },
        {
          value: "-",
          colSpan: 2,
          cellClass: "mst-access-empty-col-12"
        }
      ]
    }
  ],
  mstAccessSecurityLogo: [
    {
      key: 183, // used for v-for loop
      cells: [
        {
          value: "Drag-n-drop or copy-paste an image (JPEG or PNG format)",
          cellClass: "drag-n-drop mst-access-security-logo-drag-n-drop"
        }
      ]
    }
  ],
  mstFinancialEmptyTable: [
    {
      key: 177,
      type: "header",
      cells: [
        {
          colSpan: 3,
          value: "Upcoming Catalysts / Next News",
          cellClass: "mst-financial-empty-title"
        }
      ]
    },
    {
      key: 178,
      cells: [
        {
          value: "-",
          colSpan: 1,
          cellClass: "mst-financial-empty-col-1"
        },
        {
          value: "-",
          colSpan: 2,
          cellClass: "mst-financial-empty-col-2"
        }
      ]
    },
    {
      key: 179,
      cells: [
        {
          value: "-",
          colSpan: 1,
          cellClass: "mst-financial-empty-col-3"
        },
        {
          value: "-",
          colSpan: 2,
          cellClass: "mst-financial-empty-col-4"
        }
      ]
    },
    {
      key: 180,
      cells: [
        {
          value: "-",
          colSpan: 1,
          cellClass: "mst-financial-empty-col-5"
        },
        {
          value: "-",
          colSpan: 2,
          cellClass: "mst-financial-empty-col-6"
        }
      ]
    },
    {
      key: 181,
      cells: [
        {
          value: "-",
          colSpan: 1,
          cellClass: "mst-financial-empty-col-7"
        },
        {
          value: "-",
          colSpan: 2,
          cellClass: "mst-financial-empty-col-8"
        }
      ]
    },
    {
      key: 182,
      cells: [
        {
          value: "-",
          colSpan: 1,
          cellClass: "mst-financial-empty-col-9"
        },
        {
          value: "-",
          colSpan: 2,
          cellClass: "mst-financial-empty-col-10"
        }
      ]
    }
  ],
  geniumCoverPageTableLeft: [
    {
      key: 178,
      cells: [
        {
          value: "APIR Code/ASX Ticker",
          colSpan: 1,
          cellClass: "genium-cover-page-left-table-col-1"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-cover-page-left-table-col-2"
        }
      ]
    },
    {
      key: 179,
      cells: [
        {
          value: "Asset Class",
          colSpan: 1,
          cellClass: "genium-cover-page-left-table-col-3"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-cover-page-left-table-col-4"
        }
      ]
    },
    {
      key: 180,
      cells: [
        {
          value: "Sub-Asset Class",
          colSpan: 1,
          cellClass: "genium-cover-page-left-table-col-5"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-cover-page-left-table-col-6"
        }
      ]
    },
    {
      key: 181,
      cells: [
        {
          value: "Manager Benchmark",
          colSpan: 1,
          cellClass: "genium-cover-page-left-table-col-7"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-cover-page-left-table-col-8"
        }
      ]
    }
  ],
  geniumCoverPageTableRight: [
    {
      key: 178,
      cells: [
        {
          value: "Inception Date",
          colSpan: 1,
          cellClass: "genium-cover-page-right-table-col-1"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-cover-page-right-table-col-2"
        }
      ]
    },
    {
      key: 179,
      cells: [
        {
          value: "Strategy FUM (AUD)",
          colSpan: 1,
          cellClass: "genium-cover-page-right-table-col-3"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-cover-page-right-table-col-4"
        }
      ]
    },
    {
      key: 180,
      cells: [
        {
          value: "Fund FUM (AUD)",
          colSpan: 1,
          cellClass: "genium-cover-page-right-table-col-5"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-cover-page-right-table-col-6"
        }
      ]
    },
    {
      key: 181,
      cells: [
        {
          value: "Fee",
          colSpan: 1,
          cellClass: "genium-cover-page-right-table-col-7"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-cover-page-right-table-col-8"
        }
      ]
    }
  ],
  geniumCoverPageSecondTable: [
    {
      key: 178,
      cells: [
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-cover-page-second-table-col-1"
        }
      ]
    },
    {
      key: 179,
      cells: [
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-cover-page-second-table-col-2"
        }
      ]
    },
    {
      key: 180,
      cells: [
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-cover-page-second-table-col-3"
        }
      ]
    },
    {
      key: 181,
      cells: [
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-cover-page-second-table-col-4"
        }
      ]
    },
    {
      key: 181,
      cells: [
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-cover-page-second-table-col-5"
        }
      ]
    }
  ],
  geniumBodyPageSidebarTableTrailing: [
    {
      key: 178,
      type: "header",
      cells: [
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-trailing-table-col-1"
        },
        {
          value: "Fund",
          colSpan: 1,
          cellClass: "genium-sidebar-trailing-table-col-2"
        },
        {
          value: "Manager Benchmark",
          colSpan: 1,
          cellClass: "genium-sidebar-trailing-table-col-3"
        }
      ]
    },
    {
      key: 179,
      cells: [
        {
          value: "1 Year",
          colSpan: 1,
          cellClass: "genium-sidebar-trailing-table-col-4"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-trailing-table-col-5"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-trailing-table-col-6"
        }
      ]
    },
    {
      key: 180,
      cells: [
        {
          value: "3 Years pa",
          colSpan: 1,
          cellClass: "genium-sidebar-trailing-table-col-7"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-trailing-table-col-8"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-trailing-table-col-9"
        }
      ]
    },
    {
      key: 180,
      cells: [
        {
          value: "5 Years pa",
          colSpan: 1,
          cellClass: "genium-sidebar-trailing-table-col-7"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-trailing-table-col-8"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-trailing-table-col-9"
        }
      ]
    },
    {
      key: 181,
      cells: [
        {
          value: "10 Years pa",
          colSpan: 1,
          cellClass: "genium-sidebar-trailing-table-col-10"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-trailing-table-col-11"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-trailing-table-col-12"
        }
      ]
    },
    {
      key: 182,
      type: "footer",
      cells: [
        {
          value: "Source:",
          colSpan: 3,
          cellClass: "genium-sidebar-trailing-source"
        }
      ]
    }
  ],
  geniumBodyPageSidebarTablePerformance: [
    {
      key: 179,
      cells: [
        {
          value: "Up market Success",
          colSpan: 1,
          cellClass: "genium-body-sidebar-perf-table-col-1"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-body-sidebar-perf-table-col-2"
        }
      ]
    },
    {
      key: 179,
      cells: [
        {
          value: "Down Market Success",
          colSpan: 1,
          cellClass: "genium-body-sidebar-perf-table-col-3"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-body-sidebar-perf-table-col-4"
        }
      ]
    },
    {
      key: 180,
      cells: [
        {
          value: "All Markets",
          colSpan: 1,
          cellClass: "genium-body-sidebar-perf-table-col-5"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-body-sidebar-perf-table-col-6"
        }
      ]
    },
    {
      key: 181,
      type: "footer",
      cells: [
        {
          value: "Source:",
          colSpan: 2,
          cellClass: "genium-sidebar-perf-source"
        }
      ]
    }
  ],
  geniumBodyPageSidebarTableRisk: [
    {
      key: 178,
      type: "header",
      cells: [
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-risk-table-col-1"
        },
        {
          value: "Fund",
          colSpan: 1,
          cellClass: "genium-sidebar-risk-table-col-2"
        },
        {
          value: "Manager Benchmark",
          colSpan: 1,
          cellClass: "genium-sidebar-risk-table-col-3"
        }
      ]
    },
    {
      key: 179,
      cells: [
        {
          value: "3yr StdDev",
          colSpan: 1,
          cellClass: "genium-sidebar-risk-table-col-4"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-risk-table-col-5"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-risk-table-col-6"
        }
      ]
    },
    {
      key: 179,
      cells: [
        {
          value: "Max Drawdown",
          colSpan: 1,
          cellClass: "genium-sidebar-risk-table-col-7"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-risk-table-col-8"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-risk-table-col-9"
        }
      ]
    },
    {
      key: 180,
      cells: [
        {
          value: "Bear Mkt Correlation",
          colSpan: 1,
          cellClass: "genium-sidebar-risk-table-col-7"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-risk-table-col-8"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-risk-table-col-9"
        }
      ]
    },
    {
      key: 181,
      type: "footer",
      cells: [
        {
          value: "Source:",
          colSpan: 3,
          cellClass: "genium-sidebar-risk-source"
        }
      ]
    }
  ],
  geniumBodyPagePerformance: [
    {
      key: 178,
      type: "header",
      cells: [
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-body-performance-table-col-1"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-body-performance-table-col-2"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-body-performance-table-col-3"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-body-performance-table-col-4"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-body-performance-table-col-5"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-body-performance-table-col-6"
        }
      ]
    },
    {
      key: 179,
      cells: [
        {
          value: "Fund",
          colSpan: 1,
          cellClass: "genium-body-performance-table-col-7"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-body-performance-table-col-8"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-body-performance-table-col-9"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-body-performance-table-col-10"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-body-performance-table-col-11"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-body-performance-table-col-12"
        }
      ]
    },
    {
      key: 180,
      cells: [
        {
          value: "Manager Benchmark",
          colSpan: 1,
          cellClass: "genium-body-performance-table-col-13"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-body-performance-table-col-14"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-body-performance-table-col-15"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-body-performance-table-col-16"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-body-performance-table-col-17"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-body-performance-table-col-18"
        }
      ]
    },
    {
      key: 181,
      type: "footer",
      cells: [
        {
          value: "Source:",
          colSpan: 6,
          cellClass: "genium-body-performance-source"
        }
      ]
    }
  ],
  geniumBodyPageSidebarTableFunds: [
    {
      key: 178,
      type: "header",
      cells: [
        {
          value: "APIR/ASX codes",
          colSpan: 2,
          cellClass: "genium-sidebar-funds-table-col-1"
        }
      ]
    },
    {
      key: 179,
      cells: [
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-funds-table-col-2"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-funds-table-col-3"
        }
      ]
    },
    {
      key: 180,
      cells: [
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-funds-table-col-4"
        },
        {
          value: "",
          colSpan: 1,
          cellClass: "genium-sidebar-funds-table-col-5"
        }
      ]
    }
  ],
  geniumTopPanelLogo: [
    {
      key: 183,
      cells: [
        {
          value: "Drag-n-drop or copy-paste an image (JPEG or PNG format)",
          cellClass: "drag-n-drop genium-top-logo-drag-n-drop"
        }
      ]
    }
  ],
  geniumSidebarLogo: [
    {
      key: 184,
      cells: [
        {
          value: "Drag-n-drop or copy-paste an image (JPEG or PNG format)",
          cellClass: "drag-n-drop genium-sidebar-logo-drag-n-drop"
        }
      ]
    }
  ]
});
export const getters = {
  isOxcapTemplate: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters["user/currentEntity"].entity_custom_template_prefix &&
      rootGetters["user/currentEntity"].entity_custom_template_prefix ===
        "oxcap_"
    );
  },
  isJardenTemplate: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters["user/currentEntity"].entity_custom_template_prefix &&
      rootGetters["user/currentEntity"].entity_custom_template_prefix ===
        "jarden_"
    );
  },
  isEurozFirstLookTemplate: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters["user/currentEntity"].entity_custom_template_prefix &&
      rootGetters["user/currentEntity"].entity_custom_template_prefix ===
        "euroz_hartley_" &&
      rootState.change.currentChangeDetail.change_report_template_settings &&
      rootState.change.currentChangeDetail.change_report_template_settings[
        "front page"
      ] === "first_look_template"
    );
  },
  isEurozTemplate: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters["user/currentEntity"].entity_custom_template_prefix &&
      rootGetters["user/currentEntity"].entity_custom_template_prefix ===
        "euroz_hartley_"
    );
  },
  isRosenblattTemplate: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters["user/currentEntity"].entity_custom_template_prefix &&
      rootGetters["user/currentEntity"].entity_custom_template_prefix ===
        "rosenblatt_"
    );
  },
  isMstTemplate: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters["user/currentEntity"].entity_custom_template_prefix &&
      rootGetters["user/currentEntity"].entity_custom_template_prefix === "mst_"
    );
  },
  isMstAccessTemplate: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters["user/currentEntity"].entity_custom_template_prefix &&
      rootGetters["user/currentEntity"].entity_custom_template_prefix ===
        "mst_access_"
    );
  },
  isHovdeTemplate: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters["user/currentEntity"].entity_custom_template_prefix &&
      rootGetters["user/currentEntity"].entity_custom_template_prefix ===
        "hovde_"
    );
  },
  isMstFinancialTemplate: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters["user/currentEntity"].entity_custom_template_prefix &&
      rootGetters["user/currentEntity"].entity_custom_template_prefix ===
        "mst_financial_"
    );
  },
  isGeniumTemplate: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters["user/currentEntity"].entity_custom_template_prefix &&
      rootGetters["user/currentEntity"].entity_custom_template_prefix ===
        "genium_"
    );
  },
  isSbgTemplate: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters["user/currentEntity"].entity_custom_template_prefix &&
      rootGetters["user/currentEntity"].entity_custom_template_prefix === "sbg_"
    );
  },
  isEmailTemplate: (state, getters, rootState, rootGetters) => {
    return (
      rootState.change.currentChangeDetail.change_report_template_settings &&
      [
        "email_template",
        "email_morningnote_template",
        "email_weekly_informer_template",
        "email_market_announcement_template",
        "email_forsythbarr_template"
      ].includes(
        rootState.change.currentChangeDetail.change_report_template_settings[
          "front page"
        ]
      )
    );
  },
  isBlankTemplate: (state, getters, rootState, rootGetters) => {
    return (
      rootState.change.currentChangeDetail.change_report_template_settings &&
      ["blank_template"].includes(
        rootState.change.currentChangeDetail.change_report_template_settings[
          "front page"
        ]
      )
    );
  },
  isEurozMorningNoteTemplate: (state, getters, rootState) => {
    return (
      rootState.change.currentChangeDetail.change_report_template_settings &&
      rootState.change.currentChangeDetail.change_report_template_settings[
        "front page"
      ] === "email_morningnote_template"
    );
  },
  isMstAccessForsythbarrTemplate: (state, getters, rootState) => {
    return (
      rootState.change.currentChangeDetail.change_report_template_settings &&
      rootState.change.currentChangeDetail.change_report_template_settings[
        "front page"
      ] === "email_forsythbarr_template"
    );
  },
  isMstAccessMarketTemplate: (state, getters, rootState) => {
    return (
      rootState.change.currentChangeDetail.change_report_template_settings &&
      rootState.change.currentChangeDetail.change_report_template_settings[
        "front page"
      ] === "email_market_announcement_template"
    );
  },
  isEurozWeeklyInformerTemplate: (state, getters, rootState) => {
    return (
      rootState.change.currentChangeDetail.change_report_template_settings &&
      rootState.change.currentChangeDetail.change_report_template_settings[
        "front page"
      ] === "email_weekly_informer_template"
    );
  },
  isGTNTemplate: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters["user/currentEntity"].entity_custom_template_prefix &&
      rootGetters["user/currentEntity"].entity_custom_template_prefix === "gtn_"
    );
  },
  isMAFinancialTemplate: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters["user/currentEntity"].entity_custom_template_prefix &&
      rootGetters["user/currentEntity"].entity_custom_template_prefix ===
        "ma_financial_"
    );
  },
  getChangeData: (state, getters, rootState, rootGetters) => {
    let changeReason = "";
    if (getters.isJardenTemplate) {
      if (
        rootState.change.currentChangeDetail.change_reason_ids &&
        rootState.change.currentChangeDetail.change_reason_ids.length > 0
      )
        changeReason =
          rootState.change.currentChangeDetail.change_reason_ids[0]
            .change_reason_name;
    } else {
      if (
        rootState.change.currentChangeDetail.change_reason_ids &&
        rootState.change.currentChangeDetail.change_reason_ids.length > 0
      ) {
        const reasonNames =
          rootState.change.currentChangeDetail.change_reason_ids.map(
            (reason) => {
              return reason.change_reason_name;
            }
          );
        changeReason = reasonNames.join(" - ");
      }
    }
    const publishDate = rootGetters["change/getReportAnalystTime"]();
    return {
      reportLogo: rootState.user.currentEntityDetail.report_logo
        ? rootState.user.currentEntityDetail.report_logo
        : "",
      entityName: rootState.user.currentEntityDetail.entity_name
        ? rootState.user.currentEntityDetail.entity_name
        : "-",
      publishDate: publishDate,
      // rootState.change.currentChangeReport.security_report_updated_at ?
      // rootGetters['app_store/getUtcToTzTime'](rootState.change.currentChangeReport.security_report_updated_at, "DD MMMM yyyy (HH:mm z)", rootState.app_store.API_DATE_FORMAT) :
      // moment().tz(rootGetters['app_store/getCurrentTimeZone']).format("Do MMMM yyyy (HH:mm z)"),
      changeReason: changeReason,
      changeTitle: rootState.change.currentChangeDetail.change_title
        ? rootState.change.currentChangeDetail.change_title
        : "-"
    };
  },
  getKeyFinancialMetricsMultipleSecurities: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    return (modelSheetData) => {
      let returnArray = clone(state.keyFinancialMetricsMultiple);
      if (getters.isJardenTemplate) {
        returnArray = clone(state.jardenKeyFinancialMetricsMultiple);
      }
      if (getters.isMAFinancialTemplate) {
        returnArray = clone(state.maFinancialKeyFinancialMetricsMultiple);
      }
      let currentCount = returnArray[0].key;
      const projectedReturnId = rootGetters[
        "cdbSheet/cdbMetricsNameDictionary"
      ]["total_investment_return"]
        ? rootGetters["cdbSheet/cdbMetricsNameDictionary"][
            "total_investment_return"
          ].metrics_id
        : null;
      const priceToEarningId = rootGetters["cdbSheet/cdbMetricsNameDictionary"][
        "price_to_earnings"
      ]
        ? rootGetters["cdbSheet/cdbMetricsNameDictionary"]["price_to_earnings"]
            .metrics_id
        : null;
      const epsGrowthId = rootGetters["cdbSheet/cdbMetricsNameDictionary"][
        "eps_growth"
      ]
        ? rootGetters["cdbSheet/cdbMetricsNameDictionary"]["eps_growth"]
            .metrics_id
        : null;
      const dividendYieldId = rootGetters["cdbSheet/cdbMetricsNameDictionary"][
        "post-tax_dividend_yield"
      ]
        ? rootGetters["cdbSheet/cdbMetricsNameDictionary"][
            "post-tax_dividend_yield"
          ].metrics_id
        : null;
      const dpsDeclaredId = rootGetters["cdbSheet/cdbMetricsNameDictionary"][
        "declared_post-tax_dividend_per_share"
      ]
        ? rootGetters["cdbSheet/cdbMetricsNameDictionary"][
            "declared_post-tax_dividend_per_share"
          ].metrics_id
        : null;
      for (let i = 0; i < modelSheetData.length; i++) {
        const rowNo = i + 1;
        const securityMetadata = getters.getNewOrCurrentSecurityMetadata(
          modelSheetData[i]
        );
        currentCount++;
        returnArray[rowNo] = {
          key: currentCount,
          cells: []
        };
        //name
        returnArray[rowNo].cells.push({
          value: modelSheetData[i].security_name,
          cellClass: `security-${i}-name`
        });
        //recommendation
        returnArray[rowNo].cells.push({
          value: modelSheetData[i].change_security_recommendation
            ? modelSheetData[i].change_security_recommendation
            : "-",
          cellClass: `security-${i}-rating`
        });
        //price
        returnArray[rowNo].cells.push({
          value: modelSheetData[i].change_security_publication_price
            ? rootGetters["app_store/numberDecimal"](
                modelSheetData[i].change_security_publication_price,
                modelSheetData[i].price_decimal_places
              )
            : "-",
          cellClass: `security-${i}-price`
        });
        //target price
        returnArray[rowNo].cells.push({
          value: modelSheetData[i].change_security_targetprice
            ? rootGetters["app_store/numberDecimal"](
                modelSheetData[i].change_security_targetprice,
                modelSheetData[i].price_decimal_places
              )
            : "-",
          cellClass: `security-${i}-target-price`
        });
        let projectReturnObj,
          prceToEarningObj,
          epsGrowthObj,
          dividendYieldObj,
          dpsDeclaredObj;
        if (securityMetadata) {
          projectReturnObj = securityMetadata.find(
            (tmpR) => tmpR.metrics_id === projectedReturnId
          );
          prceToEarningObj = securityMetadata.find(
            (tmpR) => tmpR.metrics_id === priceToEarningId
          );
          epsGrowthObj = securityMetadata.find(
            (tmpR) => tmpR.metrics_id === epsGrowthId
          );
          dividendYieldObj = securityMetadata.find(
            (tmpR) => tmpR.metrics_id === dividendYieldId
          );
          dpsDeclaredObj = securityMetadata.find(
            (tmpR) => tmpR.metrics_id === dpsDeclaredId
          );
        }
        //projected return
        returnArray[rowNo].cells.push({
          value: projectReturnObj
            ? rootGetters["app_store/objectNestedPropertyCheck"](
                projectReturnObj,
                ["op_sheet_row_values", "t"],
                "-"
              )
            : "-",
          cellClass: `security-${i}-projected-return`
        });
        //Price to earnings
        if (!getters.isMAFinancialTemplate) {
          returnArray[rowNo].cells.push({
            value: projectReturnObj
              ? rootGetters["app_store/objectNestedPropertyCheck"](
                  prceToEarningObj,
                  ["op_sheet_row_values", "t+1"],
                  "-"
                )
              : "-",
            cellClass: `security-${i}-price-to-earnings`
          });
        }
        //eps growth
        // returnArray[rowNo].cells.push({
        //     value: epsGrowthObj ?
        //         rootGetters["app_store/objectNestedPropertyCheck"](
        //             epsGrowthObj,
        //             ["op_sheet_row_values", "t+1"],
        //             "-"
        //         ) : "-",
        // });
        //dividend yield
        if (!getters.isMAFinancialTemplate) {
          returnArray[rowNo].cells.push({
            value: dividendYieldObj
              ? rootGetters["app_store/objectNestedPropertyCheck"](
                  dividendYieldObj,
                  ["op_sheet_row_values", "t+1"],
                  "-"
                )
              : "-",
            cellClass: `security-${i}-dividend-yield`
          });
        }
        //DPS declared
        // returnArray[rowNo].cells.push({
        //     value: dpsDeclaredObj ?
        //         rootGetters["app_store/objectNestedPropertyCheck"](
        //             dpsDeclaredObj,
        //             ["op_sheet_row_values", "t+1"],
        //             "-"
        //         ) : "-",
        // });
      }
      const colCount = getters.isMAFinancialTemplate ? 4 : 7;
      currentCount++;
      returnArray[returnArray.length] = {
        key: currentCount,
        type: "footer",
        cells: [
          {
            colSpan: colCount,
            value: `Source: ${
              rootState.user.currentEntityDetail.entity_name || ""
            }`
          }
        ]
      };
      return returnArray;
    };
  },
  getSecurityData: (state, getters, rootState, rootGetters) => {
    return (securityObj) => {
      if (!securityObj) securityObj = {};
      const totalInvestmentReturnId = rootGetters[
        "cdbSheet/cdbMetricsNameDictionary"
      ]["total_investment_return"]
        ? rootGetters["cdbSheet/cdbMetricsNameDictionary"][
            "total_investment_return"
          ].metrics_id
        : null;
      const capitalId = rootGetters["cdbSheet/cdbMetricsNameDictionary"][
        "capital_return"
      ]
        ? rootGetters["cdbSheet/cdbMetricsNameDictionary"]["capital_return"]
            .metrics_id
        : null;
      const dividendReturnId = rootGetters["cdbSheet/cdbMetricsNameDictionary"][
        "dividend_return"
      ]
        ? rootGetters["cdbSheet/cdbMetricsNameDictionary"]["dividend_return"]
            .metrics_id
        : null;
      const securityMetadata = securityObj
        ? getters.getNewOrCurrentSecurityMetadata(securityObj)
        : {};
      return {
        securityCode: securityObj.security_code || "-",
        securityName: securityObj.security_name || "-",
        currency: securityObj.change_security_current_currency || "",
        recommendation:
          securityObj.change_security_current_recommendation || "-",
        recommendationLink:
          securityObj.change_security_recommendation_icon_cdn_link || "",
        esgScore: securityObj.change_security_esg_text || "-",
        esgScoreImg: securityObj.change_security_esg_icon || "-",
        esgScoreLink: securityObj.change_security_esg_icon_cdn_link || "",
        currentPrice: securityObj.change_security_current_price
          ? securityObj.change_security_current_price.toLocaleString(
              undefined,
              {
                minimumFractionDigits: securityObj.price_decimal_places,
                maximumFractionDigits: securityObj.price_decimal_places
              }
            )
          : "-",
        price:
          securityObj.change_security_publication_price &&
          !isNaN(securityObj.change_security_publication_price)
            ? securityObj.change_security_publication_price.toLocaleString(
                undefined,
                {
                  minimumFractionDigits: securityObj.price_decimal_places,
                  maximumFractionDigits: securityObj.price_decimal_places
                }
              )
            : "-",
        targetPrice:
          securityObj.change_security_current_targetprice &&
          !isNaN(securityObj.change_security_current_targetprice)
            ? securityObj.change_security_current_targetprice.toLocaleString(
                undefined,
                {
                  minimumFractionDigits: securityObj.price_decimal_places,
                  maximumFractionDigits: securityObj.price_decimal_places
                }
              )
            : "-",
        newTargetPrice:
          securityObj.change_security_targetprice &&
          !isNaN(securityObj.change_security_targetprice)
            ? securityObj.change_security_targetprice.toLocaleString(
                undefined,
                {
                  minimumFractionDigits: securityObj.price_decimal_places,
                  maximumFractionDigits: securityObj.price_decimal_places
                }
              )
            : "-",
        newRecommendation: securityObj.change_security_recommendation || "-",
        exchange: securityObj.exchange_name || "-",
        securityOverview:
          securityObj.security_overview_content ||
          '<div class="free-text-field">\n<p class="placeholder" data-placeholder="Add content, images and tables to this free text field."><br /></p>\n</div>',
        sectorName: securityObj.sector_name || "-",
        subSectorName: securityObj.sub_sector_name || "-",
        totalInvestmentReturn:
          securityMetadata && totalInvestmentReturnId
            ? securityMetadata.find(
                (el) => el.metrics_id == totalInvestmentReturnId
              ).op_sheet_row_values.t
            : "-",
        capitalReturn:
          securityMetadata && capitalId
            ? securityMetadata.find((el) => el.metrics_id == capitalId)
                .op_sheet_row_values.t
            : "-",
        dividendReturn:
          securityMetadata && dividendReturnId
            ? securityMetadata.find((el) => el.metrics_id == dividendReturnId)
                .op_sheet_row_values.t
            : "-",
        priceDecimalPlaces: securityObj.price_decimal_places || 2
      };
    };
  },
  selectAnalysts: (state, getters, rootState) => {
    let selectedAnalysts = [];
    // if (rootState.change.currentChangeDetail.change_analysts) {
    //     for (const member of rootState.change.currentChangeDetail.change_analysts) {
    //         let findAnalyst = rootState.member.analystsList.find(
    //             (analyst) => analyst.value.id == member.member_id
    //         );
    //         if (findAnalyst) selectedAnalysts.push(findAnalyst.value);
    //     }
    // }
    if (rootState.change.currentChangeDetail.change_analysts)
      selectedAnalysts = rootState.change.currentChangeDetail.change_analysts;
    return selectedAnalysts;
  },
  securityAnalysts: (state, getters, rootState) => {
    return (securityId) => {
      let selectedAnalysts = [];
      const findSecurity = rootState.change.linkedSecurities.find(
        (security) => security.security_id == securityId
      );
      if (findSecurity && findSecurity.security_analysts)
        selectedAnalysts = findSecurity.security_analysts;
      return selectedAnalysts;
    };
  },
  getReportTableData: (state, getters, rootState, rootGetters) => {
    return (securityObj, currentTableStructure) => {
      const newMetrics = clone(currentTableStructure);
      const hasR = getters.getNewOrCurrentSecurityMetadata(securityObj);
      const currentCdb = rootGetters["app_store/objectNestedPropertyCheck"](
        securityObj,
        ["change_security_current_cdb", "model_sheet_metadata"],
        null
      );
      const newHeaderDictionary =
        rootGetters["app_store/objectNestedPropertyCheck"](
          securityObj,
          ["change_security_new_cdb", "fy_options"],
          null
        ) ||
        rootGetters["app_store/objectNestedPropertyCheck"](
          securityObj,
          ["change_security_current_cdb", "fy_options"],
          null
        );
      // if (!hasR) return newMetrics;
      const replaceDictionary = {
        "{{EntityName}}": rootState.user.currentEntityDetail.entity_name || "",
        "{{SecurityName}}": securityObj.security_name || ""
      };
      var prior_rollover_financial_year_start = 0;
      var prior_index = 0;
      for (let i = 0; i < newMetrics.length; i++) {
        for (let j = 0; j < newMetrics[i].cells.length; j++) {
          if (
            newMetrics[i].cells[j].matchingName &&
            newMetrics[i].cells[j].matchingColumn
          ) {
            if (!newMetrics[i].cells[j].value)
              newMetrics[i].cells[j].value = "-";
            if (hasR) {
              const metricObj =
                rootGetters["cdbSheet/cdbMetricsNameDictionary"][
                  newMetrics[i].cells[j].matchingName
                ];
              if (metricObj) {
                let resultObj = hasR.find(
                  (tmpCurrentMetric) =>
                    tmpCurrentMetric.metrics_id === metricObj.metrics_id
                );
                if (newMetrics[i].cells[j].dataType == "prior") {
                  if (
                    securityObj.rollover_financial_years &&
                    securityObj.rollover_financial_years.length > 0 &&
                    securityObj.rollover_financial_years[0]
                  ) {
                    prior_rollover_financial_year_start = Number(
                      securityObj.rollover_financial_years[0].split("+")[1]
                    );
                  }

                  if (prior_rollover_financial_year_start > 0) {
                    newMetrics[i].cells[j].matchingColumn =
                      "t+" +
                      (prior_rollover_financial_year_start + prior_index);
                    prior_index++;
                  }

                  if (currentCdb) {
                    resultObj = currentCdb.find(
                      (tmpCurrentMetric) =>
                        tmpCurrentMetric.metrics_id === metricObj.metrics_id
                    );
                  } else {
                    newMetrics[i].cells[j].value = "-";
                    break;
                  }
                }
                if (
                  resultObj &&
                  resultObj.op_sheet_row_values &&
                  resultObj.op_sheet_row_values[
                    newMetrics[i].cells[j].matchingColumn
                  ]
                ) {
                  if (newMetrics[i].cells[j].value && j == 0) {
                    //for Jarden front page table
                    newMetrics[i].cells[j].value +=
                      " (" +
                      resultObj.op_sheet_row_values[
                        newMetrics[i].cells[j].matchingColumn
                      ] +
                      ")";
                  } else {
                    if (
                      (metricObj.metrics_name === "Price" ||
                        metricObj.metrics_name === "Target price") &&
                      newMetrics[i].cells[j].matchingColumn !== "unit" &&
                      !isNaN(
                        parseFloat(
                          resultObj.op_sheet_row_values[
                            newMetrics[i].cells[j].matchingColumn
                          ]
                        )
                      )
                    ) {
                      newMetrics[i].cells[j].value = rootGetters[
                        "app_store/numberDecimal"
                      ](
                        parseFloat(
                          resultObj.op_sheet_row_values[
                            newMetrics[i].cells[j].matchingColumn
                          ]
                        ),
                        securityObj.price_decimal_places
                      );
                    } else {
                      newMetrics[i].cells[j].value =
                        resultObj.op_sheet_row_values[
                          newMetrics[i].cells[j].matchingColumn
                        ];
                    }
                  }
                }
              }
            }
          }
          if (newMetrics[i].type === "footer") {
            const re = new RegExp(
              Object.keys(replaceDictionary).join("|"),
              "gi"
            );
            newMetrics[i].cells[j].value = newMetrics[i].cells[j].value.replace(
              re,
              (x) => {
                return replaceDictionary[x];
              }
            );
          }
          if (
            newMetrics[i].type === "header" &&
            newHeaderDictionary &&
            newHeaderDictionary[newMetrics[i].cells[j].value]
          ) {
            newMetrics[i].cells[j].value =
              newHeaderDictionary[newMetrics[i].cells[j].value];
          }
          if (
            newMetrics[i].type === "firstCell" &&
            j == 0 &&
            newHeaderDictionary &&
            newHeaderDictionary[newMetrics[i].cells[j].value]
          ) {
            newMetrics[i].cells[j].value =
              newHeaderDictionary[newMetrics[i].cells[j].value];
          }
        }
      }
      return newMetrics;
    };
  },
  getNewOrCurrentSecurityMetadata: (state, getters, rootState, rootGetters) => {
    return (securityObj) =>
      rootGetters["app_store/objectNestedPropertyCheck"](
        securityObj,
        ["change_security_new_cdb", "model_sheet_metadata"],
        null
      ) ||
      rootGetters["app_store/objectNestedPropertyCheck"](
        securityObj,
        ["change_security_current_cdb", "model_sheet_metadata"],
        null
      );
  },
  templateType: () => {
    return (modelSheetData) =>
      modelSheetData.length > 1 ? "multiple" : "single";
  },
  getPersonalDisclosureType: (state, getters, rootState) => {
    let personalReportType = "";
    if (
      rootState.change.currentChangeDetail.change_report_template_settings &&
      rootState.change.currentChangeDetail.change_report_template_settings[
        state.OTHER_DISCLAIMER
      ]
    ) {
      if (
        rootState.change.currentChangeDetail.change_report_template_settings[
          state.OTHER_DISCLAIMER
        ].includes("personal-terms-show")
      )
        personalReportType = "analyst";
      else if (
        rootState.change.currentChangeDetail.change_report_template_settings[
          state.OTHER_DISCLAIMER
        ].includes("personal-terms-special-show")
      )
        personalReportType = "analyst_special";
    }
    return personalReportType;
  },
  getPersonalDisclosures: (state, getters, rootState, rootGetters) => {
    return (disclosureType = "") => {
      const foundPersonalTerms = [];
      const type = disclosureType
        ? disclosureType
        : getters.getPersonalDisclosureType;
      if (rootGetters["user/getEntityLegals"].length > 0 && type) {
        const personalTerms = rootGetters["user/getEntityLegals"].find(
          (legal) => legal.key === type
        );
        if (personalTerms && type === "analyst_special") {
          const decodeHtml = document.createElement("textarea");
          decodeHtml.innerHTML = personalTerms.entity_legal_desc;
          if (decodeHtml.value !== "undefined")
            foundPersonalTerms.push(decodeHtml.value);
        } else if (
          type === "analyst" &&
          personalTerms &&
          personalTerms.entity_legal_metadata &&
          personalTerms.entity_legal_metadata.data &&
          personalTerms.entity_legal_metadata.data.length > 0
        ) {
          for (
            let i = 0;
            i < personalTerms.entity_legal_metadata.data.length;
            i++
          ) {
            const personalTerm = personalTerms.entity_legal_metadata.data[i];
            if (personalTerm.member_ids && personalTerm.member_ids.length > 0) {
              const foundMember = [];
              for (let j = 0; j < personalTerm.member_ids.length; j++) {
                const memberId = personalTerm.member_ids[j];
                const foundAnalyst = getters.selectAnalysts.find(
                  (Analyst) => Analyst.member_id == memberId
                );
                if (foundAnalyst) {
                  foundMember.push(foundAnalyst);
                  break;
                }
              }
              if (foundMember.length > 0) foundPersonalTerms.push(personalTerm);
            } else foundPersonalTerms.push(personalTerm);
          }
        }
      }
      return foundPersonalTerms;
    };
  },
  /*
    getCompanyDisclosures: (state, getters, rootState) => {
        return (modelSheetData) => {
            let securityName = "";
            if (rootState.security.securityList.length > 0 && modelSheetData.length > 0) {
                let securityData = rootState.security.securityList.find(
                    (tmpSec) => tmpSec.security_id == modelSheetData[0].security_id
                );
                if (securityData) {
                    securityName = securityData && securityData.security_name ? securityData.security_name : "";
                }
            }
            return securityName;
        };
    },
    */
  getFooterDisclaimer: (state, getters, rootState, rootGetters) => {
    return (page) => {
      if (rootGetters["user/getEntityLegals"].length > 0) {
        let footerTerms = rootGetters["user/getEntityLegals"].find(
          (legal) => legal.key == "standard_report"
        );
        if (
          rootState.change.currentChangeDetail &&
          rootState.change.currentChangeDetail
            .change_report_template_settings &&
          rootState.change.currentChangeDetail.change_report_template_settings[
            state.DISCLAIMER
          ] === "special"
        ) {
          footerTerms = rootGetters["user/getEntityLegals"].find(
            (legal) => legal.key == "special_report"
          );
        }
        if (footerTerms && footerTerms.entity_legal_metadata) {
          if (page == "cover") {
            if (footerTerms.entity_legal_metadata.standard_report_cover)
              return getters.getDecodedHtml(
                footerTerms.entity_legal_metadata.standard_report_cover
              );
            if (footerTerms.entity_legal_metadata.special_report_cover)
              return getters.getDecodedHtml(
                footerTerms.entity_legal_metadata.special_report_cover
              );
            else return " ";
          } else if (page == "body") {
            if (footerTerms.entity_legal_metadata.standard_report_footer)
              return getters.getDecodedHtml(
                footerTerms.entity_legal_metadata.standard_report_footer
              );
            if (footerTerms.entity_legal_metadata.special_report_footer)
              return getters.getDecodedHtml(
                footerTerms.entity_legal_metadata.special_report_footer
              );
            else return " ";
          }
        } else {
          return " ";
        }
      }
    };
  },
  getDecodedHtml: (state, getters) => {
    return (encodedHtml) => {
      if (!encodedHtml) return " ";
      var decodeHtml = document.createElement("textarea");
      decodeHtml.innerHTML = encodedHtml;
      return decodeHtml.value || " ";
    };
  },
  getFreeFieldTextInnerHtml: (state, getters) => {
    return (html) => {
      if (!html) return "";
      var container = document.createElement("div");
      container.innerHTML = html;
      if (container.querySelector(".free-text-field")) {
        return container.querySelector(".free-text-field").innerHTML || "";
      } else return html;
    };
  },
  getAdditionalDisclosureType: (state, getters, rootState) => {
    let additionalReportType = "additional_report";
    if (
      rootState.change.currentChangeDetail.change_report_template_settings &&
      rootState.change.currentChangeDetail.change_report_template_settings[
        state.OTHER_DISCLAIMER
      ] &&
      rootState.change.currentChangeDetail.change_report_template_settings[
        state.OTHER_DISCLAIMER
      ].includes("additional-terms-special-show")
    ) {
      additionalReportType = "additional_report_special";
    }
    return additionalReportType;
  },
  getAdditionalDisclosures: (state, getters, rootState, rootGetters) => {
    const foundAdditionalTerms = [];
    const type = getters.getAdditionalDisclosureType;
    if (rootGetters["user/getEntityLegals"].length > 0 && type) {
      const additionalTerms = rootGetters["user/getEntityLegals"].find(
        (legal) => legal.key === type
      );
      if (additionalTerms && type === "additional_report_special") {
        const decodeHtml = document.createElement("textarea");
        decodeHtml.innerHTML = additionalTerms.entity_legal_desc;
        if (decodeHtml.value !== "undefined")
          foundAdditionalTerms.push(decodeHtml.value);
      } else if (
        type === "additional_report" &&
        additionalTerms &&
        additionalTerms.entity_legal_metadata &&
        additionalTerms.entity_legal_metadata &&
        additionalTerms.entity_legal_metadata.data.length > 0
      ) {
        for (
          let i = 0;
          i < additionalTerms.entity_legal_metadata.data.length;
          i++
        ) {
          const additionalTerm = additionalTerms.entity_legal_metadata.data[i];
          // let additionalTermText = getters.getFreeFieldTextInnerHtml(getters.getDecodedHtml(additionalTerm.text));
          const foundSecurities = [];
          for (let i = 0; i < rootState.change.linkedSecurities.length; i++) {
            const security = rootState.change.linkedSecurities[i];
            const foundSecurity = additionalTerm.security_ids.find(
              (id) => id == security.security_id
            );
            if (foundSecurity) {
              foundSecurities.push(security.security_code);
              break;
            }
          }
          if (foundSecurities.length > 0)
            foundAdditionalTerms.push(additionalTerm);
        }
      }
    }
    return foundAdditionalTerms;
  },
  showPage: (state, getters, rootState, rootGetters) => {
    return (pageKey, pageId, defaultValue) => {
      const objectToCheck =
        rootState.change.currentChangeDetail.change_report_template_settings ||
        getters.defaultTemplateSettings(rootState.change.linkedSecurities);
      // if (rootGetters["app_store/objectNestedPropertyCheck"](objectToCheck, ['front page'], false) === 'none') return false; // dont dhow anything if none is selected - this is change 16/11/20
      const exist = rootGetters["app_store/objectNestedPropertyCheck"](
        objectToCheck,
        [pageKey],
        false
      );
      if (!exist) return defaultValue;
      return exist.includes(pageId);
    };
  },
  getFirstPageHeaderData: (state, getters) => {
    return (modelSheetData) =>
      modelSheetData.length === 1
        ? {
            ...getters.getChangeData,
            ...getters.getSecurityData(modelSheetData[0]),
            ...getters.getDisplaySecurityInfo(modelSheetData),
            ...getters.getDisplaySecurityNames(modelSheetData)
          }
        : {
            ...getters.getChangeData,
            ...getters.getDisplaySecurityInfo(modelSheetData),
            ...getters.getDisplaySecurityNames(modelSheetData)
          };
  },
  getHeaderData: (state, getters, rootState, rootGetters) => {
    return (modelSheetData) => ({
      ...getters.getChangeData,
      templateType: getters.templateType(modelSheetData),
      ...getters.getSecurityData(modelSheetData[0])
    });
  },
  getHeaderDataForOxcap: (state, getters, rootState, rootGetters) => {
    return (modelSheetData) => ({
      ...getters.getChangeData,
      templateType: getters.templateType(modelSheetData),
      ...getters.getSecurityData(modelSheetData[0]),
      ...getters.getSecurityInfoForOxcap(modelSheetData)
    });
  },
  defaultTemplateSettings: (state, getters, rootState, rootGetters) => {
    return (modelSheetData) => {
      const returnObj = {};
      returnObj[state.FRONT_PG_KEY] =
        modelSheetData.length > 1
          ? "multiple_security_template"
          : "single_security_template";

      for (let i = 0; i < modelSheetData.length; i++) {
        if (getters.isEurozTemplate)
          returnObj[modelSheetData[i].security_id] = "financial_template_44";
        else if (
          getters.isMstAccessTemplate ||
          getters.isMstFinancialTemplate ||
          getters.isGeniumTemplate ||
          getters.isOxcapTemplate ||
          getters.isMAFinancialTemplate ||
          getters.isHovdeTemplate
        )
          returnObj[modelSheetData[i].security_id] = "";
        else returnObj[modelSheetData[i].security_id] = "financial_template_53";
      }
      returnObj[state.DISCLAIMER] = "standard";
      return returnObj;
    };
  },
  recursiveRemoveHtmlNodes: (state, getters) => {
    return (tmpEle, acceptTags) => {
      if (tmpEle.children.length) {
        for (let i = 0; i < tmpEle.children.length; i++) {
          const tmpChildNode = tmpEle.children[i];
          if (!acceptTags.includes(tmpChildNode.tagName)) {
            tmpChildNode.remove();
          } else {
            getters.recursiveRemoveHtmlNodes(tmpChildNode, acceptTags);
          }
        }
      }
    };
  },
  getDisplaySecurityInfo: (state, getters, rootState, rootGetters) => {
    return (modelSheetData) => {
      let displaySecurityInfo = "";
      if (modelSheetData.length === 1) {
        const securityObj = modelSheetData[0];
        if (securityObj.security_code && securityObj.security_code != "-")
          displaySecurityInfo += securityObj.security_code + " | ";
        if (securityObj.security_name && securityObj.security_name != "-")
          displaySecurityInfo += securityObj.security_name + " | ";
        if (securityObj.sector_name && securityObj.sector_name != "-")
          displaySecurityInfo += securityObj.sector_name + " | ";
      } else if (modelSheetData.length > 1) {
        for (let i = 0; i < modelSheetData.length; i++) {
          const securityObj = modelSheetData[i];
          if (securityObj.security_code && securityObj.security_code != "-")
            displaySecurityInfo += securityObj.security_code + " | ";
        }
      }
      if (displaySecurityInfo) {
        return {
          displaySecurityInfo: displaySecurityInfo.slice(0, -2)
        };
      } else {
        return {
          displaySecurityInfo: ""
        };
      }
    };
  },
  getSecurityInfoForOxcap: () => {
    return (modelSheetData) => {
      let displaySecurityInfo = "";
      if (modelSheetData.length === 1) {
        const securityObj = modelSheetData[0];
        if (securityObj.security_name && securityObj.security_name != "-") {
          displaySecurityInfo = securityObj.security_name;
          return { displaySecurityInfo };
        }
      } else if (modelSheetData.length > 1) {
        for (let i = 0; i < modelSheetData.length; i++) {
          const securityObj = modelSheetData[i];
          if (securityObj.security_code && securityObj.security_code != "-")
            displaySecurityInfo += securityObj.security_code + " | ";
        }
      }
      if (displaySecurityInfo) {
        return {
          displaySecurityInfo: displaySecurityInfo.slice(0, -2)
        };
      } else {
        return {
          displaySecurityInfo: ""
        };
      }
    };
  },
  getDisplaySecurityNames: () => {
    return (modelSheetData) => {
      let displaySecurityInfo = "";
      if (modelSheetData.length === 1) {
        const securityObj = modelSheetData[0];

        if (securityObj.security_name)
          displaySecurityInfo += securityObj.security_name;
      } else if (modelSheetData.length > 1) {
        for (let i = 0; i < modelSheetData.length; i++) {
          const securityObj = modelSheetData[i];
          if (securityObj.security_name)
            displaySecurityInfo += securityObj.security_name + ", ";
        }
      }
      if (displaySecurityInfo) {
        return {
          displaySecurityNames: displaySecurityInfo.slice(0, -2)
        };
      } else {
        return {
          displaySecurityNames: ""
        };
      }
    };
  },
  convertImageSizeToEntity: () => {
    return (content) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = content;

      const allImageArray = tempDiv.querySelectorAll("img") || [];

      for (let i = 0; i < allImageArray.length; i++) {
        const tmpEle = allImageArray[i];

        if (tmpEle.getAttribute("data-original-width")) {
          tmpEle.width = tmpEle.getAttribute("data-original-width");
        }
        if (tmpEle.getAttribute("data-original-height")) {
          tmpEle.height = tmpEle.getAttribute("data-original-height");
        }
      }
      return tempDiv.innerHTML;
    };
  },
  convertImageSizeToReport: () => {
    return (data) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = data.content;

      const allImageArray = tempDiv.querySelectorAll("img") || [];

      const containerWidth = data.width - 20;
      const reportContainerWidth = 726;
      const tmpRatio = reportContainerWidth / containerWidth;

      //for (const tmpEle of allImageArray) {
      for (let i = 0; i < allImageArray.length; i++) {
        const tmpEle = allImageArray[i];
        /*
      if (!tmpEle.width) tmpEle.width = tmpEle.offsetWidth;
      if (!tmpEle.height) tmpEle.height = tmpEle.offsetHeight;
      */
        // Cloned nodes does not have offsetHeight and offsetWidth
        if (!tmpEle.width || !tmpEle.height) continue;
        // because the email template has wider image, so fix the width size
        const originalWidth = tmpEle.width;
        const originalHeight = tmpEle.height;
        tmpEle.width = (originalWidth * tmpRatio).toFixed(0);
        tmpEle.height = (originalHeight * tmpRatio).toFixed(0);
        tmpEle.setAttribute("data-original-width", originalWidth);
        tmpEle.setAttribute("data-original-height", originalHeight);
      }
      return tempDiv.innerHTML;
    };
  },
  convertImageToEmail: () => {
    return (data) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = data.content;

      const allMultipleContainers =
        tempDiv.querySelectorAll(".image-multiple-container") || [];

      // const containerWidth = 530;
      //
      // //for (const tmpEle of allImageArray) {
      // for (let i = 0; i < allMultipleContainers.length; i++) {
      //   const tmpContainer = allMultipleContainers[i];
      //   const tdCount = tmpContainer.querySelectorAll("td").length;
      //   for (var tdEl of tmpContainer.querySelectorAll("td")) {
      //     if (!tdEl.querySelector("img")) {
      //       tdEl.innerHTML = "";
      //     }
      //     tdEl.style.minWidth = (containerWidth / tdCount).toFixed(0) + "px";
      //     tdEl.style.textAlign = "center";
      //   }
      // }
      for (const tmpContainer of allMultipleContainers) {
        const multipleImageTable = tmpContainer.querySelector(
          ".image-multiple-table"
        );
        if (multipleImageTable) {
          multipleImageTable.style.width = "100%";
          const multipleImageTableTd =
            multipleImageTable.querySelectorAll("td");
          const tdCount = multipleImageTableTd.length;
          const tdWidth = (100 / tdCount).toFixed(2);
          for (var tdEl of multipleImageTableTd) {
            if (!tdEl.querySelector("img")) {
              tdEl.innerHTML = "";
            }
            tdEl.style.width = `${tdWidth}%`;
            tdEl.style.textAlign = "center";
            tdEl.style.verticalAlign = "middle";
          }
        }
      }
      return tempDiv.innerHTML;
    };
  },
  convertImageToEntity: () => {
    return (content) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = content;

      const allMultipleContainers =
        tempDiv.querySelectorAll(".image-multiple-container") || [];

      //for (const tmpEle of allImageArray) {
      for (let i = 0; i < allMultipleContainers.length; i++) {
        const tmpContainer = allMultipleContainers[i];
        for (var tdEl of tmpContainer.querySelectorAll("td")) {
          if (!tdEl.querySelector("img")) {
            tdEl.innerHTML = "+";
            tdEl.style.minWidth = "initial";
          }
        }
      }
      return tempDiv.innerHTML;
    };
  },
  shouldShowSecuitiesPricingHistoryChartSection: (state, getters, rootState) =>
    rootState.change.currentChangeDetail &&
    rootState.change.currentChangeDetail.change_report_template_settings &&
    rootState.change.currentChangeDetail.change_report_template_settings[
      state.OTHER_DISCLAIMER
    ] &&
    rootState.change.currentChangeDetail.change_report_template_settings[
      state.OTHER_DISCLAIMER
    ].includes("investment-terms-show")
};
export const mutations = {
  setReportVersionList(state, newList) {
    Vue.set(state, "previousReportVersionLinks", newList);
  },
  setUndoLevel(state, { editorId, hasUndo }) {
    Vue.set(state.hasUndo, editorId, hasUndo);
  },
  setRedoLevel(state, { editorId, hasRedo }) {
    Vue.set(state, "hasRedo", editorId, hasRedo);
  },
  setTrackChangesStatus(state, trueOrFalse) {
    Vue.set(state, "isOnTrackChanges", trueOrFalse);
  }
};
export const actions = {
  remoteGetPreviousReportVersions(
    { rootState, commit },
    { change_id, security_report_id }
  ) {
    return this.$axios
      .$get(
        `v1/change/entity/${rootState.user.selectedShownEntity}/report/${security_report_id}/versions`
      )
      .then((r) => {
        commit("setReportVersionList", r);
        return {
          action: "accept"
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e
        };
      });
  },

  async getFirstPageContentForEmail(
    { dispatch, getters, rootState, rootGetters },
    allNodes
  ) {
    if (!allNodes || allNodes.length === 0) return false;
    const copyNode = allNodes[0].cloneNode(true);
    //remove unused nodes
    const removeNodeArray =
      copyNode.querySelectorAll(
        ".ver-resize-bar, .hori-resize-bar, .container-handle, .tracking-toolbar, .comment-icon"
      ) || [];
    //for (const tmpEle of removeNodeArray) {
    for (let i = 0; i < removeNodeArray.length; i++) {
      const tmpEle = removeNodeArray[i];
      tmpEle.parentNode.removeChild(tmpEle);
    }
    // remove multiple br breaks -- the following does not work in firefox https://caniuse.com/css-has
    let brEle;
    // check if there is a p br combo
    while (
      (brEle = copyNode.querySelector(
        "p:has(br:only-child):not(.multiple-br-checked)"
      ))
    ) {
      let nextSiblingEle = brEle.nextElementSibling;
      let checkNextSibling = true;
      while (nextSiblingEle && checkNextSibling) {
        if (
          nextSiblingEle.tagName === "P" &&
          nextSiblingEle.querySelector("br:only-child")
        ) {
          // if the next sibling fits the p br combo
          nextSiblingEle.parentNode.removeChild(nextSiblingEle);
          nextSiblingEle = brEle.nextElementSibling;
        } else {
          checkNextSibling = false;
        }
      }
      // if it is check if ig has a p br sibling
      brEle.classList.add("multiple-br-checked");
    }
    // remove overflow class that stope alignment of elemnt on outlook for overlow images Ref #6830
    const allOverFlowEle = copyNode.querySelectorAll(".hidden-overflow");
    for (let i = 0; i < allOverFlowEle.length; i++) {
      const currentEle = allOverFlowEle[i];
      currentEle.classList.remove("hidden-overflow");
    }
    //add width and height for images
    const allImageArray = copyNode.querySelectorAll("img") || [];
    const templateWidthMax = 560; // change from 600 because of padding
    const containerNode = allNodes[0];
    const containerWidth = containerNode.offsetWidth;
    let tmpRatio, tmpWidthRatio, tmpPercentage;
    //for (const tmpEle of allImageArray) {
    for (let i = 0; i < allImageArray.length; i++) {
      const tmpEle = allImageArray[i];
      // Cloned nodes does not have offsetHeight and offsetWidth
      if (!tmpEle.width || !tmpEle.height) continue;
      // because the email template has wider image, so fix the width size
      const tmpImgTable = tmpEle.closest("table");
      const tmpImgTD = tmpEle.closest("td");
      if (!tmpImgTable || !tmpImgTD) continue;
      const originalWidth = tmpEle.width;
      tmpRatio = tmpEle.width / tmpEle.height;
      // change width as a ratio as well to use the full width on email
      tmpWidthRatio = tmpEle.width / containerWidth;
      tmpEle.width = templateWidthMax * tmpWidthRatio;
      tmpEle.height = tmpEle.width / tmpRatio;
      tmpImgTable.style.width = "100%";
      tmpEle.style.display = "block";
      tmpEle.style.margin = "auto";
      // add one more row of padding because MSO does not like margin
      const footerRow = tmpImgTable.querySelector("tfoot");
      if (footerRow) {
        const newPaddingRow = document.createElement("tr");
        newPaddingRow.innerHTML = '<td style="height: 10px;"></td>';
        footerRow.appendChild(newPaddingRow);
      }
      if (tmpImgTable.classList.contains("image-regular-table")) {
        // use a table to center the image
        const newEle = document.createElement("table");
        newEle.innerHTML = `<table align="center"><tr><td style="width: 13%"></td><td style="width: 74%;">${tmpImgTable.outerHTML}</td><td style="width: 13%"></td></tr></table>`;
        tmpImgTable.parentNode.replaceChild(newEle, tmpImgTable);
      }
      if (tmpImgTable.classList.contains("image-multiple-table")) {
        const tdElCount = tmpImgTable.querySelectorAll(
          "td.multiple-drag-n-drop"
        ).length;
        const tdElWidth = (containerWidth - 20) / tdElCount;
        tmpPercentage = ((originalWidth / tdElWidth) * 100).toFixed(0);
        const newEle = document.createElement("td");
        newEle.className = "multiple-drag-n-drop";
        let tdInsideHtml = tmpEle.outerHTML;
        if (tmpImgTD.querySelector("a")) {
          tdInsideHtml = tmpImgTD.querySelector("a").outerHTML;
        }
        if (tmpEle.style.float == "left") {
          newEle.innerHTML = `<table align="center" style="width: 100% !important"><tr><td class="mobile-full-width" style="width: ${tmpPercentage}%">${tdInsideHtml}</td><td class="mobile-none" style="width: ${
            100 - tmpPercentage
          }%;"></td></tr></table>`;
          tmpImgTD.parentNode.replaceChild(newEle, tmpImgTD);
        } else if (tmpEle.style.float == "right") {
          newEle.innerHTML = `<table align="center" style="width: 100% !important"><tr><td class="mobile-none" style="width: ${
            100 - tmpPercentage
          }%"></td><td class="mobile-full-width" style="width: ${tmpPercentage}%;">${tdInsideHtml}</td></tr></table>`;
          tmpImgTD.parentNode.replaceChild(newEle, tmpImgTD);
        } else {
          newEle.innerHTML = `<table align="center" style="width: 100% !important;"><tr><td class="mobile-none" style="width: ${
            (100 - tmpPercentage) / 2
          }%"></td><td class="mobile-full-width" style="width: ${tmpPercentage}%;">${tdInsideHtml}</td><td class="mobile-none" style="width: ${
            (100 - tmpPercentage) / 2
          }%"></td></tr></table>`;
          tmpImgTD.parentNode.replaceChild(newEle, tmpImgTD);
        }
      }
      if (tmpImgTable.classList.contains("image-sidebyside-table")) {
        // use a table to center the image
        const images = tmpImgTable.querySelectorAll("img");
        if (images.length > 0) {
          for (let i = 0; i < images.length; i++) {
            const image = images[i];
            if (image.width > 295) {
              const widthToHeightRatio = image.width / image.height;
              image.width = 295;
              image.height = Math.floor(image.width / widthToHeightRatio);
            }
          }
        }
      }
      if (tmpImgTable.classList.contains("image-triple-table")) {
        const images = tmpImgTable.querySelectorAll("img");
        if (images.length > 0) {
          for (let i = 0; i < images.length; i++) {
            const image = images[i];
            if (image.width > 196) {
              const widthToHeightRatio = image.width / image.height;
              image.width = 196;
              image.height = Math.floor(image.width / widthToHeightRatio);
            }
          }
        }
      }
    }
    const allMultipleImageArray =
      copyNode.querySelectorAll(".image-multiple-table") || [];
    for (let i = 0; i < allMultipleImageArray.length; i++) {
      const multipleTableEl = allMultipleImageArray[i];
      const multipleContainerEl = multipleTableEl.closest(
        ".image-multiple-container"
      );
      multipleContainerEl.style["max-width"] = "100%";
      multipleTableEl.style["table-layout"] = "fixed";

      const tdElCount = multipleTableEl.querySelectorAll(
        "td.multiple-drag-n-drop"
      ).length;
      for (var multipleTD of multipleTableEl.querySelectorAll(
        "td.multiple-drag-n-drop"
      )) {
        const imageEl = multipleTD.querySelector("img");

        if (!imageEl) {
          const newEle = document.createElement("td");
          newEle.className = "multiple-drag-n-drop";
          const tmpPercent = (100 / tdElCount).toFixed(0);
          newEle.style.width = `${tmpPercent}%`;
          multipleTD.parentNode.replaceChild(newEle, multipleTD);
        } else {
          const newEle = document.createElement("td");
          newEle.className = "multiple-drag-n-drop";
          const tmpPercent = (100 / tdElCount).toFixed(0);
          newEle.style.width = `${tmpPercent}%`;
          newEle.innerHTML = multipleTD.innerHTML;
          multipleTD.parentNode.replaceChild(newEle, multipleTD);
        }
      }
    }
    // change the class so MSO dont change color, span.font-black-bold ... etc show up as cssed color otherwise
    const linkSpanNodes = copyNode.querySelectorAll("a span") || [];
    //for (const tmpEle of linkSpanNodes) {
    for (let i = 0; i < linkSpanNodes.length; i++) {
      const tmpEle = linkSpanNodes[i];
      if (tmpEle.classList.contains("font-black-bold")) {
        tmpEle.classList.remove("font-black-bold");
        tmpEle.classList.add("font-link-bold");
      }
      if (
        tmpEle.classList.contains("font-black-italic") ||
        tmpEle.classList.contains("font-grey-italic")
      ) {
        tmpEle.classList.remove("font-black-italic", "font-grey-italic");
        tmpEle.classList.add("font-link-italic");
      }
    }
    //===
    const pageTableSelector =
      'td.paste-table>table, .report-sidebar .default-table:not([class*="hidden"])>table';
    const checkTableNodes = copyNode.querySelectorAll(pageTableSelector);
    let imgCount = 1;
    const timeStamp = new Date().getTime();
    if (checkTableNodes.length > 0) {
      const cloneImageMatches = Array.from(checkTableNodes);
      // replace table with img first, turn it back to html first as html2canvas need html ele
      const imageMatches = Array.from(
        allNodes[0].querySelectorAll(pageTableSelector)
      );
      for (let i = 0; i < cloneImageMatches.length; i++) {
        const mainTableEle = imageMatches[i];
        let canvas;
        // let canvas = document.createElement("canvas");
        // canvas.width = mainTableEle.width;
        // canvas.height = mainTableEle.height;
        const currentScrollY =
          tinymce.activeEditor.contentDocument.scrollingElement.scrollTop;
        // const currentScrollX =
        // tinymce.activeEditor.contentDocument.scrollingElement.scrollLeft;
        if (
          mainTableEle.hasAttribute("edited") &&
          mainTableEle.getAttribute("edited") == "false"
        ) {
          const s3Details = await dispatch(
            "change/remoteGetReportImgPresignedUrl",
            {
              change_id: rootState.change.currentChangeDetail.change_id,
              security_report_id:
                rootState.change.currentChangeDetail.security_report_id,
              image_count: imgCount
            },
            { root: true }
          );
          const presignedFields = rootGetters[
            "app_store/objectNestedPropertyCheck"
          ](s3Details, ["report_image_upload_url", "fields"], false);
          const presignedUrl = rootGetters[
            "app_store/objectNestedPropertyCheck"
          ](s3Details, ["report_image_upload_url", "url"], false);
          if (presignedFields && presignedUrl && s3Details.report_image_cdn) {
            const imgEle = document.createElement("img");
            // calculate the width and height here
            const originalWidth = mainTableEle.offsetWidth;
            tmpRatio = mainTableEle.offsetWidth / mainTableEle.offsetHeight;
            tmpWidthRatio = mainTableEle.offsetWidth / containerWidth;
            imgEle.width = templateWidthMax * tmpWidthRatio;
            imgEle.height = imgEle.width / tmpRatio;
            // ====
            imgEle.style.display = "block";
            imgEle.style.margin = "auto";
            imgEle.src = s3Details.report_image_cdn + "?t=" + timeStamp;
            imgEle.classList.add("img-from-table");
            const cloneTableEle = cloneImageMatches[i];
            const parentNode = cloneTableEle.parentNode;
            parentNode.replaceChild(imgEle, cloneTableEle);
            const tmpRegularTableEle = imgEle.closest("table");
            // add one more row of padding because MSO does not like margin
            if (tmpRegularTableEle) {
              const footerRow = tmpRegularTableEle.querySelector("tfoot");
              if (footerRow) {
                const newPaddingRow = document.createElement("tr");
                newPaddingRow.innerHTML = '<td style="height: 10px;"></td>';
                footerRow.appendChild(newPaddingRow);
              }
              if (
                tmpRegularTableEle.classList.contains("table-regular-table")
              ) {
                // use a table to center the image
                tmpRegularTableEle.style.width = "100%";
                const newEle = document.createElement("table");
                newEle.innerHTML = `<table align="center" style="width: 100%"><tr><td style="width: 13%"></td><td style="width: 74%;">${tmpRegularTableEle.outerHTML}</td><td style="width: 13%"></td></tr></table>`;
                tmpRegularTableEle.parentNode.replaceChild(
                  newEle,
                  tmpRegularTableEle
                );
              }
            }
          }
        } else {
          try {
            canvas = await html2canvas(mainTableEle, {
              scale: 6,
              width: mainTableEle.offsetWidth,
              height: mainTableEle.offsetHeight,
              scrollY: currentScrollY ? -1 * currentScrollY : 0,
              // scrollX: currentScrollX ? -1 * currentScrollX : 0,
              scrollX: 0,
              letterRendering: true,
              logging: false,
              onclone: (cloneCanvasDoc) => {
                const cloneTable =
                  cloneCanvasDoc.querySelector("table.resizable");
                if (!cloneTable) return;
                cloneTable.classList.add("none-border");
              }
            });
            if (!canvas) continue;
          } catch (e) {
            console.error(e);
            continue;
          }
          const s3Details = await dispatch(
            "change/remoteGetReportImgPresignedUrl",
            {
              change_id: rootState.change.currentChangeDetail.change_id,
              security_report_id:
                rootState.change.currentChangeDetail.security_report_id,
              image_count: imgCount
            },
            { root: true }
          );
          const presignedFields = rootGetters[
            "app_store/objectNestedPropertyCheck"
          ](s3Details, ["report_image_upload_url", "fields"], false);
          const presignedUrl = rootGetters[
            "app_store/objectNestedPropertyCheck"
          ](s3Details, ["report_image_upload_url", "url"], false);
          if (
            presignedFields &&
            presignedUrl &&
            s3Details.report_image_cdn &&
            canvas
          ) {
            const blob = await dispatch("getCanvasBlob", canvas);
            const fileUploaded = await dispatch(
              "app_store/remoteUploadToS3PresignedUrl",
              {
                fullBlob: blob,
                presignedUrl,
                presignedFields,
                fileType: "image/png"
              },
              { root: true }
            );
            if (fileUploaded && fileUploaded.action === "accept") {
              // replace clone nodes not actual dom nodes
              const imgEle = document.createElement("img");
              // calculate the width and height here
              const originalWidth = mainTableEle.offsetWidth;
              tmpRatio = mainTableEle.offsetWidth / mainTableEle.offsetHeight;
              tmpWidthRatio = mainTableEle.offsetWidth / containerWidth;
              imgEle.width = templateWidthMax * tmpWidthRatio;
              imgEle.height = imgEle.width / tmpRatio;
              // ====
              imgEle.style.display = "block";
              imgEle.style.margin = "auto";
              imgEle.src = s3Details.report_image_cdn + "?t=" + timeStamp;
              imgEle.classList.add("img-from-table");
              const cloneTableEle = cloneImageMatches[i];
              const parentNode = cloneTableEle.parentNode;
              parentNode.replaceChild(imgEle, cloneTableEle);
              const tmpRegularTableEle = imgEle.closest("table");
              // add one more row of padding because MSO does not like margin
              if (tmpRegularTableEle) {
                const footerRow = tmpRegularTableEle.querySelector("tfoot");
                if (footerRow) {
                  const newPaddingRow = document.createElement("tr");
                  newPaddingRow.innerHTML = '<td style="height: 10px;"></td>';
                  footerRow.appendChild(newPaddingRow);
                }
                if (
                  tmpRegularTableEle.classList.contains("table-regular-table")
                ) {
                  // use a table to center the image
                  tmpRegularTableEle.style.width = "100%";
                  const newEle = document.createElement("table");
                  newEle.innerHTML = `<table align="center" style="width: 100%"><tr><td style="width: 13%"></td><td style="width: 74%;">${tmpRegularTableEle.outerHTML}</td><td style="width: 13%"></td></tr></table>`;
                  tmpRegularTableEle.parentNode.replaceChild(
                    newEle,
                    tmpRegularTableEle
                  );
                }
              }
            }
          }
        }

        imgCount++;
      }
    }
    // change indent to table with width for MSO compatibility NOTE do this as query selector as the class can be on diffrent tags
    const allIndentClassNodes =
      copyNode.querySelectorAll(".indent-1, .indent-2, .indent-3") || [];
    //for (const tmpEle of allIndentClassNodes) {
    for (let i = 0; i < allIndentClassNodes.length; i++) {
      const tmpEle = allIndentClassNodes[i];
      const newEle = document.createElement("div");
      let tmpWidth;
      if (tmpEle.classList.contains("indent-1")) {
        tmpWidth = "6%";
      } else if (tmpEle.classList.contains("indent-2")) {
        tmpWidth = "12%";
      } else {
        tmpWidth = "18%";
      }
      newEle.innerHTML = `<table class="${tmpEle.className}" align="center"><tr><td style="width: ${tmpWidth};"></td><td align="justify"><p>${tmpEle.innerHTML}</p></td></tr></table>`;
      tmpEle.parentNode.replaceChild(newEle, tmpEle);
    }
    // ====
    // change indent of bullet to table for MSO compatibility
    // ====
    // change indent of numbers to table MSO compatibility
    // ====
    const parserRule = [
      /* Takes these out for better styling
      {
        pattern: /<span class="font-title">(.*?)<\/span>/g,
        replacement: "<strong>$1</strong>",
      },
      {
        pattern: /<span class="font-heading">(.*?)<\/span>/g,
        replacement: "<strong>$1</strong>",
      },
      {
        pattern: /<span class="font-black-italic">(.*?)<\/span>/g,
        replacement: "<i>$1</i>",
      },
      {
        pattern: /<span class="font-grey-italic">(.*?)<\/span>/g,
        replacement: "<i>$1</i>",
      },
      {
        pattern: /<span class="font-grey-italic">(.*?)<\/span>/g,
        replacement: "<i>$1</i>",
      },
      {
        pattern: /<span class="font-black-bold">(.*?)<\/span>/g,
        replacement: "<strong>$1</strong>",
      },
      */
      /*
      {
        pattern: /<table?[^>](class="?[^>][image-stretch-table|image-regular-table|image-sidebyside-table|table-stretch-table|table-regular-table|table-sidebyside-table].*?")>(.*?)<\/table>/g,
        replacement: "<table $1 align='center'>$2</table>",
      },
      */
      /*
      {
        pattern: /<table(.*? class=".*? [image-stretch-table|image-regular-table|image-sidebyside-table|table-stretch-table|table-regular-table|table-sidebyside-table] .*?")>(.*?)<\/table>/g,
        replacement: "<table $1 align='center'>$2</table>",
      },
      */
      /* NOTE do this as query selector
      {
        pattern: /<p?[^>](class="?[^>]indent-1.*?").*?>(.*?)<\/p>/g,
        replacement: "<div><table $1 align='center'><tr><td style=\"width: 6%;\"></td><td><p>$2</p></td></tr></table></div>",
      },
      {
        pattern: /<p?[^>](class="?[^]indent-2.*?").*?>(.*?)<\/p>/g,
        replacement: "<div><table $1 align='center'><tr><td style=\"width: 12%;\"></td><td><p>$2</p></td></tr></table></div>",
      },
      {
        pattern: /<p?[^>](class="?[^>]indent-3.*?").*?>(.*?)<\/p>/g,
        replacement: "<div><table $1 align='center'><tr><td style=\"width: 18%;\"></td><td><p>$2</p></td></tr></table></div>",
      },
      */
      /*
      {
        pattern: /<\/ul>/g,
        replacement: '</ul><div style="display:none;">&nbsp;</div>',
      },
      */
    ];
    let tmpHtml = copyNode.innerHTML;
    parserRule.forEach(({ pattern, replacement }) => {
      tmpHtml = tmpHtml.replace(pattern, replacement);
    });

    //upload img to s3 and src from there
    // store things to array first as async await is messed up in callback, instead of replace
    const regexToCheck = new RegExp('<img [^>]*src="([^"]*)"[^>]*>', "g");
    const matchArray = [...tmpHtml.matchAll(regexToCheck)];
    //for (const match of matchArray) {
    for (let i = 0; i < matchArray.length; i++) {
      const match = matchArray[i];
      const tmpEle = match[0];
      const currentSrc = match[1];
      if (currentSrc.includes("ic_move_up_down.png")) continue;
      if (tmpEle.indexOf("img-from-table") > -1) continue;
      const { returnString, mimeType } =
        rootGetters["app_store/dataUriToBlob"](currentSrc);
      if (!returnString || !mimeType) continue;
      const s3Details = await dispatch(
        "change/remoteGetReportImgPresignedUrl",
        {
          change_id: rootState.change.currentChangeDetail.change_id,
          security_report_id:
            rootState.change.currentChangeDetail.security_report_id,
          image_count: imgCount
        },
        { root: true }
      );
      const presignedFields = rootGetters[
        "app_store/objectNestedPropertyCheck"
      ](s3Details, ["report_image_upload_url", "fields"], false);
      const presignedUrl = rootGetters["app_store/objectNestedPropertyCheck"](
        s3Details,
        ["report_image_upload_url", "url"],
        false
      );
      if (presignedFields && presignedUrl && s3Details.report_image_cdn) {
        const fileUploaded = await dispatch(
          "app_store/remoteUploadToS3PresignedUrl",
          {
            textToUpload: returnString,
            presignedUrl,
            presignedFields,
            fileType: mimeType
          },
          { root: true }
        );
        if (fileUploaded.action === "accept") {
          tmpHtml = tmpHtml.replace(
            currentSrc,
            s3Details.report_image_cdn + "?t=" + timeStamp
          );
        }
        imgCount++;
      }
    }
    if (getters.isEurozTemplate) {
      // euroz is adding signature which does not like sigle quote, replace it with apostrophe
      tmpHtml = tmpHtml.replace(/'/g, "&#8217;");
    }
    return tmpHtml;
  },
  async getCanvasBlob(ctx, canvas) {
    return new Promise(function (resolve, reject) {
      canvas.toBlob(function (blob) {
        resolve(blob);
      });
    });
  },

  async getReportDisclaimerForEmail({ getters, rootGetters }, allNodes) {
    // can include different disclaimers if need to inside entity_configs
    if (!allNodes || allNodes.length === 0) return false;
    let disclaimerClasses;
    if (getters.isMstAccessTemplate) {
      disclaimerClasses =
        rootGetters[
          "entity_configs/mst_access/CHANGE_REPORT_DISCLAIMER_FOR_EMAIL"
        ];
    }
    if (!disclaimerClasses || disclaimerClasses.size === 0) return false;

    const returnArray = [];
    const lineBreakRe = new RegExp("(<br>|</br>)", "gi");
    allNodes.forEach((tmpNodes) => {
      disclaimerClasses.forEach(({ section, classToSearch }) => {
        const foundDisclaimer = tmpNodes.querySelector(classToSearch);
        if (!foundDisclaimer) return;
        const copyNode = foundDisclaimer.cloneNode(true);
        const removeNodeArray =
          copyNode.querySelectorAll(
            ".ver-resize-bar, .hori-resize-bar, .container-handle, .tracking-toolbar, .comment-icon"
          ) || [];

        for (let i = 0; i < removeNodeArray.length; i++) {
          const tmpEle = removeNodeArray[i];
          tmpEle.parentNode.removeChild(tmpEle);
        }
        // add full stop if missing
        const allPNodes = copyNode.querySelectorAll("p");
        for (let i = 0; i < allPNodes.length; i++) {
          const tmpEle = allPNodes[i];
          const tmpText = tmpEle.innerText;
          if (tmpText.length > 0 && tmpText[tmpText.length - 1] !== ".") {
            tmpEle.innerText = `${tmpText}.`;
          }
          tmpEle.outerHTML = `<span>${tmpEle.innerHTML}&nbsp;</span>`.replace(
            lineBreakRe,
            ""
          );
        }
        returnArray.push({
          section,
          html: copyNode.innerHTML
        });
      });
    });
    if (returnArray.length > 0) return returnArray;
    else return false;
  },

  remoteSetUndoLevel({ commit }, { editorId, hasUndo }) {
    commit("setUndoLevel", {
      editorId: editorId,
      hasUndo: hasUndo
    });
  },

  remoteSetRedoLevel({ commit }, { editorId, hasRedo }) {
    commit("setRedoLevel", {
      editorId: editorId,
      hasRedo: hasRedo
    });
  },

  remoteSetTrackChangesStatus({ commit }, trueOrFalse) {
    commit("setTrackChangesStatus", trueOrFalse);
  }
};
